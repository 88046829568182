<paedml-search>
  <input ref="search" type="text" value="" data-role={role} placeholder="{adhoc ? 'Ad-hoc-Suchfilter' : 'Suchfilter'}" class={searchClass}>

  <script>
    import * as R from 'ramda'
    this.adhoc = opts.adhoc
    this.role = opts.adhoc ? 'search' : 'tagsinput'
    this.searchClass = opts.adhoc ? 'paedml-search' : ''

    this.on('mount', function() {
      if (!this.adhoc) $(this.refs.search).tagsinput();

      const self = this

      if (this.adhoc) {
        $(this.refs.search).on("keyup", function () {
          let searchString = $(self.refs.search).val();
            // searchString = searchString.replace(/ /g, '');
            
            self.opts.searchfunc(searchString)
        });
      } else {
        $(this.refs.search).on("change", function () {
            let searchString = $(self.refs.search).val();
            searchString = searchString.replace(/,/g, ' ');
            // searchString = R.join(' ', searchString.split(',').map(e => `"${e}"`))
            
            self.opts.searchfunc(searchString)
        });
      }

    })
    
  </script>
</paedml-search>
