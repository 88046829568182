import './paedml-modal-help.tag'
import './paedml-side-nav.tag'
import './paedml-header.tag'
import './paedml-footer.tag'
import './paedml-content.tag'

<paedml-app>
    <paedml-modal-help prefix="hilfe" />

    <!-- Side Navbar --> 
    <paedml-side-nav config={config} />

    <!-- Main Section -->
    <div class="page">
      <paedml-header title="{config.headerTitle}" config={config} />

      <section>
        <paedml-content config={config} />
      </section>

      <paedml-footer />
    </div>

    <script>
        document.title = this.config.pageTitle
    </script>
</paedml-app>
