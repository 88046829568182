import store from './store'

import riot from 'riot'
import route from 'riot-route'

import './util/options-mixin'
import riotReduxConnect from 'riot-redux-connect';

import pages from './config/pages'
import './config/external'

import './css/custom.css'
import './css/paedml.css'

import './tags/paedml-app.tag'
import './tags/paedml-login.tag'
import './tags/paedml-logout.tag'

import './tags/paedml-card-admin.tag'

// for debugging
import * as request from './action/request'
import Session from './store/session'
import {client} from './util/api-util'
import API from './api'

riotReduxConnect(riot, store);

let mt

const createPageRoute = (configName) => {
    const config = pages[configName]
    const mainUrl = '/' + config.route

    route(mainUrl + '..', () => {
        const current = route._.getPathFromBase()

        const paths = current.split('/')
        const subUrl = paths.length === 1 ? undefined : paths[1]

        if (store.getState().user.loggedIn) {
            if (mt) {
                if (mt[0].config === config) {
                    config.cards.forEach(card => {
                        $('#' + card.prefix + '-collapse').collapse('hide')
                    })

                    if (subUrl) {
                        const cards = config.cards.filter(card => card.route === subUrl)
                        if (cards !== undefined && cards.length === 1) {
                            $('#' + cards[0].prefix + '-collapse').collapse('show')
                        }
                    }
                } else {
                    mt.forEach(m => {
                        m.unmount(true)
                    })

                    mt = riot.mount('#paedml-main', 'paedml-app', {config, mainUrl});

                    config.cards.forEach(card => {
                        $('#' + card.prefix + '-collapse').collapse('hide')
                    })

                    if (subUrl) {
                        setTimeout(() => {
                            const cards = config.cards.filter(card => card.route === subUrl)
                            if (cards !== undefined && cards.length === 1) {
                                $('#' + cards[0].prefix + '-collapse').collapse('show')
                            }
                        }, 250)
                    }
                }
            } else {
                mt = riot.mount('#paedml-main', 'paedml-app', {config, mainUrl});
            }
        } else {
            route('/login?to=' + current);
        }
    })
}

document.addEventListener('DOMContentLoaded', () => {
    // just for development
    window.store = store
    window.request = request
    window.Session = Session
    window.client = client
    window.API = API

    // define login route
    route("/login..", function() {
        let {to} = route.query()
        if (to) {
            if (!to.startsWith('/')) to = '/' + to
        } else {
            to = '/dashboard/home'
        }
        mt = riot.mount('#paedml-main', 'paedml-login', { to });
    })

    // define logout route
    route("/logout..", function() {
        if (mt) {
            mt.forEach(m => {
                m.unmount(true)
            })
        }
        mt = riot.mount('#paedml-main', 'paedml-logout');
    })

    // main route
    route("/", function() {
        // currently working on ...
        route('/dashboard/home')
    })

    // create routes for all pages
    Object.keys(pages).forEach(pageName => {
        createPageRoute(pageName)
    })

    // not found
    route("/*..", function() {
        console.error('not found', route._.getPathFromBase())
    })

    route.start(true);
    store.subscribe(() => {
        if (!store.getState().user.loggedIn) {
            route('/login')
        }
    })
})
