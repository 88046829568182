import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'

<paedml-card-kalogs>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{active && selectedNone() && parent.startTimer()}">
      <span class="btn paedml {parent.session.timers.protokolle ? 're_sync' : ''}" onclick={parent.handleIntervalRefresh} id="{prefix}-rescan">
        <paedml-icon icon="aktualisieren" title="automatisch synchronisieren" type="zoom" />
      </span>
    </virtual>

    <virtual if="{selectedOneOrMore() && parent.clearTimer()}"></virtual>

  </paedml-card>

  <script>
    import {usingExternalConfig} from '../util/config-util'
    import * as R from 'ramda'
    import Session from '../store/session'
    import {createTimerTask, createTimerCanceler, createRefresher} from '../util/api-util'
    this.session = Session

    this.handleRefresh = e => {
      this.refs['card'].trigger('reloadTable')
    }

    this.handleIntervalRefresh = createRefresher(this, 'protokolle', usingExternalConfig("klassenarbeiten","refreshInterval", 30000))
    this.startTimer = createTimerTask('protokolle', this.handleIntervalRefresh)
    this.clearTimer = createTimerCanceler('protokolle')

  </script>
</paedml-card-kalogs>
