<paedml-modal-run-jobs>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 0}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true}
                >

    <yield to="fields">
      <div class="row">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">{parent.text}</span>
          <virtual if={patient.singular === 'Job'}>
            <p></p>
            <paedml-form-input name="redirect" placeholder="Nach dem Starten der Jobs zur 'Tasks'-Ansicht springen" fgclass="py-1"
              type="checkbox" revlut="jaNein"
              />
          </virtual>
        </div>
      </div>
    
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'

    this.prefix = opts.prefix
    this.data = { }
    this.api = this.opts.api
    this.patient = this.opts.patient || {singular: 'Job', plural: 'Jobs'}

    this.on('dataRequested', (selectedRows) => {
      this.data.redirect = false
      if (selectedRows.length === 1) {
        this.text = `Ausgewählte(r) ${this.patient.singular} "${selectedRows[0][this.opts.idtextcol]}" wird gestartet.`
      } else {
        this.text = `Die unten aufgeführten ${this.patient.plural} werden gestartet.`
        this.data.redirect = true
      }
    })

    this.on('hide', () => {
      this.text = ''
    })
    
    this.submitHandler = formData => {
      // console.debug('Jobs run', formData.ids)
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const runMessageSuccess = formatter(idLut, true)

      return this.api.run(formData.ids).then(rsp => {
        const succeeded = formData.ids
        // this.parent.trigger('reloadTable')
        this.parent.parent.opts.siblings.forEach(card => {
          card.tags['paedml-card'].trigger('reloadTable')
        })

        if (this.data.redirect) setTimeout(() => { 
          $('body').removeClass('modal-open')
          window.location.href = '/#jobverwaltung/tasks' 
        }, 500)

        return runMessageSuccess(succeeded)
      })
    }

    const formatter = patientFormatter(this.patient, 'gestartet')

  </script>
</paedml-modal-run-jobs>
