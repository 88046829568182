export const profilverwaltung = {
  headerTitle: 'Profilverwaltung',
  route: 'profilverwaltung',
  pageTitle: 'paedML - Profilverwaltung',
  related: [
    { route: 'benutzerverwaltung/lehrerinnen', menuTitle: 'Lehrer*innen verwalten' },
    { route: 'benutzerverwaltung/schuelerinnen', menuTitle: 'Schüler*innen verwalten' },
  ],
  cards: [
    {
      route: 'lehrerinnen',
      icon: 'far-id-card',
      endpoint: '/api/profileManagement/teachers',
      endpoints: {
      },
      preHooks: {
        list: 'profile_list_preHook'
      },
      tag: 'paedml-card-profil',
      category: 'Profilverwaltung',
      menuTitle: 'Lehrer*innen',
      pageTitle: 'paedML - Profilverwaltung-Lehrer*innen',
      name: 'proflul',
      contentTitle: 'Lehrer*innen',
      prefix: 'proflul',
      suffix: undefined,
      custom: {
        variant: 'teachers'
      },
      table: {
        columns: [
          { title: 'Id', name: 'id', col: 0, visible: false,printable: false, exportable: false  },
          { title: 'Name', name: 'name', col: 1, visible: false },
          { title: 'Name / Beschreibung', name: 'description', col: 2 },
          { title: 'Schulart', name: 'schoolTypeName', col: 3, filter: 'SchulartOverall', filtertitle: 'Schulart', filterType: 'regex' },
          { title: 'Schulart', name: 'schoolType', col: 4, visible: false, printable: false, exportable: false },
          { title: 'Plattform', name: 'platformName', col: 5, filter: 'Plattform', filterType: 'regex' },
          { title: 'Plattform', name: 'platform', col: 6, visible: false, printable: false, exportable: false },
          { title: 'Letzte Bearbeitung', name: 'dateEditedDP', col: 7, orderData: [7], searchable: false },
          { title: 'dateEdited', name: 'dateEdited', col: 8, visible: false, printable: false, exportable: false, searchable: false },
          { title: 'Zuletzt aktiv', name: 'dateActivatedDP', col: 9, orderData: [9], searchable: false },
          { title: 'dateActivated', name: 'dateActivated', col: 10, visible: false, printable: false, exportable: false, searchable: false },
          { title: 'Aktiv', name: 'isActive', col: 11 },
          { title: 'AProf', name: 'allocatedToAProf', col: 12 },
          { title: 'Prof', name: 'allocatedToProf', col: 13 },
          { title: 'overview', name: 'overview', group: 'simple', col: 14, visible: false, printable: false, exportable: false },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },

    {
      route: 'schuelerinnen',
      icon: 'far-id-card',
      endpoint: '/api/profileManagement/students',
      endpoints: {
      },
      preHooks: {
        list: 'profile_list_preHook'
      },
      tag: 'paedml-card-profil',
      category: 'Profilverwaltung',
      menuTitle: 'Schüler*innen',
      pageTitle: 'paedML - Profilverwaltung-Schüler*innen',
      name: 'profsus',
      contentTitle: 'Schüler*innen',
      prefix: 'profsus',
      suffix: undefined,
      custom: {
        variant: 'students'
      },
      table: {
        columns: [
          { title: 'Id', name: 'id', col: 0, visible: false,printable: false, exportable: false  },
          { title: 'Name', name: 'name', col: 1, visible: false },
          { title: 'Name / Beschreibung', name: 'description', col: 2 },
          { title: 'Schulart', name: 'schoolTypeName', col: 3, filter: 'SchulartOverall', filtertitle: 'Schulart', filterType: 'regex' },
          { title: 'Schulart', name: 'schoolType', col: 4, visible: false, printable: false, exportable: false },
          { title: 'Plattform', name: 'platformName', col: 5, filter: 'Plattform', filterType: 'regex' },
          { title: 'Plattform', name: 'platform', col: 6, visible: false, printable: false, exportable: false },
          { title: 'Letzte Bearbeitung', name: 'dateEditedDP', col: 7, orderData: [7], searchable: false },
          { title: 'dateEdited', name: 'dateEdited', col: 8, visible: false, printable: false, exportable: false, searchable: false },
          { title: 'Zuletzt aktiv', name: 'dateActivatedDP', col: 9, orderData: [9], searchable: false },
          { title: 'dateActivated', name: 'dateActivated', col: 10, visible: false, printable: false, exportable: false, searchable: false },
          { title: 'Aktiv', name: 'isActive', col: 11 },
          { title: 'AProf', name: 'allocatedToAProf', col: 12 },
          { title: 'Prof', name: 'allocatedToProf', col: 13 },
          { title: 'overview', name: 'overview', group: 'simple', col: 14, visible: false, printable: false, exportable: false },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },
    
    {
      route: 'klassenarbeiten',
      icon: 'far-id-card',
      endpoint: '/api/profileManagement/exams',
      endpoints: {
      },
      preHooks: {
        list: 'profile_list_preHook'
      },
      tag: 'paedml-card-profil',
      category: 'Profilverwaltung',
      menuTitle: 'Klassenarbeiten',
      pageTitle: 'paedML - Profilverwaltung-Klassenarbeiten',
      name: 'profexams',
      contentTitle: 'Klassenarbeiten',
      prefix: 'profexams',
      suffix: undefined,
      custom: {
        variant: 'exams'
      },
      table: {
        columns: [
          { title: 'Id', name: 'id', col: 0, visible: false,printable: false, exportable: false  },
          { title: 'Name', name: 'name', col: 1, visible: false },
          { title: 'Name / Beschreibung', name: 'description', col: 2 },
          { title: 'Schulart', name: 'schoolTypeName', col: 3, filter: 'SchulartOverall', filtertitle: 'Schulart', filterType: 'regex' },
          { title: 'Schulart', name: 'schoolType', col: 4, visible: false, printable: false, exportable: false },
          { title: 'Plattform', name: 'platformName', col: 5, filter: 'Plattform', filterType: 'regex' },
          { title: 'Plattform', name: 'platform', col: 6, visible: false, printable: false, exportable: false },
          { title: 'Letzte Bearbeitung', name: 'dateEditedDP', col: 7, orderData: [7], searchable: false },
          { title: 'dateEdited', name: 'dateEdited', col: 8, visible: false, printable: false, exportable: false, searchable: false },
          { title: 'Zuletzt aktiv', name: 'dateActivatedDP', col: 9, orderData: [9], searchable: false },
          { title: 'dateActivated', name: 'dateActivated', col: 10, visible: false, printable: false, exportable: false, searchable: false },
          { title: 'Aktiv', name: 'isActive', col: 11 },
          { title: 'AProf', name: 'allocatedToAProf', col: 12 },
          { title: 'Prof', name: 'allocatedToProf', col: 13 },
          { title: 'overview', name: 'overview', group: 'simple', col: 14, visible: false, printable: false, exportable: false },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },
    
  ]
}
