<paedml-modal-screenshot-computer>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={true}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 0}"
                submitfunc={submitHandler} patient={patient} large={large}
                okbutton="OK"
                >

    <yield to="fields">

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
        <p class="my-auto">Bildschirminhalt wird geladen...</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </div>
    
      <div show={!parent.loading} class="d-flex justify-content-center">
        <img id="screenshot" class="paedml-screenshot">
      </div>
  
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import { toTrueMap } from '../util/mapping-util'
    import { patientFormatter } from '../util/modal-util'
    import API from '../api'

    this.prefix = opts.prefix
    this.data = { }
    this.api = this.opts.api
    this.variant = opts.variant
    this.large = true
    this.loading = false
    
    this.on('update', () => {
      this.loading = false
    })

    this.refreshScreenshot = id => {
      this.loading = true
      const self = this

      $('#screenshot').prop('src',"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==")
    
      API.computer.screenshot(this.data.id).then(response =>
      {
        self.loading = false
        self.update()

        const statusCode = response[1].code
        if(statusCode === 200)
        {
          const image = response[0]
          
          var reader = new FileReader();
          reader.onloadend = function() {
            $('#screenshot').prop('src', reader.result)
          }
          reader.readAsDataURL(image);
        }
       
      });
    }

    this.on('rowMappingRequested', cols => {
      this.data.id = cols[0]
     
      this.refreshScreenshot(this.data.id)
    })

    this.on('hide', () => {
      $('#screenshot').prop('src',"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==")
    })

  </script>
</paedml-modal-screenshot-computer>
