import '../paedml-modal.tag'

<paedml-modal-software-ondemand>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true}>

    <yield to="fields">
      <div class="row">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">{parent.text}</span>
        </div>
      </div>
      <div>
        <div class="mt-2 mr-1 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">{parent.infoText}</strong>
          </span>
        </div>
      </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import { patientFormatter } from '../../util/modal-util'
    import API from '../../api'

    this.prefix = opts.prefix
    this.data = { }
    this.patient = this.opts.patient || {singular: 'PC', plural: 'PCs'}
    this.infoText = ''
    
    this.on('dataRequested', (selectedRows) => {
      if (selectedRows.length === 1) {
        this.text = `Die Softwarepakete werden auf dem ${this.patient.singular} "${selectedRows[0][this.opts.idtextcol]}" sofort installiert.`
        this.infoText = 'Der Computer muss eingeschaltet sein, damit die Installation direkt ausgeführt werden kann. Ansonsten wird die Software beim nächstem Start des Computers installiert.'
      } else {
        this.text = `Die Softwarepakete werden auf den unten aufgeführten ${this.patient.plural} sofort installiert.`
        this.infoText = 'Die Computer müssen eingeschaltet sein, damit die Installation direkt ausgeführt werden kann. Ansonsten wird die Software beim nächstem Start des Computers installiert.'
      }
    })

    this.on('hide', () => {
      this.text = ''
      this.infoText = ''
    })
    
    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const runMessageSuccess = formatter(idLut, true)
      return API.software.computers.ondemand(formData.ids).then(rsp => {
        const succeeded = formData.ids
        this.parent.parent.opts.siblings.forEach(card => {
          card.tags['paedml-card'].trigger('reloadTable')
        })

        return runMessageSuccess(succeeded)
      })
    }

    const formatter = patientFormatter(this.patient, 'installiert')

  </script>
</paedml-modal-software-ondemand>
