<paedml-modal-fix-perms-projekte>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname='Korrekturen durchführen'
                selectedrowsfunc={opts.selectedrowsfunc}
                submitfunc={submitHandler} patient={patient}
                idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                cancelbutton={true} ref="modal"
                okcondition={checkDeleteHomeDirs}
                >

    <yield to="fields">
        <div class="custom-control">
        <label class="text-danger font-weight-bold" for="{prefix}-move-long">Lange Dateinamen korrigieren</label>
        <div class="col text-danger">
            Alle Dateien mit langen Namen werden aus dem Projekt-Verzeichnis nach D:\ZuLang auf dem Server SP01 verschoben.
          </br></br>
            Auf diesen Ordner haben nur Systemadministratoren Zugriff.
          <paedml-form-input name="moveLongDirsOK" placeholder="Ich möchte die Dateien verschieben"
            type="checkbox" revlut="jaNein" handlechange={parent.updateMoveLongDirsOK}
          />
        </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../../util/mapping-util'
    import {patientFormatterFuture, multiSubmitHandler} from '../../util/modal-util'

    this.prefix = opts.prefix
    this.api = this.opts.api
    this.data = {
      moveLongDirsOK: false
    }
    
    const patientSupplier = () => {
      return {singular: 'Bereinigung des Tauschverzeichnis', plural: 'Bereinigung der Tauschverzeichnisse', for: 'für'};
    }
    
    const opFuncSupplier = () =>{
      return this.api.moveLongFiles;
    }

    this.patient = {singular: 'Projekt', plural: 'Projekte'}

    this.checkDeleteHomeDirs = () => {
      return this.data.moveLongDirsOK;
    } 

    this.updateMoveLongDirsOK = (val) => {
      this.data.moveLongDirsOK = val;
    }

    this.updateModalButton = () => {
      this.refs.modal.trigger('updateButton', "Dateien verschieben", "danger")
    }

    this.on('dataRequested', (selectedRows) => {
      this.data = {
        moveLongDirsOK: false
      }
    })

    this.on('validate', () => {
      this.updateModalButton()
    })

    const formatter = patientFormatterFuture(patientSupplier, 'durchgeführt')

    this.submitHandler = multiSubmitHandler(this, opFuncSupplier, formatter)

  </script>
</paedml-modal-fix-perms-projekte>
