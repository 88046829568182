import './paedml-icon.tag'

<paedml-tiles>
  <div class="paedml-tiles-container row">
    
      <div class="paedml-tile-container col-sm-6 col-md-4 col-xl-3" each={tile in entries()}>
        <div class="paedml-tile" onclick={select} id="{prefix}-{tile.id}">
          <div class="paedml-tile-header paedml-tile-header-{tile.ncategory}">
            <span>{tile.category}</span>
            <paedml-icon if={tile.favorite} simple="yellow" icon="fas-star" style="float: right;"></paedml-icon>
          </div>
          <div class="paedml-tile-body">
            <a href="#{tile.url}" if={!tile.selected}><paedml-icon icon="far-share-square" title="{tile.ncategory}/{tile.contentTitle}"></paedml-icon></a>
            <span>{tile.contentTitle}</span>
          </div>
        </div>
      </div>
    
    
    <div class="col-1 my-sizer-element"></div>
  </div>

    <script>
      import { getAPIForCard, createDataTablesCallback } from '../util/api-util'
      import API from '../api'
      import * as R from 'ramda'

      this.requiresOpt('config');
      this.prefix = this.config.prefix;
      
      const tableConfig = this.config.table
      if (! tableConfig) throw Error("Unknown tableConfig '" + this.prefix + "'");

      this.columns = tableConfig.columns;


      this.selectedRows = []
      this.totalRows = -1;

      this.tiles = []
      this.terms = ''

      const includes = (term, content) => R.includes(term, R.toLower(content))
      const includesAny = (terms, content) => {
        for(i=0; i < terms.length; ++i) {
          if (includes(terms[i], content)) return true
        }
        return false
      }

      this.entries = () => {
        if (this.terms === undefined || this.terms == "") return this.tiles

        const terms = R.map(R.toLower, R.split(' ',this.terms))
        return this.tiles.filter(tile => {
          return includesAny(terms, tile['category']) || includesAny(terms, tile['contentTitle'])
        })
      }

      const toArray = e => [e.id, e.pseudoId, e.url, e.category, e.contentTitle, e.favorite]
      const toObj = e => ({
              id: e[0],
              pseudoId: e[1],
              url: e[2],
              category: e[3],
              ncategory: e[3].replace(/[.]+/,''),
              contentTitle: e[4],
              favorite: e[5],
              selected: false,
            })

      this.select = e => {
        const {tile} = e.item
        tile.selected = !tile.selected

        this.selectedRows = this.tiles.filter(e => e.selected).map(toArray)

        $(`#${this.prefix}-${tile.id}`).toggleClass('selected')

        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      }

      this.fetchTiles = () => {
        API[this.prefix].list().then(rsp => {
          const [list, status] = rsp
          const tiles = []
          list.forEach(e => {
            tiles.push(toObj(e))
          })
          this.tiles = R.sortWith([
            R.ascend(R.prop('category')),
          ])(tiles)
          this.totalRows = tiles.length
          this.parent.trigger('tableLoaded', { totalRows: this.totalRows})
        })
      }

      this.on('mount', function() {
        this.fetchTiles()
        const self = this
      });

      this.on('update', () => {
        setTimeout(() => {
          $('input', this.parent.tags['paedml-search'].root).focus()
        }, 500)
      })

      this.on('reload', () => {
        this.fetchTiles()
      })

      // triggered via search field
      this.on('search', function(term) {
        this.terms = term
        this.update()
      })

      // triggered via filter dropdown
      this.on('setFilter', function(colname, value) {
        const colIndex = R.findIndex(R.propEq('filter', colname))(this.columns);

        this.datatable.columns(colIndex).search(value).draw();
      })

      this.on('selectAll', function() {
        this.selectedRows = this.tiles.map(tile => {
          tile.selected = true
          $(`#${this.prefix}-${tile.id}`).addClass('selected')
          return toArray(tile)
        })
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectPage', function() {
        this.selectedRows = this.tiles.map(tile => {
          tile.selected = true
          $(`#${this.prefix}-${tile.id}`).addClass('selected')
          return toArray(tile)
        })
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectFiltered', function() {
        this.entries().forEach(tile => {
          tile.selected = true
          $(`#${this.prefix}-${tile.id}`).addClass('selected')
        })
        this.selectedRows = this.tiles.filter(e => e.selected).map(toArray)

        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectNothing', function() {
        this.selectedRows = []
        this.tiles.forEach(tile => {
          tile.selected = false
          $(`#${this.prefix}-${tile.id}`).removeClass('selected')
        })
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

    </script>
</paedml-tiles>
