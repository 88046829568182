import '../paedml-modal.tag'
import '../paedml-form-input.tag'

<paedml-modal-info-software>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="name" col="1" label="Name" readonly />
          </div>
          <div class="col-sm-12">
            <paedml-form-input name="productId" col="2" label="Produkt ID" readonly />
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label>Beschreibung</label>
              <textarea class="form-control" name="description" readonly>{parent.description}</textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
          <div class="form-group">
              <label>Hinweis</label>
              <textarea class="form-control" name="message" readonly>{parent.message}</textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="paedml-input-group">
        <div class="pb-1">
          Standardaktionen:
        </div>
        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="defaultInstallActionDP" col="13" label="Install" readonly />
            </div>
            <div class="col-sm-4">
              <paedml-form-input name="defaultUpdateActionDP" col="15" label="Softwareupdate" readonly />
            </div>
            <div class="col-sm-4">
              <paedml-form-input name="defaultUninstallActionDP" col="17" label="Uninstall" readonly />
            </div>
        </div>
      </div>
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="productVersion" col="5" label="Softwareversion" readonly />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="packageVersion" col="6" label="OPSI-Paketversion" readonly />
          </div>
        </div>
      </div>
    </yield>
  </paedml-modal>
    
  <script>
    import {autoMapRowToData} from '../../util/modal-util'

    this.opname = this.opts.opname
    this.data = {}
    this.actions = []
    this.description = ''
    this.message = ''

    this.on('rowMappingRequested', cols => {
      autoMapRowToData(this, cols)
      this.description = cols[9]
      this.message = cols[18]
    })

    this.submitHandler = formData => {
      return Promise.resolve('OK')
    }
      
    </script>
</paedml-modal-info-software>
