import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-createoredit-admin>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opname} edit={edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="userName" col="1" label="Benutzername"
              append="-{parent.userSuffix}" readonly={parent.edit}
              required data-msg="Bitte geben Sie einen Benutzernamen ein"
              data-rule-username data-msg-username="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende."
              handlechange={parent.handleUserNameChange} changemode="keyup"
              verifychange={parent.verifyUsernameChanged}
              data-rule-check="userName" data-msg-check="Benutzername bereits vorhanden!"
              maxlength="19" data-rule-maxlength="11" data-msg-maxlength="Der Benutzername ohne Suffix darf nicht mehr als 11 Zeichen enthalten!" 
              minlength="2" data-rule-minlength="2" data-msg-minlength="Der Benutzername ohne Suffix muss mindestens 2 Zeichen enthalten!" 
            />
            <svg if={parent.isChecking} class="paedml-icon-overlay suffix fullrotation">
              <use xlink:href="#far-question-circle" />
            </svg>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="surname" col="2" label="Nachname"
              data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Nachnamen ein."
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="givenName" col="3" label="Vorname"
              data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Vornamen ein."
            />
          </div>
        </div>
  
      </div>

      <div class="paedml-input-group" if={!parent.edit}>
        <paedml-form-input-pw if={!parent.edit}
          required uselabel={true}
          disablepasswordpolicy={true}
          minLength={parent.minPwLength}
          passwordpolicy={parent.passwordPolicy} />
      </div>

      <div class="paedml-input-group" if={parent.isSchoolTypeBound()}>
        <paedml-form-input if={parent.isSchoolTypeBound()}
          type="select" multiple
          name="schoolTypes" col="4" revlut="schularten" placeholder="Schulart(en)"
          required data-msg="Bitte wählen Sie mindestens eine Schulart aus!" 
          values={parent.session.map.schularten}
        />
        <paedml-form-input if={parent.isRoleBound()}
          type="select" multiple
          name="roles" col="5" revlut="rollen" placeholder="Rolle(n)"
          required data-msg="Bitte wählen Sie mindestens eine Rolle aus!" 
          values={parent.session.map.rollen}
        />
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import {userNameAvailable, clearUserNameAvailabilityCtx, handleSubmitCreateOrEditUser} from '../util/api-util'
      import API from '../api'
      import * as R from 'ramda'

      this.session = Session
      this.opname = this.opts.opname
      this.userSuffix = this.opts.usersuffix
      this.minPwLength = Session.settings[0]['adminPasswordLength'];
      this.edit = this.opts.edit ? true : false
      this.passwordPolicy = 1
      this.data = {
        passwordPolicy: this.passwordPolicy
      }
      this.api = this.opts.api

      this.isSchoolTypeBound = () => this.userSuffix === 'radm' || this.userSuffix === 'sadm' // || this.userSuffix === 'fadm'
      this.isRoleBound = () => this.userSuffix === 'radm'

      this.userNameAvailable = userNameAvailable(this, 1000)
      this.lastUserName = this.data.userName
      
      this.handleUserNameChange = (val) => {
        this.lastUserName = val
        
        if (!val) {
          this.isChecking = false
          this.resolved = true
          this.update()
          return
        }

        $('input[name=userName]', this.root).data('userName', true)
        const userName = val + '-' + this.userSuffix
        this.lastUserName = userName
        if (/^[A-Za-z0-9][-._A-Za-z0-9]{0,16}[A-Za-z0-9]$|^[A-Za-z0-9]$/.test(val)) {
          this.isChecking = true
          this.userNameAvailable(userName)
        }
      }

      this.verifyUsernameChanged = (userName) => {
        return this.lastUserName !== userName
      }

      this.on('dataRequested', () => {
        this.data.passwordPolicy = this.passwordPolicy
        clearUserNameAvailabilityCtx(this)
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
      })

      this.submitHandler = handleSubmitCreateOrEditUser(this, "Benutzer")
    </script>
</paedml-modal-createoredit-admin>
