export const meins = {
  headerTitle: 'Meine...',
  route: 'meine',
  pageTitle: 'paedML - Meine...',
  related: [
    { route: 'raumverwaltung', menuTitle: 'Raumverwaltung' },
    { route: 'klassenarbeiten/klassenarbeiten', menuTitle: 'Klassenarbeiten' },
    { route: 'firewallverwaltung/projekte', menuTitle: 'Projekt-Firewall' },
  ],
  cards: [
    {
      route: 'klassen',
      icon: 'klasse',
      endpoint: '/api/school/schoolClasses/my',
      endpoints: {
      },
      preHooks: {
        list: 'klassen_list_preHook'
      },
      tag: 'paedml-card-klassen',
      category: 'Meine...',
      menuTitle: 'Klassen verwalten',
      pageTitle: 'paedML - Meine Klassen',
      name: 'meineklassen',
      contentTitle: 'Klassen',
      prefix: 'meineklassen',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Schulart', name: 'schoolType', col: 1, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'name', col: 2 },
          { title: 'Schuljahr', name: 'currentSchoolYear', col: 3, priority: 9, searchable: false },
          { title: 'Internet sperren', name: 'isInternetLocked', col: 4, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Tauschlaufwerk', name: 'sharedFolderPath', col: 5, visible: false, searchable: false, printable: false },
          { title: 'Zugriff Tauschlaufwerk', name: 'sharedFolderAccessLevel', col: 6, searchable: false, printable: false, exportable: false },
          { title: 'Klassenbezeichnung', name: 'klassenbezeichnung', col: 7, visible: false, searchable: false, printable: false },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 7, 'asc' ]]
        }
      }
    },

    {
      route: 'schuelerinnen',
      icon: 'schueler',
      endpoint: '/api/students/my',
      endpoints: {
        password: '/api/students/password',
      },
      preHooks: {
        list: 'schueler_list_preHook'
      },
      tag: 'paedml-card-schueler',
      category: 'Meine...',
      menuTitle: 'Schüler*innen verwalten',
      pageTitle: 'paedML - Meine Schüler*innen',
      name: 'meineschueler',
      contentTitle: 'Schüler*innen',
      prefix: 'meineschueler',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart', name: 'schoolType', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 5, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 6, searchable: false },
          { title: 'Internet sperren', name: 'isInternetLocked', col: 7, exportable: false, printable: false, searchable: false },
          { title: 'Ist deaktiviert', name: 'isDeactivated', col: 8, exportable: false, printable: false, searchable: false },
          { title: 'Homelaufwerk', name: 'homeDirectory', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'E-Mail', name: 'email', col: 10, priority:2, exportable: false, printable: false },
          { title: 'Schüler ID', name: 'externalIdentifier', col: 11, priority:1, visible: false, exportable: false, printable: false },
          { title: 'Klassenbezeichnung', name: 'klassenbezeichnung', col: 12, visible: false, searchable: false, printable: false },
          { title: 'Klassenbezeichnung2', name: 'klassenbezeichnung2', col: 13, visible: false, searchable: false, printable: false, exportable: false }
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 12, 'asc' ], [ 2, 'asc' ], [ 3, 'asc'] ],
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'ausgeteilte-dateien',
      icon: 'fas-file-export',
      endpoint: '/api/shareFiles',
      endpoints: {
      },
      preHooks: {
        list: 'handout_list_preHook'
      },
      tag: 'paedml-card-handout',
      category: 'Meine...',
      menuTitle: 'Ausgeteilte Dateien verwalten',
      pageTitle: 'paedML - Meine ausgeteilten Dateien',
      name: 'handout',
      contentTitle: 'Ausgeteilte Dateien',
      prefix: 'handout',
      suffix: undefined,
      custom: {
        variant: 'handout'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Ausgeteilt am', name: 'createdUF', col: 2, orderData: [4] },
          { title: 'created', name: 'created', col: 3, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Ausgeteilt von', name: 'initiatorDP', className: 'paedml-nowrap', col: 4, priority: 7 },
          { title: 'initiator', name: 'initiator', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'usersDP', className: 'paedml-nowrap', col: 6, visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Teilnehmer', name: 'usersP', col: 7, visible: false, searchable: true, printable: true, exportable: true },
          { title: 'users', name: 'users', col: 8, visible: false, searchable: false, printable: false, exportable: false  },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },

    {
      route: 'projekte',
      icon: 'fas-project-diagram',
      endpoint: '/api/projects',
      endpoints: {
      },
      preHooks: {
        list: 'projects_list_preHook'
      },
      tag: 'paedml-card-projekte',
      category: 'Meine...',
      menuTitle: 'Projekte verwalten',
      pageTitle: 'paedML - Meine Projekte',
      name: 'projekte',
      contentTitle: 'Projekte',
      prefix: 'projekte',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Beschreibung', name: 'description', col: 2, priority: 6 },
          { title: 'Leitung', name: 'leadersDP', className: 'paedml-nowrap', col: 3, visible: true, searchable: false, printable: false, exportable: false, priority: 8  },
          { title: 'Leitung', name: 'leadersP', col: 4, visible: false, searchable: true, printable: true, exportable: true },
          { title: 'leaders', name: 'leaders', col: 5, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Mitglieder', name: 'membersDP', className: 'paedml-nowrap', col: 6, priority: 8, visible: true, searchable: false, printable: false, exportable: false },
          { title: 'Mitglieder', name: 'membersP', col: 7, visible: false, searchable: true, printable: true, exportable: true },
          { title: 'members', name: 'members', col: 8, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Tauschlaufwerk', name: 'sharedFolderPath', col: 9, visible: false, searchable: false, printable: false },
          { title: 'Zugriff Tauschlaufwerk', name: 'sharedFolderAccessLevel', col: 10, priority: 7 },
          { title: 'numMembers', name: 'numMembers', col: 11, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Ablaufdatum', name: 'expiryDateDP', col: 12, visible: true, searchable: false, printable: true, exportable: true },
          { title: 'expiryDate', name: 'expiryDate', col: 13, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Ablaufdatum aktiv', name: 'expiryDateActive', col: 14, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'expiryDateDP1', name: 'expiryDateDP1', col: 15, visible: false, searchable: false, printable: false, exportable: true },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },

    {
      route: 'alle-schuelerinnen',
      icon: 'fas-users',
      endpoint: '/api/students',
      endpoints: {
        password: '/api/students/password',
      },
      preHooks: {
        list: 'schueler_list_preHook'
      },
      tag: 'paedml-card-schueler',
      category: 'Alle',
      menuTitle: 'Schüler*innen',
      pageTitle: 'paedML - Alle Schüler*innen',
      name: 'alleschueler',
      contentTitle: 'Alle Schüler*innen',
      prefix: 'alleschueler',
      suffix: undefined,
      hideInDashboard: true,
      custom: {
        disablealle : true
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart', name: 'schoolType', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 5, filter: 'Klasse', dynamicFilter: 'klassen', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 6, searchable: false },
          { title: 'Internet sperren', name: 'isInternetLocked', col: 7, exportable: false, printable: false, searchable: false },
          { title: 'Ist deaktiviert', name: 'isDeactivated', col: 8, exportable: false, printable: false, searchable: false },
          { title: 'Homelaufwerk', name: 'homeDirectory', col: 9, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'E-Mail', name: 'email', col: 10, priority:2, exportable: false, printable: false },
          { title: 'Schüler ID', name: 'externalIdentifier', col: 11, priority:1, visible: false, exportable: false, printable: false },
          { title: 'Klassenbezeichnung', name: 'klassenbezeichnung', col: 12, visible: false, searchable: false, printable: false },
          { title: 'Klassenbezeichnung2', name: 'klassenbezeichnung2', col: 13, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 12, 'asc' ], [ 2, 'asc' ], [ 3, 'asc'] ],
          select: {
            style: 'os'
          }
        }
      }
    },
    
  ]
}