import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-createoredit-exam>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="title" col="2" label="Titel"
              readonly={edit}
              required data-msg="Bitte geben Sie den Titel der Klassenarbeit ein"
              data-rule-maxlength="30" data-msg-maxlength="Der Titel darf nicht länger als 30 Zeichen sein"
              data-rule-project data-msg-project='Erlaubt sind: Buchstaben, Ziffern, Umlaute. Optional kann &quot;_&quot; verwendet werden, jedoch nicht am Anfang und Ende.'
            />
          </div>
          <div class="col-sm-12">
            <paedml-form-input name="description" col="3" label="Beschreibung"
              required data-msg="Bitte geben Sie die Beschreibung der Klassenarbeit ein"
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="password" col="7" label="Kennwort"
              required data-msg="Bitte geben Sie das Kennwort für die Klassenarbeit-Benutzerkonten ein"
              data-rule-maxlength="64" data-msg-maxlength="Das Kennwort darf nicht mehr als 64 Zeichen enthalten!"
              data-rule-minlength={parent.getPasswordLength()} data-msg-minlength="Das Kennwort muss mindestens {parent.getPasswordLength()} Zeichen haben!" 
          />
          </div>
        </div>
      </div>

      <div class="paedml-input-group" if={parent.fwaccess()}>
        <div class="row">
          <div class="col-sm-7">
            <div class="form-group">
              <label>Whitelist</label>
              <textarea class="form-control" maxlength=4097 data-rule-maxlength=4096 data-msg-maxlength="Es sind bis zu 4096 Zeichen erlaubt" name="whitelist" cols="28" rows="10"></textarea>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="p-3"></div>
            <paedml-form-input name="whitelistActive" col="16" placeholder="Whitelist aktiv" fgclass="py-1"
                type="checkbox" revlut="jaNein"
                handlechange={parent.handleWhitelistActive}
            />
          </div>
        </div>
      </div>
      <virtual if={!parent.fwaccess()}>
        <div class="mt-2 mr-1 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">Whitelist wird nur bei OctoGate unterstützt.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>
    </yield>
  </paedml-modal>
     
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client, handleSubmitCreateOrEdit } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session
      
      this.fwaccess = this.opts.fwaccess === undefined ? () => Session.settings[0].firewallType === "OCTO" : this.opts.fwaccess

      const convertingBooleans = R.mapObjIndexed(v => {
          if (v === 'true' || v === 'ja') return true
          if (v === 'false' || v === 'nein') return false
          return v
      })

      this.handleWhitelistActive = (val) => {
        if (!val) this.data.whitelistOnly = false
      }

      this.handleWhitelistOnly = (val) => {
        if (val) {
          this.data.whitelistActive = true
        }
      }

      this.getPasswordLength = () => {
        const baseSetting = Session.settings[0]
        return baseSetting['examPasswordLength']
      }

      this.on('dataRequested', () => {
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
        this.data.whitelist = R.join('\n', cols[15])
      })

      const normalizeList = str => str ? str.split('\n').map(R.trim).filter(e => !R.isEmpty(e)) : []

      this.submitHandler = formData => {
       
        formData.whitelist = normalizeList(formData.whitelist)
        
        return handleSubmitCreateOrEdit(this, data => `Klassenarbeit "${data.title}"`, data => `Klassenarbeit "${data.title}"`, false, true)(formData)

      }
    </script>
</paedml-modal-createoredit-exam>
