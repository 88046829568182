import './paedml-card.tag'
import './paedml-modal-createoredit-admin.tag'
import './paedml-modal-pw.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-info-admin-password.tag'

<paedml-card-admin>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
    <virtual if={parent.variant === 'admin' && canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Benutzer anlegen" />
      <paedml-modal-createoredit-admin prefix="{prefix}-create" usersuffix="{prefix}" 
        heading="{config.contentTitle}: Benutzer anlegen" opname="Benutzer anlegen" api={restApi} variant={parent.variant}/>
    </virtual>

    <virtual if="{parent.variant === 'admin' && canChangePassword()}">
      <paedml-icon-modal icon="passwort-bearbeiten" target="#{prefix}-pw-modal" title="Kennwort ändern" />
      <paedml-modal-pw prefix="{prefix}-pw" heading={config.contentTitle} selectedrowsfunc={getSelectedRows}
        preset="{config.preset ? config.preset.password : {}}" api={restApi} variant={parent.variant}/>
    </virtual>

    <virtual if="{parent.variant === 'admin' && canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Benutzer bearbeiten" />
      <paedml-modal-createoredit-admin prefix="{prefix}-edit" edit={true} usersuffix="{prefix}" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Benutzer bearbeiten" opname="Änderung speichern" api={restApi} variant={parent.variant}/>
    </virtual>

    <virtual if="{parent.variant === 'admin' && canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Benutzer löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Benutzer löschen" prefix="{prefix}-delete" selectedrowsfunc={getSelectedRows} api={restApi} variant={parent.variant}/>
    </virtual>

    <virtual if="{parent.variant === 'computer' && selectedExactlyOne()}">
      <paedml-icon-modal icon="passwort-bearbeiten" target="#{prefix}-view-modal" title="Kennwort des lokalen Administrators anzeigen" />
      <paedml-modal-info-admin-password prefix="{prefix}-view"
        heading="Lokales Computer-Administrator Kennwort" opname="OK" api={restApi} selectedrowsfunc={getSelectedRows} edit={true} variant={parent.variant}/>
    </virtual>
  </paedml-card>

  <script>

    this.prefix = opts.prefix
    this.variant = opts.config.custom.variant

  </script>

</paedml-card-admin>
