<paedml-logout>
  <script>
    import API from '../api'
    import store from '../store'
    
    this.on('mount', () => {
      // setTimeout(() => {
      //   API.user.logout()
      //   store.dispatch({type: 'user/logout'})
      // }, 500)
      API.user.logout()
      store.dispatch({type: 'user/logout'})
      // setTimeout(() => this.route('/login'), 250)
    })

  </script>

</paedml-logout>
  