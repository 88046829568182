import riot from 'riot'
import route from 'riot-route'

const OptionsMixin = {

    init: function() {
      this.route = route
      if (this.opts.config) {
        this.config = this.opts.config
      }
      if (this.opts.user) {
        this.user = this.opts.user
      }
    },

    isLoggedIn: function() {
      const {loggedIn} = (this.user || this.opts.user)
      return loggedIn === true
    },
  
    requiresOpt: function(optName) {
      if (!this.opts[optName]) {
        throw Error(this.root.localName + " tag requires attribute '" + optName + "' to be set.");
      }
      return this.opts[optName]
    },
  
    requiresOpts: function() {
      for(let i = 0; i < arguments.length; ++i) {
        requiresOpt(arguments[i]);
      }
    }
  }
  
  riot.mixin(OptionsMixin);
  