import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-access-cloud.tag'
import './paedml-modal-domain-moodle.tag'
import './paedml-modal-edit-email.tag'

<paedml-card-moodle>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{isPermitted('edit') && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-link" target="#{prefix}-edit-moodle-modal" title="Moodle Emaildomäne zuweisen" />
      <paedml-modal-domain-moodle prefix="{prefix}-edit-moodle" edit={true} selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant} domain='moodle'
        heading="{config.contentTitle}: Moodle Emaildomäne zuweisen" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{isPermitted('edit') && selectedExactlyOne()}">
      <paedml-icon-modal icon="fas-envelope" target="#{prefix}-email-moodle-modal" title="Moodle E-Mail manuell zuweisen" />
      <paedml-modal-edit-email prefix="{prefix}-email-moodle" edit={true} selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant} domain='moodle' emailcol={config.custom.emailCol}
        heading="{config.contentTitle}: Moodle E-Mail manuell zuweisen" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{isPermitted('access') && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-toggle-on" target="#{prefix}-access-moodle-modal" title="Moodlezugriff (de)aktivieren" />
      <paedml-modal-access-cloud prefix="{prefix}-access-moodle" selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant} domain='moodle'
        patient="{{singular: 'Moodlezugriff', plural: 'Moodlezugriff', for: 'für'}}"
        heading="{config.contentTitle}: Moodlezugriff (de)aktivieren" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
  </paedml-card>

  <script>
    import API from '../api'
    this.prefix=opts.prefix
    this.variant = opts.config.custom.variant

  </script>
</paedml-card-moodle>
