export const rechteverwaltung = {
  headerTitle: 'Rechteverwaltung',
  route: 'rechteverwaltung',
  pageTitle: 'paedML - Rechteverwaltung',
  cards: [
    {
      route: 'dienstekonten',
      icon: 'fas-cogs',
      endpoint: '/api/administrators/serviceAccounts',
      endpoints: {
        password: '/api/administrators/password',
      },
      tag: 'paedml-card-admin',
      category: 'SysAdmin',
      menuTitle: 'Dienste-Konten verwalten',
      pageTitle: 'paedML - Rechteverwaltung: Dienstekonten',
      name: 'srva',
      contentTitle: 'Dienstekonten',
      prefix: 'srva',
      suffix: null,
      preset: {
        password: {
          passwordPolicy: 1
        }
      },
      custom: {
        variant: 'admin'
      },
      preHooks: {
        list: 'serviceAccount_list_preHook'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Konto', name: 'userName' , col: 1 },
          { title: 'Aufgabe', name: 'function' , col: 2 },
          { title: 'Beschreibung', name: 'description', col: 3 }
        ],
        data: {
          url: '/data/dienstekonten.json',
          dataSrc: ''
        },
        custom: {
        }
      }
    },

    {
      route: 'computer-administratoren',
      icon: 'geraete-laps',
      endpoint: '/api/computers',
      preHooks: {
        list: 'devices_list_preHook'
      },
      tag: 'paedml-card-admin',
      menuTitle: 'Lokale Computer-Admins verwalten',
      pageTitle: 'paedML - Rechteverwaltung: Lokale Computer-Admins',
      name: 'cadm',
      contentTitle: 'Lokale Computer-Admins',
      prefix: 'cadm',
      suffix: undefined,
      custom: {
        variant: 'computer'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Name', name: 'name', col: 1 },
          { title: 'Raum', name: 'roomDP', col: 2, priority: 1, filter:"Raum", dynamicfilter: "rooms", filterType: 'regex' },
          { title: 'room', name: 'room', col: 3, visible: false, searchable: false, printable: false, exportable: false },
         ],
        data: {
          url: '',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 2, 'asc' ], [ 1, 'asc' ]]
        }
      }
    },

    {
      route: 'system-administratoren',
      icon: 'fas-user-astronaut',
      endpoint: '/api/administrators/systemAdmin',
      endpoints: {
        get: '/api/administrators',
        put: '/api/administrators',
        delete: '/api/administrators',
        password: '/api/administrators/password',
      },
      tag: 'paedml-card-admin',
      category: 'SysAdmin',
      menuTitle: 'System-Admins verwalten',
      pageTitle: 'paedML - Rechteverwaltung: System-Administratoren',
      name: 'sysadm',
      contentTitle: 'System-Administratoren',
      prefix: 'sysadm',
      suffix: 'sysadm',
      preset: {
        password: {
          passwordPolicy: 1
        }
      },
      custom: {
        variant: 'admin'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 }
        ],
        data: {
          url: '/data/sysadm.json',
          dataSrc: ''
        },
        custom: {
        }
      }
    },

    {
      route: 'firewall-administratoren',
      icon: 'fas-user-shield',
      endpoint: '/api/administrators/firewallAdmin',
      endpoints: {
        get: '/api/administrators',
        put: '/api/administrators',
        delete: '/api/administrators',
        password: '/api/administrators/password',
      },
      tag: 'paedml-card-admin',
      category: 'SysAdmin',
      menuTitle: 'Globale Firewall-Admins verwalten',
      pageTitle: 'paedML - Rechteverwaltung: Globale Firewall-Admins',
      name: 'fadm',
      contentTitle: 'Globale Firewall-Admins',
      prefix: 'fadm',
      suffix: 'fadm',
      preset: {
        password: {
          passwordPolicy: 1
        }
      },
      custom: {
        variant: 'admin'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
        ],
        data: {
          url: '',
          dataSrc: ''
        },
        custom: {
        }
      }
    },

    {
      route: 'schulart-administratoren',
      icon: 'fas-user-lock',
      endpoint: '/api/administrators/schoolTypeAdmin',
      endpoints: {
        get: '/api/administrators',
        put: '/api/administrators',
        delete: '/api/administrators',
        password: '/api/administrators/password',
      },
      tag: 'paedml-card-admin',
      category: 'SysAdmin',
      menuTitle: 'Schulart-Admins verwalten',
      pageTitle: 'paedML - Rechteverwaltung: Schulart-Administratoren',
      name: 'sadm',
      contentTitle: 'Schulart-Administratoren',
      prefix: 'sadm',
      suffix: 'sadm',
      preset: {
        password: {
          passwordPolicy: 1
        }
      },
      custom: {
        variant: 'admin'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart(en)', name: 'schoolTypes', separator: ', ', col: 4, filter: 'Schulart', filterType: 'regex' }
        ],
        data: {
          url: '',
          dataSrc: ''
        },
        custom: {
        }
      }
    },

    {
      route: 'rollen-administratoren',
      icon: 'fas-user-cog',
      endpoint: '/api/administrators/roleAdmin',
      endpoints: {
        get: '/api/administrators',
        put: '/api/administrators',
        delete: '/api/administrators',
        password: '/api/administrators/password',
      },
      tag: 'paedml-card-admin',
      category: 'SchulartAdmin',
      menuTitle: 'Rollen-Admins verwalten',
      pageTitle: 'paedML - Rechteverwaltung: Rollen-Administratoren',
      name: 'radm',
      contentTitle: 'Rollen-Administratoren',
      prefix: 'radm',
      suffix: 'radm',
      preset: {
        password: {
          passwordPolicy: 1
        }
      },
      custom: {
        variant: 'admin'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart(en)', name: 'schoolTypes', separator: ', ', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Rolle(n)', name: 'roles', separator: ', ', col: 5, filter: 'Rollen', filterType: 'regex' }
        ],
        data: {
          dataSrc: ''
        },
        custom: {
        }
      }
    }
  ]
}
