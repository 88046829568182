import './paedml-card.tag'
import './paedml-modal-pw.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-createoredit-schueler.tag'
import './paedml-modal-info-schueler.tag'
import './paedml-modal-lockunlock-internet.tag'
import './paedml-modal-handout.tag'
import './paedml-modal-add-to-proj.tag'
import './paedml-modal-usersync.tag'
import './paedml-modal-fix-perms.tag'

<paedml-card-schueler>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
    <virtual if="{selectedNone() && isPermitted('sync')}">
      <paedml-icon-modal icon="fas-recycle" target="#{prefix}-sync-modal" title="Schüler*innen synchronisieren" />
      <paedml-modal-usersync prefix="{prefix}-sync" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: AD &lt;-&gt; DB Synchronisation" opname="OK" api={restApi}
        variant="schueler" />
    </virtual>

    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Schüler*in anlegen" />
      <paedml-modal-createoredit-schueler prefix="{prefix}-create" usersuffix="{prefix}" 
        heading="{config.contentTitle}: Schüler*in anlegen" opname="Schüler*in anlegen" api={restApi} />
    </virtual>

    <virtual if="{canSeeInfo()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Schüler*in-Info ansehen" />
      <paedml-modal-info-schueler prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Info" opname="OK" api={restApi} />
    </virtual>

    <virtual if="{canChangePassword()}">
      <paedml-icon-modal icon="passwort-bearbeiten" target="#{prefix}-pw-modal" title="Kennwort ändern" />
      <paedml-modal-pw prefix="{prefix}-pw" heading={config.contentTitle} selectedrowsfunc={getSelectedRows}
        variant="schueler" preset="{config.preset ? config.preset.password : {}}" api={restApi} />
    </virtual>
    
    <virtual if="{selectedOneOrMore() && isPermitted('handout')}">
      <paedml-icon-modal icon="fas-file-upload" target="#{prefix}-handout-modal" title="Dateien austeilen" />
      <paedml-modal-handout prefix="{prefix}-handout" variant="schueler" selectedrowsfunc={getSelectedRows}
        patient="{{singular: 'Schüler', plural: 'Schüler*innen'}}"
        heading="{config.contentTitle}: Dateien austeilen" opname="Dateien austeilen" api={restApi} />
    </virtual>
  
    <virtual if="{selectedOneOrMore() && isPermitted('addtoproj')}">
      <paedml-icon-modal icon="fas-project-diagram" target="#{prefix}-addtoproj-modal" title="Zum Projekt hinzufügen" />
      <paedml-modal-add-to-proj prefix="{prefix}-addtoproj" variant="schueler" selectedrowsfunc={getSelectedRows}
        patient="{{singular: 'Schüler', plural: 'Schüler*innen'}}"
        heading="{config.contentTitle}: Zum Projekt hinzufügen" opname="Zum Projekt hinzufügen" api={restApi} />
    </virtual>
  
    <virtual if="{canChangeInternetLock()}">
      <paedml-icon-modal icon="internet" target="#{prefix}-run-lock-internet-modal" title="Internet (ent)sperren" />
      <paedml-modal-lockunlock-internet prefix="{prefix}-run-lock-internet" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Internet (ent)sperren" opname="Ausführen" api={restApi} variant='student'
      />
    </virtual>
  
    <virtual if="{selectedOneOrMore() && isPermitted('fix')}">
      <paedml-icon-modal icon="fas-folder-open" target="#{prefix}-fix-modal" title="Homeverzeichnis bearbeiten" />
      <paedml-modal-fix-perms prefix="{prefix}-fix" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Homeverzeichnis bearbeiten" opname="Korrekturen ausführen" api={restApi}
        variant="fix" />
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Schüler*in bearbeiten" />
      <paedml-modal-createoredit-schueler prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Schüler*in bearbeiten" opname="Änderung speichern" api={restApi} />
    </virtual>

    <virtual if="{selectedOneOrMore() && isPermitted('activate')}">
      <paedml-icon-modal icon="fas-unlock-keyhole" target="#{prefix}-run-activate-user-modal" title="Schüler*in(nen) (de)aktivieren" />
      <paedml-modal-activate-user prefix="{prefix}-run-activate-user" selectedrowsfunc={getSelectedRows}
        heading="Schüler*in(nen) (de)aktivieren" opname="Ausführen" api={restApi} variant='student' />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Schüler*in(nen) löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Schüler*in(nen) löschen" prefix="{prefix}-delete" 
        selectedrowsfunc={getSelectedRows} api={restApi} doredirect={true}/>
    </virtual>
    </div>
  </paedml-card>

</paedml-card-schueler>
