<paedml-modal-freigabe-versetzung>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler}
                cancelbutton={true}
                >

    <yield to="fields">
      <div class="row">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">{parent.text}</span>
          <p></p>
          <paedml-form-input name="redirect" placeholder="Nach der Freigabe zur 'Verarbeitungsstatus'-Ansicht springen" fgclass="py-1"
            type="checkbox" revlut="jaNein"
          />
        </div>
      </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'

    this.prefix = opts.prefix
    this.data = {
    }
    this.api = this.opts.api
    
    this.on('dataRequested', (selectedRows) => {
      this.data.redirect = false
      if (selectedRows.length === 1) {
        this.text = `Ausgewählte(r) Schüler*in mit dem Benutzernamen "${selectedRows[0][this.opts.idtextcol]}" wird zur Verschiebung / Versetzung freigegeben.`
      } else {
        this.text = 'Die unten aufgeführten Schüler*innen werden zur Verschiebung / Versetzung freigegeben.'
        this.data.redirect = true
      }
    })

    this.submitHandler = formData => {
      // console.debug('Freigabe', formData.ids)
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      return this.api.approve(formData.ids).then(rsp => {
        const [data, status] = rsp
        // console.debug(data)
        if (status.success) {
          this.parent.parent.opts.siblings.forEach(card => {
            card.tags['paedml-card'].trigger('reloadTable')
          })

          if (this.data.redirect) setTimeout(() => { 
            $('body').removeClass('modal-open')
            window.location.href = '/#versetzung/verarbeitung' 
          }, 500)

          return `Freigabe erfolgreich`
        }

        console.error('error-data', data)
        console.error('error-status', status)
        const reason = data && data.message ? data.message : 'unbekannt'
        return Promise.reject(`Freigabe fehlgeschlagen. Grund: ${reason}`)
      })

    }

  </script>
</paedml-modal-freigabe-versetzung>
