import '../paedml-icon.tag'
import '../paedml-form-input.tag'
import '../paedml-modal.tag'

<paedml-modal-software-installations>
  <paedml-modal prefix={opts.prefix} heading={heading} opname={opts.opname} edit={true}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient} large={true}
                cancelbutton={true} okcondition={okcondition} >

    <yield to="fields">
      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
          <p class="my-auto">Liste der Computer wird aktualisiert...</p>
          <div class="sk-wave">
            <div class="sk-rect sk-rect1"></div>
            <div class="sk-rect sk-rect2"></div>
            <div class="sk-rect sk-rect3"></div>
            <div class="sk-rect sk-rect4"></div>
            <div class="sk-rect sk-rect5"></div>
          </div>
        </div>

      <div class="d-flex" show={!parent.loading}>
        <div style="width: 45%;">
          <div class="row">
            <div class="col-md-12 col-lg-5">
              <strong>Nicht installiert / wird deinstalliert</strong>
            </div>
            <div class="col-md-12 col-lg-7 col-xl-7">
              <paedml-form-input placeholder="Ganzen Raum installieren" small={true}
                type="select" values={parent.getRoomsMapped()} handlechange={parent.handleSourceRoomChange} />
            </div>
          </div>
          <table id="{opts.prefix}-source-modal-table" style="width: 45%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningSource} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningSource}</strong> </span>
          </div>
        </div>
        <div style="width: 10%;">
          <div class="d-flex justify-content-center pt-5 mt-5">
            <paedml-icon icon="fas-long-arrow-alt-right" inv={true} title="Ausgewählte(n) Computer hinzufügen" onclick={parent.moveSource} />
          </div>
          <div class="d-flex justify-content-center">
            <paedml-icon icon="fas-long-arrow-alt-left" inv={true} title="Ausgewählte(n) Computer entfernen" onclick={parent.moveTarget} />
          </div>
        </div>
        <div style="width: 45%;">
          <div class="row">
            <div class="col-md-12 col-lg-5">
              <strong>Installiert / wird installiert</strong>
            </div>
             <div class="col-md-12 col-lg-7 col-xl-7">
              <paedml-form-input placeholder="Ganzen Raum deinstallieren" small={true}
                type="select" values={parent.getRoomsMapped(false)}
                handlechange={parent.handleTargetRoomChange}
              />
            </div>
          </div>
          <table id="{opts.prefix}-target-modal-table" style="width: 100%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningTarget} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningTarget}</strong> </span>
          </div>
        </div>
      </div>
      <div class="paedml-input-group p-0 mt-3" show={!parent.loading}/>
        <div class="row">
          <div class="col-sm-3">
            <label class="py-2">
              <paedml-icon icon="fas-minus-circle" inv="true" class="pr-1"/> Wird deinstalliert
            </label>
          </div>
          <div class="col-sm-3">
            <paedml-form-input name="updateVersion" placeholder="Wird aktualisiert" fgclass="py-2"
                type="checkbox" revlut="jaNein" icon="fas-exclamation-circle"/>
          </div>
          <div class="col-sm-3">
            <paedml-form-input name="reinstall" placeholder="Wird nochmal installiert" fgclass="py-2"
                type="checkbox" revlut="jaNein" icon="fas-check-circle" />
          </div>
          <div class="col-sm-3">
            <label class="py-2">
              <paedml-icon icon="fas-plus-circle" inv="true" class="pr-1"/> Wird installiert
            </label>
          </div>
        </div>
      </div>
    </yield>
  </paedml-modal>

  <script>
    import * as i18n from '../../config/i18n/german'
    import * as R from 'ramda'
    import { toTrueMap } from '../../util/mapping-util'
    import API from '../../api'
    import { patientFormatter, handleSubmitEdit } from '../../util/api-util'
    import Session from '../../store/session'
    
    this.op = opts.op
    this.prefix = opts.prefix
    this.variant = opts.variant
    this.data = {
    }
    this.patient = opts.patient 
    this.data.updateVersion = false
    this.data.reinstall = false
    
    this.okcondition = () => !(this.data.warningSource || this.data.warningTarget)
    this.heading = this.opts.heading

    this.on('update', () => {
      this.loading = false
      this.heading = this.data.computer === undefined ? this.opts.heading : `${this.data.softwareProduct[1]} (${this.data.softwareProduct[7]}): Installation verwalten`
    })

    this.getRoomsMapped = (add = true) => {
      const rooms = [[' ','Ganzen Raum ' + (add ? 'installieren' : 'deinstallieren')]]

       R.forEachObjIndexed((room, roomId) => {
        rooms.push([roomId, room])
      })(this.data.rooms)

      return rooms
    }

    this.handleSourceRoomChange = (val) => {
      if (val === ' ' || val == null) {
        this.tableSource.columns(4).search('').draw()
        return
      }
      this.tableSource.columns(4).search('^' + val + '$', true, false).draw()
      this.tableSource.rows({filter: 'applied'}).select()
      this.tableSource.columns(4).search('').draw()
      this.moveSource()
    }

    this.handleTargetRoomChange = (val) => {
      if (val === ' ' || val == null) {
        this.tableTarget.columns(3).search('').draw()
        return
      }
      this.tableTarget.columns(3).search('^' + val + '$', true, false).draw()
      this.tableTarget.rows({filter: 'applied'}).select()
      this.tableTarget.columns(3).search('').draw()
      this.moveTarget()
    }

    this.moveSource = () => {
      const from = this.tableSource
      const to = this.tableTarget
      const selected = from.rows( { selected: true } )
      const selectedRows = selected.data()
      const targetRow = to.row
     
      selectedRows.each((e, i) => {
        
        const installedSoftware = { 
          id: 0,
          computerId: e.id,
          roomId: this.data.computers[e.id].room.id,
          room: this.data.computers[e.id].room.name,
          softwareId: this.data.softwareProduct[0],
          computer: e.computer,
          software: this.data.softwareProduct[1],
          version: this.data.softwareProduct[7],
          softwareVersion: this.data.softwareProduct[7],
          icon: 'fas-plus-circle'
        }
        targetRow.add(installedSoftware)
      })
      selected.remove()

      this.redrawTables()

      $('input[type=search]').val('').trigger('keyup')
      $('select[ref=input]').val(' ').trigger('change')
    }

    this.moveTarget = () => {
      const from = this.tableTarget
      const to = this.tableSource
      const selected = from.rows( { selected: true } )
      const selectedRows = selected.data()
      
      const targetRow = to.row
     
      selectedRows.each((e, i) => {
        const computer = this.data.computers[e.computerId]
        targetRow.add(this.mapSourceComputer(computer, 'fas-minus-circle'))
      })
      selected.remove()

      this.redrawTables()

      $('input[type=search]').val('').trigger('keyup')
      $('select[ref=input]').val(' ').trigger('change')

    }

    this.mapSourceComputer = (e, icon) => ({ 
      id: e.id,
      computer: e.name,
      room: e.room.name,
      roomId: e.room.id,
      icon: icon
    })

    this.mapTargetComputer = (e) => ({ 
      id: e.id,
      computerId: e.computer.id,
      roomId: this.data.computers[e.computer.id].room.id,
      room: this.data.computers[e.computer.id].room.name,
      softwareId: e.softwareProduct.id,
      computer: e.computer.name,
      version: `${e.productVersion}-${e.packageVersion}`,
      softwareVersion: `${e.softwareProduct.productVersion}-${e.softwareProduct.packageVersion}`,
      icon: this.isOldVersion(`${e.softwareProduct.productVersion}-${e.softwareProduct.packageVersion}`, `${e.productVersion}-${e.packageVersion}`) ? 'fas-exclamation-circle' : 'fas-check-circle'
    })

    this.isOldVersion = (software, installed) => {
      return software !== installed
    }

    this.getTableSource = () => this.tableSource
    this.getTableTarget = () => this.tableTarget

    this.getIcon = (icon) => {
      let title = ''
      switch (icon) {
        case "fas-plus-circle": title = "Softwarepaket wird installiert."; break;
        case "fas-minus-circle": title = "Softwarepaket wird deinstalliert."; break;
        case "fas-exclamation-circle": title = "Softwarepaket ist installiert. Version ist veraltet."; break;
        case "fas-check-circle": title = "Softwarepaket ist installiert. Version ist aktuell."; break;
        default: title = ''; break
      }
      return `<span title="${title}"><svg class="paedml-icon black" style="margin-top: -3px"><use xlink:href="#${icon}" /></svg></span>`
    }

    this.createSourceTable = (computers) => {
      const selector = `#${opts.prefix}-source-modal-table`
        this.tableSource = $(selector).DataTable( {
          data: computers,
          columns: [
            { data: 'id', title: 'id', visible: false, searchable: false },
            { data: 'icon', title: '', fnCreatedCell: (td, val) => val ? $(td).html(this.getIcon(val)) : '', priority: 1 },
            { data: 'computer', title: 'Computer' },
            { data: 'room', title: 'Raum' },
            { data: 'roomId', title: 'roomId', visible: false },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          order: [[ 3, 'asc' ], [ 2, 'asc' ]],
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableSource()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningSource = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningSource = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningSource = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningSource = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        const sourceNode = $(selector + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
        $(selector + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-7 col-xl-7')
    }

    this.createTargetTable = (computers) => {
       const selectorTarget = `#${opts.prefix}-target-modal-table`
        this.tableTarget = $(selectorTarget).DataTable( {
          data: computers,
          columns: [
            { data: 'icon', title: '', fnCreatedCell: (td, val) => val ? $(td).html(this.getIcon(val)) : '', priority: 1},
            { data: 'id', title: 'id', visible: false, searchable: false },
            { data: 'computer', title: 'Computer' },
            { data: 'roomId', title: 'roomId', visible: false },
            { data: 'room', title: 'Raum' },
            { data: 'computerId', title: 'computerId', visible: false, searchable: false },
            { data: 'softwareId', title: 'softwareId', visible: false, searchable: false },
            { data: 'softwareVersion', title: 'softwareVersion', visible: false, searchable: false },
            { data: 'version', title: 'Version' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          order: [[ 4, 'asc' ], [ 2, 'asc']],
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableTarget()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningTarget = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningTarget = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningTarget = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningTarget = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        const targetNode = $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
        $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-6 col-xl-7')
    }

    this.refreshComputerGrids = (installed) => {
      this.loading = true
      API.computer.getAll().then(rsp => {
        this.loading = false
        const [computers, status] = rsp
               
        this.data.computers = computers.reduce((acc, e) => {
          acc[e.id] = e
          return acc
        }, {})

         this.data.rooms = computers.reduce((acc, e) => {
          acc[e.room.id] = e.room.name
          return acc
        }, {})

        const exIds = {}
        const targetList = installed.map(e => {
          
          const id = e.computer.id
          exIds[id] = true
          const access = this.data.computers[id]
          return this.mapTargetComputer(e, access === undefined)
        })
        this.createTargetTable(targetList)

        const computersList = computers.filter(e => !exIds[e.id]).map(e => this.mapSourceComputer(e, ''))
        this.createSourceTable(computersList)
        this.update()
      })
    }

    this.redrawTables = () => {
      if (this.tableSource) this.tableSource.draw()
      if (this.tableTarget) this.tableTarget.draw()
    }

    this.on('hide', () => {
      if (this.tableSource) this.tableSource.destroy()
      this.tableSource = null
      if (this.tableTarget) this.tableTarget.destroy()
      this.tableTarget = null
    })

    this.on('shown', () => {
      this.redrawTables()
    })

    this.on('rowMappingRequested', (cols) => {
      this.loading = true
      this.data.softwareProduct = cols
      API.software.installations.get(cols[0]).then(rsp => {

        this.loading = false
        const [installed, status] = rsp
        if (status.error) {
          this.update()
        }
        this.data.installed = installed
        
        this.update()
        this.refreshComputerGrids(installed)
      })
    })

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const softwareId = formData.ids[0]
      
      const install = []
      this.tableTarget.data().each(e => {
        install.push(e.computerId)
      })
      const uninstall = []
      this.tableSource.data().each(e => {
        uninstall.push(e.id)
      })
      const { updateVersion, reinstall } = formData
          
      return API.software.installations.set(softwareId, { install, uninstall, updateVersion, reinstall }).then(rsp => {
        return handleSubmitEdit(this, rsp, undefined, undefined, true)
      })
    }

  </script>
</paedml-modal-software-installations>
