import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-edit-quota.tag'

<paedml-card-quota>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{isPermitted('edit') && selectedOneOrMore()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-quota-modal" title="Kontingent bearbeiten" />
      <paedml-modal-edit-quota prefix="{prefix}-edit-quota" edit={true} selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant}
        heading="{config.contentTitle}: Kontingent bearbeiten" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
  </paedml-card>

  <script>
    import API from '../api'
    this.prefix=opts.prefix
    this.variant = opts.config.custom.variant

  </script>
</paedml-card-quota>
