import './paedml-card.tag'
import './paedml-modal-update-fav.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'

<paedml-card-dashboard>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
    <virtual if={suffix === 'all' && canChangeFav() && parent.favNotSelected(this)}>
      <paedml-icon-modal icon="fas-star" target="#{prefix}-create-modal" title="Zu Favoriten hinzufügen" />
      <paedml-modal-update-fav prefix="{prefix}-create" mode='create' selectedrowsfunc={getSelectedRows} idcol="2" idtextcol="1"
        heading="{config.contentTitle}: Zu Favoriten hinzufügen" opname="Hinzufügen" api={restApi}/>
    </virtual>

    <virtual if={suffix === 'all' && canChangeFav() && parent.favSelected(this)}>
      <paedml-icon-modal icon="far-star" target="#{prefix}-remove-modal" title="Von Favoriten entfernen" />
      <paedml-modal-update-fav prefix="{prefix}-remove"  mode='remove' selectedrowsfunc={getSelectedRows} idcol="2" idtextcol="1"
        heading="{config.contentTitle}: Aus den Favoriten entfernen" opname="Entfernen" api={restApi}
        />
    </virtual>

    <virtual if={suffix === 'fav' && canChangeFav()}>
      <paedml-icon-modal icon="far-star" target="#{prefix}-remove-modal" title="Von Favoriten entfernen" />
      <paedml-modal-update-fav prefix="{prefix}-remove"  mode='remove' selectedrowsfunc={getSelectedRows} idcol="2" idtextcol="1"
        heading="{config.contentTitle}: Aus den Favoriten entfernen" opname="Entfernen" api={restApi}
        />
    </virtual>

  </paedml-card>

  <script>
    import * as R from 'ramda'

    this.favSelected = (target) => {
      const selected = target.getSelectedRows()
      for(i=0; i < selected.length; ++i) {
        if (selected[i][5] === true) return true
      }
      return false
    }

    this.favNotSelected = (target) => {
      const selected = target.getSelectedRows()
      for(i=0; i < selected.length; ++i) {
        if (selected[i][5] === false) return true
      }
      return false
    }
  </script>
</paedml-card-dashboard>
