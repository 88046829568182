import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-accesslist-meineklassen>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
        <p class="my-auto">Firewall-Einstellungen werden aktualisiert...</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </div>

    <div class="paedml-input-group" show={!parent.loading}>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Whitelist</label>
            <textarea class="form-control" maxlength=4097 data-rule-maxlength=4096 data-msg-maxlength="Es sind bis zu 4096 Zeichen erlaubt" name="whitelist" cols="28" rows="10"></textarea>
          </div>
          <paedml-form-input name="whitelistActive" col="10" placeholder="Whitelist aktiv" fgclass="py-1"
              type="checkbox" revlut="jaNein"
              handlechange={parent.handleWhitelistActive}
          />
          <paedml-form-input name="whitelistOnly" col="11" placeholder="Nur Whitelist verwenden" fgclass="py-1"
              type="checkbox" revlut="jaNein"
              handlechange={parent.handleWhitelistOnly}
          />
        </div>
      </div>
    </div>

    </yield>
  </paedml-modal>
    
  <script>
    import store from '../store'
    import { client } from '../util/api-util'
    import * as mapper from '../mapper'
    import * as R from 'ramda'
    import Session from '../store/session'
    import Masterdata from '../config/masterdata'
    import API from '../api'

    this.opname = this.opts.opname
    this.edit = this.opts.edit ? true : false
    this.data = {}
    this.api = this.opts.api
    this.session = Session

    this.handleBlacklistActive = (val) => {
      if (val) this.data.whitelistOnly = false
    }

    this.handleWhitelistActive = (val) => {
      if (!val) this.data.whitelistOnly = false
    }

    this.handleWhitelistOnly = (val) => {
      if (val) {
        this.data.whitelistActive = true
        this.data.blacklistActive = false
      }
    }

    this.load = (id) => {
      this.loading = true
      return API.fwklassen.get(id).then(rsp => {
        this.loading = false
        return rsp
      })
    }

    this.on('update', () => {
      this.loading = false
    })

    this.on('rowMappingRequested', (cols, modal) => {
      this.data.id = cols[0] || 0

      this.load(this.data.id).then(rsp => {
        const [fw, status] = rsp
        fw.blacklist = R.join('\n', fw.blacklist)
        fw.whitelist = R.join('\n', fw.whitelist)
        this.data = fw
        modal.trigger('refreshData')
      })

    })

    const normalizeList = str => str ? str.split('\n').map(R.trim).filter(e => !R.isEmpty(e)) : []

    this.submitHandler = formData => {
      const blacklist = normalizeList(formData.blacklist)
      const whitelist = normalizeList(formData.whitelist)
      const payload = { blacklist, whitelist }
      const id = this.data.id
        
      if (id) {
        payload.firewallCategories = this.data.firewallCategories
        payload.blacklistActive = this.data.blacklistActive
        payload.whitelistActive = this.data.whitelistActive
        payload.whitelistOnly = this.data.whitelistOnly
      }

      return API.fwklassen.update(id, payload)
          .then(rsp => {
            const [data, status] = rsp
            if (status.success) {
              this.parent.trigger('reloadTable', true)
              return 'Änderung(en) erfolgreich übernommen'
            }

            console.error('error-status', status)
            console.error('error-data', data)
            if (data.message) {
              return Promise.reject(data.message)
            }
            return Promise.reject('Änderung(en) konnten nicht übernommen werden!')
          })
    }
  </script>
</paedml-modal-accesslist-meineklassen>
