import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon-modal.tag'
import './projects/paedml-modal-info-project.tag'
import './projects/paedml-modal-createoredit-projekt.tag'
import './paedml-modal-people-picker.tag'
import './paedml-modal-handout.tag'
import './projects/paedml-modal-fix-perms-projekte.tag'

<paedml-card-projekte>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Projekt anlegen" />
      <paedml-modal-createoredit-projekt prefix="{prefix}-create"
        heading="{config.contentTitle}: Projekt anlegen" opname="Projekt anlegen" api={restApi} />
    </virtual>

    <virtual if="{canSeeInfo()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Projekt-Info ansehen" />
      <paedml-modal-info-project prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Info" opname="OK" api={restApi} />
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="lehrer" target="#{prefix}-manage-leaders-modal" title="Leiter*innen verwalten" />
      <paedml-modal-people-picker prefix="{prefix}-manage-leaders" lock={true} selectedrowsfunc={getSelectedRows}
        target="lehrer" variant="leaders" op="{prefix}"
        heading="{config.contentTitle}: Leiter*innen verwalten" opname="Änderungen übernehmen" api={restApi} />
    </virtual>
  
    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="schueler" target="#{prefix}-manage-members-modal" title="Mitglieder verwalten" />
      <paedml-modal-people-picker prefix="{prefix}-manage-members" selectedrowsfunc={getSelectedRows} edit={true}
        target="lehrer+schueler" variant="members" op="{prefix}"
        heading="{config.contentTitle}: Mitglieder verwalten" opname="Änderungen übernehmen" api={restApi} />
    </virtual> 

    <virtual if="{selectedExactlyOne() && isPermitted('handout') && parent.hasMembers(getSelectedRows)}">
      <paedml-icon-modal icon="fas-file-upload" target="#{prefix}-handout-modal" title="Dateien austeilen" />
      <paedml-modal-handout prefix="{prefix}-handout" variant="projekte" selectedrowsfunc={getSelectedRows}
        patient="{{singular: 'Projekt', plural: 'Projekte'}}"
        heading="Projekt: Dateien austeilen" opname="Dateien austeilen" api={restApi} />
    </virtual>
  
    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Projekt bearbeiten" />
      <paedml-modal-createoredit-projekt prefix="{prefix}-edit" selectedrowsfunc={getSelectedRows} edit={true}
        heading="{config.contentTitle}: Projekt bearbeiten" opname="Projekt bearbeiten" api={restApi} />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Projekte löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Projekt löschen" prefix="{prefix}-delete" 
        idcol="0" idtextcol="1"
        patient="{{singular: 'Projekt', plural: 'Projekte', as: 'das'}}" selectedrowsfunc={getSelectedRows} api={restApi} doredirect={true}/>
    </virtual>

    <virtual if="{selectedOneOrMore() && isPermitted('cleanup')}">
      <paedml-icon-modal icon="fas-folder-open" target="#{prefix}-fix-modal" title="Tauschverzeichnis bearbeiten" />
      <paedml-modal-fix-perms-projekte prefix="{prefix}-fix" edit={true} selectedrowsfunc={getSelectedRows}
        variant="cleanup" edit={true}
        heading="{config.contentTitle}: Tauschverzeichnis bearbeiten" opname="Korrekturen ausführen" api={restApi} />
    </virtual>
    </div>
  </paedml-card>

  <script>
    this.hasMembers = (getSelectedRows) => getSelectedRows()[0][11] > 0
  </script>
  
</paedml-card-projekte>
