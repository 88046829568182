import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-create-domain.tag'

<paedml-card-domain>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{canCreate()}">
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-domain-modal" title="{parent.texts.addDomain}" />
      <paedml-modal-create-domain prefix="{prefix}-create-domain" selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant} idtextcol=0
        heading="{config.contentTitle}: {parent.texts.addDomain}" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="{parent.texts.removeDomain}" />
      <paedml-modal-delete heading="{config.contentTitle}: {parent.texts.removeDomain}" prefix="{prefix}-delete" selectedrowsfunc={getSelectedRows} api={restApi}
        idtextcol=0 patient="{{singular: 'Domäne', plural: 'Domänen'}}"
      />
    </virtual>
  
  </paedml-card>

  <script>
    import API from '../api'
    this.prefix = opts.prefix
    this.variant = opts.config.custom.variant

    this.texts = {
      addDomain: this.variant === 'moodledomain' ? 'Externe Emaildomäne hinzufügen': 'Verknüpfung mit externen Domäne hinzufügen',
      removeDomain: this.variant === 'moodledomain' ? 'Externe Emaildomäne entfernen': 'Verknüpfung mit externen Domäne aufheben',
    }

  </script>
</paedml-card-domain>
