<paedml-modal-run-computer>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 0}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true} okcondition={selected}
                >

    <yield to="fields">

      <div class="p-2">
        <div class="custom-control custom-radio">
          <input type="radio" id="{prefix}-admin-logoff" name="{prefix}-admin-power" class="custom-control-input" onclick={update}>
          <label class="custom-control-label" for="{prefix}-admin-logoff">Benutzer abmelden</label>
        </div>
        <div class="p-2"></div>
        <div class="custom-control custom-radio">
          <input type="radio" id="{prefix}-admin-start" name="{prefix}-admin-power" class="custom-control-input" onclick={update}>
          <label class="custom-control-label" for="{prefix}-admin-start">Einschalten</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="{prefix}-admin-shutdown" name="{prefix}-admin-power" class="custom-control-input" onclick={update}>
          <label class="custom-control-label" for="{prefix}-admin-shutdown">Ausschalten</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="{prefix}-admin-reboot" name="{prefix}-admin-power" class="custom-control-input" onclick={update}>
          <label class="custom-control-label" for="{prefix}-admin-reboot">Neustarten</label>
        </div>
      </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatterFuture} from '../util/modal-util'

    this.prefix = opts.prefix
    this.data = { }
    this.api = this.opts.api
    this.patient = this.opts.patient || {singular: 'Computer', plural: 'Computer'}
    
    this.selected = () => {
      return $(`input[name='${this.prefix}-admin-power']:checked`).attr('id') !== undefined
    }

    this.submitHandler = formData => {
      // console.debug('Computers run', formData)
      const opid = $(`input[name='${this.prefix}-admin-power']:checked`).attr('id')
      // console.debug('opid', opid)
      const op = opid.replace('computer-run-power-admin-', '')
      // console.debug('op', op, this.api[op])
    
      // return Promise.reject('implementing')
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const opLut = {
        logoff: patientFormatterFuture({singular: 'Benutzer am PC', plural: 'Benutzer am PC'}, 'abgemeldet'),
        start: patientFormatterFuture({singular: 'Computer', plural: 'Computer'}, 'gestartet'),
        shutdown: patientFormatterFuture({singular: 'Computer', plural: 'Computer'}, 'runtergefahren'),
        reboot: patientFormatterFuture({singular: 'Computer', plural: 'Computer'}, 'neugestartet'),
      }

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const formatter = opLut[op]
      // const op = this.opts.lock ? 'lock' : 'unlock'
      const deleteMessageSuccess = formatter(idLut, true)
      const deleteMessageFailure = formatter(idLut, false)

      return this.api[op](formData.ids).then(rsp => {
        const [deletedIds, status] = rsp
        if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server')

        const {succeeded, failed} = deletedIds
        if (failed.length) {
          if (succeeded.length) {
            // partly success
            const sLut = toTrueMap(R.map(id => idLut[id], succeeded))
            // remove deleted entries
            $('span.tag', this.refs.tip).each(function(i,e) { 
              if (sLut[$(e).text()]) $('span', e).click()
            })

            // this.parent.trigger('reloadTable')
            // if (this.opts.relogin) window.request.relogin()
            return Promise.reject([deleteMessageSuccess(succeeded), deleteMessageFailure(failed)])
          }
          return Promise.reject(deleteMessageFailure(failed))
        }

        this.parent.trigger('reloadTable')
        // if (this.opts.relogin) window.request.relogin()
        return deleteMessageSuccess(succeeded)
      })

    }

  </script>
</paedml-modal-run-computer>
