import store, {emit} from '../store'
import thunkAction from '../util/thunk-action'
import * as userAPI from '../api/user'
import {LocalStorageAPI} from '../api/storage'

export const login = (username, password) => {
  const func = username && password ? () => userAPI.loginWithUsernameAndPassword(username, password) : () => userAPI.login()
  store.dispatch(thunkAction('user/login', func))
}

export const relogin = () => {
  const refreshToken = LocalStorageAPI.get('refreshToken')
  store.dispatch(thunkAction('user/login', () => userAPI.loginWithRefreshToken(refreshToken)))
}

export const logout = () => {
  emit('user/logout')
  userAPI.logout()
}
