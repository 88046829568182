import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-createoredit-schularten.tag'
import './paedml-modal-run-sjw.tag'

<paedml-card-schularten>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Schulart anlegen" />
      <paedml-modal-createoredit-schularten prefix="{prefix}-create" usersuffix="{prefix}" 
        heading="{config.contentTitle}: Schulart anlegen" opname="Schulart anlegen" api={restApi} />
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Schulart bearbeiten" />
      <paedml-modal-createoredit-schularten prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Schulart bearbeiten" opname="Änderung speichern" api={restApi} />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Schularten löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Schulart löschen" prefix="{prefix}-delete" 
        patient="{{singular: 'Schulart', plural: 'Schularten', as: 'die'}}" selectedrowsfunc={getSelectedRows} api={restApi} relogin={true}/>
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="fas-retweet" target="#{prefix}-sjw-modal" title="Schuljahreswechsel durchführen" />
      <paedml-modal-run-sjw prefix="{prefix}-sjw" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Schuljahreswechsel durchführen" opname="Schuljahreswechsel durchführen" api={restApi} />
    </virtual>

  </paedml-card>
</paedml-card-schularten>
