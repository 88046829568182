import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-benutzer.tag'
import './paedml-modal-basis.tag'
import './paedml-logout.tag'

<paedml-header>
  <header class="header">
    <nav class="navbar paedml-navbar">
      <div class="container-fluid pl-2">
        <div class="container-fluid pl-0">
          <div class="navbar-holder d-flex align-items-center justify-content-between">
            <div class="navbar-header">
              <a id="toggle-btn" href="#" class="menu-btn" title="Seitennavigation umschalten" style="transform: translateY(-0.2rem);"><i class="icon-bars"> </i></a>
              <a href="{homeUrl()}" class="navbar-brand ml-0 mr-1" title="Home">
                <div class="brand-text d-none d-lg-inline-block">
                  <span class="paedml-nav-text-orange pr-0">paedML<span class="paedml-nav-text-super">&reg;</span> Windows</span>
                </div>
                <div class="d-lg-none">
                  <svg class="paedml-icon-nav">
                      <use xlink:href="#home" />
                  </svg>
                </div>
              </a>
              <a href="/#{categoryUrl()}" class="navbar-brand ml-0" title="{opts.title}">
                <div class="brand-text d-none d-md-inline-block">
                  <strong id="nav-title" class="paedml-nav-text-title {hasNoTarget}">{opts.title}</strong>
                  <span if="{contentTitle}" class="d-none d-lg-inline">
                    <span class="paedml-nav-text-title px-0" style="font-size: 0.9rem; transform: translateY(-0.1rem);">&gt;</span>
                    <strong class="paedml-nav-text-title selected">{contentTitle}</strong>
                  </span>
                </div>
              </a>
            </div>
            <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
              <li class="nav-item" title="Applikation aktualisieren">
                <span class="btn paedml" onclick={handleRefresh}>
                  <paedml-icon icon="fas-database-sync" title="Applikation und Daten aktualisieren" type="zoom" />
                </span>
              </li>
              <li class="nav-item">
                <paedml-icon-modal icon="fas-user-edit" target="#{prefix}-user-edit-modal" title="Benutzer-Einstellungen" />
                <paedml-modal-benutzer prefix="{prefix}-user-edit" />
              </li>
              <li class="nav-item" if={showBasisPreferences()}>
                <paedml-icon-modal icon="einstellungen" target="#{prefix}-base-settings-modal" title="paedML Voreinstellungen anpassen" />
                <paedml-modal-basis prefix="{prefix}-base-settings" usersuffix="{prefix}" 
                  heading="paedML Voreinstellungen" opname="Änderungen übernehmen" />
              </li>
              <li class="nav-item" title="Admin-Login">
                <a href="#logout" class="p-0" onclick={logout} style="margin-left: 16px; margin-right: -16px;">
                  <paedml-icon title="ausloggen und als einen anderen Benutzer einloggen" icon="logout-g" type="zoom" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <script>
    import API from '../api'
    import store from '../store'

    this.reduxConnect(state => ({user: state.user, page: state.page}));

    this.logout = () => {
      location.replace('')
    }

    this.showBasisPreferences = () => {
      if (!this.opts.user) return false
      const {roles} = this.opts.user
      return roles[0].id === 1 || roles[0].id === 16
    }

    this.homeUrl = () => {
      if (!this.opts.page) return '/#dashboard/home'
      return this.opts.page.sub === 'home' ? '/#dashboard' : '/#dashboard/home'
    }

    this.hasNoTarget = ''

    this.categoryUrl = () => {
      const page = this.opts.page
      const catRoute = this.opts.config.route
      this.hasNoTarget = ''
      if (page.sub) return catRoute
      if (page && page.from && page.from.main === catRoute) {
        if (page.from.sub) return page.from.url
      }
      this.hasNoTarget = 'notarget'
      return catRoute
    }

    this.requiresOpt('title');

    this.handleRefresh = () => {
      window.request.relogin()
      this.route('/')
      this.route(this.opts.page.url)
    }

    this.handleNavChange = () => {
      const sub = this.opts.page.sub
      if (sub) {
        $('#nav-title').removeClass('selected')
        const card = this.opts.config.cards.find(e => e.route === sub)
        this.contentTitle = card ? card.contentTitle : ''
      } else {
        $('#nav-title').addClass('selected')
        this.contentTitle = ''
      }
    }

    this.on('update', () => {
      this.handleNavChange()
    })

    this.on('mount', function() {
      $('#toggle-btn').on('click', function (e) {
          e.preventDefault();
          if ($(window).outerWidth() > 1194) {
              $('nav.side-navbar').toggleClass('shrink');
              $('.page').toggleClass('active');
          } else {
              $('nav.side-navbar').toggleClass('show-sm');
              $('.page').toggleClass('active-sm');
          }
      });

      this.handleNavChange()
      this.update()
    });
  </script>
</paedml-header>
