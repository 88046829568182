import './paedml-icon.tag'

<paedml-table>
    <table ref="dt" style="width: 100%;" class="table table-striped table-hover">
      <thead>
        <tr>
          <th each={column in columns} class="paedml-table-header"></th>
        </tr>
      </thead>
    </table>
    <div if={warning} class="mt-2 ml-1 p-2 border border-danger">
      <span><paedml-icon icon="fas-exclamation-triangle" inv={true} /> <strong class="text-danger pl-1 pt-1">{warning}</strong> </span>
    </div>

    <script>
      import * as i18n from '../config/i18n/german'
      import * as R from 'ramda'
      import { getAPIForCard, createDataTablesCallback } from '../util/api-util'
      import API from '../api'
      this.requiresOpt('config');
      this.prefix = this.config.prefix;
      
      // defined in paedml-tables-config.js
      const tableConfig = this.config.table
      if (! tableConfig) throw Error("Unknown tableConfig '" + this.prefix + "'");

      const filterExportedColumns = col => col.reduce((acc, c, i) => {
        if (c.exportable !== false) acc.push(i)
        return acc
      }, [])

      const filterPrintableColumns = col => col.reduce((acc, c, i) => {
        if (c.printable !== false) acc.push(i)
        return acc
      }, [])

      const remapResponsivePriority = cols => cols.map(v => {
        const {priority = 10} = v
        return { ...v, responsivePriority: 10000 - priority * 1000 }
      })

      const getColumnDefs = cols => cols.map(c => {
        if(c.width !== undefined)
          return { orderData: [c.sortCol ? c.sortCol : c.col], targets: [c.col], width: c.width }

        return { orderData: [c.sortCol ? c.sortCol : c.col], targets: [c.col] }
      });

      this.columns = remapResponsivePriority(tableConfig.columns);
      this.columnDefs = getColumnDefs(this.columns)

      this.groupDrawCallback = (groupColumn, numVisibleColumns) => function() {
        const api = this.api();
        const rows = api.rows( {page:'current'} ).nodes();
        let last = null;

        api.column(groupColumn, {page:'current'} ).data().each( ( group, i ) => {
            if ( last !== group ) {
                $(rows).eq( i ).before(
                    '<tr class="paedml-group-simple"><td colspan="' + numVisibleColumns + '">'+group+'</td></tr>'
                );

                last = group;
            }
        } );
      }

      const datatableDefinition = {
        'sDom': '"top"i',
        columns: this.columns,
        columnDefs:  this.columnDefs,
        ajax: { url: tableConfig.data.url, dataSrc: tableConfig.data.dataSrc },
        pageLength: 10,
        lengthChange: false,
        select: {
            style: 'os'
        },
        buttons: [
            { extend: 'print', className: 'btn btn-outline-primary btn-sm', 
              text: `<svg class="paedml-icon"><use xlink:href="#drucker-entsperrt" /></svg>`,
              exportOptions: {
                columns: filterPrintableColumns(this.columns)
              }
            },
            { extend: 'pdf', className: 'btn btn-outline-primary btn-sm', 
              text: `<svg class="paedml-icon"><use xlink:href="#fas-file-pdf" /></svg>`,
              exportOptions: {
                columns: filterPrintableColumns(this.columns)
              }
            },
            { extend: 'excel', className: 'btn btn-outline-primary btn-sm', 
              text: `<svg class="paedml-icon"><use xlink:href="#fas-file-excel" /></svg>`,
              exportOptions: {
                columns: filterExportedColumns(this.columns)
              }
            },
        ],
        responsive: {
            details: false
        },

        infoCallback: ( settings, start, end, max, total, pre ) => {
          let selected = this.getSelectedRows().length
          if (total < max && selected && false) {
            this.warning = 'Achtung: Die Datensätze wurden gefiltert. Es kann evtl. dazu kommen, dass selektierte Einträge nicht in den angezeigten Datensätzen vorkommen!'
          } else {
            const dt = this.getDataTable()
            if (dt) {
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.warning = 'Achtung: Es befindet sich ein ausgewählter Eintrag außerhalb der sichtbaren Seite.'
                } else {
                  this.warning = `Achtung: Es befinden sich ${hidden} ausgewählte Einträge außerhalb der sichtbaren Seite.`
                }
              } else {
                this.warning = ''
              }
            } else {
              this.warning = ''
            }
          }
          this.update()
          return pre
        }
      };

      datatableDefinition.oLanguage = i18n.datatables

      datatableDefinition.columns.forEach((e, i) => {
        const visible = datatableDefinition.columns.filter(e => e.visible !== false).length
        if (e.group === 'simple') {
          Object.assign(datatableDefinition, {drawCallback: this.groupDrawCallback(i, visible)});
        }
      })

      // override above definition if specified
      if (tableConfig.custom) {
        Object.assign(datatableDefinition, tableConfig.custom);
      }

      if (this.config.endpoint) {
        const api = getAPIForCard(this.config)
        datatableDefinition.ajax = createDataTablesCallback(api)
      }

      if (this.config.customAPI) {
        datatableDefinition.ajax = createDataTablesCallback(API[this.config.customAPI])
      }

      this.selectedRows = []
      this.totalRows = -1;

      this.getSelectedRows = () => this.selectedRows
      this.getDataTable = () => this.datatable

      this.on('mount', function() {
        this.datatable = $(this.refs.dt).DataTable(datatableDefinition);
        if (tableConfig.custom.select && tableConfig.custom.select.style === 'multi+shift') {
          $('thead > tr > th.paedml-table-header', this.refs.dt).addClass('multi')
        }
        const self = this
        
        $(this.refs.dt).on('init.dt', function() {
          self.totalRows = self.datatable.page.info().recordsTotal
          self.parent.trigger('tableLoaded', { totalRows: self.totalRows})
          const extBtns = $('#' + self.prefix + '-button-extension').append(self.datatable.buttons().container());
          $('button.buttons-print', extBtns).prop('title', "Drucken")
          $('button.buttons-excel', extBtns).prop('title', "Als Excel exportieren")
          $('button.buttons-pdf', extBtns).prop('title', "Als PDF exportieren")
        }) 

        const handleWarning = (dt, selected) => {
          const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.warning = 'Achtung: Es befindet sich ein ausgewählter Eintrag außerhalb der sichtbaren Seite.'
                } else {
                  this.warning = `Achtung: Es befinden sich ${hidden} ausgewählte Einträge außerhalb der sichtbaren Seite.`
                }
              } else {
                this.warning = ''
              }
        }
        $(this.refs.dt).on( 'select.dt', function ( e, dt, type, indexes ) {
          self.selectedRows = self.datatable.rows({ selected: true }).data();
          handleWarning(self.datatable, self.selectedRows.length)
          self.parent.trigger('rowSelectionChanged', self.selectedRows)
        })
        $(this.refs.dt).on( 'deselect.dt', function ( e, dt, type, indexes ) {
          self.selectedRows = self.datatable.rows({ selected: true }).data();
          handleWarning(self.datatable, self.selectedRows.length)
          self.parent.trigger('rowSelectionChanged', self.selectedRows)
        })
      });

      this.on('reload', (keepSelection) => {
        const selected = this.datatable.rows('.selected');
        this.datatable.ajax.reload(() => {
          this.totalRows = this.datatable.page.info().recordsTotal
          this.parent.trigger('tableLoaded', { totalRows: this.totalRows, keepSelection})
          if (keepSelection && selected) {
            selected.select()
          }
        }, false)
      })

      // triggered via search field
      this.on('search', function(term) {
        this.datatable.search(term).draw();
      })

      // triggered via paging dropdown
      this.on('setPageLength', function(newLength) {
        this.datatable.page.len(newLength).draw();
      })

      // triggered via filter dropdown
      this.on('setFilter', function(colname, value) {

        if(value === null || value === ''){
          this.datatable.columns(colIndex).search( '' ).draw();
          return;
        }

        const colIndex = R.findIndex(R.propEq('filter', colname))(this.columns);
        //filterType: smart, contains, match
        const filterType = datatableDefinition.columns[colIndex].filterType ?? 'smart';

        switch (filterType){
          case 'regex':
          const regex = `^${value}$|${value},| ${value}$`
            this.datatable.columns(colIndex).search(regex, true, false).draw();
            break;
          case 'smart':
          default:
            this.datatable.columns(colIndex).search( value ).draw();
            break;
          }
      })

      this.on('selectAll', function() {
        this.datatable.rows().select();
        this.selectedRows = this.datatable.rows('.selected').data();
        this.datatable.draw('page')
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectFiltered', function() {
        this.datatable.rows({filter: 'applied'}).select();
        this.selectedRows = this.datatable.rows('.selected').data();
        this.datatable.draw('page')
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectPage', function() {
        this.datatable.rows({page: 'current'}).select();
        this.selectedRows = this.datatable.rows('.selected').data();
        this.datatable.draw('page')
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectNothing', function() {
        this.datatable.rows().deselect();
        this.selectedRows = this.datatable.rows('.selected').data();
        this.datatable.draw('page')
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('recalc', () => {
        this.datatable.responsive.recalc()
      })
    </script>
</paedml-table>
