<paedml-modal-access-cloud>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true} ref="modal"
                >

    <yield to="fields">
      <div class="row justify-content-center">
        <div class="col-5 ">
          <span>{parent.data.text} deaktivieren</span>
        </div>
        <div class="col-1">
          <paedml-form-input name="syncWithO365"
            type="checkbox" revlut="jaNein" handlechange={parent.handleToggle}
          />
        </div>
        <div class="col-5">
          <span>{parent.data.text} aktivieren</span>
        </div>
      </div>
  
      <virtual if={parent.data.selectedRows > 1 }>
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-warning">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">Der aktuelle Zustand der {parent.data.text} kann nicht ermittelt werden, da mehrere {parent.patient.plural} ausgewählt sind.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientSingularFormatter} from '../util/modal-util'
    import API from '../api'

    this.prefix = opts.prefix
    this.variant = opts.variant
    this.domain = opts.domain
    this.patient = opts.variant === "students" ? {singular: 'Schüler', plural: 'Schüler*innen'} : {singular: 'Lehrer', plural: 'Lehrer*innen'}
    this.data = {
      text: opts.patient.singular + " wird aktiviert",
      patient: opts.patient,
      syncWithO365: false,
      selectedRows: 0
    }
    this.api = this.opts.api
    this.op = opts.variant === 'students' ? "student" : 'teacher'
    
    this.handleToggle = (val) => {
      this.data.syncWithO365 = val
      this.refs.modal.trigger('updateButton', this.data.syncWithO365 ? 'Aktivieren' : 'Deaktivieren')
    }

    this.on('dataRequested', (selectedRows) => {
      this.data = {
        text: opts.patient.singular,
        selectedRows: selectedRows.length
      }
      var lockedState = opts.variant === "students" ? selectedRows[0][8] : selectedRows[0][7]
      this.setInitialState(lockedState)
    })

    this.setInitialState = (lockedState) => {
      var syncWithO365 = false;
      if(this.data.selectedRows === 1) {
        syncWithO365 = lockedState === "ja"
      } 
      this.handleToggle(syncWithO365)
    }

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const formatter = this.data.syncWithO365 ? formatterLock : formatterUnlock
      const deleteMessageSuccess = formatter(idLut, true)
      const deleteMessageFailure = formatter(idLut, false)

      return API[this.domain][this.op].sync(formData.ids, this.data.syncWithO365).then(rsp => {
        const [deletedIds, status] = rsp
        if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server')

        const {succeeded, failed} = deletedIds
        if (failed.length) {
          if (succeeded.length) {
            // partly success
            const sLut = toTrueMap(R.map(id => idLut[id], succeeded))
            // remove deleted entries
            $('span.tag', this.refs.tip).each(function(i,e) { 
              if (sLut[$(e).text()]) $('span', e).click()
            })

            this.parent.trigger('reloadTable')
            return Promise.reject([deleteMessageSuccess(succeeded), deleteMessageFailure(failed)])
          }
          return Promise.reject(deleteMessageFailure(failed))
        }

        this.parent.trigger('reloadTable')
        
        return deleteMessageSuccess(succeeded)
      })

    }

   const formatterLock = patientSingularFormatter(this.data.patient, 'aktiviert')
   const formatterUnlock = patientSingularFormatter(this.data.patient, 'deaktiviert')

  </script>
</paedml-modal-access-cloud>
