<paedml-modal-add-to-proj>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true}
                >

    <yield to="fields">
      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
          <p class="my-auto">Verfügbare Projekte werden aktualisiert...</p>
          <div class="sk-wave">
            <div class="sk-rect sk-rect1"></div>
            <div class="sk-rect sk-rect2"></div>
            <div class="sk-rect sk-rect3"></div>
            <div class="sk-rect sk-rect4"></div>
            <div class="sk-rect sk-rect5"></div>
          </div>
        </div>
    
      <div class="row" if={!parent.loading}>
        <div class="col-sm-12">
          <paedml-form-input name="projId" label="Ziel-Projekt"
              type="select" values={parent.getTargetProjects()}
              required data-msg="Bitte das Ziel-Projekt aus"
            />
        </div>
      </div>

      <div>
        <paedml-form-input name="redirect" placeholder="Nach dem Hinzufügen zu 'meine Projekte' springen" fgclass="py-1"
          type="checkbox" revlut="jaNein"
        />
      </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'

    
    this.prefix = opts.prefix
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient
    
    this.on('update', () => {
      this.loading = false
    })

    this.getTargetProjects = () => this.data.availableProjects || []

    this.refreshProjects = () => {
      this.loading = true
      API.projekte.getAll().then(rsp => {
        const [projects, status] = rsp
        this.data.projects = projects.reduce((acc, v) => { acc[v.id] = v; return acc}, {})
        this.data.availableProjects = projects.map(e => [e.id, e.name])
        this.loading = false
        this.update()
      })

    }
    this.on('dataRequested', () => {
      this.refreshProjects()
      this.data.redirect = true
    })

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const members = formData.ids.map(e => parseInt(e))
      const project = this.data.projects[formData.projId]
    
      const projectMembers = R.union(members, project.members.map(e => e.id))

      return API.projekte.members(formData.projId, projectMembers).then(rsp => {
        const [data, status] = rsp
          if (status.success) {
            this.parent.trigger('reloadTable')
            this.parent.parent.opts.siblings.filter(card => card.config.name === 'projekte').forEach(card => {
              card.tags['paedml-card'].trigger('reloadTable')
            })

            if (this.data.redirect) setTimeout(() => { window.location.href = '/#meine/projekte' }, 500)
            return `Hinzufügen zum Projekt erfolgreich`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Hinzufügen zum Projekt fehlgeschlagen. Grund: ${reason}`)
      })

    }

  </script>
</paedml-modal-add-to-proj>
