<paedml-paging-dropdown>
  <span class="dropdown">
    <button class="btn dropdown-toggle paedml-btn-dropdown" type="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>Zeige</span>
      <span>{currentValue}</span>
      <span>Zeilen</span>
    </button>
    <div class="dropdown-menu paedml-btn-dropdown">
      <a class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={setPageLength} data-value="-1">alle</a>
      <a each={val in values} class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={setPageLength}>{val}</a>
    </div>
  </span>

  <script>
    import * as R from 'ramda'

    this.values = R.split(',', this.requiresOpt('values'))
    this.currentValue = this.opts.currentvalue || 10;

    this.setPageLength = function(e) {
      const newLength = e.target.dataset.value || e.target.text
      opts.pagelengthfunc(newLength)
      this.currentValue = e.target.text
    }.bind(this)
  </script>
</paedml-paging-dropdown>
