import Masterdata from '../config/masterdata'
import {reversePairs} from '../util/mapping-util'

import * as R from 'ramda'
import {isEnabled} from '../util/config-util'

const Session = {}

export const getMap = key => Session.map[key]
export const getLut = key => Session.lut[key]

const getSessionDefaults = () => {
    
    const rollen = Masterdata.roles.filter(r => r.roleTypeId === 4).reduce((acc, r) => {
        acc[r.id] = r.shortName
        return acc
    }, {})
    
    const shareFolderAccessPolicy = Masterdata.shareFolderAccessPolicyMap
    const projectShareFolderAccessPolicy = Masterdata.projectShareFolderAccessPolicyMap
    const firewallCategories = Masterdata.firewallCategories
    const profileType = Masterdata.profileTypeMap
    const profileTypeByVariant = Masterdata.profileTypeByVariantMap
    const opsiActions = Masterdata.opsiActions

    return {
        map: {
            jaNein: {
                true: 'ja',
                false: 'nein'
            },
            trueFalse: {
                true: 'true',
                false: 'false'
            },
            rollen,
            shareFolderAccessPolicy,
            projectShareFolderAccessPolicy,
            schularten: {},
            klassen: {},
            firewallCategories,
            rooms: {},
            platforms: {},
            profileType,
            profileTypeByVariant,
            opsiActions
        },
        lut: {
            jaNein: {ja: true, nein: false},
            trueFalse: {true: true, false: false},
            rollen: reversePairs(rollen),
            shareFolderAccessPolicy: reversePairs(shareFolderAccessPolicy),
            projectShareFolderAccessPolicy: reversePairs(projectShareFolderAccessPolicy),
            schularten: {},
            klassen: {},
            rooms: {},
            platforms: {},
            profileType: reversePairs(profileType),
            profileTypeByVariant: reversePairs(profileTypeByVariant),
            opsiActions: reversePairs(opsiActions)
        },
        user: { loggedIn: false },
        getMap,
        getLut,
        settings: {},
        feSettings: {
            firewall: {},
        },
        room: '',
        pref: {},
        dyn: {},
        timers: {},
    }
}

export const initSession = () => {
    $.get('/frontend-settings.json').then(data => {
        Session.feSettings = data
        
        // dynamically remove roles
        const rolesToBeRemoved = { }
            if (!isEnabled('extensions', 'enableM365')) {
            rolesToBeRemoved[20] = true
        }
        if (!isEnabled('extensions', 'enableMoodle')) {
            rolesToBeRemoved[21] = true
        }

        if (rolesToBeRemoved) {
            Masterdata.roles = Masterdata.roles.filter(e => !rolesToBeRemoved[e.id])
        }
        R.forEach(k => delete Session[k], R.keys(Session))
        R.forEachObjIndexed((v,k) => {
            Session[k] = v
        })(getSessionDefaults())
        Session.feSettings = data
    })
}

initSession()

export default Session
