import '../paedml-modal.tag'
import '../paedml-form-input.tag'

<paedml-modal-info-project>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="row">
        <div class="col-sm-12">
          <paedml-form-input name="name" col="1" label="Name" readonly />
        </div>
        <div class="col-sm-12">
          <paedml-form-input name="description" col="2" label="Beschreibung" readonly />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-9">
          <paedml-form-input name="leadersDP" col="3" label="Leitung" readonly />
        </div>
        <div class="col-sm-3">
          <paedml-form-input name="numMembers" col="11" label="# Teilnehmer" readonly />
        </div>
      </div>

    <div class="row">
        <div class="col-sm-12">
          <paedml-form-input name="shareFolder" col="9" label="Tauschlaufwerk" readonly />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <paedml-form-input name="expiryDateDP" col="12" label="Ablaufdatum" readonly />
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../../util/modal-util'

      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}

      this.on('rowMappingRequested', cols => {
        (cols)
        autoMapRowToData(this, cols)
      })

      this.submitHandler = formData => {
        return Promise.resolve('OK')
      }
      
    </script>
</paedml-modal-info-project>
