import '../paedml-modal.tag'
import '../paedml-form-input.tag'

<paedml-modal-info-computer>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} 
    selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} large={true} >
  
    <yield to="fields">
      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
          <p class="my-auto">Liste der Softwarepakete wird aktualisiert...</p>
          <div class="sk-wave">
            <div class="sk-rect sk-rect1"></div>
            <div class="sk-rect sk-rect2"></div>
            <div class="sk-rect sk-rect3"></div>
            <div class="sk-rect sk-rect4"></div>
            <div class="sk-rect sk-rect5"></div>
          </div>
      </div>

      <div show={!parent.loading} class="relative">
          <div class="max-width">
            <table id="{opts.prefix}-table" class="max-width table table-striped table-hover">
            </table>
          </div>
          <button class="btn btn-sm refresh-btn" onclick={parent.refreshSoftwareProducts}>
            <paedml-icon icon="aktualisieren" inv={true} title="Installationsstand von opsi abrufen" />
          </button>
      </div>
    </yield>
  </paedml-modal>
    
  <script>
    import * as i18n from '../../config/i18n/german'
    import * as R from 'ramda'
    import API from '../../api'
    import Masterdata from '../../config/masterdata'
    
    this.op = opts.op
    this.prefix = opts.prefix
    this.variant = opts.variant
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient 
    
    this.heading = this.opts.heading

    this.on('update', () => {
      this.loading = false
    })

    this.mapSoftware = (e) => ({ 
      requestedAction: Masterdata.opsiActions[e.requestedAction],
      lastAction: Masterdata.opsiActions[e.lastAction],
      actionResult: Masterdata.opsiActionResult[e.actionResult],
      status : Masterdata.opsiStatus[e.status],
      id: e.softwareProduct.id,
      name: e.softwareProduct.name,
      version: `${e.productVersion}-${e.packageVersion}`
    })

    this.getTable = () => this.table

    this.refreshSoftwareProducts = (e) => {
      e.preventDefault()
      e.stopPropagation()
      
      this.loading = true
      
      API.software.computers.info.refresh(this.data.computerId).then(rsp => {
        const [software, status] = rsp
        this.loading = false
        this.data.software = software
        
        const dataSource = this.data.software.map(e => {
          const id = e.softwareId
          return this.mapSoftware(e)
        })
        this.table.clear().draw()
        this.table.rows.add(dataSource)
        this.update()
        this.table.draw()
      })
    }

    this.getSoftwareProducts = () => {
      this.loading = true
      API.software.computers.info.get(this.data.computerId).then(rsp => {
        const [software, status] = rsp
        this.loading = false
        this.data.software = software
        
        this.update()
        this.refreshTable(this.data.software)
        this.table.draw()
      })
    }

    this.refreshTable = (software) => {
      const dataSource = software.map(e => {
        const id = e.softwareId
        return this.mapSoftware(e)
      })

      const tableSelector = `#${opts.prefix}-table`
      this.table = $(tableSelector).DataTable( 
        {
          data: dataSource,
          columns: [
            { data: 'id', title: 'id', visible: false, searchable: false },
            { data: 'name', title: 'Softwarepaket' },
            { data: 'status', title: 'Status' },
            { data: 'requestedAction', title: 'Gewünschte Aktion' },
            { data: 'lastAction', title: 'Letzte Aktion' },
            { data: 'actionResult', title: 'Resultat' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'none'
          },
          oLanguage: i18n.short
        })
    }

    this.on('hide', () => {
      this.destroyTable()
    })

    this.destroyTable = () => {
      if (this.table) this.table.destroy()
      this.table = null
    }

    this.on('rowMappingRequested', (cols) => {
      this.data.computerId = cols[0]
      this.getSoftwareProducts()
    })

    this.on('shown', ()=> {
      if (this.table) this.table.draw()
    })

    this.submitHandler = formData => {
      return Promise.resolve('OK')
    }
      
    </script>
</paedml-modal-info-computer>
