<paedml-modal-freigabe-singlelogin>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="0" idtextcol="1"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true} ref="modal"
                >
    <yield to="fields">
     <div class="row">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">{parent.text}</span>
          <span class="pl-1 pt-2">Die Anmeldung wird für {parent.loginPeriod} Minuten freigegeben.</span>
        </div>
      </div>
      <div class="row" if={parent.isLoggedOn()}>
        <div class="col-1">
          <paedml-form-input name="logoffDevices" type="checkbox" revlut="jaNein"
          />
        </div>
        <div class="col-11">
          <span>Benutzer von allen Computern abmelden</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <paedml-form-input name="loggedOnPcs" readonly label="Der Benutzer ist an folgenden Computern angemeldet" />
        </div>
      </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientSingularFormatter} from '../util/modal-util'
    import API from '../api'
    import Session from '../store/session'

    this.prefix = opts.prefix
    this.variant = opts.variant
    this.patient = opts.variant === "teachers" 
      ? {singular: 'Lehrer', plural: 'Lehrer*innen'} 
      : opts.variant === "students" ? {singular: 'Schüler', plural: 'Schüler*innen'} 
      : {singular: 'Klassenarbeits-Teilnehmer', plural: 'Klassenarbeits-Teilnehmer'} 
    this.api = API.singlelogin[opts.variant]
    this.op = opts.variant
    this.data = {
      loggedOn: 'nein',
      loggedOnPcs: "",
      userName: ""
    }
    this.loginPeriod = Session.feSettings.mehrfachanmeldung.loginPeriod

    this.isLoggedOn = () => this.data.loggedOn === 'ja'

    this.on('dataRequested', (cols) => {
      this.data = {
        loggedOn: this.getLoggedOnState(opts.variant, cols[0]),
        loggedOnPcs: this.getLoggedOnDevices(opts.variant, cols[0]),
        userName: cols[0][0],
      }

    this.text = `Anmeldung wird freigegeben für ${this.patient.singular} mit dem Benutzernamen "${this.data.userName}"`
    })
    
    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')
      
      return this.api.freigabe(formData.ids[0], this.data.logoffDevices).then(rsp => {
        const [data, status] = rsp
        if (status.error) return Promise.reject('Freigabe fehlgeschlagen')

        if(status.success){
          this.parent.trigger('reloadTable')
          return 'Freigabe erfolgreich.'
        }
      })
    }

    this.getLoggedOnState = (variant, cols) => {
      switch(variant) {
        case 'teachers': return cols[5]
        case 'students': return cols[6]
        case 'exammembers': return cols[9]
      }
      return 'nein';
    }

    this.getLoggedOnDevices = (variant, cols) => {
      switch(variant) {
        case 'teachers': return cols[6]
        case 'students': return cols[7]
        case 'exammembers': return cols[8]
       
      }
      return '';
    }

  </script>
</paedml-modal-freigabe-singlelogin>
