<paedml-modal-help>
  <paedml-modal prefix={opts.prefix} heading="Hilfe" opname="OK" large={true}
                submitfunc={submitHandler}
                okbutton="Schließen"
                >

    <yield to="fields">

      <table id="{opts.prefix}-modal-table" style="width: 100%;" class="table table-striped table-hover">
      </table>
      
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import Session from '../store/session'
    import * as i18n from '../config/i18n/german'

    this.reduxConnect(state => ({user: state.user, page: state.page}));

    this.prefix = opts.prefix
    this.data = { }
    
    // this.on('update', () => {
    //   this.loading = false
    // })

    this.on('dataRequested', () => {
      const selector = `#${opts.prefix}-modal-table`
      const mlut = {
        'application/pdf' : 'fas-file-pdf',
        'video/mp4' : 'fas-file-video',
      }
      const term = (this.opts.page ? this.opts.page.url : '').split('/')[0]
      const lut = Session.help.reduce((acc,v) => {
        acc[v.index] = v
        return acc
      }, {})
      const desc = Session.help.reduce((acc,v) => {
        acc[v.filename] = v.description
        return acc
      }, {})
      this.table = $(selector).DataTable( {
        data: Session.help,
        columns: [
          { data: 'category', title: 'Kategorie' },
          { data: 'filename', title: 'Beschreibung', fnCreatedCell: (td, val) => $(td).html(`<a href="${val}" target="_blank">${desc[val]}</a>`) },
          { data: 'description', title: 'description', visible: false },
          { data: 'index', title: 'Medium', fnCreatedCell: (td, val) => $(td).html(`<a href="${lut[val].filename}" target="_blank"><svg class="paedml-icon black" style="margin-top: -3px"><use xlink:href="#${mlut[lut[val].mime]}" /></svg></a>`)},
        ],
        paging: true,
        // scrollY: 400,
        // select: true
        info: false,
        // searching: false,
        oLanguage: i18n.short,
        destroy: true,
        "search": {
          "search": term
        }
      } )
      setTimeout(() => $('input[type=search]','#hilfe-modal').focus().select(), 500)
      // console.debug('page', this.opts.page)

    })

    this.submitHandler = formData => {
      // console.debug('help', formData)
      return Promise.reject('implementing')
    }

  </script>
</paedml-modal-help>
