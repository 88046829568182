import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-bsa>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} 
      selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} 
      idtextcol={opts.idtextcol} submitfunc={submitHandler} okcondition="{() => !isChecking}">
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="schoolType" col="5" label="Schulart" readonly/>
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="schoolClass" col="7" label="Klasse" readonly/>
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="surname" col="3" label="Nachname"
              required data-msg="Bitte geben Sie den Nachnamen ein"
              data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Nachnamen ein."
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="givenName" col="4" label="Vorname"
                required data-msg="Bitte geben Sie den Vornamen ein"
                data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Vornamen ein."
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="userName" col="2" label="Benutzername" placeholder="Falls leer, dann wird er automatisch generiert"
              data-rule-username data-msg-username="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende."
              handlechange={parent.handleUserNameChange} changemode="keyup"
              verifychange={parent.verifyUsernameChanged}
              maxlength="19" data-rule-maxlength="18" data-msg-maxlength="Der Benutzername darf nicht mehr als 18 Zeichen enthalten!" 
              data-rule-check="userName" data-msg-check="Benutzername bereits vorhanden!"
            />
            <svg if={parent.isChecking} class="paedml-icon-overlay fullrotation">
              <use xlink:href="#far-question-circle" />
            </svg>
          </div>
        </div>
        
        <virtual if={parent.data.isDuplicate}>
          <div class="paedml-input-group py-0"></div>
          <div class="row">
            <div class="col-sm-3">
              <paedml-icon icon="fas-exclamation-triangle" inv={true} />
              <span>Duplikat</span>
            </div>
            <div class="col-sm-9">
              <paedml-form-input name="surpressWarnings" placeholder="Zur Kenntnis genommen / Warnung ignorieren." type="checkbox" 
                handleChange={parent.handleSuppressWarnings}
              />
            </div>
          </div>
        </virtual>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client, userNameAvailable, clearUserNameAvailabilityCtx } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Masterdata from '../config/masterdata'

      this.opname = this.opts.opname
      this.data = {}
      this.api = this.opts.api
      this.lastUserName = this.data.userName
      
      this.isResolved = () => {
         return this.resolved ? this.resolved : false;
        }

      this.handleSuppressWarnings = val => {
        $('input[name=userName]', this.root).data('userName', val)
        $('input[name=userName]', this.root).valid()
        this.resolved = val
      }

      this.userNameAvailable = userNameAvailable(this, 1000)
      this.userNameAvailableQuick = userNameAvailable(this, 250)

      this.handleUserNameChange = (userName) => {
        this.lastUserName = userName
        $('input[name=userName]', this.root).data('userName', true)
        if (!userName) {
          this.isChecking = false
          this.resolved = true
          this.update()
          return
        }
        if (/^[A-Za-z0-9][-._A-Za-z0-9]{0,16}[A-Za-z0-9]$|^[A-Za-z0-9]$/.test(userName)) {
          this.isChecking = true
          this.userNameAvailable(userName)
        }
      }

      this.verifyUsernameChanged = (userName) => {
        return this.lastUserName !== userName
      }

      this.on('dataRequested', () => {
        clearUserNameAvailabilityCtx(this)
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
        this.data.isDuplicate = cols[9] === 'true'
        this.data.surpressWarnings = false
        this.resolved = !this.data.isDuplicate
        this.userNameAvailableQuick(this.data.userName)
      })

      this.submitHandler = formData => {
        const sData = {
          ...R.omit(['ids', 'idTexts'], formData),
        }
        return this.api.update(formData.ids[0], sData)
            .then(rsp => {
              const [data, status] = rsp

              if (status.success) {
                this.parent.trigger('reloadTable')
                return 'Änderung(en) erfolgreich übernommen'
              }

              console.error('error-status', status)
              console.error('error-data', data)
              if (data.message) {
                return Promise.reject(data.message)
              }
              return Promise.reject('Änderung(en) konnten nicht übernommen werden!')
            })

      }
    </script>
</paedml-modal-edit-bsa>
