import './paedml-search.tag'
import './paedml-paging-dropdown.tag'
import './paedml-selection-dropdown.tag'
import './paedml-filter-dropdown.tag'
import './paedml-table.tag'
import './paedml-tiles.tag'
import './paedml-tiles-home.tag'
import './paedml-icon.tag'

<paedml-card if="{isLoggedIn()}" show={visible && inclusive()}>
  <div class="paedml-card mb-{active ? '0' : '1'}" id="{prefix}-rv-card">
    <div>
      <div class="row">
        <div class="col-{this.splitBreak}-{expanded ? this.xxlLeft : 12}">
          <div class="row p-2 align-items-center">
            <div class="col-auto" style="min-width: 240px">
              <a href="{active ? pageUrl : perm}" class="btn p-0 mt-2" title="{active ? 'Zuklappen und die Übersicht anzeigen' : 'Datensätze anzeigen / bearbeiten'}">
                <h4 class="paedml-text-white-clickable {active:active}">{config.contentTitle}</h4>
              </a>
            </div>
            <div class="col-auto paedml-icon-group">
              <yield />
            </div>
      
            <div class="col">
                <paedml-search show={active} searchfunc={searchFunc} adhoc="{representation !== 'table'}"></paedml-search>
            </div>
            <div class="col-auto p-0" show="{representation === 'table'}">
              <svg show={active} class="inline-help" data-toggle="tooltip" data-placement="auto" data-html="true" title="Bitte geben Sie einen Suchbegriff ein und bestätigen Sie diesen mit der Eingabe-Taste.<br/><br/>Sie können diesen Vorgang beliebig oft wiederholen, um die gesuchten Datensätze effektiv einzuschränken.<br/><br/>Der Filter wird auf allen Spalten (mit wenigen Ausnahmen) angewandt.">
                <use xlink:href="#far-question-circle" />
              </svg>
            </div>
            <div if={active} class="pull-right mr-2">
              <a if={expanded} href="javascript:void(0);" onclick={toggleHeadingExtension}>
                <paedml-icon class="d-{this.splitBreak}-none" title="Erweiterte Filtersteuerung ausblenden" icon="far-caret-square-up" type="zoom" />
                <paedml-icon class="d-none d-{this.splitBreak}-block" title="Erweiterte Filtersteuerung ausblenden" icon="far-caret-square-right" type="zoom" />
              </a>
              <a if={!expanded} href="javascript:void(0);" onclick={toggleHeadingExtension}>
                <paedml-icon class="d-{this.splitBreak}-none" title="Erweiterte Filtersteuerung einblenden" icon="far-caret-square-down" type="zoom" />
                <paedml-icon class="d-none d-{this.splitBreak}-block" title="Erweiterte Filtersteuerung einblenden" icon="far-caret-square-left" type="zoom" />
              </a>
            </div>
          </div>
        </div>
        <div show={active && expanded} class="my-auto col-{this.splitBreak}-{expanded ? this.xxlRight : 12}">
          <div class="row pt-0 pr-2 align-items-center justify-content-between">
            <div class="col-auto pr-2" if={representation === 'table'}>
              <paedml-paging-dropdown title="Länge der angezeigten Einträge pro Seite setzen" pagelengthfunc={pageLengthFunc} currentvalue={pageLengthDropdown} 
                values="5,10,15,30,60,100"></paedml-paging-dropdown>
            </div>
            <div class="col-auto px-2" if={representation !== 'tiles-home'}>
              <span class="pr-1" if={representation === 'tiles'}></span>
              <paedml-selection-dropdown disablealle={disablealle} title="Auswahl aufheben oder setzen" selectioninfofunc={selectionInfoFunc} 
                selectionstatusfunc={selectionStatusFunc} selectfilteredfunc={selectFilteredFunc} selectallfunc={selectAllFunc} selectnothingfunc={selectNothingFunc} 
                selectpagefunc={selectPageFunc}></paedml-selection-dropdown>
            </div>
            <div class="col-auto px-2" each="{v, k in getFilters()}" >
              <paedml-filter-dropdown ref="filters" title="Datensätze nach {v.title} filtern" if={k} colname={k} coltitle={v.title} values={v.values} 
                filterfunc={filterFunc}></paedml-filter-dropdown>
            </div>
            <div class="col-auto ml-auto">
              <div id="{prefix}-button-extension"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="{prefix}-collapse" class="collapse" aria-labelledby="{prefix}-heading" data-parent="#accordion">
    <div if="{representation === 'table'}" class="table-responsive">
      <paedml-table ref="{prefix}" config={config} />
    </div>
    <div if="{representation === 'tiles'}" class="paedml-tiles">
      <paedml-tiles ref="{prefix}" config={config} />
    </div>
    <div if="{representation === 'tiles-home'}" class="paedml-tiles">
      <paedml-tiles-home ref="{prefix}" config={config} />
    </div>
  </div>

  <script>
    import { isPermitted } from '../util/permission'
    import * as R from 'ramda'
    import API from '../api'
    import Session from '../store/session'

    this.reduxConnect(state => ({user: state.user, page: state.page}));
    
    this.disablealle = opts.disablealle || (this.config.custom && this.config.custom.disablealle)

    this.getFilters = () => {
      const availableFilters = this.opts.user.filters || {}
      return this.config.table.columns
        .filter(col => col.filter)
        .reduce((acc, e) => {
          if (e.dynamicfilter) {
            acc[e.filter] = {title: e.filtertitle || e.filter, values: API.dynamicfilter[e.dynamicfilter]}
          } else {
            acc[e.filter] = {title: e.filtertitle || e.filter, values: availableFilters[e.filter]}
          }
          return acc
        }
        , {})
    }

    this.prefix = this.config.prefix
    this.suffix = this.config.suffix
    this.selectedRows = []
    this.totalRows = -1
    this.pageUrl = opts.perm
    this.perm = opts.perm + '/' + this.config.route
    this.siblings = opts.siblings
    this.pageLengthDropdown = (this.config.table.custom || {}).pageLength || 10
    this.representation = this.config.representation === 'tiles' ? 'tiles' : this.config.representation === 'tiles-home' ? 'tiles-home' : 'table'

    // generic functions for child elements
    this.getRestApi = () => API[this.config.name]
    this.restApi = this.getRestApi()
    this.splitBreak = this.config.splitBreak || 'xxl',
    this.xxlLeft = this.config.left || 6
    this.xxlRight = 12 - this.xxlLeft

    const getPref = (key, name) => {
      const pref = Session.pref[key]
      if (!pref) {
        Session.pref[key] = {}
        return undefined
      }
      return pref[name]
    }

    const setPref = (key, name, val) => {
      const pref = Session.pref[key]
      if (pref) {
        pref[name] = val
      } else {
        Session.pref[key] = { name: val }
      }
    }

    this.getLastExpanded = () => {
      const lastExpanded = getPref(this.config.name, 'lastExpanded')
      if (lastExpanded === undefined) {
        this.setLastExpanded(this.config.name, 'lastExpanded', true)
        return true
      }
      return lastExpanded
    }

    this.setLastExpanded = (val) => {
      setPref(this.config.name, 'lastExpanded', val)
    }

    //
    // status / visibility
    //
    this.visible = true;
    this.active = false;
    this.expanded = this.getLastExpanded();
    
    this.inclusive = () => {
      if (this.active) return true
      return this.active || this.config.hideInDashboard !== true
    }

    this.toggleHeadingExtension = () => {
      this.expanded = !this.expanded;
      this.setLastExpanded(this.expanded)
    }

    this.toggleCard = () => {
      this.active = !this.active
      this.expanded = this.active
    }

    this.atLeastOnePositiveValueInColumn = col => {
      for (let i = 0; i < this.selectedRows.length; ++i) {
        if (this.selectedRows[i][col] === 'ja') return true
      }
      return false
    }

    this.atLeastOneDefaultProfileInColumn = col => {
      for (let i = 0; i < this.selectedRows.length; ++i) {
        if (this.selectedRows[i][col].match(/^basisprofil/gi)) return true
      }
      return false
    }

    this.atLeastOneNegativeValueInColumn = col => {
      for (let i = 0; i < this.selectedRows.length; ++i) {
        if (this.selectedRows[i][col] === 'nein') return true
      }
      return false
    }

    this.atLeastOneMatchingValueInColumn = (value, col) => {
      for (let i = 0; i < this.selectedRows.length; ++i) {
        if (this.selectedRows[i][col] === value) return true
      }
      return false
    }

    this.isSchoolSettingMatchingValue = (schoolColumn, setting, value) => {
      var schoolType = this.selectedRows[0][schoolColumn]
      var schoolTypeId = Session.lut.schularten[schoolType]
      return Session.settings[schoolTypeId][setting] === value
    }

    this.isPermitted = (action) => isPermitted(this.perm + '.' + action)

    this.canCreate = () => {
      return isPermitted(this.perm + '.create')
    }

    this.selectedNone = () => this.active && this.selectedRows.length === 0

    this.selectedExactlyOne = () => this.active && this.selectedRows.length === 1

    this.selectedOneOrMore = () => this.active && this.selectedRows.length > 0

    this.canSeeInfo = () => this.active && this.selectedRows.length === 1 && isPermitted(this.perm + '.info')
    
    this.canRun = () => this.active && this.selectedRows.length > 0 && isPermitted(this.perm + '.run')

    this.canChangeFav = () => this.active && this.selectedRows.length > 0 && isPermitted(this.perm + '.fav')

    this.canChangeInternetLock = () => this.active && this.selectedRows.length > 0 && isPermitted(this.perm + '.internetLock')

    this.canChangePassword = () => this.active && this.selectedRows.length > 0 && isPermitted(this.perm + '.password');

    this.canEdit = () => this.active && this.selectedRows.length === 1 && isPermitted(this.perm + '.edit')

    this.canDelete = () =>  this.active && this.selectedRows.length > 0 && isPermitted(this.perm + '.delete')

    //
    // events
    //
    this.on('mount', () => {
      this.table = this.refs[this.prefix];

      $('#' + this.prefix + '-collapse').on('show.bs.collapse', () => {
        const lastExpanded = this.getLastExpanded()
        if (lastExpanded || $(window).width() >= 1900) {
          this.expanded = true
          if (!lastExpanded) this.setLastExpanded(this.expanded)
        }
        const siblings = this.siblings.map(card => card.tags['paedml-card'])
        if (siblings) {
          siblings
            .filter(card => card != this)
            .forEach(card => {
              $('>div', $(card.root)).addClass('paedml-fadeout')
            });
          siblings.forEach((card, i) => {
            $('>div', $(card.root)).addClass('paedml-transcardy' + i)
          })
        }
      })

      $('#' + this.prefix + '-collapse').on('shown.bs.collapse', () => {

        const siblings = this.siblings.map(card => card.tags['paedml-card'])
        const target = this; 
        target.visible = true
        target.active = true;
        document.title = this.config.pageTitle

        if (siblings) {
          siblings.forEach((card, i) => {
            $('>div', $(card.root)).removeClass('paedml-transcardy' + i)
          })
          siblings
            .filter(card => card != target)
            .forEach(card => {
              $('>div', $(card.root)).removeClass('paedml-fadeout')
              card.visible = false
              card.update()
            });
         }

        this.update()
        this.table.trigger('recalc')
      })

      $('#' + this.prefix + '-collapse').on('hide.bs.collapse', () => {
        const siblings = this.siblings.map(card => card.tags['paedml-card'])
        const target = this;
        target.active = false;
        target.expanded = false;

        // if none is active make all visible
        if (siblings) {
          const tags = siblings
          if (!tags.find(card => card.active)) {
            tags
              .filter(card => card != target)
              .forEach(card => {
                card.visible = true
                card.update()
              });
          }
        }

        this.update()
      })

      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="popover"]').popover()
    });

    this.on('tableLoaded', info => {

      if (this.parent) this.parent.trigger('tableLoaded', info)
      this.totalRows = info.totalRows
      if (!info.keepSelection) this.table.trigger('selectNothing')
      this.update()
    })

    this.on('rowSelectionChanged', selected => {
      this.selectedRows = selected;
      this.update()
    })

    const refreshFilters = (colname, value) => {
      const filters = this.refs.filters
      if (!filters) return
      if (filters.length) {
        filters.forEach(e => e.trigger('refresh', colname, value))
        return
      }
      if (filters.trigger) filters.trigger('refresh', colname, value)
    }

    this.on('reloadTable', (keepSelection = false) => {
      this.table.trigger('reload', keepSelection)
      refreshFilters()
    })

    //
    // functions for child elements
    //
    this.getSelectedRows = () => this.selectedRows;

    // used to notify page length changes
    this.pageLengthFunc = newLength => 
      this.table.trigger('setPageLength', newLength)

    // used to notify filter changes
    this.filterFunc = (colname, value) => {
      this.table.trigger('setFilter', colname, value)
      refreshFilters(colname, value)
    }

    // used to notify selection changes (requesting select filter)
    this.selectFilteredFunc = () => this.table.trigger('selectFiltered')
    
    // used to notify selection changes (requesting select all)
    this.selectAllFunc = () => this.table.trigger('selectAll')
    
    // used to notify selection changes (requesting select page)
    this.selectPageFunc = () => this.table.trigger('selectPage')
    
    // used to notify selection changes (requesting select none)
    this.selectNothingFunc = () => this.table.trigger('selectNothing')

    // used to notify selections status changes
    // returns:
    //  0: nothing selected
    //  1: something selected
    //  2: all selected
    this.selectionStatusFunc = () => {
      if (! this.selectedRows.length) return 0
      if (this.selectedRows.length === this.totalRows) return 2;
      return 1;
    }

    this.selectionInfoFunc = () => ({
      total: this.totalRows,
      selected: this.selectedRows.length,
      status: this.selectionStatusFunc()
    })

    // used to notify search
    this.searchFunc = searchString => this.table.trigger('search', searchString)

  </script>
</paedml-card>
