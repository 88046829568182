<paedml-modal-freigabe-bsa>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 1}" idtextcol="{opts.idtextcol || 2}"
                submitfunc={submitHandler}
                cancelbutton={true}
                >

    <yield to="fields">
      <div class="row">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">{parent.text}</span>
          <p></p>
          <paedml-form-input if={parent.isTeacher()} name="redirect" placeholder="Nach der Freigabe zu 'Meine Schüler*innen'-Ansicht springen" fgclass="py-1"
            type="checkbox" revlut="jaNein"
          />
        </div>
      </div>

    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'

    this.prefix = opts.prefix
    this.data = {
    }
    this.api = this.opts.api
    
    this.isTeacher = () => Session.user.roleAbbrev === 'L'

    this.on('dataRequested', (selectedRows) => {
      this.data.redirect = false
      if (selectedRows.length === 1) {
        this.text = `Schüler*in "${selectedRows[0][4]} ${selectedRows[0][3]}" wird erstellt.`
      } else {
        this.text = 'Die unten aufgeführten Schüler*innen werden erstellt.'
        if (this.isTeacher()) this.data.redirect = true
      }
    })

    this.submitHandler = formData => {
      // console.debug('Freigabe', formData.ids)
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      return this.api.approve(formData.ids).then(rsp => {
        const [data, status] = rsp
        // console.debug(data)
        if (status.success) {
          this.parent.trigger('reloadTable')

          if (this.data.redirect && this.isTeacher()) setTimeout(() => { 
            $('body').removeClass('modal-open')
            window.location.href = '/#meine/schuelerinnen' 
          }, 500)

          return `Freigabe erfolgreich`
        }

        console.error('error-data', data)
        console.error('error-status', status)
        const reason = data && data.message ? data.message : 'unbekannt'
        return Promise.reject(`Freigabe fehlgeschlagen. Grund: ${reason}`)
      })

    }

  </script>
</paedml-modal-freigabe-bsa>
