import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-create-domain>
  <paedml-modal prefix={opts.prefix} 
                heading={opts.heading} 
                opname={opts.opname} 
                edit={opts.edit} 
                selectedrowsfunc={opts.selectedrowsfunc} 
                idcol={opts.idcol} idtextcol={opts.idtextcol}
                submitfunc={submitHandler}>
  
    <yield to="fields">
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="domain" col="1" label="Name der externen Domäne"
              required data-msg="Bitte geben Sie den Namen der externen Domäne ein"
              handlechange={parent.handleDomainChange} changemode="keyup"
              data-rule-domain data-msg-domain='Geben Sie bitte einen gültigen Namen für Domäne ein'
              data-rule-check="domain" data-msg-check="Angegebene externe Domäne konnte nicht aufgelöst werden"
            />
            <svg if={parent.isChecking} class="paedml-icon-overlay fullrotation">
              <use xlink:href="#far-question-circle" />
            </svg>
          </div>
        </div>

        <virtual if={parent.data.isDuplicate}>
          <div class="row">
            <div class="col-sm-12">
              <paedml-form-input name="surpressWarnings" placeholder="Externe Domäne dennoch hinzufügen" type="checkbox" 
                handleChange={parent.handleSuppressWarnings}
              />
            </div>
          </div>
        </virtual>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import { handleSubmitCreateOrEdit, domainAvailable, clearDomainAvailabilityCtx } from '../util/api-util'
      import * as R from 'ramda'
      
      this.opname = this.opts.opname
      this.data = {}
      this.api = this.opts.api

      this.domainAvailable = domainAvailable(this, 1000)

      this.handleDomainChange = (domain) => {
        this.data.surpressWarnings = false

        if (!domain) {
          this.isChecking = false
          this.resolved = true
          this.update()
          return
        }

        if (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(domain)) {
          this.isChecking = true
          this.domainAvailable(domain)
        }
      }

      this.handleSuppressWarnings = (val) => {
        $('input[name=domain]', this.root).data('domain', val)
        $('input[name=domain]', this.root).valid()
      }

      this.submitHandler = formData => {
         return this.api.create(formData).then(rsp => {
           const [data, status] = rsp
           if (status.success) {
             this.parent.trigger('reloadTable')
             return `Externe Domäne "${formData.domain}" erfolgreich hinzugefügt.`
           }

           return Promise.reject(`Externe Domäne "${formData.domain}" konnte nicht hinzugefügt werden.`)
         })
      }
    </script>
</paedml-modal-create-domain>
