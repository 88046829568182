import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-benutzer>
  <paedml-modal prefix={opts.prefix} heading="Benutzer-Einstellungen" opname="Änderung(en) übernehmen" submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="pb-1">
          Kennwort ändern:
        </div>
        <paedml-form-input-pw uselabel={false}
          required={true}
          minLength={8}
          disablepasswordpolicy={true}
          passwordpolicy={1} />
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      this.reduxConnect(state => ({user: state.user, page: state.page}));

      this.userGroup = this.opts.usergroup
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      this.getPasswordLength = () => {
        const schulartSetting = Session.settings[this.data.schoolType || 0]
        return schulartSetting['teacherPasswordLength']
      }

      this.on('dataRequested', () => { 
        const {userName, givenName, surname, displayName} = this.opts.user
        if (Session.user.mainSchoolType) {
          const schulart = Session.lut.schularten[Session.user.mainSchoolType]
          const pwTag = this.tags['paedml-modal'].tags['paedml-form-input-pw']
          pwTag.trigger('refresh', { minPwLength: Session.settings[schulart].teacherPasswordLength })
        }
      })

      this.on('validate', (form, validator) => {
        if (this.data.errorType === 'e') {
          setTimeout(() => {
            $(form).valid()
          }, 200)
        }
      })

      this.submitHandler = formData => {
        const {id, roles} = this.opts.user
        const roleId = roles[0].id

        const urlPart = roleId === 4 ? 'teachers' : 'administrators'
        const url = `/api/${urlPart}/password`
        const payload = {
          ids: [ id ],
          password: formData.password,
          passwordPolicy: 1
        }

        return client.post(url, payload)
            .then(rsp => {
              const [data, status] = rsp
              if (status.success) {
                return 'Änderungen erfolgreich übernommen'
              }

              console.error('error-status', status)
              console.error('error-data', data)
              if (data.message) {
                return Promise.reject(data.message)
              }
              return Promise.reject('Änderungen konnten nicht übernommen werden!')
            })
      }
    </script>
</paedml-modal-benutzer>
