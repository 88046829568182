import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-domain>
  <paedml-modal prefix={opts.prefix} 
                heading={opts.heading} 
                opname={opts.opname} 
                edit={opts.edit} 
                selectedrowsfunc={opts.selectedrowsfunc} 
                idcol={opts.idcol} 
                idtextcol={opts.idtextcol}
                submitfunc={submitHandler}>
  
    <yield to="fields">

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
        <p class="my-auto">Externe Domänen werden aktualisiert...</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </div>
  
      <div class="row" if={!parent.loading}>
        <div class="col-sm-11">
          <paedml-form-input name="domain" label="Externe Domäne"
              type="select" values={parent.getDomains()}
              required data-msg="Bitte wählen Sie eine externe Domäne aus"
            />
        </div>
        <div class="col-sm-1 my-auto">
          <button class="btn btn-sm" onclick="{() => parent.refreshDomains(true)}" style="margin-left: -18px; margin-top: 18px;">
            <paedml-icon icon="aktualisieren" inv={true} title="Externe Domänen aktualisieren" />
          </button>
        </div>
      </div>
    </yield>
  </paedml-modal>
    
    <script>
      import store from '../store'
      import * as R from 'ramda'
      import API from '../api'
      import {isEnabled} from '../util/config-util'

      this.opname = this.opts.opname
      this.domain = this.opts.domain

      this.getDefault = () => ({
        domain: 'musterschule.schule.paedml',
        domains: ['musterschule.schule.paedml']
      })

      this.data = this.getDefault()
      this.api = this.opts.api
      this.op = opts.variant === 'student' ? "student" : 'teacher'

      this.getDomains = () => this.data.domains ? this.data.domains.map(domains => [domains, domains])
        : ['musterschule.schule.paedml', 'musterschule.schule.paedml']

      this.refreshDomains = () => {
        this.loading = true
        API[this.domain].domain.getAll().then(rsp => {
          const [data, status] = rsp
          this.loading = false

          this.data.domains = data.map(list => list.domain)

          this.update()
        })
      }

      this.on('dataRequested', () => {
        this.data = this.getDefault()

        this.refreshDomains()
      })

      this.submitHandler = formData => {
        
        return API[this.domain][this.op].domain(formData.ids, formData.domain)
          .then(rsp => {
            const [data, status] = rsp
            if (status.success) {
              this.parent.trigger('reloadTable')
              let text = formData.ids > 1 ? 'Der Benutzer wurde ' : 'Die Benutzer wurden ' 

              return `${text} erfolgreich mit der MS 365-Domäne '${formData.domain}' verknüpft.`
            }

            if (data.message) {
              return Promise.reject(data.message)
            }
              let text = formData.ids > 1 ? 'Der Benutzer konnte ' : 'Die Benutzer konnten ' 
              return Promise.reject(`${text} mit der MS 365-Domäne '${formData.domain}' nicht verknüpft werden.`)
          })
      }
    </script>
</paedml-modal-domain>
