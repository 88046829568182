<paedml-modal-lockunlock-internet>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true} ref="modal"
                >

    <yield to="fields">
      <div class="row justify-content-center">
        <div class="col-5 ">
          <span>{parent.data.patient} aufgehoben</span>
        </div>
        <div class="col-1">
          <paedml-form-input name="lock"
            type="checkbox" revlut="jaNein" handlechange={parent.handleToggle}
          />
        </div>
        <div class="col-5">
          <span>{parent.data.patient} gesetzt</span>
        </div>
      </div>
  
      <virtual if={parent.data.selectedRows > 1 && parent.op !== 'raum'}>
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-warning">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">Der aktuelle Zustand der Internetsperre kann nicht ermittelt werden, da mehrere {parent.patient.plural} ausgewählt sind.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

     <virtual if="{patient.plural === 'Räume'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">{parent.data.patient} bei allen Computer im Raum {parent.data.text}.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'

    
    this.prefix = opts.prefix
    this.variant = opts.variant
    this.data = {
      text: "Internetsperre wird aufgehoben",
      lock: false,
      selectedRows: 0
    }
    this.api = this.opts.api
    this.patient = opts.variant === "student" ? {singular: 'Schüler', plural: 'Schüler*innen'} : opts.variant === 'computer' ? {singular: 'Computer', plural: 'Computer'} : {singular: 'Raum', plural: 'Räume'}
    this.op = opts.variant === 'student' ? "student" : opts.variant === 'computer' ? 'computer' : 'raum'
    
    this.handleToggle = (val) => {
      this.data.text = (val ? "gesetzt" : "aufgehoben")
      this.data.lock = val
      this.refs.modal.trigger('updateButton', this.data.lock ? 'Internetsperre setzen' : 'Internetsperre aufheben')
    }

    this.on('dataRequested', (selectedRows) => {
      this.data = {
        patient: 'Internetsperre' + (selectedRows.length > 1 ? 'n werden' : ' wird'),
        text: "aufgehoben",
        selectedRows: selectedRows.length
      }
      this.setInitialState(opts.variant === 'computer' ? selectedRows[0][9] : selectedRows[0][7])
    })

    this.setInitialState = (lockedState) => {
      var locked = false;
      if(this.data.selectedRows === 1 && this.op !== 'raum') {
        locked = lockedState === "ja"
      } 
      this.handleToggle(locked)
    }

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const formatter = this.data.lock ? formatterLock : formatterUnlock
      const deleteMessageSuccess = formatter(idLut, true)
      const deleteMessageFailure = formatter(idLut, false)

      return API[this.op].internetLock(formData.ids, this.data.lock).then(rsp => {
        const [deletedIds, status] = rsp
        if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server')

        const {succeeded, failed} = deletedIds
        if (failed.length) {
          if (succeeded.length) {
            // partly success
            const sLut = toTrueMap(R.map(id => idLut[id], succeeded))
            // remove deleted entries
            $('span.tag', this.refs.tip).each(function(i,e) { 
              if (sLut[$(e).text()]) $('span', e).click()
            })

            this.parent.trigger('reloadTable')
            return Promise.reject([deleteMessageSuccess(succeeded), deleteMessageFailure(failed)])
          }
          return Promise.reject(deleteMessageFailure(failed))
        }

        this.parent.trigger('reloadTable')
        return deleteMessageSuccess(succeeded)
      })

    }

    const formatterLock = patientFormatter({singular: 'Internetsperre', plural: 'Internetsperren', for: 'für'}, 'gesetzt')
    const formatterUnlock = patientFormatter({singular: 'Internetsperre', plural: 'Internetsperren', for: 'für'}, 'entfernt')

  </script>
</paedml-modal-lockunlock-internet>
