import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-icon.tag'
import './paedml-qrcode.tag'

<paedml-modal-contact>
  <paedml-modal prefix={opts.prefix} heading="Kontakt" opname="OK" submitfunc={submitHandler}
    okbutton="OK"
  >
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div>
          <span style="margin-top: -0.25rem;"><strong>paedML<span style="vertical-align: super;">&reg;</span> Windows</strong></span>
          <span class="pull-right"><strong>Version {parent.version}</strong></span><br/>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <span><strong>Hotline paedML Windows</strong></span><br/>
            <span>Die Hotline am Landesmedienzentrum Baden-Württemberg (LMZ) bietet zentralen technischen Support für alle Kunden. Unsere Hotline-Mitarbeiterinnen und Mitarbeiter helfen Ihnen gerne bei der Installation, Aktualisierung oder Wartung Ihres pädagogischen Computernetzwerkes weiter. Sie nehmen Ihre Anfragen per Ticket auf und versuchen, die Probleme zeitnah zu lösen.</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-7">
            <p class="pt-2">
              <strong>Die Hotline-Servicezeiten sind:</strong><br/>
              Montag bis Donnerstag: 8:00–16:00 Uhr<br/>
              Freitag: 8:00–14:30 Uhr<br/>
            </p>

            <p class="pt-2">
              Telefon: 0711 490963-89<br/>
              E-Mail: <a href="mailto:windows-hotline@lmz-bw.de" style="text-decoration: none;">windows-hotline@lmz-bw.de</a><br/>
            </p>

            <p class="pt-2">
              <strong>Fragen zum Produkt/Bestellvorgang/Rechnungen:</strong><br/>
              Telefon: 0711 490963-36<br/>
              E-Mail: <a href="mailto:paedML@lmz-bw.de" style="text-decoration: none;">paedML@lmz-bw.de</a><br/>
            </p>

            <a class="pt-2" href="/agb.pdf" target="_blank">Leistungskatalog/AGB</a>
          </div>
          <div class="col-sm-6 col-md-5">
            <div class="d-none d-sm-block" style="padding-top: 42px;"></div>
            <paedml-qrcode content="{() => 'tel:0711 49096387'}" width="192" height="192" />
            
            <div style="margin: -10px 0 10px 30px;">
              <paedml-icon icon="fas-phone-volume" simple="dark" />&nbsp;Hotline anrufen
            </div>
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      this.on('mount', () => {
        this.version = $('meta[name=version]').attr('content')
        this.backendVersion = $('meta[name=bev]').attr('content')
        this.update()
      })
    </script>
</paedml-modal-contact>
