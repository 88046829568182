import '../paedml-modal.tag'
import '../paedml-form-input.tag'

<paedml-modal-edit-software>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="name" col="1" label="Name" readonly />
          </div>
          <div class="col-sm-12">
            <paedml-form-input name="productId" col="2" label="Produkt ID" readonly />
          </div>
          <div class="col-sm-12"> 
            <div class="form-group">
              <label>Beschreibung</label>
              <textarea class="form-control" name="description" readonly>{parent.description}</textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="message" col="18" label="Hinweis" maxlength="251" data-rule-maxlength="250" data-msg-maxlength="Der Hinweis darf nicht länger als 250 Zeichen sein"/>
          </div>
        </div>
      </div>
      <div class="paedml-input-group">
        <div class="pb-1">
          Standardaktionen:
        </div>
        <div class="row">
          <div class="col-sm-4">
              <paedml-form-input name="defaultInstallAction" col="12" label="Install"
                type="select" values={parent.actions}
                required data-msg="Bitte wählen Sie eine Aktion aus" />
            </div>
            <div class="col-sm-4">
              <paedml-form-input name="defaultUpdateAction" col="14" label="Softwareupdate"
                type="select" values={parent.actions}
                required data-msg="Bitte wählen Sie eine Aktion aus" />
            </div>
            <div class="col-sm-4">
              <paedml-form-input name="defaultUninstallAction" col="16" label="Uninstall"
                type="select" 
                values={parent.actions} 
                required data-msg="Bitte wählen Sie eine Aktion aus" />
            </div>
        </div>
      </div>
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="productVersion" col="5" label="Softwareversion" readonly />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="packageVersion" col="6" label="OPSI-Paketversion" readonly />
          </div>
        </div>
      </div>
    </yield>
  </paedml-modal>
    
  <script>
    import { autoMapRowToData } from '../../util/modal-util'
    import Session from '../../store/session'
    import * as R from 'ramda'
    import { handleSubmitEdit } from '../../util/api-util'
    import API from '../../api'

    this.opname = this.opts.opname
    this.edit = this.opts.edit
    this.data = {}
    this.actions = []
    this.description = ''
    this.session = Session

    this.on('dataRequested', (rows) => {
      R.forEach((action) => {
        this.actions.push([action, Session.map.opsiActions[action]])
      }, rows[0][8])
    })

    this.on('rowMappingRequested', cols => {
      autoMapRowToData(this, cols)
      this.description = cols[9]
      this.update()
    })

    this.on('hide', () => {
      this.actions = []
    })

    this.submitHandler = formData => {
      return API.software.update(formData.ids[0], R.omit(['ids', 'idTexts'], formData))
        .then(rsp => {
          return handleSubmitEdit(this, rsp, undefined, undefined, false)
        })
    }    
  </script>
</paedml-modal-edit-software>
