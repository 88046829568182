import './paedml-icon.tag'

<paedml-side-nav>
  <nav class="side-navbar">
    <div class="side-navbar-wrapper">
      <!-- Sidebar Header    -->
      <div class="sidenav-header d-flex align-items-center justify-content-center">
        <!-- User Info-->
        <div class="sidenav-header-inner text-center">
          <a if={opts.user.loggedIn} href="{homeUrl()}">
            <img id="avatar" src={opts.user.profile.avatarUrl} alt="avatar image" class="img-fluid rounded-circle" style="border: solid 3px white;">
          </a>
          <div if={!opts.user.loggedIn} class="sk-double-bounce">
            <div class="sk-child sk-double-bounce1"></div>
            <div class="sk-child sk-double-bounce2"></div>
          </div>
          <h2 class="h5">{opts.user.userName}</h2>
          <span>{opts.user.displayName}</span>
        </div>
        <!-- Small Brand information, appears on minimized sidebar-->
        <div class="sidenav-header-logo">
          <a href="{homeUrl()}" class="brand-small text-center"> 
            <strong>{opts.user.roleAbbrev}</strong>
          </a>
        </div>
      </div>
      <!-- Sidebar Navigation Menus-->
      
      <div class="main-menu">
        <ul id="side-main-menu" class="side-menu list-unstyled">
          <li><a href="{homeUrl()}" style="font-size: 1rem;"> <paedml-icon icon="home" /> <span class="paedml-text-white-clickable">Home</span></a></li>
          <li><span data-target="#hilfe-modal" class="btn paedml" data-toggle="modal" style="padding: 12px; font-size: 1rem;">
              <paedml-icon icon="hilfe" /> <span class="paedml-text-white-clickable">Hilfe</span>
          </span></li>
        </ul>
      </div>
      <div class="main-menu">
        <ul id="side-main-menu-suggestion" class="side-menu list-unstyled">
          <li each="{menu in createMenues()}">
            <a href="#{menu.id}" aria-expanded="true" data-toggle="collapse" class="{opts.page.sub || !menu.highlight ? '' : 'text-primary'}">
              <paedml-icon icon={menu.icon} class="pr-1" />
              <virtual if={menu.highlight}>
                <span if={isOverview()} class="paedml-menu-highlight noclick" title="" onclick={handleCategoryClick}>{menu.category}</span>
                <span if={!isOverview()} class="paedml-menu-highlight" title="zur Kartenübersicht" onclick={handleCategoryClick}>{menu.category}</span>
              </virtual>
              <span if={!menu.highlight}>{menu.category}</span>
            </a>

            <ul id="{menu.id}" class="collapse show list-unstyled">
              <li each="{entry in menu.entries}">
                <a href="{entry.url}" class="{active: entry.active, history: entry.history}">{entry.title}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  


  <script>
    import { md5Hex } from '../util/crypto-util'
    import { isPermitted } from '../util/permission'
    import route from 'riot-route'
    import * as R from 'ramda'
    import Session from '../store/session'
    import API from '../api'

    this.reduxConnect(state => ({user: state.user, page: state.page}));

    this.homeUrl = () => '/#dashboard/home'
    this.isOverview = () => this.opts.page && !this.opts.page.sub

    this.isSubPage = sub => this.opts.page && this.opts.page.sub === sub

    this.handleCategoryClick = e => {
      e.preventDefault()
      e.stopPropagation()
      if (!this.isOverview()) {
        location.href = '#' + this.config.route
      }
    }

    const getCategory = card => card.category || 'Allgemein'
    const getMenuTitle = card => card.menuTitle || card.contentTitle + ' verwalten'
    
    this.recommended = []

    this.getRecommendation = async () => {
      const roleId = this.opts.user.roles[0].id
      const rc = []

      // Man sollte nicht als 'paedml-sysadm' arbeiten, sondern einen weiteren anlegen
      if (this.opts.user.userName === 'paedml-sysadm') {
        const sysadms = Session.lut.sysadms
        if (!sysadms || sysadms.length <= 1) {
          rc.push({ route: 'rechteverwaltung/system-administratoren', menuTitle: `Einen weiteren System-Adm anlegen` })
        }
      }

      // Empfehlungen für Sys-Admins
      if (roleId === 1) {
        const schularten = R.values(Session.map.schularten)

        // Falls noch keine Schularten: +Schulart
        if (schularten.length === 0) {
          rc.push({ route: 'benutzerverwaltung/schularten', menuTitle: 'Schulart anlegen' },)
        } else {
        const sadms = Session.lut.sadms
          const sadmLut = {}
          sadms.forEach(sadm => {
            sadm.schoolTypes.split(', ').forEach(e => sadmLut[e] = true)
          })

          // Für alle Schularten ohne SADM: +SADM für die Schulart
          schularten.forEach(e => {
            if (!sadmLut[e]) rc.push({ route: 'rechteverwaltung/schulart-administratoren', menuTitle: `Schulart-Adm für die Schulart "${e}" anlegen` })
          })
        }
      }
      return rc
    }

    this.createMenues = () => {
      const currentUrl = '#' + route._.getPathFromBase();

      const byCategory = R.groupBy(getCategory, this.config.cards)

      const categoryNames = R.pipe(R.map(getCategory), R.uniq)(this.config.cards)

      const isActiveUrl = r => currentUrl === ('#' + this.config.route + '/' + r)

      const targetUrl = r => '#' + this.config.route + '/' + r

      const isHistory = route => this.opts.page && this.opts.page.from && this.opts.page.from.sub === route

      const menu = categoryNames.map(n => ({
          category: n,
          icon: 'fas-list',
          highlight: true,
          url: this.config.route,
          id: md5Hex(this.config.route + '-menu-' + n),
          entries: byCategory[n].map(card => ({
            url: targetUrl(card.route),
            active: isActiveUrl(card.route),
            title: getMenuTitle(card),
            history: isHistory(card.route)
          })).filter(e => isPermitted(e.url))
        })).filter(c => c.entries.length)

      if (this.config.related) {
        const related = {
          category: 'Weiterführend',
          icon: 'far-list-alt',
          highlight: false,
          id: md5Hex(this.config.route + '-menu-related'),
          entries: this.config.related
            .filter(v => isPermitted(v.route))
            .map(v => ({
              url: '#' + v.route,
              active: false,
              title: v.menuTitle
            }))
        }
        if (related.entries && related.entries.length) menu.push(related)
      }

      if (this.recommended.length) {
        const recommended = {
          category: 'Empfehlungen',
          icon: 'fas-list-ol',
          highlight: false,
          id: md5Hex(this.config.route + '-menu-recommended'),
          entries: this.recommended
            .filter(v => isPermitted(v.route))
            .map(v => ({
              url: '#' + v.route,
              active: false,
              title: v.menuTitle
            }))
        }
        if (recommended.entries && recommended.entries.length) menu.push(recommended)
      }

      return menu
    }

    this.on('mount', () => {
      $("nav.side-navbar").mCustomScrollbar({
        scrollInertia: 200
      });
      this.recommended = []
      this.getRecommendation().then((rc) => {
        this.recommended = rc
        this.update()
      })
    })
    </script>

</paedml-side-nav>
