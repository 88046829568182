import './paedml-card.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-run-jobs.tag'

<paedml-card-tasks>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{active && selectedNone() && parent.startTimer()}">
      <span class="btn paedml {parent.session.timers.tasks ? 're_sync' : ''}" onclick={parent.handleIntervalRefresh} id="{prefix}-rescan">
        <paedml-icon icon="aktualisieren"  title="automatisch synchronisieren" type="zoom" />
      </span>
    </virtual>

    <virtual if="{selectedOneOrMore() && parent.clearTimer()}"></virtual>

    <virtual if="{canRun()}">
      <paedml-icon-modal icon="ausgewaehlte-jobs-ausfuehren-g" target="#{prefix}-run-modal" title="Task(s) ausführen" />
      <paedml-modal-run-jobs prefix="{prefix}-run" selectedrowsfunc={getSelectedRows}
        patient="{{singular: 'Task', plural: 'Tasks'}}"
        heading="{config.contentTitle}: Tasks ausführen" opname="Tasks ausführen" idcol="0" idtextcol="0" api={restApi}
      />
    </virtual>

  </paedml-card>
  <script>
    import {usingExternalConfig} from '../util/config-util'
    import * as R from 'ramda'
    import Session from '../store/session'
    import {createTimerTask, createTimerCanceler, createRefresher} from '../util/api-util'
    this.session = Session

    this.handleRefresh = e => {
      this.refs['card'].trigger('reloadTable')
    }

    this.handleIntervalRefresh = createRefresher(this, 'tasks', usingExternalConfig("jobverwaltung","refreshInterval", 30000))
    this.startTimer = createTimerTask('tasks', this.handleIntervalRefresh)
    this.clearTimer = createTimerCanceler('tasks')

  </script>
</paedml-card-tasks>
