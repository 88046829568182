import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-bsa>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <paedml-form-input name="isBsaEnabled" col="11" placeholder="BSA-Modus aktiv"
        type="checkbox" revlut="jaNein" handlechange={parent.handleToggle}
      />

      <div class="paedml-input-group" show={parent.data.isBsaEnabled}>
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="bsaSchool" col="15" label="Schulart" ref="schoolType"
              readonly={parent.data.wasBsaEnabled}
              type="select" values={parent.session.map.schulartenLong} revlut="schularten"
              required data-msg="Bitte wählen Sie die Schulart aus"
              handlechange={parent.handleSchoolTypeChange}
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="bsaSchoolClass" col="16" label="Klasse"
              readonly={parent.data.wasBsaEnabled}
              type="select" values={parent.getSchoolClassValues()}
              required data-msg="Bitte wählen Sie eine Klasse aus"
              handlechange={parent.handleSchoolClassChange}
              ref="klasse"
            />
          </div>
        </div>
      </div>
      
      <div class="row text-info p-3" show={parent.data.isBsaEnabled && parent.data.wasBsaEnabled}>
        <p>Wenn Sie den BSA-Modus für eine andere Klasse in diesem Raum starten möchten, deaktivieren Sie zunächst den laufenden BSA-Modus und starten Sie einen neuen.</p>
      </div>
  
    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      const convertingBooleans = R.mapObjIndexed(v => {
          if (v === 'true' || v === 'ja') return true
          if (v === 'false' || v === 'nein') return false
          return v
      })

      this.getSchoolClassValues = () => 
        (this.session.lut.klassenBySchulart[this.data.bsaSchool] || []).map(v => [v[0], v[1]])

      this.handleSchoolTypeChange = (val) => {
        const existingClasses = (this.session.lut.klassenBySchulart[this.data.bsaSchool] || []).map(v => v[0])
        if (!R.includes(this.data.bsaSchoolClass)(existingClasses)) {
          this.data.bsaSchoolClass = ''
          $('select[name=bsaSchoolClass]').removeClass('is-valid').addClass('is-invalid')
        }
      }

      this.handleSchoolClassChange = val => {
        $('select[name=bsaSchoolClass]').removeClass('is-invalid').addClass('is-valid')
      }

      const firstOrEmptySelection = (name, map, callback) => {
        if (this.data[name]) return
        const keys = R.keys(map)
        this.data[name] = keys.length === 1 ? keys[0] : ''
        if (callback && keys.length === 1) {
          callback(keys[0])
        }
      }

      this.on('dataRequested', () => {
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
        this.data.wasBsaEnabled = this.data.isBsaEnabled
        this.data.bsaSchool = cols[15]
        firstOrEmptySelection('bsaSchool', Session.map.schularten, this.handleSchoolTypeChange)
      })

      this.submitHandler = formData => {
        const action = this.data.isBsaEnabled ? 'gestartet' : 'beendet'

        return this.api.bsa(formData.ids[0], R.omit(['ids', 'idTexts', 'wasBsaEnabled'], formData))
          .then(rsp => {
            const [data, status] = rsp
            if (status.success) {
              this.parent.trigger('reloadTable')
              return 'BSA-Modus erfolgreich ' + action
            }

            console.error('error-status', status)
            console.error('error-data', data)
            if (data.message) {
              return Promise.reject(data.message)
            }
            return Promise.reject('BSA-Modus konnte nicht ' + action + ' werden')
          })
      }
    </script>
</paedml-modal-bsa>
