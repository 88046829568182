import './paedml-card-dashboard.tag'
import './paedml-card-admin.tag'
import './paedml-card-schularten.tag'
import './paedml-card-klassen.tag'
import './paedml-card-lehrer.tag'
import './paedml-card-schueler.tag'
import './paedml-card-jobs.tag'
import './paedml-card-tasks.tag'
import './paedml-card-import.tag'
import './paedml-card-versetzung.tag'
import './paedml-card-versetzung-klassen.tag'
import './paedml-card-projekte.tag'
import './paedml-card-handout.tag'
import './paedml-card-raum.tag'
import './paedml-card-firewall.tag'
import './paedml-card-bsa.tag'
import './paedml-card-exam.tag'
import './paedml-card-kalogs.tag'
import './paedml-card-quota.tag'
import './paedml-card-domain.tag'
import './paedml-card-cloud.tag'
import './paedml-card-moodle.tag'
import './paedml-card-profil.tag'
import './paedml-card-singlelogin.tag'
import './paedml-card-software.tag'

<paedml-content>
  <div class="p-1">
    <div ref="content" if={isLoggedIn()} id="accordion" class="accordion">
    </div>
  </div>

  <script>
    import { isPermitted } from '../util/permission'
    this.reduxConnect(state => ({user: state.user, page: state.page}));

    this.perm = '#' + this.config.route

    this.permittedCards = () => 
      this.config.cards.filter(card => isPermitted(this.perm + `/${card.route}`))
    
    this.on('mount', () => {
      const outerTag = this.refs.content

      const tags = []
      this.permittedCards().forEach(card => {
        const cardTag = card.tag
        const elem = document.createElement(cardTag)
        outerTag.appendChild(elem)
        const tag = riot.mount(elem, cardTag, {
          config: card,
          perm: this.perm,
          siblings: tags
        })
        tags.push(tag[0])
      })
    })

    this.on('update', () => {
      document.title = this.config.pageTitle
    })
  </script>

</paedml-content>
