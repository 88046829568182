import './paedml-card.tag'
import './paedml-modal-createoredit-admin.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-info-jobs.tag'
import './paedml-modal-run-jobs.tag'

<paedml-card-jobs>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{active && selectedNone() && parent.startTimer()}">
      <span class="btn paedml {parent.session.timers.jobs ? 're_sync' : ''}" onclick={parent.handleIntervalRefresh} id="{prefix}-rescan">
        <paedml-icon icon="aktualisieren" title="automatisch synchronisieren" type="zoom" />
      </span>
    </virtual>

    <virtual if="{selectedOneOrMore() && parent.clearTimer()}"></virtual>

    <virtual if="{canSeeInfo()}">
      <paedml-icon-modal icon="far-list-alt" target="#{prefix}-see-modal" title="zugehörige Tasks ansehen" />
      <paedml-modal-info-jobs prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: zugehörige Tasks" opname="OK" api={restApi}
      />
    </virtual>

    <virtual if="{canRun()}">
      <paedml-icon-modal icon="ausgewaehlte-jobs-ausfuehren-g" target="#{prefix}-run-modal" title="Job(s) ausführen" />
      <paedml-modal-run-jobs prefix="{prefix}-run" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Jobs ausführen" opname="Jobs ausführen" idcol="0" idtextcol="0" api={restApi}
      />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Job(s) l&ouml;schen" />
      <paedml-modal-delete heading="{config.contentTitle}: Jobs löschen" idcol="0" idtextcol="0" patient="{{singular: 'Job', plural: 'Jobs', as: 'den'}}" prefix="{prefix}-delete" selectedrowsfunc={getSelectedRows} api={restApi}/>
    </virtual>
  </paedml-card>

  <script>
    import {usingExternalConfig} from '../util/config-util'
    import * as R from 'ramda'
    import Session from '../store/session'
    import {createTimerTask, createTimerCanceler, createRefresher} from '../util/api-util'
    this.session = Session

    this.handleRefresh = e => {
      this.refs['card'].trigger('reloadTable')
    }

    this.handleIntervalRefresh = createRefresher(this, 'jobs', usingExternalConfig("jobverwaltung","refreshInterval", 30000))
    this.startTimer = createTimerTask('jobs', this.handleIntervalRefresh)
    this.clearTimer = createTimerCanceler('jobs')

  </script>
</paedml-card-jobs>
