import './paedml-icon-modal.tag'
import './paedml-modal-collect.tag'
import './paedml-modal-info-handout.tag'

<paedml-card-handout>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
  
    <virtual if="{selectedExactlyOne()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Ausgeteilte Dateien-Info ansehen" />
      <paedml-modal-info-handout prefix="{prefix}-see" edit={true}
        heading="Ausgeteilte Dateien-Info" opname="OK" api={restApi}
        selectedrowsfunc={getSelectedRows} />
    </virtual>

    <virtual if="{selectedExactlyOne() && isPermitted('collect')}">
      <paedml-icon-modal icon="fas-file-download" target="#{prefix}-collect-modal" title="Dateien einsammeln" />
      <paedml-modal-collect prefix="{prefix}-collect" selectedrowsfunc={getSelectedRows} edit={true}
        variant="{opts.variant || (opts.custom && opts.custom.variant)}"
        heading="{config.contentTitle}: Dateien einsammeln" opname="Dateien einsammeln" api={restApi} />
    </virtual>

  </paedml-card>
</paedml-card-handout>
