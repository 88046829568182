import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-fwsettings>
<paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit}
    selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol} submitfunc={submitHandler}>

    <yield to="fields">
        <div class="paedml-input-group">
            <div class="row">
                <div class="col-sm-6">
                    <paedml-form-input name="firewallType" label="Firewalltyp" ref="firewallType" type="select"
                        values={parent.firewalls} required data-msg="Bitte wählen Sie den Firewalltyp" />
                </div>
                <div class="col-sm-6" show={parent.data.firewallType==='OCTO'}>
                    <paedml-form-input name="firewallInstanceName" label="Instanzname Ihrer OctoGate"
                        required
                        data-msg="Bitte geben Sie den Instanznamen ein" data-rule-fwinstname
                        data-msg-fwinstname="Bitte geben Sie den Hostnamen Ihrer OctoGate ein (8 Zeichen)." />
                </div>
                <div class="col-sm-6" show={parent.isSophosFirewall()}>
                    <paedml-form-input name="sophosApiUrl" label="Sophos API-URL" 
                        required data-msg="Bitte geben Sie den Hostnamen ein" />
                </div>
                <div class="col-sm-6" show={parent.data.firewallType==='SOPHOS_XG'}>
                    <paedml-form-input name="sophosXgApiUsername" label="Sophos API-Benutzer" required
                        data-msg="Bitte geben Sie den API-Benutzer ein" />
                </div>
                <div class="col-sm-6" show={parent.isSophosFirewall()}>
                    <paedml-form-input name="sophosApiToken" label="API-Token" required
                        data-msg="Bitte geben Sie den API-Token ein" />
                </div>
            </div>
        </div>
    </yield>
</paedml-modal> 
    
<script>
  import store from '../store'
  import { client } from '../util/api-util'
  import * as mapper from '../mapper'
  import * as R from 'ramda'
  import Session from '../store/session'
  import Masterdata from '../config/masterdata'
  import API from '../api'
  import { isDisabled } from '../util/config-util'

  this.userGroup = this.opts.usergroup

  this.opname = this.opts.opname
  this.edit = this.opts.edit ? true : false
  this.data = {}
  this.api = this.opts.api
  this.session = Session
  this.firewalls = []

  this.isSophosFirewall = () => {
      if (!this.data.firewallType) return false;
      return R.startsWith('SOPHOS', this.data.firewallType)
  }

  this.on('dataRequested', () => {
    console.log(this.opts)
      this.getFirewalls();

      this.data = {
          firewallType: sData.settings[0].firewallType,
          firewallInstanceName: sData.settings[0].firewallInstanceName,
          sophosApiUrl: sData.settings[0].sophosApiUrl,
          sophosApiToken: sData.settings[0].sophosApiToken
      }
  })

  this.getFirewalls = () => {
      API.fw.get().then(rsp => {
          const [data, status] = rsp
          this.firewalls = data.map(firewall => [firewall.type, firewall.name])
          this.update()

          $('select[name=firewallType]').val(this.data.firewallType)
      })
  }

  this.submitHandler = formData => {

      return API.fwsettings.update(R.omit(['ids', 'idTexts'], formData))
          .then(rsp => {
              const [data, status] = rsp
              if (status.success) {
                  window.request.relogin()
                  return 'Änderung(en) erfolgreich übernommen'
              }

              console.error('error-status', status)
              console.error('error-data', data)
              if (data.message) {
                  return Promise.reject(data.message)
              }
              return Promise.reject('Änderung(en) konnten nicht übernommen werden!')
          })
  }   
</script>
</paedml-modal-edit-fwsettings>
