import './paedml-icon.tag'

<paedml-form-input>
    <virtual if={checkCondition(opts.condition)}>
        <div if="{opts.type !== 'hidden'}" class="line"></div>
        <div class="form-group {opts.fgclass}" title={opts.title || opts.placeholder || opts.label}>
            <label if="{opts.step && opts.label}" class="paedml-label-step">{getStepNumber(opts.step)}</label>
            <label if="{opts.label}">{opts.label}</label>
            <div if="{'select' !== opts.type && 'checkbox' !== opts.type}" class="input-group">
                <div if="{opts.prepend && !isDisabled()}" class="input-group-prepend"><span class="input-group-text">{opts.prepend}</span></div>
                <input 
                    id={opts.inputid}
                    type="{opts.type || 'text'}" 
                    name="{opts.name}"
                    disabled={isDisabled()}
                    required={isRequired()}
                    placeholder="{opts.placeholder}"
                    onkeydown="{this.opts.changemode === 'keydown' ? handleInputChange : undefined}"
                    onkeyup="{this.opts.changemode === 'keyup' ? handleInputChange : undefined}"
                    onchange="{this.opts.changemode !== 'keyup' || this.opts.changemode !== 'keydown' ? handleInputChange : undefined}"
                    autocomplete="{opts.type === 'password' ? 'new-password' : 'off'}"
                    class="form-control {opts.small ? 'form-control-sm' : ''} {opts.additionalclass} {getClassRequired()}" 
                    maxlength={opts.maxlength || 256}
                    ref="input"
                >
                <div if="{opts.append && !isDisabled()}" class="input-group-append"><span class="input-group-text">{opts.append}</span></div>
            </div>

            <div if="{'select' === opts.type}" class={getClassRequired()}>
                <select 
                    id={opts.inputid}
                    name={opts.name}
                    disabled={isDisabled()}
                    required={isRequired()}
                    multiple={isMultiple()}
                    placeholder={opts.placeholder}
                    onchange={handleSelectionChange}
                    class="form-control {opts.small ? 'form-control-sm' : ''} {isMultiple() ? 'multipleSelect' : ''}"
                    ref="input">
                    <option if={!isMultiple()} disabled="disabled" style="display: none;"></option>
                    <option value={this[0]} each={getSelectionValues(opts.values)} key={this[0]}>{this[1]}</option>
                </select>
            </div>
            <div if={opts.addremoveall} class="pull-right">
                <span class="btn btn-outline-primary btn-sm" onclick={selectAll}>Alle auswählen</span>
                <span class="btn btn-outline-primary btn-sm" onclick={selectNone}>Alle entfernen</span> 
            </div>

            <div if="{'checkbox' === opts.type}" class="custom-control custom-switch">
                <input 
                    id={inputid} 
                    name={opts.name}
                    disabled={isDisabled()}
                    required={isRequired()}
                    type="checkbox" 
                    onchange={handleCheckboxChange}
                    class="custom-control-input"
                    ref="input">
                <label class="custom-control-label" for={inputid}><paedml-icon if={opts.icon} icon={opts.icon} inv="true" class="pr-1"/>{opts.placeholder}</label>
            </div>
          
        </div>
    </virtual>

    <script>
        import {genDocId} from '../util/crypto-util'
        import * as R from 'ramda'

        const nums = ['🄌', '➊', '➋', '➌', '➍', '➎', '➏', '➐', '➑', '➒', '➓']
        this.getStepNumber = (num) => {
            
            return nums[parseInt(num)]
        }

        // input is array return array otherwise convert as array pair and return
        this.getSelectionValues = R.ifElse(R.is(Array), R.identity, R.toPairs)

        this.handleChange = this.opts.handlechange
        this.verifyChange = this.opts.verifychange

        this.handleCheckboxChange = function(e) {
            const val = this.refs.input.checked
            this.handleAndNotify(val, e)
        }

        this.handleSelectionChange = e => {
            const val = $(e.target).val()
            if (val === '') {
                e.preventDefault()
                e.stopPropagation()
                return
            }

            this.handleAndNotify(val, e)
        }

        this.handleInputChange = e => {
            const val = $(e.target).val()
            
            if (this.verifyChange) {
                if (! this.verifyChange(val, e)) {
                    e.preventDefault()
                    e.stopPropagation()
           
                    return
                }
            }

            this.handleAndNotify(val, e)
        }

        this.selectAll = () => {
            $('.fstQueryInput', this.root).click()
            setTimeout(() => {
                $('.fstResultItem', this.root).click()
            }, 100);
        }

        this.selectNone = () => {
            $('.fstChoiceRemove', this.root).click()
        }

        this.handleAndNotify = (val, e) => {
            this.parent.trigger('formToData')
            
            if (this.handleChange) {
                this.parent.trigger('updateModel', opts.name, val)
                const ret = this.handleChange(val, this, e)
                this.parent.trigger('updateModal', ret)
            } else {
                this.parent.trigger('updateModelAndModal', opts.name, val)
            }
        }

        this.hasBooleanAttr = attrName => () => this.opts[attrName] !== undefined && this.opts[attrName] !== false
        this.isRequired = this.hasBooleanAttr('required')
        this.isDisabled = () => this.hasBooleanAttr('readonly')() || this.hasBooleanAttr('disabled')()
        this.isMultiple = this.hasBooleanAttr('multiple')
        this.getClassRequired = () => this.isDisabled() || !this.isRequired() ? '' 
            : !this.isMultiple() ? 'paedml-input-required' : 'paedml-input-required-multiple'
        
        this.inputid = this.opts.inputid ? this.opts.inputid : genDocId();

        this.checkCondition = (expression) => {
            if (expression === undefined) return true

            if (typeof expression === 'function') {
                return expression()
            }
            
            return expression
        }

        // FIXME: get rid of this hack
        this.updateData = (additionalConfig) => {
            const data = $(this.root).data()
            const inp = $(this.refs.input)
            R.forEachObjIndexed((value, key) => {
                inp.data(key, value)
            }, data)
            R.forEachObjIndexed((value, key) => {
                inp.data(key, value)
            }, additionalConfig)
        }

        this.on('mount', () => {
            this.updateData()

            if (this.isMultiple()) {
                $(this.root).change(this.handleSelectionChange)
            }
        })

        this.on('refresh', (additionalConfig) => {
            this.updateData(additionalConfig)
            this.update()
        })
        
    </script>
</paedml-form-input>
