import './paedml-card.tag'
import './paedml-modal-createoredit-raum.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-info-raum.tag'
import './paedml-modal-run-computer.tag'
import './paedml-modal-move-devices.tag'
import './paedml-modal-screenshot-computer.tag'
import './paedml-modal-lockunlock-internet.tag'
import './paedml-modal-lockunlock-computer.tag'
import './paedml-modal-bsa.tag'
import './paedml-modal-edit-printer.tag'
import './paedml-modal-edit-computer.tag'

<paedml-card-raum>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card' disablealle={isTeacher()}>

    <virtual if="{active && isPermitted('sync')}">
      <span class="btn paedml" onclick={parent.handleRescan} id="{prefix}-rescan">
        <paedml-icon icon="fas-recycle" title="Scannen und synchronisieren" type="zoom" id="{prefix}-rescan1" />
        <paedml-icon icon="fas-cog" type="zoom" id="{prefix}-rescan2" style="display: none;"/>
      </span>
    </virtual>

    <virtual if="{active && selectedNone() && parent.startTimer()}">
      <span class="btn paedml {parent.session.timers[parent.variant] ? 're_sync' : ''}" onclick={parent.handleIntervalRefresh} id="{prefix}-refresh">
        <paedml-icon icon="aktualisieren" title="Synchronisieren" type="zoom" id="{prefix}-refresh1" />
      </span>
    </virtual>

    <virtual if="{selectedOneOrMore() && parent.clearTimer()}"></virtual>

    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Raum anlegen" />
      <paedml-modal-createoredit-raum prefix="{prefix}-create" usersuffix="{prefix}" 
        heading="{config.contentTitle}: Raum anlegen" opname="Raum anlegen" api={restApi}/>
    </virtual>
  
    <virtual if="{parent.variant === 'raum' && canSeeInfo()}">
      <paedml-icon-modal icon="geraete-aio" target="#{prefix}-see-modal" title="Computer im Raum ansehen" />
      <paedml-modal-info-raum prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Computer im Raum ansehen" opname="OK" api={restApi}
      />
    </virtual>

    <virtual if="{parent.variant === 'computer' && canSeeInfo()}">
      <paedml-icon-modal icon="bildschirme-ansehen" target="#{prefix}-sshot-modal" title="Screenshot des Computers ansehen" />
      <paedml-modal-screenshot-computer prefix="{prefix}-sshot" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Screenshot des Computers ansehen" opname="OK" api={restApi}
      />
    </virtual>

    <virtual if="{parent.variant !== 'raum' && selectedOneOrMore() && isPermitted('move')}">
      <paedml-icon-modal icon="geraete-verschieben" target="#{prefix}-run-move-modal" title="Geräte verschieben" />
      <paedml-modal-move-devices prefix="{prefix}-run-move" selectedrowsfunc={getSelectedRows} variant={parent.variant}
        heading="{config.contentTitle}: Geräte verschieben" opname="Verschieben" api={restApi}
      />
    </virtual>

    <virtual if="{parent.variant !== 'drucker' && canRun() && parent.isRoomOperationAllowed(getSelectedRows)}">
      <paedml-icon-modal icon="internet" target="#{prefix}-run-lock-internet-modal" title="Internet (ent)sperren" />
      <paedml-modal-lockunlock-internet prefix="{prefix}-run-lock-internet" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Internet (ent)sperren" opname="Ausführen" api={restApi} variant={parent.variant}
      />
    </virtual>

    <virtual if="{parent.variant !== 'drucker' && canRun() && parent.isRoomOperationAllowed(getSelectedRows)}">
      <paedml-icon-modal icon="fas-unlock-alt" target="#{prefix}-run-lock-modal" title="Computer (ent)sperren" />
      <paedml-modal-lockunlock-computer prefix="{prefix}-run-lock" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Computer (ent)sperren" opname="Ausführen" api={restApi} variant={parent.variant}
      />
    </virtual>

    <virtual if="{parent.variant === 'computer' && canRun() && parent.isRoomOperationAllowed(getSelectedRows)}">
      <paedml-icon-modal icon="fas-power-off" target="#{prefix}-run-power-modal" title="Geräte ein/ausschalten" />
      <paedml-modal-run-computer prefix="{prefix}-run-power" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Geräte ein/ausschalten" opname="Ausführen" api={restApi}
      />
    </virtual>

    <virtual if="{selectedExactlyOne() && isPermitted('bsa') && parent.isRoomOperationAllowed(getSelectedRows)}">
      <paedml-icon-modal icon="far-id-badge" target="#{prefix}-bsa-modal" title="BenutzerSelbstAufnahme" />
      <paedml-modal-bsa prefix="{prefix}-bsa" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: BenutzerSelbstAufnahme" opname="Änderung speichern" api={restApi}
      />
    </virtual>
    
    <virtual if="{parent.variant === 'raum' && canEdit() && parent.isRoomOperationAllowed(getSelectedRows)}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Raum bearbeiten" />
      <paedml-modal-createoredit-raum prefix="{prefix}-edit" edit={true} usersuffix="{prefix}" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Raum bearbeiten" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{parent.variant === 'drucker' && canEdit() && parent.canConfigurePrinter(getSelectedRows)}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-printer-modal" title="Drucker bearbeiten" />
      <paedml-modal-edit-printer prefix="{prefix}-edit-printer" edit={true} usersuffix="{prefix}" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Drucker bearbeiten" opname="Änderung speichern" api={restApi}
      />
    </virtual>

    <virtual if="{parent.variant === 'computer' && canEdit() && selectedExactlyOne()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-computer-modal" title="Computer bearbeiten" />
      <paedml-modal-edit-computer prefix="{prefix}-edit-computer" edit={true} usersuffix="{prefix}" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Computer bearbeiten" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{parent.variant === 'raum' && selectedExactlyOne() && isPermitted('delete') && parent.canDeleteRoom(getSelectedRows) }">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Räume löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Räume löschen" prefix="{prefix}-delete" 
        patient="{{singular: 'Raum', plural: 'Räume', as: 'den'}}" selectedrowsfunc={getSelectedRows} api={restApi} relogin={true}/>
    </virtual>

  </paedml-card>

  <script>
    import API from '../api'
    import * as R from 'ramda'
    import {usingExternalConfig} from '../util/config-util'
    import Session from '../store/session'
    import {createTimerTask, createTimerCanceler, createRefresher} from '../util/api-util'
    this.session = Session

    this.prefix = opts.prefix
    this.variant = opts.config.custom.variant

    this.isTeacher = () => Session.user.roleAbbrev === 'L'
    this.canConfigurePrinter = (getSelectedRows) => {
      if (this.isTeacher()) {
        const isManagementAllowed = getSelectedRows()[0][7]
        return isManagementAllowed === 'ja'
      }
      return true
    }

    this.isEmpty = (col, getSelectedRows) => {
      const selectedRows = getSelectedRows()
      for (let i = 0; i < selectedRows.length; ++i) {
        if (selectedRows[i][col] > 0) return false
      }
      return true
    }

    this.isRoomOperationAllowed = (getSelectedRows) => {
      const selectedRows = getSelectedRows()
      for (let i = 0; i < selectedRows.length; ++i) {
        if (this.isReadonlyRoom(selectedRows[i][1])) return false
      }
      return true
    }

    this.isReadonlyRoom = (room) => {
      switch(room) {
        case 'Domain Controllers':
        case 'SP':
        case 'ADMIN_PC':
        case 'NICHT_ZUGEWIESEN':
          return true;
        return false;
      }
    }

    this.canDeleteRoom = (getSelectedRows) => {
      return this.isRoomOperationAllowed(getSelectedRows) && this.isEmpty(5, getSelectedRows) && this.isEmpty(7, getSelectedRows);
    }

    this.on('mount', () => {
      if (this.isTeacher()) {
        if (Session.room && (this.variant === 'computer' || this.variant === 'drucker')) {
          setTimeout(() => {
            this.tags['paedml-card'].tags['paedml-filter-dropdown'].trigger('filter', Session.room || 'NICHT_ZUGEWIESEN')
          }, 200)
        }
      }
    })

    this.handleRescan = e => {
      $(`#${opts.config.prefix}-rescan1`).hide()
      $(`#${opts.config.prefix}-rescan2`).show()
      $(`#${opts.config.prefix}-rescan`).addClass('fullrotation')
      API[opts.config.name].sync().then(() => {
        $(`#${opts.config.prefix}-rescan`).removeClass('fullrotation')
        $(`#${opts.config.prefix}-rescan2`).hide()
        $(`#${opts.config.prefix}-rescan1`).show()
        this.refs['card'].trigger('reloadTable')
      })
    }

    this.handleRefresh = e => {
      $(`#${opts.config.prefix}-refresh1`).hide()
      $(`#${opts.config.prefix}-refresh2`).show()
      $(`#${opts.config.prefix}-refresh`).addClass('fullrotation')
      API[opts.config.name].refresh().then(() => {
        $(`#${opts.config.prefix}-refresh`).removeClass('fullrotation')
        $(`#${opts.config.prefix}-refresh2`).hide()
        $(`#${opts.config.prefix}-refresh1`).show()
        this.refs['card'].trigger('reloadTable')
      })
    }

    this.handleIntervalRefresh = createRefresher(this, this.variant, usingExternalConfig("raumverwaltung", "refreshInterval", 30000))
    this.startTimer = createTimerTask(this.variant, this.handleIntervalRefresh)
    this.clearTimer = createTimerCanceler(this.variant)

  </script>
</paedml-card-raum>
