export const quotaverwaltung = {
  headerTitle: 'Kontingentverwaltung',
  route: 'quotaverwaltung',
  pageTitle: 'paedML - Kontingentverwaltung',
  related: [
    { route: 'benutzerverwaltung/lehrerinnen', menuTitle: 'Lehrer*innen verwalten' },
    { route: 'benutzerverwaltung/schuelerinnen', menuTitle: 'Schüler*innen verwalten' },
  ],
  cards: [
    {
      route: 'lehrerinnen',
      icon: 'far-hdd',
      endpoint: '/api/diskQuota/teachers',
      endpoints: {
      },
      preHooks: {
        list: 'quotaTeachers_list_preHook'
      },
      tag: 'paedml-card-quota',
      category: 'Kontingentverwaltung',
      menuTitle: 'Lehrer*innen',
      pageTitle: 'paedML - Quota-Lehrer*innen',
      name: 'quotalul',
      contentTitle: 'Lehrer*innen',
      prefix: 'quotalul',
      suffix: undefined,
      custom: {
        variant: 'teachers'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Name', name: 'displayName', col: 2 },
          { title: 'Basis-Schulart', name: 'mainSchoolType', col: 3 },
          { title: 'Schulart(en)', name: 'schoolTypes', separator: ', ', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Quota aktiv', name: 'limitDiskUsage', col: 5 },
          { title: 'Limit', name: 'quotaLimitText', className: 'paedml-nowrap', col: 6, sortCol: 7 },
          { title: 'quotaLimit', name: 'quotaLimit', col: 7, visible: false, printable: false, exportable: false },
          { title: 'Warngrenze', name: 'quotaThresholdText', col: 8, sortCol: 9 },
          { title: 'quotaThreshold', name: 'quotaThreshold', col: 9, visible: false, printable: false, exportable: false },
          { title: 'In Nutzung', name: 'inUseDP', className: 'paedml-nowrap', col: 10, sortCol: 11 },
          { title: 'quotaUsed', name: 'quotaUsed', col: 11, visible: false, printable: false, exportable: false },
          { title: 'Status', name: 'status', className: 'paedml-nowrap', col: 12, filter: 'QuotaStatus' },
        ],
        data: {
          url: '/data/quota-lul.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'schuelerinnen',
      icon: 'far-hdd',
      endpoint: '/api/diskQuota/students',
      endpoints: {
      },
      preHooks: {
        list: 'quotaStudents_list_preHook'
      },
      tag: 'paedml-card-quota',
      category: 'Kontingentverwaltung',
      menuTitle: 'Schüler*innen',
      pageTitle: 'paedML - Quota-Schüler*innen',
      name: 'quotasus',
      contentTitle: 'Schüler*innen',
      prefix: 'quotasus',
      suffix: undefined,
      custom: {
        variant: 'students'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Name', name: 'displayName', col: 2 },
          { title: 'Schulart', name: 'schoolType', col: 3, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 4, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex' },
          { title: 'Quota aktiv', name: 'limitDiskUsage', col: 5 },
          { title: 'Limit', name: 'quotaLimitText', className: 'paedml-nowrap', col: 6, sortCol: 7 },
          { title: 'quotaLimit', name: 'quotaLimit', col: 7, visible: false, printable: false, exportable: false },
          { title: 'Warngrenze', name: 'quotaThresholdText', col: 8, sortCol: 9 },
          { title: 'quotaThreshold', name: 'quotaThreshold', col: 9, visible: false, printable: false, exportable: false },
          { title: 'In Nutzung', name: 'inUseDP', className: 'paedml-nowrap', col: 10, sortCol: 11 },
          { title: 'quotaUsed', name: 'quotaUsed', col: 11, visible: false, printable: false, exportable: false },
          { title: 'Status', name: 'status', className: 'paedml-nowrap', col: 12, filter: 'QuotaStatus' },
        ],
        data: {
          url: '/data/quota-sus.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },
    
  ]
}
