import { configureStore } from '@reduxjs/toolkit'

import route from 'riot-route'
import {hideAllModal} from '../util/modal-util'
import {cardsByUrl} from '../config/pages'
import * as R from 'ramda'
import Session from './session'

const userInitialState = { loggedIn: false }

const userReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case 'user/loginSuccess':
            Session.user = action.payload
            return action.payload

        case 'user/loginFailure':
        case 'user/logout':
            Session.user = userInitialState
            return userInitialState

        default:
            return state;
    }
}


const pageInitialState = { url: '/' }
const pageReducer = (state = pageInitialState, action) => {
    switch (action.type) {
        case 'page/changed':
            return action.payload
    
        default:
            return state;
    }
}

const store = configureStore({
    reducer: {
        user: userReducer,
        page: pageReducer,
    }
})

if (typeof window !== 'undefined') {
    let from = {}
    window.addEventListener('popstate', function(e){
        const current = route._.getPathFromBase();
        const sp = current.split('/')
        const sub = sp.length === 1 ? undefined : sp[sp.length-1]
        const main = sp[0]
        hideAllModal()
            R.forEachObjIndexed((v,k) => {
                clearTimeout(v)
            }, Session.timers)
            Session.timers = {}
        if (!main) return
        store.dispatch({type: 'page/changed', payload: { url: current, main, sub, from }})
        from = {url: current, main, sub}
        if (sub) {
            setTimeout(() => {
                const card = cardsByUrl[current]
                if (!$('#' + card.prefix + '-collapse').hasClass('show')) {
                    $('#' + card.prefix + '-collapse').collapse('show')
                }
            }, 500)
        }
    })
}

export const getUserState = () => store.getState().user
export const emit = (type, payload) => store.dispatch({type, payload})

export default store
