import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-createoredit-klassen>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol="{opts.idtextcol || 10}"
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">

        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="schoolYear" col="3" label="Schuljahr"
              required readonly
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="schoolTypeId" col="1" label="Schulart"
              type="select" values={parent.session.map.schularten} revlut="schularten"
              required data-msg="Bitte wählen Sie eine Schulart aus"
              handlechange={parent.handleSchoolTypeChange}
              readonly={edit}
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="name" col="2" label="Klasse"
              required data-msg="Bitte geben Sie die anzulegende Klasse ein"
              readonly={edit} handlechange={parent.handleSchoolClassChange}
              changemode="keyup"
              data-rule-check="name" data-msg-check="Klasse bereits vorhanden!"
              data-rule-alphanum data-msg-alphanum="Nur Kleinbuchstaben und Zahlen sind erlaubt!"
              data-rule-maxlength="20" data-msg-maxlength="Erlaubt sind maximal 20 Zeichen."
            />
          </div>
        </div>

        <div class="p-1" />
        <paedml-form-input if={!edit} name="existingClasses" label="bereits vorhandene Klassen in ausgewählter Schulart" readonly/>
      </div>

      <div class="paedml-input-group">

        <paedml-form-input name="shareFolder" col="5" label="Tauschlaufwerk"
          if={edit} readonly
        />

        <paedml-form-input name="sharedFolderAccessLevel" col="6" label="Zugriff Tauschlaufwerk"
          type="select" values={parent.session.map.shareFolderAccessPolicy} revlut="shareFolderAccessPolicy"
          required data-msg="Bitte wählen Sie eine Zugriffsart aus"
        />

      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import {reversePairs} from '../util/mapping-util'
      import * as R from 'ramda'
      import * as fu from '../util/func-util'
      import Session from '../store/session'
      import {handleSubmitCreateOrEdit} from '../util/api-util'

      this.session = Session
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.api = this.opts.api

      this.data = {      }

      this.getSchoolClassValues = () => 
        this.session.lut.klassenBySchulart[this.data.schoolType]

      this.handleSchoolTypeChange = (val) => {
        if (!R.includes(this.data.schoolClass)(this.session.lut.klassenBySchulart[this.data.schoolTypeId])) {
          this.data.schoolClass = ''
        }
        const settings = (Session.settings[val] || Session.settings[0])
        this.data.schoolYear = settings.currentSchoolYear

        const existingClasses = mapKlassen(this.data.schoolTypeId)
        this.data.existingClasses = R.join(', ', existingClasses)

        if (this.data.name) {
          $('input[name=name]').data('name', !R.includes(R.toLower(this.data.name), existingClasses))
          $('#name-error').remove()
          $('input[name=name]').valid()
        }
      }

      this.handleSchoolClassChange = val => {
        const existingClasses = mapKlassen(this.data.schoolTypeId)
        $('input[name=name]').data('name', !R.includes(R.toLower(this.data.name), existingClasses))
      }

      const mapKlassen = schulart => R.map(R.prop(1), Session.lut.klassenBySchulart[schulart] || [])
      
      const firstOrEmptySelection = (name, map, callback) => {
        if (this.data[name]) return
        const keys = R.keys(map)
        this.data[name] = keys.length === 1 ? keys[0] : ''
        if (callback && keys.length === 1) {
          callback(keys[0])
        }
      }

      this.on('dataRequested', () => {
        firstOrEmptySelection('schoolTypeId', Session.map.schularten, this.handleSchoolTypeChange)
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
      })

      const classNameExtractorSuccess = data => `Klasse "${data.schoolType}_${data.name}"`
      const classNameExtractorError = data => `Klasse "${data.name}"`
      this.submitHandler = handleSubmitCreateOrEdit(this, classNameExtractorSuccess, classNameExtractorError, true)
     
    </script>
</paedml-modal-createoredit-klassen>
