<paedml-modal-update-fav>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 1}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler}
                patient="{{singular: 'Favorit', plural: 'Favoriten'}}"
                cancelbutton={mode === 'remove'}
                >

    <yield to="fields">
      <p>{parent.opText}</p>
      <p></p>
      <paedml-form-input name="redirect" placeholder="Nach dem {parent.opTextShort} zum 'Homescreen' springen" fgclass="py-1"
        type="checkbox" revlut="jaNein"
      />

    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'

    this.prefix = opts.prefix
    this.mode = opts.mode
    this.data = {
    }
    this.api = this.opts.api
    this.opTextShort = this.mode === 'create' ? "Hinzufügen" : "Entfernen"
    this.opText = this.mode === 'create' ? 
      "Fügt ausgewählte Einträge zu Favoriten. Diese erscheinen auch im Homescreen." : 
      "Entfernt ausgewählte Einträge aus den Favoriten. Diese verschwinden auch vom Homescreen."
    
    this.on('dataRequested', () => {
      this.data.redirect = true
    })

    this.submitHandler = formData => {
      // console.debug('Fav', this.mode, formData.ids)
      // console.debug(this.api)
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')
      const op = this.mode

      return this.api[op](formData.ids).then(rsp => {
        const [emptyRsp, status] = rsp

        // this.parent.trigger('reloadTable')
        this.parent.parent.opts.siblings.forEach(card => {
          card.tags['paedml-card'].trigger('reloadTable')
        })

        if (status.error) {
          return Promise.reject('Kommnicationsfehler.')
        }

        // console.debug('op', op)
        if (this.data.redirect) setTimeout(() => { 
          $('body').removeClass('modal-open')
          // setTimeout(() => $('body').removeClass('modal-open'), 100)
          window.location.href = '/#dashboard/home' 
        }, 500)
        return 'Favoriten erfolgreich ' + (op === 'create' ? 'hinzugefügt' : 'entfernt')
      })

    }

  </script>
</paedml-modal-update-fav>
