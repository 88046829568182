import './paedml-form-input-pw.tag'

<paedml-modal-pw>
  <paedml-modal prefix={opts.prefix} heading={opts.heading + ': Kennwort ändern'} opname="Kennwort ändern"
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} okbutton="{warning ? 'OK' : undefined}" hidemulti="{warning ? true : false}"
                >

    <yield to="fields">

      <div if={parent.warning} class="mt-2 ml-1 p-2 border border-danger">
        <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-1">{parent.warning}</strong> </span>
      </div>
      <paedml-form-input-pw show={!warning} uselabel={true} 
        minLength={parent.minPwLength}
        passwordpolicy={parent.passwordPolicy} />
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import Session from '../store/session'

    this.prefix = opts.prefix
    this.minPwLength = 3; 
    this.passwordPolicy = (this.opts.preset || {}).passwordPolicy
    this.data = {
      passwordPolicy: this.passwordPolicy || 0
    }
    this.api = this.opts.api
    this.variant = this.opts.variant
    this.warning = ''
    
    this.on('dataRequested', (selected) => {
      this.warning = ''
      this.data.passwordPolicy = this.passwordPolicy

      if (this.variant !== 'admin') {
        const pwTag = this.tags['paedml-modal'].tags['paedml-form-input-pw']

        const slut = {}
        const splut = {}
        const indexSchulart = this.variant === 'lehrer' ? 5 : 4
        let pwLenProp = this.variant === 'lehrer' ? 'teacherPasswordLength' : 'studentPasswordLength'
        let pwPolicyProp = this.variant === 'lehrer' ? 'teacherDefaultPasswordPolicy' : 'studentDefaultPasswordPolicy'
        
          selected.each((e) => {
            const schulart = e[indexSchulart]
            if (!slut[schulart]) {
              slut[schulart] = Session.settings[Session.lut.schularten[schulart]][pwLenProp]
              splut[schulart] = Session.settings[Session.lut.schularten[schulart]][pwPolicyProp]
            }
          })
          const uniq = R.uniq(R.values(slut))
          const spuniq = R.uniq(R.values(splut))
          if (uniq.length !== 1) {
            this.warning = `Die ausgewählten Benutzer gehören zu verschiedenen Schularten mit unterschiedlichen Kennwortlängen: `
             + R.compose(R.join(', '), R.values, R.mapObjIndexed((v,k) => `"${k}" Mindestlänge ${v}`))(slut)
            this.update()
            return
          }
          if (spuniq.length !== 1) {
            this.warning = `Die ausgewählten Benutzer gehören zu verschiedenen Schularten mit unterschiedlichen Kennwortoptionen.`
            this.update()
            return
          }
          
          this.data.passwordPolicy = spuniq[0]
          pwTag.trigger('refresh', { minPwLength: uniq[0] })
      } else {
        this.data.passwordPolicy = 1
      }
    })

    this.submitHandler = formData => {
      const userNames = formData.idTexts
      const payload = {
        ids: formData.ids,
        password: formData.password,
        passwordPolicy: formData.passwordPolicy
      }

      return this.api.password(payload).then(rsp => {
        const [rspData, status] = rsp
        if (status.success) {
          if (userNames.length === 1) {
              return `Das Kennwort für das Benutzerkonto "${userNames[0]}" wurde erfolgreich geändert`
            } else if (userNames.length === 2) {
              return `Kennwörter für die Benutzerkonten "${userNames[0]}" und "${userNames[1]}" wurden erfolgreich geändert`
            } else {
              return `Kennwörter für "${userNames[0]}" und ${userNames.length-1} weitere Konten wurden erfolgreich geändert`
            } 
        } else {
          return Promise.reject('Fehler bei Kennwortänderung(en)!')
        }
      })
    }

  </script>
</paedml-modal-pw>
