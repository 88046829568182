<paedml-login>
  <div class="page login-page">
    <div class="container">
      <div class="form-outer text-center align-items-center row" >
        <div class="form-inner col">
          <div class="logo"><strong class="text-primary">paedML&reg; Windows</strong></div>
          <div class="logo"><span>Benutzeranmeldung</span></div>

          <form ref="form" class="text-left form-validate">
            <div class="form-group-material">
              <input id="login-username" autocomplete="off" type="text" name="username" required data-msg="Bitte geben Sie Ihre Benutzerkennung ein" class="input-material">
              <label for="login-username" class="label-material">Benutzerkennung</label>
            </div>
            <div class="form-group-material">
              <input id="login-password" type="password" name="password" required data-msg="Bitte geben Sie das Passwort ein" class="input-material">
              <label for="login-password" class="label-material">Passwort</label>
            </div>
            <div class="form-group text-center">
              <button ref="sb" data-style="expand-left" type="submit" class="btn btn-primary">Anmelden</button>
            </div>
          </form>
          <p><br></p>
        </div>
      </div>
    </div>
  </div>

  <script>
    import { loginWithUsernameAndPassword } from '../api/user'
    import store from '../store'
    import * as R from 'ramda'
    
    this.on('mount', () => {
      const self = this
      this.validate = $(this.refs.form).validate({
        errorElement: "div",
        errorClass: 'is-invalid',
        validClass: 'is-valid',
        ignore: ':hidden:not(.summernote),.note-editable.card-block',
        errorPlacement: function (error, element) {
            error.addClass("invalid-feedback");
            if (element.prop("type") === "checkbox") {
                error.insertAfter(element.siblings("label"));
            } 
            else {
                error.insertAfter(element);
            }
        },
        submitHandler: function(form, e) {
          e.preventDefault();
          e.stopPropagation();
  
          const l = Ladda.create(self.refs.sb);
          l.start();
  
          const userInput = { }
  
          $('input', form).each(function(i, e) {
            const fieldName = $(e).attr('name');
            if (fieldName) {
              userInput[fieldName] = $(e).val()
            }
          })
  
          store.dispatch(dispatch => {
            loginWithUsernameAndPassword(userInput.username, userInput.password)
              .then(function(user) {
                l.stop()
                form.reset();
                dispatch({type: 'user/loginSuccess', payload: user})
                const target = self.opts.to ? self.opts.to : '/dashboard/home'
                const sp = target.split('/')
                const targetSub = sp.length === 3 ? sp[2] : undefined
                dispatch({type: 'page/changed', payload: { url: target, main: sp[1], sub: targetSub, from: {url: 'login', main:'login'} }})
                self.route(target)
              }, function(error) {
                l.stop()
                const [msg, status] = error
                
                dispatch({type: 'user/loginFailure', payload: error.message})
                Messenger().post({
                  message: msg.error === 'invalid_grant' ? 'Benutzerkennung oder Kennwort falsch' : 
                    'Anmeldung konnte nicht ausgeführt werden.',
                  type: 'error'
                })
              })
          })

        }
      });
      initMaterialInput()
    })

    const initMaterialInput = () => {
      var materialInputs = $('input.input-material');

      // activate labels for prefilled values
      materialInputs.filter(function () {
          return $(this).val() !== "";
      }).siblings('.label-material').addClass('active');

      // move label on focus
      materialInputs.on('focus', function () {
          $(this).siblings('.label-material').addClass('active');
      });

      // remove/keep label on blur
      materialInputs.on('blur', function () {
          $(this).siblings('.label-material').removeClass('active');

          if ($(this).val() !== '') {
              $(this).siblings('.label-material').addClass('active');
          } else {
              $(this).siblings('.label-material').removeClass('active');
          }
      });
    }
  </script>

</paedml-login>
  