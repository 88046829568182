import * as R from 'ramda'
import Session from '../store/session'

$(document).ready(function () {

    Messenger.options = {
        extraClasses: 'messenger-fixed messenger-on-top  messenger-on-right',
        theme: 'flat',
        messageDefaults: {
            showCloseButton: true
        }
    }

    // ------------------------------------------------------- //
    // Tooltips init
    // ------------------------------------------------------ //    
    $('[data-toggle="tooltip"]').tooltip()

    // ------------------------------------------------------- //
    // Jquery Progress Circle
    // ------------------------------------------------------ //
    var progress_circle = $("#progress-circle").gmpc({
        color: '#FF9F1C',
        line_width: 5,
        percent: 80
    });
    progress_circle.gmpc('animate', 80, 3000);

    // ------------------------------------------------------- //
    // External links to new window
    // ------------------------------------------------------ //

    $('.external').on('click', function (e) {

        e.preventDefault();
        window.open($(this).attr("href"));
    });



    // ------------------------------------------------------- //
    // paedML custom rules
    // ------------------------------------------------------ //
    $.validator.addMethod("username", function(value, element, params) {
        return this.optional(element) || /^[A-Za-z0-9][-._A-Za-z0-9]{0,16}[A-Za-z0-9]$|^[A-Za-z0-9]$/.test(value);
    }, "Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende.");

    $.validator.addMethod("schema", function(value, element, params) {
        return this.optional(element) || /^N(\.?(V|v+))?$|^V(\.?(N|n+))?$|^n+(\.?(V|v+))?$|^v+(\.?(N|n+))?$/.test(value);
    }, "Erlaubt sind: n, N, v, V. Optional kann ein &quot;.&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende.");
    
    $.validator.addMethod("prefix", function(value, element, params) {
        return this.optional(element) || /^[A-Za-z0-9][-._A-Za-z0-9]{0,3}$/.test(value);
    }, "Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang.");

    $.validator.addMethod("suffix", function(value, element, params) {
        return this.optional(element) || /^[-._A-Za-z0-9]{0,3}[A-Za-z0-9]$/.test(value);
    }, "Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Ende.");

    $.validator.addMethod("fail", function(value, element, params) {
        return false;
    }, "Fehler");

    $.validator.addMethod("succeed", function(value, element, params) {
        return true;
    }, "OK");

    $.validator.addMethod("check", function(value, element, params) {
        if (this.optional(element)) return true
        const key = $(element).data('ruleCheck')
        const result = $(element).data(key)
        return result

    }, "OK");

    $.validator.addMethod( "schuljahr", function( value, element ) {
        return this.optional( element ) || /^[0-9]{1,6}$/.test( value );
    }, "max 6 stellige Zahlen" );

    $.validator.addMethod( "fwinstname", function( value, element ) {
        return this.optional( element ) || /^[-0-9A-Za-z_]{8}$/.test( value );
    }, "Fehler" );

    $.validator.addMethod( "personname", function( value, element ) {
        return this.optional( element ) || /(?=^[a-zA-ZàáâäãåąăčćęèéêëėìíîïńòóôöõøșțùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĂĆČĖĘÈÉÊËÌÍÎÏŃÒÓÔÖÕØȘȚÙÚÛÜŲŪŸÝŻŹÑßÇČŠŽẞ0-9])(?=[a-zA-ZàáâäãåąăčćęèéêëėìíîïńòóôöõøșțùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĂĆČĖĘÈÉÊËÌÍÎÏŃÒÓÔÖÕØȘȚÙÚÛÜŲŪŸÝŻŹÑßÇČŠŽẞ0-9-._ ']*$)(?=.{2,}$)(.*(?<![-._ ])$)$/.test( value );
    }, "Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang oder am Ende."
    );

    $.validator.addMethod( "alphanum", function( value, element ) {
        return this.optional( element ) || /^[A-Za-z0-9]+$/.test( value );
    }, "Nur Buchstaben und Zahlen sind erlaubt" );

    $.validator.addMethod( "alphanumsmall", function( value, element ) {
        return this.optional( element ) || /^[a-z0-9]+$/.test( value );
    }, "Nur Kleinbuchstaben und Zahlen sind erlaubt" );

    $.validator.addMethod( "alphanumchar", function( value, element ) {
        if (this.optional(element)) return true
        if (/[- .]{2,}/.test(value)) return false
        return /^[A-Za-z0-9ÜüÖöÄä][- .A-Za-z0-9ÜüÖöÄä]*[A-Za-z0-9ÜüÖöÄäß]*$/.test(value);
    }, "Buchstaben, Zahlen und sowie Bindestrich und Leerzeichen sind erlaubt." );

    $.validator.addMethod( "quota", function( value, element ) {
        if (this.optional(element)) return false
        return /^[1-9][0-9]*$|^[1-9][,][0-9]+$|^[1-9][0-9]+[,][0-9]+$/.test(value);
    }, "nur Fliesskommazahlen erlaubt" );

    $.validator.addMethod( "csv", function( value, element ) {
        if (this.optional(element)) return true
        return /\.csv|\.txt$/i.test(value);
    }, "CSV" );

    $.validator.addMethod( "project", function( value, element ) {
        return this.optional( element ) || /^[A-Za-z0-9ÜüÖöÄäß][_A-Za-z0-9ÜüÖöÄäß]{0,28}[A-Za-z0-9ÜüÖöÄäß]$|^[A-Za-z0-9ÜüÖöÄäß]$/.test( value );
    }, "project" );

    $.validator.addMethod( "domain", function( value, element ) {
        return this.optional( element ) || /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test( value );
    }, "domain" );

    $.validator.addMethod( "url", function( value, element ) {
        return this.optional( element ) || /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test( value );
    }, "Keine gültige Url" );

    // https://github.com/jquery-validation/jquery-validation/blob/master/src/additional/greaterThan.js
    $.validator.addMethod( "greaterThan", function( value, element, param ) {
        const target = $( param );
    
        if ( this.settings.onfocusout && target.not( ".validate-greaterThan-blur" ).length ) {
            target.addClass( "validate-greaterThan-blur" ).on( "blur.validate-greaterThan", function() {
                $( element ).valid();
            } );
        }
    
        return parseInt(value) > parseInt(target.val())
    }, "Please enter a greater value." );

    
    $.validator.addMethod( "lessThan", function( value, element, param ) {
        var target = $( param );

        if ( this.settings.onfocusout && target.not( ".validate-lessThan-blur" ).length ) {
            target.addClass( "validate-lessThan-blur" ).on( "blur.validate-lessThan", function() {
                $( element ).valid();
            } );
        }

        return parseInt(value) < parseInt(target.val())
    }, "Please enter a lesser value." );

    $.validator.addMethod("firewallType", function(value, element, params) {
       return Session.feSettings.firewall[value] === true;
    }, "Fehler");

    $.validator.addMethod("notin", function(value, element, params) {
        if (!value) return true
        const val = value.toLowerCase()
        const found = params.split(',').find(e => e.toLowerCase() === val )
        return found ? false : true
    }, "Fehler");

    $.validator.addMethod("emailextra", function(value, element, params) {
        return this.optional( element ) || ! /[\!\$\%\&\/\=\?\~\{\}\#\*\+\'\°\^]/.test( value );
    }, "Fehler");

    $.validator.addMethod("sessionmap", function(value, element, key) {
        if (!value) return true
        const val = value.toLowerCase()
        const found = R.values(Session.map[key]).find(e => e.toLowerCase() === val )
        return found ? false : true
    }, "Fehler");
})
