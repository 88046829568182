import pages from '../config/pages'
import { client, getAPIForCard } from '../util/api-util'
import { createRequestCanceler, withPseudoClientResponse } from '../api/client'
import { isPermitted } from '../util/permission'
import Session from '../store/session'
import { toTrueMap } from '../util/mapping-util'
import { usingExternalConfig } from '../util/config-util'
import * as R from 'ramda'

const API = {}

R.pipe(
    // extract cards from pages
    R.values,
    R.map(R.prop('cards')),
    R.flatten,

    // create generic APIs for each card
    R.forEach(card => {
        API[card.name] = getAPIForCard(card)
    })
)(pages)

API.settings = {
    list: () => client.get('/api/settings'),
    update: (schoolTypeId, settings) => client.patch('/api/settings/' + schoolTypeId, ({ settings }))
}

API.user = {
    logout: client.logout,
    login: client.login,
    loginWithRefreshToken: client.loginWithRefreshToken,
    loginWithUsernameAndPassword: client.loginWithUsernameAndPassword,
    exists: userName => client.get('/api/users/exists/' + userName + '/').then(rsp => {
        const [data, status] = rsp
        return status.success
    }),
    available: userName => client.get('/api/users/exists/' + userName + '/').then(rsp => {
        const [data, status] = rsp
        return status.code === 404
    }),
    // Workaround: https://dev.azure.com/lmzbw/paedml/_workitems/edit/53/
    saveProfile: profileSettings => client.post('/api/users/profileSettings',
        JSON.stringify(JSON.stringify(profileSettings)), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
}

API.schulart.yearChange = (id) => client.post(`/api/school/${id}/yearChange`)
API.schulart.validateYearChange = (id) => client.get(`/api/school/${id}/yearChange`)

API.klasse.fixPermissions = ids => client.post(`/api/school/schoolClasses/permissionCorrection`, ids)
API.klasse.cleanupFolder = ids => client.post(`/api/school/schoolClasses/cleanupFolder`, ids)
API.klasse.moveLongFiles = ids => client.post(`/api/school/schoolClasses/correctLongFilenames`, ids)

API.student.internetLock = (ids, isInternetLocked) => client.post('/api/students/internetLock', { ids, isInternetLocked }),
API.student.sync = () => client.post('/api/sync/students')
API.student.fixPermissions = ids => client.post(`/api/students/permissionCorrection`, ids)
API.student.cleanupFolder = ids => client.post(`/api/students/cleanupFolder`, ids)
API.student.moveLongFiles = ids => client.post(`/api/students/correctLongFilenames`, ids)
API.student.activate = (ids, isDeactivated) => client.post(`/api/students/activate`, { ids, isDeactivated })

API.lehrer.sync = () => client.post('/api/sync/teachers')
API.lehrer.fixPermissions = ids => client.post(`/api/teachers/permissionCorrection`, ids)
API.lehrer.cleanupFolder = ids => client.post(`/api/teachers/cleanupFolder`, ids)
API.lehrer.moveLongFiles = ids => client.post(`/api/teachers/correctLongFilenames`, ids)
API.lehrer.activate = (ids, isDeactivated) => client.post(`/api/teachers/activate`, { ids, isDeactivated })

const domainResolver = context => domain => client
    .post(`/api/${context}/domains/resolve`, `"${domain}"`, { headers: { "Content-Type": "application/json" } })
    .then(rsp => {
        const [data, status] = rsp
        return status.code === 200
    })

API.domain.resolve = domainResolver('cloud')

API.moodledomain.resolve = domainResolver('cloud')

API.cloud = {
    student: {
        sync: (ids, syncWithO365) => client.post('/api/cloud/students/sync', { ids, syncWithO365 }),
        domain: (ids, domain) => client.post('/api/cloud/students/domain', { ids, domain })
    },
    teacher: {
        sync: (ids, syncWithO365) => client.post('/api/cloud/teachers/sync', { ids, syncWithO365 }),
        domain: (ids, domain) => client.post('/api/cloud/teachers/domain', { ids, domain })
    },
    domain: API.domain
}

API.moodle = {
    student: {
        sync: (ids, enable) => client.post('/api/moodle/students/enable', { ids, enable }),
        domain: (ids, domain) => client.post('/api/moodle/students/domain', { ids, domain }),
        update: (id, body) => client.patch(`/api/moodle/students/${id}`, { email: body.email })
    },
    teacher: {
        sync: (ids, enable) => client.post('/api/moodle/teachers/enable', { ids, enable }),
        domain: (ids, domain) => client.post('/api/moodle/teachers/domain', { ids, domain }),
        update: (id, body) => client.patch(`/api/moodle/teachers/${id}`, { email: body.email })
    },
    domain: API.moodledomain
}

API.wartung = {
    dbsync: () => client.post('/api/sync/db')
}

API.projekte.available = name => client.get('/api/projects/exists/' + name + '/').then(rsp => {
    const [data, status] = rsp
    return status.code === 404
})

API.projekte.share = (id, shareFile) => client.post(`/api/projects/${id}/share`, shareFile)
API.projekte.moveLongFiles = ids => client.post(`/api/projects/correctLongFilenames`, ids)
API.projekte.members = (id, members) => client.post(`/api/projects/${id}/members`, members)
API.projekte.leaders = (id, members) => client.post(`/api/projects/${id}/leaders`, members)

API.profileManagement = {
    duplicate: roles => po => client.post(`api/profileManagement/${roles}/duplication`, po),
    load: roles => po => client.post(`api/profileManagement/${roles}/allocation`, po),
    save: roles => po => client.put(`api/profileManagement/${roles}/allocation`, po),
    activate: roles => po => client.post(`api/profileManagement/${roles}/activation`, po),
    remove: roles => ids => client.delete(`api/profileManagement/${roles}`, { data: ids }),
    reset: roles => po => client.post(`api/profileManagement/${roles}/reset`, po)
}

API.singlelogin = {
    students: {
        edit: (users, isSingleLoginEnabled) => client.post('/api/singlelogin/students', { users, isSingleLoginEnabled }),
        freigabe: (userName, logoffDevices) => client.post('/api/singlelogin/students/allowLogin', { userName, logoffDevices }),
    },
    teachers: {
        edit: (users, isSingleLoginEnabled) => client.post('/api/singlelogin/teachers', { users, isSingleLoginEnabled }),
        freigabe: (userName, logoffDevices) => client.post('/api/singlelogin/teachers/allowLogin', { userName, logoffDevices }),
    },
    exammembers: {
        edit: (users, isSingleLoginEnabled) => client.post('/api/singlelogin/examMembers', { users, isSingleLoginEnabled }),
        freigabe: (userName, logoffDevices) => client.post('/api/singlelogin/examMembers/allowLogin', { userName, logoffDevices }),
    }
}

if (API.software === undefined) API.software = {}
if (API.software.computers === undefined) API.software.computers = {}

API.software.sync = () => client.get('/api/software/sync')

API.software.installations = {
    get: (id) => client.get(`api/software/${id}/installed`),
    set: (id, installations) => client.post(`api/software/${id}/installed`, installations),
}

API.software.computers.installations = {
    get: (id) => client.get(`api/software/computers/${id}/installed`),
    set: (id, installations) => client.post(`api/software/computers/${id}/installed`, installations),
}

API.software.computers.info = {
    get: (id) => client.get(`api/software/computers/${id}/software`),
    refresh: (id) => client.get(`api/software/computers/${id}/refresh`),
}

API.software.computers.ondemand = ids => client.post('/api/software/computers/ondemand', ids)
API.software.computers.sync = () => client.get('/api/software/computers/sync')
API.software.computers.getAll = () => client.get('/api/software/computers')

const profLut = {
    proflul: 'teachers',
    profsus: 'students',
    profexams: 'exams'
}
Object.keys(profLut).forEach(e => {
    const roleName = profLut[e]
    API[e].duplicate = API.profileManagement.duplicate(roleName)
    API[e].activate = API.profileManagement.activate(roleName)
    API[e].load = API.profileManagement.load(roleName)
    API[e].save = API.profileManagement.save(roleName)
    API[e].reset = API.profileManagement.reset(roleName)
})

function delay(t, v) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t)
    });
}

const timedRequest = (url, maxWait) => ids => {
    const requestCanceler = createRequestCanceler()
    const postReq = client.post(url, ids, {
        cancelToken: requestCanceler.token
    })
    return Promise.race([
        postReq,
        delay(maxWait).then(() => Promise.resolve(requestCanceler.cancel('Race lost against timer')))
    ])
}

API.jobs.run = timedRequest('/api/jobs/run', 2000)

API.jobs.getTasksByJobId = jobId => client.get(`/api/jobs/${jobId}/tasks`)

API.tasks.run = timedRequest('/api/jobs/tasks/run', 2000)

API.importlehrer.run = timedRequest('/api/import/start', 2000)
API.importschueler.run = timedRequest('/api/import/start', 2000)

API.importlehrer.create = (payload) => client.post('/api/import', payload)
API.importschueler.create = (payload) => client.post('/api/import', payload)

API.getPermittedCards = () => {
    const cards = []
    R.forEachObjIndexed(page => {
        page.cards.forEach(card => {
            const route = `${page.route}/${card.route}`
            if (isPermitted(route)) cards.push({ route, page: { route: page.route, title: page.headerTitle }, card })
        })
    })(pages)
    return cards
}

const dashboardAPI = {
    list: () => {
        const favMap = toTrueMap(Session.profile.favorites || [])
        const rows = API.getPermittedCards()
            .filter(({ route, card, page }) => page.route !== 'dashboard' && card.hideInDashboard !== true)
            .map(({ route, card, page }, i) => [i, card.contentTitle, route, page.title, card.contentTitle, favMap[route] ? true : false, card.representation === 'tiles' ? 'tiles' : 'table', card.icon])
        return withPseudoClientResponse(rows)
    }
}

API.fav = {
    list: () => dashboardAPI.list().then(rsp => {
        const [result, status] = rsp
        const lut = Session.profile.favorites.reduce((acc, route) => { acc[route] = true; return acc }, {})
        const inFav = result.reduce((acc, e) => {
            if (lut[e[2]]) acc.push(e)
            return acc
        }, [])
        return [inFav, status]
    }),
    remove: urls => {
        if (urls == null || urls.length === 0) return
        Session.profile.favorites = R.without(urls, Session.profile.favorites)
        return API.user.saveProfile(Session.profile)
    }

}

API.home = API.fav

API.all = {
    list: () => dashboardAPI.list(),
    create: urls => {
        if (urls == null || urls.length === 0) return
        const favorites = Session.profile.favorites
        Session.profile.favorites = [...favorites, ...R.difference(urls, favorites)]
        return API.user.saveProfile(Session.profile)
    },
    remove: urls => {
        if (urls == null || urls.length === 0) return
        Session.profile.favorites = R.without(urls, Session.profile.favorites)
        return API.user.saveProfile(Session.profile)
    }
}

API.meineklassen.set = (klassenIds) => client.post('/api/teachers/mySchoolClasses', klassenIds)
API.handout.collect = (id, deleteAfterwards) => client.put(`/api/shareFiles/${id}/collect`, ({ deleteAfterwards }))
API.handout.shareToClass = (id, name) => client.post(`/api/school/schoolClasses/${id}/share`, ({ name }))

// Versetzung
API.versetzungfreigabe.reset = (userNames) => client.post('/api/relocation/reset', userNames)
API.versetzungverarbeitung.reset = (userNames) => client.post('/api/relocation/reset', userNames)
API.versetzungfreigabe.approve = (userNames) => client.post('/api/relocation/approve', userNames)

// Raumverwaltung
API.raum.sync = (id) => client.get('/api/rooms/' + (id ? id + '/sync' : 'sync'), { timeout: usingExternalConfig("raumverwaltung", "adSyncTimeout", 180000) })
API.raum.refresh = (id) => client.get('/api/rooms/' + (id ? id + '/refresh' : 'refresh'), { timeout: usingExternalConfig("raumverwaltung", "refreshTimeout", 180000) })
API.raum.internetLock = (ids, isInternetLocked) => client.post('/api/rooms/internetLock', { ids, isInternetLocked }),
API.raum.block = (ids, isBlocked) => client.post('/api/rooms/computerblock', { ids, isBlocked }),
API.raum.bsa = (id, payload) => client.patch('/api/rooms/' + id, payload),

API.computer.sync = (roomId) => client.get('/api/computers/' + (roomId ? 'room/' + roomId + '/sync' : 'sync'), { timeout: usingExternalConfig("raumverwaltung", "adSyncTimeout", 180000) })
API.computer.refresh = (roomId) => client.get('/api/computers/' + (roomId ? 'room/' + roomId + '/refresh' : 'refresh'), { timeout: usingExternalConfig("raumverwaltung", "refreshTimeout", 180000) })
API.computer.getInRoom = (roomId) => client.get(`/api/computers/room/${roomId}`, { timeout: usingExternalConfig("raumverwaltung", "refreshTimeout", 180000) })
API.computer.internetLock = (ids, isInternetLocked) => client.post('/api/computers/internetLock', { ids, isInternetLocked }),
API.computer.move = (ids, room) => client.post('/api/computers/move', { ids, room }),
API.computer.block = (ids, isBlocked) => client.post('/api/computers/computerblock', { ids, isBlocked }),
API.computer.logoff = (ids) => client.post('/api/computers/logoff', ids),
API.computer.shutdown = (ids) => client.post('/api/computers/shutdown', ids),
API.computer.reboot = (ids) => client.post('/api/computers/reboot', ids),
API.computer.start = (ids) => client.post('/api/computers/start', ids),
API.computer.screenshot = (id) => client.get(`api/computers/${id}/screenshot`, { responseType: 'blob' }),
API.computer.password = (id) => client.get(`api/computers/${id}/password`),

API.drucker.sync = (roomId) => client.get('/api/printers/' + (roomId ? 'room/' + roomId + '/sync' : 'sync'), { timeout: usingExternalConfig("raumverwaltung", "adSyncTimeout", 180000) })
API.drucker.refresh = (roomId) => client.get('/api/printers/' + (roomId ? 'room/' + roomId + '/refresh' : 'refresh'), { timeout: usingExternalConfig("raumverwaltung", "refreshTimeout", 180000) }),
API.drucker.move = (ids, room) => client.post('/api/printers/move', { ids, room })
API.drucker.patch = (id, payload) => client.patch('/api/printers/' + id, payload)
API.bsa.approve = (ids) => client.post('/api/bsa/approve', ids)

if (API.fwsettings === undefined) API.fwsettings = {}
API.fwsettings.update = (payload) => client.post('/api/firewall/settings', payload)
if (API.fw === undefined) API.fw = {}
API.fw.get = () => client.get('/api/firewall/')
if (API.fwglobal === undefined) API.fwglobal = {}
API.fwglobal.update = (payload) => client.post('/api/firewall/global', payload)
API.fwklassen.update = (schoolClassId, payload) => client.patch('/api/firewall/schoolClasses/' + schoolClassId, payload)

API.exam.update = (id, payload) => client.put(`/api/exams/${id}`, payload)
API.exam.share = (id) => client.put(`/api/exams/${id}/share`)
API.exam.collect = (id) => client.put(`/api/exams/${id}/collect`)
API.exam.members = (id, members) => client.post(`/api/exams/${id}/members`, members)
API.exam.leaders = (id, members) => client.post(`/api/exams/${id}/leaders`, members)
API.exam.start = (id, payload) => client.put(`/api/exams/${id}/start`, payload)

API.dynamicfilter = {
    kaTitle: (callback) => client.get('/api/exams').then(rsp => {
        const [exams, status] = rsp
        const values = exams.map(e => e.title)
        callback(values)
    }),
    klassen: (callback, col, value) => {
        if (col === 'Klasse') return
        const sa = col === 'Schulart' ? Session.lut.schularten[value] : undefined
        const values = R.map(R.nth(1))(Session.lut.klassenBySchulart[sa])
        callback(values)
    },
    rooms: callback => {
        callback(R.values(Session.map.rooms), Session.user.roleAbbrev === 'L')
    },
}

API.getUserNameFor = {
    teacher: (schoolType, surname, givenName) => client.post('/api/teachers/validateUserName', ({ schoolType, surname, givenName })),
    student: (schoolType, surname, givenName) => client.post('/api/students/validateUserName', ({ schoolType, surname, givenName })),
}

export default API
