import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'
import './paedml-qrcode.tag'

<paedml-modal-init-import>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} large={true} extra={true} okcondition={requiredFieldsSelected}>
  
    <yield to="fields">

      <div class="row">
        <div class="col-sm-3">
          <paedml-form-input name="schoolType" step="1" label="Schulart auswählen" ref="schoolType"
            type="select" values={parent.session.map.schulartenLong} revlut="schularten"
            required data-msg="Bitte wählen Sie die Schulart aus"
            readonly={parent.data.csv}
            handlechange={parent.handleSchoolTypeChange}
          />
        </div>
        <virtual if="{!parent.data.schoolType && parent.isPermitted('benutzerverwaltung/schularten.create')}">
          <div class="col-sm-9">
            <a href="/#benutzerverwaltung/schularten" style="margin-top: 45px; color: black;">
              <paedml-icon icon="fas-external-link-alt" inv={true} /> <span class="pl-1">Klicken Sie hier, falls die zu importierende Schulart angelegt werden muss.</span> 
            </a> 
          </div>
        </virtual>
        <virtual if={parent.data.schoolType}>
          <div class="col-sm-6">
            <label for="{opts.prefix}-import-file" class="paedml-label-step">➋</label>
            <label for="{opts.prefix}-import-file">Zu importierende CSV-Datei auswählen</label>
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="{opts.prefix}-import-file" accept="text/csv,.txt" 
                onchange={parent.handleFileSelection}
                disabled={parent.disableCsv()}
                required data-msg-required="Bitte wählen Sie eine CSV-Datei aus"
                data-rule-csv data-msg-csv='Nur Dateien mit Endungen csv oder txt sind erlaubt'
                >
              <label class="custom-file-label {bg-disabled: parent.disableCsv()}" for="{opts.prefix}-import-file">CSV Datei auswählen</label>
            </div>
          </div>
          <div class="col-sm-2">
            <paedml-form-input step="3" label="Encoding überprüfen" inputid="{opts.prefix}-import-encoding"
              type="select" values="{{'windows-1252':'windows-1252', 'utf8':'utf8'}}"
              disabled={parent.disableCsv()}
              handlechange={parent.handleEncodingChange}
            />
          </div>
          <div class="col-sm-1 pull-right">
            <paedml-form-input name="header" step="4" label="Header" inputid="{opts.prefix}-import-header"
              title="1. Zeile enthält Spaltennamen" fgclass="py-0"
              type="checkbox"
              handlechange={parent.handleHeaderOptionChange}
            />
          </div>
        </virtual>

      </div>
      
      <virtual if={parent.data.csv}>
        <div class="row">
          <div class="col-sm-3">
            <span class="paedml-label-step">➎</span><span class="paedml-span-step"> Spalten auswählen / festlegen</span>
          </div>
          <div class="col-sm-4 text-danger">
              <span class="paedml-span-step" show={!parent.requiredFieldsSelected()}>Es fehlen noch: </span>
              <span class="paedml-span-step" show={!parent.requiredFieldsSelected()}>{parent.getMissingFields()}</span>
          </div>
          <div class="col-sm-5" show={parent.userGroup === 0 && !parent.isFieldSelected('schoolClass')}>
            <div class="row">
              <div class="col-sm-5">
                <paedml-form-input name="manualClass" placeholder="Klasse eingeben"
                  title="Wählen Sie diese Option, falls die CSV-Datei keine Klassen-Information enthält"
                  fgclass="py-2 px-0" type="checkbox"
                />
              </div>
              <div class="col-sm-7">
                <paedml-form-input name="schoolClass" placeholder="Klasse"
                  show={parent.data.manualClass}
                  inputId="{prefix}-input-schoolClass"
                  required data-msg="Bitte geben Sie die Klasse ein"
                  data-rule-alphanum data-msg-alphanum="Nur Kleinbuchstaben und Zahlen sind erlaubt!"
                  data-rule-maxlength="20" data-msg-maxlength="Erlaubt sind maximal 20 Zeichen."
                />
              </div>
            </div>
          </div>
        </div>
      </virtual>
      <div id="{opts.prefix}-table-outer" show={parent.data.csv}></div>

      <virtual if={parent.requiredFieldsSelected()}>
        <div class="paedml-input-group p-0" />

        <span class="pb-1 paedml-label-step">➏</span><span class="pb-1 paedml-span-step"> Kennwort und Moodle Optionen festlegen</span>
       
        <div class="row">
          <div class="col-sm-4" if={!parent.data.fields.password}>
            <paedml-form-input name="password" placeholder="Kennwort: Falls Leer wird das Basiskennwort verwendet" />
          </div>
       
          <div class="col-sm-4">
            <paedml-form-input name="changePasswordOnNextLogin" placeholder="Kennwortänderung erzwingen" fgclass="py-2"
                type="checkbox" revlut="jaNein"
            />
          </div>
     
          <div class="col-sm-4" if={parent.isMoodleAvailable()}>
            <paedml-form-input name="activateMoodle" placeholder="Moodlezugriff aktivieren" fgclass="py-2"
              type="checkbox" revlut="jaNein" if={parent.isMoodleActive()}
            />
            <span if={!parent.isMoodleActive()} class="py-2 text-info" style="font-size: 0.9rem;">Moodle ist nicht verfügbar</span>
          </div>
     
        </div>
      </virtual>

    </yield>
  </paedml-modal>
    
    <script>
      import * as i18n from '../config/i18n/german'
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import { isPermitted } from '../util/permission'

      import moment from 'moment'
      import Papa from 'papaparse'

      this.userGroup = this.opts.usergroup
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = { fields: {} }
      this.api = this.opts.api
      this.session = Session
      this.isPermitted = isPermitted

      this.table = null

      this.isMoodleAvailable = () => {
        return this.session.feSettings.extensions.enableMoodle 
      }

      this.isMoodleActive = () => {
        const settings = Session.settings

        const schulartSetting = settings[this.data.schoolType]
        const moodleTarget = this.userGroup === 0 ? 'studentMoodleEnabled' : 'teacherMoodleEnabled'

        return settings[this.data.schoolType][moodleTarget] === 'true'
      }

      this.isFieldSelected = name => name in this.data.fields
      this.getMissingFields = () => {
        const {givenName, surname, schoolClass} = this.data.fields
        const missing = []
        if (givenName === undefined) missing.push('Vorname')
        if (surname === undefined) missing.push('Nachname')
        if (this.userGroup === 0) {
          if (schoolClass === undefined) {
            if (!(this.data.manualClass && this.data.schoolClass)) {
              missing.push('Klasse')
            }
          }
        }
        return R.join(', ', missing)
      }

      this.handleEncodingChange = val => {
        this.handleFileSelection(undefined, val)
      }

      this.handleHeaderOptionChange = val => {
        if (this.data.numLines === 1) {
          this.data.header = false
          $(`#${opts.prefix}-import-header`).attr('checked', false)
          return
        }

        this.data.header = val
        $(`#${opts.prefix}-import-header`).attr('checked', val)

        const tableNode = $('tbody > tr:nth-child(1)', `#${opts.prefix}-modal-table`)
        if (val) {
          tableNode.addClass('paedml-import-table-header')
        } else {
          tableNode.removeClass('paedml-import-table-header')
        }
      }

      this.handleFileSelection = (event, suppliedEncoding) => {
        this.data.numLines = 0
        const valid = $(`#${this.opts.prefix}-import-file`).valid()

        const files = $('input[type=file]', this.root)[0].files
        if (files.length === 0) {
          $(`label[for=${this.opts.prefix}-import-file].custom-file-label`).text('CSV Datei auswählen')
          this.data.csv = undefined
          this.update()
          return
        } else {
          const fname = valid ? files[0].name : ''
          $(`label[for=${this.opts.prefix}-import-file].custom-file-label`).text(fname)
          this.data.csv = fname
          if (!valid) return
        }

        const config = {
          comments: '#',
          skipEmptyLines: true,
          encoding: $(`#${opts.prefix}-import-encoding`).val(),
          complete: results => {
            const {data, meta, error} = results
            if (data.length === 0) {
              Messenger().post({
                message: `Die Datei '${this.data.csv}' enthält keinen gültigen Inhalt. Bitte wählen Sie eine andere Datei aus.`,
                type: 'error'
              })
              this.data.csv = undefined
              this.update()
              return
            }

            const columns = []
            this.data.numLines = data.length

            let colSize = data[data.length-1].length + 1
            const freq = {}
            data.forEach(v => {
              const len = v.length
              if (freq[len] === undefined) {
                freq[len] = 1
              } else {
                ++freq[len]
              }
            })
            const fval = R.values(freq)
            if (fval.length > 1) {
              let maxf = 0
              R.forEachObjIndexed((v,i) => {
                if (v > maxf) {
                  maxf = v;
                  colSize = parseInt(i)
                }
              })(freq)

              const badData = []
              data.forEach((v,i) => {
                const len = v.length
                if (len !== colSize) {
                  badData.push(i)
                }
              })

              const txt = badData.length === 1 ? `Die Zeile ${badData[0]} enhält ungültige Spalten anzahl.` : 
                badData.length > 5 ? `${badData.length} Zeilen enthalten ungültige Spaltenanzahl.` :
                'Die Zeilen ' + R.join(', ', badData) + ' enthalten ungültige Spaltenanzahl.'
              Messenger().post({
                message: txt + ' Bitte bereinigen Sie die Datei und wählen Sie Datei erneut aus.',
                type: 'error'
              })
              this.data.csv = undefined
              this.update()
              return
            }

            for (let i=1; i < colSize; ++i) {
              columns.push({ name: 'spalte' + i, title: 'Spalte ' + i })
            }

            if (this.table) {
              this.table.destroy(true)
              this.table = null
            }

            $('#' + this.opts.prefix + '-table-outer').append(`<table id="${opts.prefix}-modal-table" style="width: 100%;" class="table table-striped table-hover" />`)

            const selector = `#${opts.prefix}-modal-table`
            this.table = $(selector).DataTable( {
              data,
              columns,
              paging: true,
              pageLength: 50,
              sort: false,
              scrollY: 350,
              searching: false,
              lengthChange: false,
              responsive: false,
              scrollX: true,
              oLanguage: i18n.datatables
            } )

            const options = {
              givenName: 'Vorname',
              surname: 'Nachname',
              username: 'Benutzername',
              password: 'Kennwort'
            }

            if (this.userGroup === 1) {
              options.initials = 'Kürzel'
              options.initialsWithID = 'Benutzername & Kürzel'
              options.externalId = 'Lehrer-ID'
            } else {
              options.schoolClass = 'Klasse'
              options.externalId = 'Schüler-ID'
            }

            options.email = 'Email'

            if(this.isMoodleAvailable() && this.isMoodleActive()) {
              options.moodleEmail = 'Moodle Email'
            }

            const optionHtml = index => `<option value="">ungenutzt</option>` + R.map(k => `<option name="${k}" value="${k}">${options[k]}</option>`)(R.keys(options))

            $( 'tr > th', this.table.table().header() ).each((i,e) => {
              $(e).css('min-width', '110px').css('padding-top', '0px')
              const template = 
              `<div class="input-group input-group-sm mb-0" style="margin-left: -10px;">
                  <select class="form-control" id="${this.opts.prefix}-col-${i}">${optionHtml(i)}</select>
              </div>`
              $(e).html(template)
              $('select', e).change((ev) => this.columnSelected(i, ev, e))
            })

            $( 'tr > td', this.table.table().body() ).css('min-width', '110px')

            this.handleHeaderOptionChange(this.data.header)
          }
        }
        const reader = new FileReader();
        reader.onload = e => {
          const encoding = suppliedEncoding ? suppliedEncoding
                                            : /[äöüÄÖÜßé]/.test(e.target.result) ? 'windows-1252' : 'utf8'
          $(`#${opts.prefix}-import-encoding`).val(encoding)
          config.encoding = encoding
          Papa.parse(files[0], config)
        }
        reader.readAsText(files[0], 'windows-1252')
        
      }

      this.disableCsv = () => {
        return R.keys(this.data.fields).length > 0
      }

      this.requiredFieldsSelected = () => {
        if (!R.keys(this.data.fields).length) return false

        const {surname, givenName, schoolClass} = this.data.fields
        if (this.userGroup === 0) {
          const sc = schoolClass === undefined ? this.data.manualClass && this.data.schoolClass : schoolClass
          return surname !== undefined && givenName !== undefined && (sc || sc === 0)
        }
        return surname !== undefined && givenName !== undefined
      }

      this.columnSelected = (i, ev, e) => {
        const target = ev.target
        const old = $(target).data('last')
        const changed = $(target).val()
        $(target).data('last', changed)
        if (old) {
          $(`option[name="${old}"]`).prop('disabled', false)
        }
        if (changed) {
          $(`option[name="${changed}"]`).prop('disabled', true)
        }
        if (changed) {
          $('button', e).hide()
          if (old) {
            delete this.data.fields[old]
          }
          this.data.fields[changed] = i
        } else {
          if (old) {
            delete this.data.fields[old]
          }
          $('button', e).show()
        }
        this.update()
      }

      this.hideColumn = (i, table) => {
        table.column(i).visible(false)
      }

      const firstOrEmptySelection = (name, map, callback) => {
        if (this.data[name]) return
        if (this.isPermitted('benutzerverwaltung/schularten.create')) {
          this.data[name] = ''
          return
        }
        const keys = R.keys(map)
        this.data[name] = keys.length === 1 ? keys[0] : ''
        if (callback && keys.length === 1) {
          callback(keys[0])
        }
      }

      this.on('dataRequested', () => {
        this.data = {
          fields: {},
          csv: undefined,
          header: false,
          numLines: 0,
        }
        firstOrEmptySelection('schoolType', Session.map.schularten)
      })

      this.on('rowMappingRequested', cols => {

      })

      this.on('hide', () => {
        if (this.table) {
          this.table.destroy(true)
          this.table = null
        }

        $(`label[for=${this.opts.prefix}-import-file]`).text('CSV Datei auswählen')
      })

      this.submitHandler = formData => {
        const {
          surname,
          givenName,
          username,
          password,
          initials,
          initialsWithID,
          externalId,
          schoolClass,
          email,
          moodleEmail
        } = this.data.fields

        const reqData = {
          schoolType: formData.schoolType,
          userGroup: this.userGroup,
          surname: { column: 0 },
          givenName: { column: 1 },
        }

        const cm = [
          surname, // 0
          givenName, // 1
        ]

        if (initialsWithID > -1) {
          reqData.username = { column: cm.length }
          reqData.initials = { column: cm.length }
          cm.push(initialsWithID)
        }

        if (username > -1) {
          reqData.username = { column: cm.length }
          cm.push(username)
        }

        if (initials > -1) {
          reqData.initials = { column: cm.length }
          cm.push(initials)
        }

        if (password > -1) {
          reqData.password = { 
            column: cm.length,
          }
          cm.push(password)
        } else {
          reqData.password = {
            password: formData.password
          }
        }
        reqData.password.changePasswordOnNextLogin = formData.changePasswordOnNextLogin

        if (externalId > -1) {
          reqData.externalId = { column: cm.length }
          cm.push(externalId)
        }

        if (schoolClass > -1) {
          reqData.schoolClass = { column: cm.length }
          cm.push(schoolClass)
        }
        
        if (email > -1) {
          reqData.email = { column: cm.length }
          cm.push(email)
        }

        if (moodleEmail > -1) {
          reqData.moodleEmail = { column: cm.length }
          cm.push(moodleEmail)
        }
        
        reqData.activateMoodle = formData.activateMoodle ? formData.activateMoodle : false

        const data = []
        const tdata = this.table.rows().data()
        let i = formData.header ? 1 : 0
        const end = tdata.length
        for (; i < end; ++i) {
          const row = tdata[i]
          const cols = []
          cm.forEach(v => {
            cols.push(row[v])
          })
          data.push(cols)
        }
        reqData.data = data

        if (formData.manualClass) {
          reqData.schoolClass = {
            value: formData.schoolClass
          }
        }

        return this.api.create(reqData).then(rsp => {
          const [data, status] = rsp
          if (status.success) {
            this.parent.trigger('reloadTable')
            return `Datenaufbereitung erfolgreich`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          return Promise.reject(`Datenaufbereitung fehlgeschlagen.`)
        })
      }


    </script>
</paedml-modal-init-import>
