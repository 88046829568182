import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-import>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} okcondition={isResolved}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="schoolType" col="3" label="Schulart" readonly/>
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="surname" col="5" label="Nachname"
              required data-msg="Bitte geben Sie den Nachnamen ein"
              data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Nachnamen ein."
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="givenName" col="6" label="Vorname"
                required data-msg="Bitte geben Sie den Vornamen ein"
                data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Vornamen ein."
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="userName" col="4" label="Benutzername" placeholder="Falls leer, dann wird er automatisch generiert"
              data-rule-username data-msg-username="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende."
              handlechange={parent.handleUserNameChange} changemode="keyup"
              verifychange={parent.verifyUsernameChanged}
              data-rule-check="userName" data-msg-check="Benutzername bereits vorhanden!"
              maxlength="19" data-rule-maxlength="18" data-msg-maxlength="Der Benutzername darf nicht mehr als 18 Zeichen enthalten!" 
            />
            <svg if={parent.isChecking} class="paedml-icon-overlay fullrotation">
              <use xlink:href="#far-question-circle" />
            </svg>
          </div>
          <div class="col-sm-6">
            <paedml-form-input if="{parent.userGroup === 1}" name="initials" col="7" label="Kürzel" 
              data-rule-alphanum data-msg-alphanum="Nur Buchstaben und Zahlen sind erlaubt!"
              data-rule-maxlength="6" data-msg-maxlength="Erlaubt sind maximal 6 Zeichen."
            />
            <paedml-form-input if="{parent.userGroup === 0}" name="schoolClass" col="7" label="Klasse" 
              data-rule-alphanum data-msg-alphanum="Nur Kleinbuchstaben und Zahlen sind erlaubt!"
              data-rule-maxlength="20" data-msg-maxlength="Erlaubt sind maximal 20 Zeichen."
            />
          </div>
        </div>
        
        <virtual if={parent.data.errorType === 'd' || parent.dup}>
          <div class="paedml-input-group py-0"></div>
          <div class="row">
            <div class="col-sm-3">
              <paedml-icon icon="fas-exclamation-triangle" inv={true} />
              <span>Duplikat</span>
            </div>
            <div class="col-sm-9">
              <paedml-form-input name="surpressWarnings" placeholder="Zur Kenntnis genommen / Warnung ignorieren." type="checkbox" 
                handleChange={parent.handleSuppressWarnings}
              />
            </div>
          </div>
        </virtual>

         <virtual if={parent.data.errorType === 'b'}>
          <div class="paedml-input-group py-0"></div>
          <div class="row">
            <div class="col-sm-12">
              <paedml-icon icon="far-edit" inv={true} />
              <span class="text-small">Benutzername wurde automatisch angepasst. Bitte nach Wunsch bearbeiten.</span>
            </div>
          </div>
        </virtual>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="password" col="9" label="Kenntwort" placeholder="Falls leer, dann wird das Basiskennwort verwendet" 
              data-rule-maxlength="64" data-msg-maxlength="Das Kennwort darf nicht mehr als 64 Zeichen enthalten!" 
              data-rule-minlength="4" data-msg-minlength="Das Kennwort muss mindestens 4 Zeichen haben!" 
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="changePasswordOnNextLogin" col="8" placeholder="Kennwortänderung erzwingen" label="&nbsp;" fgclass="py-2"
              type="checkbox" revlut="jaNein"
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group" if={parent.isMoodleAvailable()}>
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="moodleEmail" col="13" placeholder="Moodle E-Mail" label="Moodle E-Mail" 
            data-rule-email data-msg-email="Bitte geben Sie eine gültige E-Mailadresse ein."
            data-rule-emailextra data-msg-emailextra="Bitte geben Sie eine gültige E-Mailadresse ein."
            />
          </div>
          <div class="col-sm-6">
          <paedml-form-input name="activateMoodle" col="12" placeholder="Moodlezugriff aktivieren"  label="&nbsp;" fgclass="py-2"
            type="checkbox" revlut="jaNein"
          />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-8">
            <paedml-form-input name="email" col="10" label="E-Mail" 
            data-rule-email data-msg-email="Bitte geben Sie eine gültige E-Mailadresse ein."
            data-rule-emailextra data-msg-emailextra="Bitte geben Sie eine gültige E-Mailadresse ein."
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input if="{parent.userGroup === 1}" name="externalId" col="11" label="Lehrer-ID" />
            <paedml-form-input if="{parent.userGroup === 0}" name="externalId" col="11" label="Schüler-ID" />
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client, userNameAvailable, clearUserNameAvailabilityCtx } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      this.userGroup = this.opts.usergroup
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session
      this.dup = false
      this.moodleActive = true
      this.lastUserName = this.data.userName
      
      this.isResolved = () => this.resolved === true

      this.handleSuppressWarnings = val => {
        $('input[name=userName]', this.root).data('userName', val)
        $('input[name=userName]', this.root).valid()
        this.resolved = val
      }

      this.isMoodleAvailable = () => {
        return Session.feSettings.extensions.enableMoodle && this.moodleActive
      }

      this.userNameCallback = available => {
        this.dup = !available
      }
      this.userNameAvailable = userNameAvailable(this, 1000, this.userNameCallback)
      this.userNameAvailableQuick = userNameAvailable(this, 250, this.userNameCallback)

      this.handleUserNameChange = (userName) => {
        this.lastUserName = userName
        if (!userName) {
          this.isChecking = false
          this.resolved = true
          this.update()
          return
        }

        $('input[name=userName]', this.root).data('userName', true)
        if (/^[A-Za-z0-9][-._A-Za-z0-9]{0,16}[A-Za-z0-9]$|^[A-Za-z0-9]$/.test(userName)) {
          this.isChecking = true
          this.surpressWarnings = false
          this.userNameAvailable(userName)
        }
      }

      this.verifyUsernameChanged = (userName) => {
        return this.lastUserName !== userName
      }

      this.getPasswordLength = () => {
        const schulartSetting = Session.settings[this.data.schoolTypeId]
        return schulartSetting['teacherPasswordLength']
      }

      this.getMoodleAvailability = () => {
        const schulartSetting = Session.settings[this.data.schoolTypeId]

        const moodleTarget = this.userGroup === 0 ? 'studentMoodleEnabled' : 'teacherMoodleEnabled'

        this.moodleActive = Session.settings[0][moodleTarget] === 'true'
      }

      this.on('dataRequested', () => {
        clearUserNameAvailabilityCtx(this)
      })

      this.on('rowMappingRequested', cols => {
        this.data.schoolTypeId = cols[2] || 0
        this.getMoodleAvailability();

        autoMapRowToData(this, cols)
        this.data.surpressWarnings = false
        this.data.icon = cols[0]
        this.data.errorType = cols[16]
        this.dup = this.data.errorType === 'd'
        this.resolved = !this.dup
        this.userNameAvailableQuick(this.data.userName)
      })

      this.on('validate', (form, validator) => {
        if (this.data.errorType === 'e') {
          setTimeout(() => {
            $(form).valid()
          }, 200)
        }
      })

      this.submitHandler = formData => {
        return this.api.update(formData.ids[0], R.omit(['ids', 'idTexts'], formData))
            .then(rsp => {
              const [data, status] = rsp
              if (status.success) {
                this.parent.trigger('reloadTable')
                return this.opname + ' erfolgreich durchgeführt'
              }

              console.error('error-status', status)
              console.error('error-data', data)
              if (data.message) {
                return Promise.reject(data.message)
              }
              return Promise.reject('Fehler bei ' + this.opname + '!')
            })
      }
    </script>
</paedml-modal-edit-import>
