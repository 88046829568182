import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './software/paedml-modal-info-software.tag'
import './software/paedml-modal-info-computer.tag'
import './software/paedml-modal-edit-software.tag'
import './software/paedml-modal-computer-installations.tag'
import './software/paedml-modal-software-installations.tag'
import './software/paedml-modal-software-ondemand.tag'

<paedml-card-software>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref="card">
    <virtual if="{isPermitted('rescan')}">
      <span class="btn paedml" onclick={parent.handleRescan} id="{prefix}-rescan">
        <paedml-icon icon="fas-recycle" title="Von Opsi synchronisieren" type="zoom" id="{prefix}-rescan1" />
        <paedml-icon icon="fas-cog" type="zoom" id="{prefix}-rescan2" style="display: none;"/>
      </span>
    </virtual>

    <virtual>
      <span class="btn paedml" onclick={parent.handleRefresh} id="{prefix}-refresh">
        <paedml-icon icon="aktualisieren" title="Automatisch synchronisieren" type="zoom" id="{prefix}-refresh1" />
      </span>
    </virtual>

    <virtual if="{parent.variant === 'software' && selectedExactlyOne()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Software-Info ansehen" />
      <paedml-modal-info-software prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Info" opname="OK" api={restApi} />
    </virtual>

    <virtual if="{parent.variant === 'computer' && selectedExactlyOne()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Installationsstand ansehen" />
      <paedml-modal-info-computer prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Installationsstand" opname="OK" api={restApi} />
    </virtual>

    <virtual if="{parent.variant === 'software' && isPermitted('edit') && selectedExactlyOne()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Hinweis und Aktionen bearbeiten" />
      <paedml-modal-edit-software prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Hinweis und Aktionen" opname="Änderungen speichern" api={restApi} />
    </virtual>

    <virtual if="{parent.variant === 'software' && isPermitted('assign') && selectedExactlyOne()}">
      <paedml-icon-modal icon="geraete-aio" target="#{prefix}-software-installations-modal" title="Diese Software auf Clients verwalten" />
      <paedml-modal-software-installations prefix="{prefix}-software-installations" selectedrowsfunc={getSelectedRows} edit={true}
        op="{prefix}" heading="Software auf Clients verwalten" opname="Änderungen übernehmen" api={restApi} />
    </virtual> 

    <virtual if="{parent.variant === 'computer' && isPermitted('assign') && selectedExactlyOne()}">
      <paedml-icon-modal icon="fas-gift" target="#{prefix}-computer-installations-modal" title="Auf diesem Client Softwarepakete verwalten" />
      <paedml-modal-computer-installations prefix="{prefix}-computer-installations" selectedrowsfunc={getSelectedRows} edit={true}
        op="{prefix}" heading="{config.contentTitle}: Softwarepakete verwalten" opname="Änderungen übernehmen" api={restApi} />
    </virtual> 

    <virtual if="{parent.variant ==='computer' && isPermitted('assign') && selectedOneOrMore()}">
      <paedml-icon-modal icon="ausgewaehlte-jobs-ausfuehren-g" target="#{prefix}-ondemand-modal" title="Jetzt installieren" />
      <paedml-modal-software-ondemand prefix="{prefix}-ondemand" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Jetzt installieren" opname="Jetzt installieren" idcol="0" idtextcol="1" api={restApi}/>
    </virtual> 
  </paedml-card>

  <script>
    import { usingExternalConfig } from '../util/config-util'
    import * as R from 'ramda'
    import Session from '../store/session'
    import API from '../api'

    this.session = Session
    
    this.variant = opts.config.custom.variant

    this.api = opts.config.custom.variant === 'software' ? API.software : API.software.computers

    this.handleRefresh = e => {
      $(`#${opts.config.prefix}-refresh`).addClass('fullrotation')
      this.api.getAll().then(() => {
        $(`#${opts.config.prefix}-refresh`).removeClass('fullrotation')
        this.refs['card'].trigger('reloadTable')
      })
    }

    this.handleRescan = e => {
      $(`#${opts.config.prefix}-rescan1`).hide()
      $(`#${opts.config.prefix}-rescan2`).show()
      $(`#${opts.config.prefix}-rescan`).addClass('fullrotation')
      this.api.sync().then(() => {
        $(`#${opts.config.prefix}-rescan`).removeClass('fullrotation')
        $(`#${opts.config.prefix}-rescan2`).hide()
        $(`#${opts.config.prefix}-rescan1`).show()
        this.refs['card'].trigger('reloadTable')
      })
    }

  </script>
  
</paedml-card-software>
