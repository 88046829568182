<paedml-modal-collect>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true}
                >

    <yield to="fields">
      <div class="row">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">Die von den Schüler*innen verarbeiteten Dateien werden gezippt in den Ordner '<strong class="text-primary">_eingesammelt</strong>' abgelegt</span>
        </div>
      </div>

      <paedml-form-input if="{parent.variant !== 'exam'}" name="deleteAfterwards" placeholder="Nach dem Einsammeln die Daten bei den Schüler*innen löschen und den Eintrag aus der Schulkonsole entfernen" fgclass="py-1"
        type="checkbox" revlut="jaNein"
      />
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'
    
    this.prefix = opts.prefix
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient
    this.variant = opts.variant
    
    this.on('dataRequested', () => {
    })

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      if (this.variant === 'exam') {
        return API.exam.collect(formData.ids[0]).then(rsp => {
        const [data, status] = rsp
          if (status.success) {
            return `Dateien erfolgreich eingesammelt`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Einsammeln fehlgeschlagen. Grund: ${reason}`)
      })
      }

      return API.handout.collect(formData.ids[0], formData.deleteAfterwards).then(rsp => {
        const [data, status] = rsp
          if (status.success) {
            if (formData.deleteAfterwards) {
              this.parent.trigger('reloadTable')
              return `Dateien erfolgreich eingesammelt und gelöscht`
            }
            return `Dateien erfolgreich eingesammelt`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Einsammeln fehlgeschlagen. Grund: ${reason}`)
      })

    }

  </script>
</paedml-modal-collect>
