import './paedml-form-input.tag'

<paedml-form-input-pw>

    <div class="row">
        <div class="col-sm-6">
            <paedml-form-input name="password" ref="pwMain"
                label="{getLabel('Kennwort')}" 
                placeholder="{getPlaceholder(opts.placeholder)}"
                type="password" inputid="{prefix}-form-password"
                required={settings.required} data-msg="Bitte geben Sie ein Kennwort ein" 
                data-rule-maxlength="64" data-msg-maxlength="Das Kennwort darf nicht mehr als 64 Zeichen enthalten!" 
                data-rule-minlength="{getMinPwLength()}" data-msg-minlength="Das Kennwort muss mindestens {getMinPwLength()} Zeichen haben!" 
            />
        </div>
        <div class="col-sm-6">
            <paedml-form-input type="password" 
                label="{getLabel('Kennwortbestätigung')}"
                placeholder="{getPlaceholder('Kennwort bestätigen')}"
                required={settings.required} data-msg="Bitte bestätigen Sie das Kennwort" 
                data-rule-equalto="#{prefix}-form-password" data-msg-equalto="Die Kennwörter stimmen nicht überein!" 
            />
        </div>
    </div>
    <paedml-form-input type="select" ref="pwopt" if={!opts.disablepasswordpolicy}
                    name="passwordPolicy"
                    label="{getLabel('Kennwortoption')}"
                    placeholder="{getPlaceholder('Kennwortoption')}"
                    disabled="{opts.passwordpolicy}"
                    required
                    values={values}
    />


    <script>
        const shortid = require('shortid')
        this.prefix = 'pw' + shortid.generate()
        this.uselabel = this.opts.uselabel
        this.getLabel = label => this.uselabel ? label : undefined
        this.getPlaceholder = (ph) => ph ? ph : this.uselabel ? undefined : 'Kennwort'
        this.values = {
            1: 'Kennwort läuft nie ab',
            2: 'Kennwortänderung erzwingen',
            3: 'Kennwort nicht änderbar'
        }

        this.hasBooleanAttr = attrName => () => this.opts[attrName] !== undefined && this.opts[attrName] !== false
        this.isRequired = this.hasBooleanAttr('required')

        this.settings = {
            required: this.isRequired(),
            minPwLength: Session.settings[0].adminPasswordLength
        }

        this.getMinPwLength = () => this.settings.minPwLength

        this.on('refresh', (opt) => {
            this.settings.required = opt.required || false
            this.settings.minPwLength = opt.minPwLength || Session.settings[0].adminPasswordLength
            this.refs.pwMain.trigger("refresh", {
                'ruleMinlength': opt.minPwLength,
                'msgMinlength' : `Das Kennwort muss mindestens ${opt.minPwLength} Zeichen haben!`
            })
        })
        
    </script>

</paedml-form-input-pw>
