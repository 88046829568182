import * as R from 'ramda'

export const toIdentPair = value => [value, value]

export const toIdentPairs = R.map(toIdentPair)

export const setterUsing = (obj, defaultValue = {}) => (key, value) => {
    if (obj[key] === undefined) {
        obj[key] = defaultValue
    }
    obj[key] = value
}

export const isEmpty = R.either(R.isNil, R.isEmpty)

export const isFunction = param => R.type(param) === 'Function'
export const getValue = valueOrFunction => isFunction(valueOrFunction) ? valueOrFunction() : valueOrFunction