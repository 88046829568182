<paedml-modal-hinweis-schulart>

  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="text-center">
        <p>Leider gibt es noch keine Schulart, für die Sie den Importvorgang einleten können.
        Bitte legen Sie zuerst eine Schulart an</p>
        <p></p>
        <p><a href="#benutzerverwaltung/schularten">Zur Schulartverwaltung</a></p>
      </div>

    </yield>
  </paedml-modal>

  <script>
    this.data = {}
      this.submitHandler = formData => {
        return Promise.resolve('OK')
      }
  </script>
</paedml-modal-hinweis-schulart>
