<paedml-modal-run-sjw>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 0}"
                submitfunc={submitHandler} patient={patient} edit={edit}
                cancelbutton={true}
                okcondition={checkSchoolYearChange}
                >

    <yield to="fields">
      <div>
        <p>Möchten Sie wirklich den Schuljahreswechsel ({parent.data.currentSchoolYear} ⇨ {parent.data.nextSchoolYear}) durchführen?</p>
        <p></p>
        <p>Dieser Vorgang ist <b class="text-danger">unwiderruflich</b>!</p>
      </div>
      <div class="mt-2 mr-1 p-2 border border-info">
        <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">Hierdurch wird auch der Versetzungsmodus für diese Schulart ({parent.data.name}) deaktiviert.</strong>
          </span>
        </div>
      <div class="p-2"></div>
      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
        <p class="my-auto">Schularteinstellungen werden aktualisiert...</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </div>
      <div class="text-danger" if={parent.notAllMigrated && !parent.loading}>
        Sie haben noch nicht alle Schüler*innen in das neue Schuljahr versetzt.
        <paedml-form-input name="schoolyearChangeOK" placeholder="Ich möchte den Schuljahreswechsel trotzdem durchführen"
          type="checkbox" revlut="jaNein" handlechange={parent.updateSchoolYearChangeOk}
          />
      </div>
    </yield>
  </paedml-modal> 

  <script>
    import * as R from 'ramda'

    this.prefix = opts.prefix
    this.data = { }
    this.edit = opts.edit
    this.api = this.opts.api
    this.notAllMigrated = true
    this.schoolYearChangeOk = false
    this.loading = true

    this.on('rowMappingRequested', (cols, modal) => {
      this.data.id = cols[0]
      this.data.currentSchoolYear = cols[4]
      this.data.nextSchoolYear = cols[3]
      this.data.name = cols[1]
      
      this.loading = true
      this.api.validateYearChange(this.data.id).then(rsp => {
        this.loading = false
        const [data, status] = rsp
        this.notAllMigrated = data.currentSchoolYearStudentCount !== 0
        this.schoolYearChangeOk = data.currentSchoolYearStudentCount === 0
        modal.trigger('refreshData')
      })
    })

    this.updateSchoolYearChangeOk = (val) => {
      this.schoolYearChangeOk = val
    }

     this.checkSchoolYearChange = () => {
      return this.schoolYearChangeOk
    } 

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      return this.api.yearChange(formData.ids[0]).then(rsp => {
        const [data, status] = rsp
        if (status.success) {
          this.parent.trigger('reloadTable')
          return 'Schuljahreswechsel erfolgreich eingeleitet'
        }

        console.error('error-status', status)
        console.error('error-data', data)
        if (data.message) {
          return Promise.reject(data.message)
        }
        return Promise.reject('Fehler beim Schuljahreswechsel!')
      })
    }

  </script>
</paedml-modal-run-sjw>
