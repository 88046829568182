import Masterdata from '../config/masterdata'
import Session from '../store/session'
import * as R from 'ramda'
import moment from 'moment'
moment.locale('de')

export const custom = {}

const schulartWithSettings = schulart => R.mergeLeft(R.dissoc('settings', schulart), R.prop('settings', schulart))

custom.schulart_list_preHook = client => (rsp) => {
    const [schularten, status, ctx] = rsp
    return [R.map(schulartWithSettings, schularten), status, ctx]
}

custom.klassen_list_preHook = client => (rsp) => {
    const [klassen, status, ctx] = rsp
    const settings = Session.settings
    klassen.forEach(v => {
        v.currentSchoolYear = settings[v.schoolTypeId].currentSchoolYear
        v.klassenbezeichnung = v.currentSchoolYear + '_' + v.schoolType + '_' + v.name
        if (v.sharedFolderPath == null) v.sharedFolderPath = ''
        v.sharedFolderAccessLevel = Masterdata.shareFolderAccessPolicyMap[v.sharedFolderAccessLevel]
    })
    return [klassen, status, ctx]
}

custom.schueler_list_preHook = client => (rsp) => {
    const [schueler, status, ctx] = rsp
    schueler.forEach(v => {
        v.klassenbezeichnung = v.schoolYear + '_' + v.schoolType + '_' + v.schoolClass
        v.klassenbezeichnung2 = v.schoolType + ' / ' + v.schoolClass
    })
    return [R.map(schulartWithSettings, schueler), status, ctx]
}

custom.handout_list_preHook = client => (rsp) => {
    const [files, status, ctx] = rsp
    files.forEach(v => {
        v.initiatorDP = v.initiator ? `${v.initiator.givenName} ${v.initiator.surname}` : 'UNBEKANNT'
        v.usersP = R.join('\r', R.map(e=> e ? `${e.givenName} ${e.surname}` : 'UNBEKANNT', v.users))
        v.usersDP = R.join('\r', R.map(e=> e ? `${e.givenName} ${e.surname}` : 'UNBEKANNT', R.take(3, v.users)))
        if (v.users.length > 3) v.usersDP += ' +' + (v.users.length - 3)
        
        v.createdUF = formatDateTimeWithYear(v.created)
    })
    return [files, status, ctx]
}

custom.exam_list_preHook = client => (rsp) => {
    const [exams, status, ctx] = rsp

    exams.forEach(v => {
        v.titleDP = `${v.title} - ${v.description}`
      
        v.leadersP = R.join('\r', R.map(e=> `${e.givenName} ${e.surname}`, v.leaders))
        v.leadersDP = R.join(', ', R.map(e=> `${e.givenName} ${e.surname}`, R.take(3, v.leaders)))
        if (v.leaders.length > 3) v.leadersDP += ' +' + (v.leaders.length - 3)
       
       v.membersP = R.join('\r', R.map(e=> `${e.givenName} ${e.surname} (${e.examUsername ? e.examUsername : 'in Erstellung'})`, v.members))
       v.membersDP = R.join('\r', R.map(e=> `${e.givenName} ${e.surname} (${e.examUsername ? e.examUsername : 'in Erstellung'})`, R.take(3, v.members)))
       if (v.members.length > 3) v.membersDP += ' +' + (v.members.length - 3)

        v.whitelistDP = R.join('\r', v.whitelist)
        v.createdUF = formatDateTimeWithYear(v.created)
    })
    return [exams, status, ctx]
}

custom.examlogs_list_preHook = client => (rsp) => {
    const [logs, status, ctx] = rsp

    logs.forEach(v => {
        v.examDP = `${v.exam.name}`
        const member = v.member
        v.userDP = `${member.givenName} ${member.surname} (${member.userName})`
        v.kaUserDP = member.examUsername ? member.examUsername : ''
        v.actionDP = Masterdata.examAction[v.action]
        v.computerDP = v.computer ? v.computer : ''
        v.roomDP = v.room ? v.room : ''
        v.createdUF = formatDateTimeWithYear(v.created)
    })
    return [logs, status, ctx]
}

custom.projects_list_preHook = client => (rsp) => {
    const [projects, status, ctx] = rsp
    projects.forEach(v => {
        v.leadersP = R.join('\r', R.map(e=> `${e.givenName} ${e.surname}`, v.leaders))
        v.leadersDP = R.join(', ', R.map(e=> `${e.givenName} ${e.surname}`, R.take(3, v.leaders)))
        if (v.leaders.length > 3) v.leadersDP += ' +' + (v.leaders.length - 3)
       
        v.membersP = R.join('\r', R.map(e=> `${e.givenName} ${e.surname}`, v.members))
        v.membersDP = R.join('\r', R.map(e=> `${e.givenName} ${e.surname}`, R.take(3, v.members)))
        if (v.members.length > 3) v.membersDP += ' +' + (v.members.length - 3)

        if (v.sharedFolderPath == null) v.sharedFolderPath = ''
        v.sharedFolderAccessLevel = Masterdata.projectShareFolderAccessPolicyMap[v.sharedFolderAccessLevel]
        v.numMembers = v.members.length
        v.expiryDateDP = v.expiryDate ? formatDateDisplay(v.expiryDate) : 'nie'
        v.expiryDateDP1 = v.expiryDate ? formatDate(v.expiryDate) : ''
        v.expiryDateActive = v.expiryDate ? true : false
    })
    return [projects, status, ctx]
}

custom.jobs_list_preHook = client => (rsp) => {
    const [jobs, status, ctx] = rsp
    jobs.forEach(v => {
        v.createdUF = formatDateTime(v.created)
        v.jobType = Masterdata.jobType[v.jobType]
        v.jobTarget = Masterdata.jobTarget[v.jobTarget]
    })
    return [jobs, status, ctx]
}

custom.tasks_list_preHook = client => (rsp) => {
    const [jobs, status, ctx] = rsp
    jobs.forEach(v => {
        v.lastRunTimeUF = v.lastRunTime ? formatDateTime(v.lastRunTime) : ''
        v.status = Masterdata.taskStatus[v.status]
        v.jobDescription = 'Job #' + v.jobId
    })
    return [jobs, status, ctx]
}

custom.imports_list_preHook = client => (rsp) => {
    const [data, status, ctx] = rsp

    data.forEach(v => {

        const isDuplikat = R.any(e => e == 1, v.warnings)
        const isImportError = R.any(e => e == 4, v.errors)
        const isTmpError = R.any(e => e == 6, v.errors)

        v.errorType = v.errors.length ? (isImportError ? 'i' : isTmpError ? 'r' : 'e') 
        : v.warnings.length ? (isDuplikat ? 'd' : 'b') : '',
        v.vermerk = v.errors.length ? (isImportError ? 'Importfehler' : isTmpError ? 'Temporärer Fehler' : 'Datensatz fehlerhaft') 
            : v.warnings.length ? (isDuplikat ? 'Duplikat' : 'Benutzername') : '',
        v.icon = v.errors.length ? (isImportError ? 'fas-bug' : isTmpError ? 'fas-redo-alt' : 'fas-bolt') 
            : v.warnings.length ? (isDuplikat ? 'fas-exclamation-triangle' : 'far-edit') : '',
        v.delId = v.id + '_' + v.userName
    })
    return [data, status, ctx]
}

custom.versetzung_init_list_preHook = client => (rsp) => {
    const [data, status, ctx] = rsp
    const filtered = data.filter(v => v.relocationStatus === 0)
    return [filtered, status, ctx]
}

custom.versetzung_edit_list_preHook = client => (rsp) => {
    const [data, status, ctx] = rsp
    const filtered = data.filter(v => v.relocationStatus === 1)
    filtered.forEach(v => {
        v.versetzungVon = `${v.schoolYear}_${v.schoolType}_${v.schoolClass}`
        v.versetzungNach = `${v.targetSchoolYear}_${v.targetSchoolType}_${v.targetSchoolClass}`
    })
    return [filtered, status, ctx]
}

custom.versetzung_progress_list_preHook = client => (rsp) => {
    const [data, status, ctx] = rsp
    const filtered = data.filter(v => v.relocationStatus > 1)
    filtered.forEach(v => {
        v.versetzungVon = `${v.schoolYear}_${v.schoolType}_${v.schoolClass}`
        v.versetzungNach = `${v.targetSchoolYear}_${v.targetSchoolType}_${v.targetSchoolClass}`
        switch (v.relocationStatus) {
            case 3:
                v.status = 'in Verarbeitung'
                break;
            case 5:
            case 6:
            case 7:
                v.status = 'Verarbeitungsfehler'
                break;
        
            default:
                v.status = ''
        }
    })

    return [filtered, status, ctx]
}

custom.raum_list_preHook = client => rsp => {
    const [data, status, ctx] = rsp
    data.forEach(v => {
        v.computersDP = v.computers.length
        v.printersDP = v.printers.length
        v.defaultPrinterDP = v.defaultPrinter ? v.defaultPrinter.name : ''
        v.schoolTypes = R.join(', ', v.schoolTypes.map(e => e.name))
        if (v.isBsaEnabled) {
            v.bsaSchoolDP = v.bsaSchool.name
            v.bsaSchool = v.bsaSchool.id
            v.bsaSchoolClassDP = v.bsaSchoolClass.name
            v.bsaSchoolClass = v.bsaSchoolClass.id
        }
    })
    return [data, status, ctx]
}

custom.devices_list_preHook = client => rsp => {
    const [data, status, ctx] = rsp
    data.forEach(v => {
        v.roomDP = v.room.name
    })
    return [data, status, ctx]
}

custom.serviceAccount_list_preHook = client => rsp => {
    let [data, status, ctx] = rsp
   
    if(Session.feSettings.extensions.enableLaps === true)
    {
        // Remove local admin account
        data = R.filter(({id})=> id != 13, data);
    }
    return [data, status, ctx]
}

custom.bsa_list_preHook = client => rsp => {
    const [data, status, ctx] = rsp

    data.forEach(v => {
        v.schoolTypeDP = v.schoolType.name
        v.schoolType = v.schoolType.id
        v.schoolClassDP = v.schoolClass.name
        v.schoolClass = v.schoolClass.id
        if (v.isDuplicate) {
            v.icon = 'fas-exclamation-triangle'
            v.vermerk = 'Duplikat'
        }
    })
    return [data, status]
}

custom.fw_settings_list_preHook = client => (rsp) => {
    const [settings, status, ctx] = rsp
    
    const lut = Session.map.firewallCategories

    const v = settings
    v.firewallCategoriesDP = R.join('\r', R.map(e => lut[e], v.firewallCategories))
    v.blacklistDP = R.join('\r', v.blacklist)
    v.whitelistDP = R.join('\r', v.whitelist)
    
    status.code = 200
    status.success = true
    status.error = false

    return [[settings], status, ctx]
}

custom.fw_templates_list_preHook = client => (rsp) => {
        const [settings, status, ctx] = rsp
        
        const lut = Session.map.firewallCategories
    
        const v = settings
        
        settings.forEach(v => {
            v.schoolTypeDP = `${v.schoolType.name}`
            v.firewallCategoriesDP = R.join('\r', R.sort(R.comparator((a, b) => a < b))(R.map(e => lut[e], v.firewallCategories)))
            v.blacklistDP = R.join('\r', v.blacklist)
            v.whitelistDP = R.join('\r', v.whitelist)
        })
    
        return [settings, status, ctx]
}

custom.fw_global_list_preHook = client => (rsp) => {
    const [data, status, ctx] = rsp
    
    const settings = [data]
    const v = settings
        
    settings.forEach(v => {
        v.id = -1
        v.blacklistDP = R.join('\r', v.blacklist)
        v.whitelistDP = R.join('\r', v.whitelist)
    })
    
    status.code = 200
    status.success = true
    status.error = false

    return [settings, status, ctx]
}

custom.fw_klassen_list_preHook = client => (rsp) => {
    const [settings, status, ctx] = rsp
    
    const lut = Session.map.firewallCategories

    const v = settings
    
    settings.forEach(v => {
        v.schoolTypeDP = `${v.schoolType.name}`
        v.schoolClassDP = `${v.schoolClass.name}`
        v.firewallCategoriesDP = R.join('\r', R.sort(R.comparator((a, b) => a < b))(R.map(e=> lut[e], v.firewallCategories)))
        v.blacklistDP = R.join('\r', v.blacklist)
        v.whitelistDP = R.join('\r', v.whitelist)
    })

    return [settings, status, ctx]
}

custom.fw_project_list_preHook = client => (rsp) => {
    const [settings, status, ctx] = rsp
    
    const v = settings
    
    settings.forEach(v => {
        v.projectDP = `${v.project.name}`
        v.whitelistDP = R.join('\r', v.whitelist)
    })

    return [settings, status, ctx]
}

const mergeQuota = (subject) => client => quotaRsp => {
    const [quota, statusQuota] = quotaRsp

        const defaultSettings = {
            inUseDP: "UNBEKANNT",
            status: "UNBEKANNT",
        }

        return client.get(`/api/${subject}`).then(subjectRsp => {
            const [subjects, status2] = subjectRsp

            const lut = quota.reduce((acc, v) => {
                acc[v.id] = v
                return acc
            }, {})

            const merged = subjects.reduce((acc, v) => {
                const user = lut[v.id]
                if (user) {
                    // explicit quota information
                    const percentile = user.quotaLimit > 0 ? Math.round(user.quotaUsed * 100 / user.quotaLimit) + ' %' : ' - '
                    const inUseDP = `${user.quotaUsedText} (${percentile})`
                    const status = getQuotaStatus(user)
            
                    acc.push({...v, ...user, inUseDP, status})
                } else {
                    acc.push({...v, displayName: `${v.givenName} ${v.surname}`, ...defaultSettings})
                }
                return acc
            }, [])
            return [merged, status2]
        })
}

const getQuotaStatus = (user) => {
    if(user.quotaLimit == -1) 
        return "Unbegrenzt" 
    if(user.quotaUsed > user.quotaLimit)
        return "Limit überschritten" 
    if(user.quotaUsed > user.quotaThreshold) 
        return "Warngrenze überschritten" 
    if(user.quotaLimit  == 0 && user.quotaUsed == 0 && user.quotaThreshold == 0)
        return "UNBEKANNT" 

    return "OK"
}

custom.quotaTeachers_list_preHook = mergeQuota('teachers')

custom.quotaStudents_list_preHook = mergeQuota('students')

custom.cloud_schueler_list_preHook = client => (rsp) => {
    const [schueler, status, ctx] = rsp
    schueler.forEach(v => {
        v.displayName = `${v.givenName} ${v.surname}`
        v.klassenbezeichnung = v.schoolYear + '_' + v.mainSchoolType + '_' + v.schoolClass
        v.klassenbezeichnung2 = v.mainSchoolType + ' / ' + v.schoolClass
    })
    return [R.map(schulartWithSettings, schueler), status, ctx]
}

custom.singlelogin_list_preHook = client => (rsp) => {
    const [user, status, ctx] = rsp
    user.forEach(s => {
        s.displayName = `${s.givenName} ${s.surname}`
    })
    return [user, status, ctx]
}

custom.moodle_schueler_list_preHook = client => (rsp) => {
    const [schueler, status, ctx] = rsp
    schueler.forEach(v => {
        v.displayName = `${v.givenName} ${v.surname}`
        v.klassenbezeichnung = v.schoolYear + '_' + v.mainSchoolType + '_' + v.schoolClass
        v.klassenbezeichnung2 = v.mainSchoolType + ' / ' + v.schoolClass
        if (!v.email) {
            if (!v.domain) {
                v.domain = 'nomail.belwue.de'
            }
            v.email = `${v.userName}@${v.domain}`
        }
    })
    return [R.map(schulartWithSettings, schueler), status, ctx]
}

custom.moodle_lehrer_list_preHook = client => (rsp) => {
    const [lehrer, status, ctx] = rsp
    lehrer.forEach(v => {
        if (!v.email) {
            if (!v.domain) {
                v.domain = 'nomail.belwue.de'
            }
            v.email = `${v.userName}@${v.domain}`
        }
    })
    return [lehrer, status, ctx]
}

custom.profile_list_preHook = client => (rsp) => {
    const [data, status, ctx] = rsp
    data.forEach(v => {
        v.dateEditedDP = v.dateEdited !== '0001-01-01T00:00:00' ? formatDateTimeRelative(v.dateEdited) : 'nie',
        v.dateActivatedDP = v.dateActivated ? formatDateTimeRelative(v.dateActivated) : 'nie',
        v.schoolTypeName = v.schoolTypeName ? v.schoolTypeName : '«Übergreifend»',
        v.platformName = v.platformName.replace('‎ ', ' ')
        v.overview = `${v.name}`
    })
    return [data, status]
}

custom.software_list_preHook = client => (rsp) => {
    const [data, status, ctx] = rsp
    data.forEach(v => {
        v.schoolTypesDP = R.join(', ', v.schoolTypes)
        v.version = `${v.productVersion}-${v.packageVersion}`
        v.defaultInstallActionDP = Masterdata.opsiActions[v.defaultInstallAction]
        v.defaultUninstallActionDP = Masterdata.opsiActions[v.defaultUninstallAction]
        v.defaultUpdateActionDP = Masterdata.opsiActions[v.defaultUpdateAction]
    })
    return [data, status]
}

const formatDateTimeRelative = dt => moment(dt+'Z').fromNow()
const formatDateTime = dt => moment(dt+'Z').format('DD.MMM LTS')
const formatDateTimeWithYear = dt => moment(dt+'Z').format('DD.MMM YYYY LTS')
const formatDateDisplay = dt => moment(dt+'Z').format('DD.MMMYYYY')
const formatDate = dt => moment(dt+'Z').format('DD-MM-YYYY')