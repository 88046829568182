import './paedml-modal.tag'
import './paedml-form-input.tag'

<paedml-modal-info-admin-password>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc}
    idcol={opts.idcol} idtextcol={opts.idtextcol} submitfunc={submitHandler}>
   
    <yield to="fields">

      <div class="row">
        <div class="col-sm-6">
          <paedml-form-input name="name" col="1" label="Computer" readonly />
        </div>
        <div class="col-sm-6">
          <paedml-form-input name="roomDP" col="2" label="Raum" readonly />
        </div>
      </div>

      <div class="row" if={!parent.loading}>
        <div class="col-sm-12">
          <paedml-form-input name="account" label="Konto" readonly />
        </div>
      </div>

      <div class="row" if={!parent.loading}>
        <div class="col-sm-12">
          <paedml-form-input name="adminPassword" label="Kennwort" additionalclass="paedml-password" readonly />
        </div>
      </div>

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
          <p class="my-auto">Das Kennwort wird aus dem AD gelesen...</p>
          <div class="sk-wave">
            <div class="sk-rect sk-rect1"></div>
            <div class="sk-rect sk-rect2"></div>
            <div class="sk-rect sk-rect3"></div>
            <div class="sk-rect sk-rect4"></div>
            <div class="sk-rect sk-rect5"></div>
          </div>
      </div>

    </yield>
  </paedml-modal>
    
  <script>
    import { autoMapRowToData } from '../util/modal-util'
    import * as mapper from '../mapper'
    import API from '../api'
      
    this.opname = this.opts.opname
    this.edit = false
    this.data = {}
    this.api = this.opts.api
    this.loading = true

    this.on('rowMappingRequested', cols => {
      this.data.computerId = cols[0]
      autoMapRowToData(this, cols)
      this.getComputerPassword()
    })

    this.on('hide', () => {
      this.loading = false
      this.data = {}
    })

    this.submitHandler = formData => {
      return Promise.resolve('OK')
    }

    this.getComputerPassword = () => {
      this.loading = true
      API.computer.password(this.data.computerId).then(rsp => {
        const [computer, status] = rsp
        this.loading = false
        this.update()
        this.data.password = computer.password
        $('input[name=adminPassword]').val(this.data.password)
        this.data.account = computer.account
        $('input[name=account]').val(this.data.account)
      })
    }
    </script>
</paedml-modal-info-admin-password>
