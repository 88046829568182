export const moodleverwaltung = {
  headerTitle: 'Moodle',
  route: 'moodle',
  pageTitle: 'paedML - Moodle',
  related: [
    { route: 'benutzerverwaltung/lehrerinnen', menuTitle: 'Lehrer*innen verwalten' },
    { route: 'benutzerverwaltung/schuelerinnen', menuTitle: 'Schüler*innen verwalten' },
    { route: 'cloud', menuTitle: 'Microsoft 365' },
  ],
  cards: [
    {
      route: 'domains',
      icon: 'moodle',
      endpoint: '/api/moodle/domains',
      endpoints: {
      },
      preHooks: {
      },
      tag: 'paedml-card-domain',
      category: 'Moodle',
      menuTitle: 'Moodle-Emaildomäne',
      pageTitle: 'paedML - Domäne',
      name: 'moodledomain',
      contentTitle: 'Moodle-Emaildomäne',
      prefix: 'moodledomain',
      suffix: undefined,
      custom: {
        variant: 'moodledomain'
      },
      table: {
        columns: [
          { title: 'Moodle-Emaildomäne', name: 'domain', col: 0 },
          { title: 'Aufgelöst', name: 'resolved', col: 1 }
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },

    {
      route: 'lehrerinnen',
      icon: 'moodle',
      endpoint: '/api/moodle/teachers',
      endpoints: {
      },
      preHooks: {
        list: 'moodle_lehrer_list_preHook'
      },
      tag: 'paedml-card-moodle',
      category: 'Moodle',
      menuTitle: 'Lehrer*innen',
      pageTitle: 'paedML - Moodle-Lehrer*innen',
      name: 'moodlelul',
      contentTitle: 'Lehrer*innen',
      prefix: 'moodlelul',
      suffix: undefined,
      custom: {
        variant: 'teachers',
        emailCol: 6
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Name', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Basis-Schulart', name: 'mainSchoolType', col: 4 },
          { title: 'Schulart(en)', name: 'schoolTypes', separator: ', ', col: 5, filter: 'Schulart', filterType: 'regex' },
          { title: 'Moodle E-Mail', name: 'email', col: 6 },
          { title: 'Moodle aktiv', name: 'isEnabled', col: 7, filter: 'JaNein', filtertitle:"Moodle aktiv" },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'schuelerinnen',
      icon: 'moodle',
      endpoint: '/api/moodle/students',
      endpoints: {
      },
      preHooks: {
        list: 'moodle_schueler_list_preHook'
      },
      tag: 'paedml-card-moodle',
      category: 'Moodle',
      menuTitle: 'Schüler*innen',
      pageTitle: 'paedML - Moodle-Schüler*innen',
      name: 'moodlesus',
      contentTitle: 'Schüler*innen',
      prefix: 'moodlesus',
      suffix: undefined,
      custom: {
        variant: 'students',
        emailCol: 7
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Name', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart', name: 'mainSchoolType', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 5, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 6, searchable: false },
          { title: 'Moodle E-Mail', name: 'email', col: 7 },
          { title: 'Moodle aktiv', name: 'isEnabled', col: 8, filter: 'JaNein', filtertitle:"Moodle aktiv" },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

  ]
}
