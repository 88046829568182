import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-edit-bsa.tag'
import './paedml-modal-freigabe-bsa.tag'

<paedml-card-bsa>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{active && selectedNone() && parent.startTimer()}">
        <span class="btn paedml {parent.session.timers.bsa ? 're_sync' : ''}" onclick={parent.handleIntervalRefresh}>
          <paedml-icon icon="aktualisieren" title="Synchronisieren" type="zoom" />
        </span>
      </virtual>

    <virtual if="{selectedOneOrMore() && parent.clearTimer()}"></virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="BSA-Anfrage(n) bearbeiten" />
      <paedml-modal-edit-bsa prefix="{prefix}-edit" edit={true} usersuffix="{prefix}" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: BSA-Anfrage(n) bearbeiten" opname="Änderung speichern" api={restApi}
        idcol="1" idtextcol="2"
      />
    </virtual>

    <virtual if="{selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-check-double" target="#{prefix}-freigabe-modal" title="BSA-Anfrage(n) freigeben" />
      <paedml-modal-freigabe-bsa prefix="{prefix}-freigabe" selectedrowsfunc={getSelectedRows} edit={true}
        heading="{config.contentTitle}: BSA-Anfrage(n) freigeben" opname="Freigeben" api={restApi}
        patient="{{singular: 'BSA-Anfrage', plural: 'BSA-Anfragen'}}"
        idcol="1" idtextcol="2"
      />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="BSA-Anfrage(n) löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: BSA-Anfrage(n) löschen" prefix="{prefix}-delete" 
        patient="{{singular: 'BSA-Anfrage', plural: 'BSA-Anfragen'}}" selectedrowsfunc={getSelectedRows} api={restApi}
        idcol="1" idtextcol="2"
      />
    </virtual>
  
  </paedml-card>

  <script>
    import API from '../api'
    import * as R from 'ramda'
    import {usingExternalConfig} from '../util/config-util'
    import {createTimerTask, createTimerCanceler, createRefresher} from '../util/api-util'
    import Session from '../store/session'
    this.session = Session

    this.prefix = opts.prefix
    this.variant = opts.config.custom.variant

    this.isTeacher = () => Session.user.roleAbbrev === 'L'

    this.handleRefresh = e => {
      this.refs['card'].trigger('reloadTable')
    }

    this.handleIntervalRefresh = createRefresher(this, 'bsa', usingExternalConfig("raumverwaltung","refreshInterval", 30000))
    this.startTimer = createTimerTask('bsa', this.handleIntervalRefresh)
    this.clearTimer = createTimerCanceler('bsa')

  </script>
</paedml-card-bsa>
