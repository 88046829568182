import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-createoredit-raum>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="name" col="1" label="Name"
              required data-msg="Bitte geben Sie den Raumnamen ein"
              readonly={edit}
              data-rule-sessionmap="rooms" data-msg-sessionmap="Bereits vorhanden!"
              maxlength="31" data-rule-maxlength="30" data-msg-maxlength="Der Name darf nicht länger als 30 Zeichen sein"
              data-rule-project data-msg-project='Erlaubt sind: Buchstaben, Ziffern, Umlaute. Optional kann &quot;_&quot; verwendet werden, jedoch nicht am Anfang und Ende.'
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group" if={parent.isAssignable()}>
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input
              type="select" multiple
              name="schoolTypes" col="2" revlut="schularten" label="Schulart(en)" placeholder="Schulart(en)"
              required data-msg="Bitte wählen Sie mindestens eine Schulart aus!" 
              addremoveall={parent.hasMultipleSchularten()}
              values={parent.session.map.schularten}
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group" if={parent.isDefaultInternetLockEnabled() || parent.isDefaultComputerLockEnabled()}>
        <div class="row">
          <div class="col-sm-6" if={parent.isDefaultInternetLockEnabled()}>
            <paedml-form-input name="defaultInternetLock" col="3" placeholder="Default-Internetsperre" fgclass='py-2'
              type="checkbox" revlut="jaNein"
            />
            </div>
          <div class="col-sm-6" if={parent.isDefaultComputerLockEnabled()}>
            <paedml-form-input name="defaultComputerLock" col="4" placeholder="Default-Computersperre" fgclass='py-2'
              type="checkbox" revlut="jaNein"
            />
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client, handleSubmitCreateOrEdit } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import {isEnabled} from '../util/config-util'
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.session = Session
      this.api = this.opts.api

      const convertingBooleans = R.mapObjIndexed(v => {
          if (v === 'true' || v === 'ja') return true
          if (v === 'false' || v === 'nein') return false
          return v
      })

      this.isDefaultInternetLockEnabled = () => isEnabled('raumverwaltung', 'enableDefaultInternetLock')
      this.isDefaultComputerLockEnabled = () => isEnabled('raumverwaltung', 'enableDefaultComputerLock')

      this.isAssignable = () => this.data.name !== 'NICHT_ZUGEWIESEN'

      this.hasMultipleSchularten = () => R.values(Session.map.schularten).length > 1

      this.roomValues = () => R.join(',', R.values(Session.map.rooms))

      this.on('dataRequested', () => {
        this.data.defaultInternetLock = false
        if (!this.edit) {
          this.data.schoolTypes = R.keys(Session.map.schularten)
        }
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
      })

      this.submitHandler = formData => {
        if (!formData.computers) {
          formData.computers = [ ]
        }
        if (!formData.printers) {
          formData.printers = [ ]
        }
        if (!formData.defaultInternetLock) {
          formData.defaultInternetLock = this.data.defaultInternetLock
        }
        if (!formData.defaultComputerLock) {
          formData.defaultComputerLock = this.data.defaultComputerLock
        }
        return handleSubmitCreateOrEdit(this, data => `Raum "${data.name}"`, data => `Raum "${data.name}"`, true)(formData)
      }
    </script>
</paedml-modal-createoredit-raum>
