export const benutzerverwaltung = {
  headerTitle: 'Benutzerverwaltung',
  route: 'benutzerverwaltung',
  pageTitle: 'paedML - Benutzerverwaltung',
  related: [
    { route: 'jobverwaltung/jobs', menuTitle: 'Jobs ansehen' },
    { route: 'rechteverwaltung/schulart-administratoren', menuTitle: 'Schulart-Admins anlegen' },
    { route: 'imports', menuTitle: 'Importverwaltung' },
    { route: 'versetzung', menuTitle: 'Schüler*innen versetzen / verschieben' },
    { route: 'quotaverwaltung/lehrerinnen', menuTitle: 'Kontingentverwaltung - Lehrer*innen' },
    { route: 'quotaverwaltung/schuelerinnen', menuTitle: 'Kontingentverwaltung - Schüler*innen' },
    { route: 'firewallverwaltung/templates', menuTitle: 'Firewall-Vorlagen verwalten' },
    { route: 'firewallverwaltung/klassen', menuTitle: 'Klassen-Firewall verwalten' },
  ],
  cards: [
    {
      route: 'schularten',
      icon: 'fas-hotel',
      endpoint: '/api/school/schoolTypes',
      endpoints: {
      },
      preHooks: {
        list: 'schulart_list_preHook'
      },
      tag: 'paedml-card-schularten',
      category: 'Benutzerverwaltung',
      menuTitle: 'Schularten verwalten',
      pageTitle: 'paedML - Benutzerverwaltung: Schularten',
      name: 'schulart',
      contentTitle: 'Schularten',
      prefix: 'schulart',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Kürzel', name: 'name', col: 1 },
          { title: 'Name', name: 'description', col: 2 },

          { title: 'neues Schuljahr', name: 'nextSchoolYear', col: 3, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'aktuelles Schuljahr', name: 'currentSchoolYear', col: 4, visible: true, searchable: false, printable: false, exportable: true},
          { title: 'Firewalltyp', fn: () => '', col: 5, visible: false, searchable: false, printable: false, exportable: false},
          
          { title: 'Schema Lehrer', name: 'teacherUsernamePolicy', col: 6, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Lehrer Kennwort', name: 'teacherDefaultPassword', col: 7, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Kennwortlänge Lehrer', name: 'teacherPasswordLength', col: 8, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Lehrer Kennwortoption', name: 'teacherDefaultPasswordPolicy', col: 9, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Lehrer-Email erstellen', name: 'teacherEmailEnabled', col: 10, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Lehrer-Email-Domäne', name: 'teacherEmailDomain', col: 11, visible: false, searchable: false, printable: false, exportable: true},
          
          { title: 'Schema Schüler', name: 'studentUsernamePolicy', col: 12, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Schüler Kennwort', name: 'studentDefaultPassword', col: 13, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Kennwortlänge Schüler', name: 'studentPasswordLength', col: 14, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Schüler Kennwortoption', name: 'studentDefaultPasswordPolicy', col: 15, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Schüler-Email erstellen', name: 'studentMailEnabled', col: 16, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Schüler-Email-Domäne', name: 'studentMailDomain', col: 17, visible: false, searchable: false, printable: false, exportable: true},
          { title: 'Versetzungsmodus aktiv', name: 'isRelocationModeActive', col: 18, visible: false, searchable: false, printable: false, exportable: false},

          { title: 'teacherUsernamePrefix', name: 'teacherUsernamePrefix', col: 19, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'teacherUsernameSuffix', name: 'teacherUsernameSuffix', col: 20, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'studentUsernamePrefix', name: 'studentUsernamePrefix', col: 21, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'studentUsernameSuffix', name: 'studentUsernameSuffix', col: 22, visible: false, searchable: false, printable: false, exportable: false},

          { title: 'teacherMoodleEnabled', name: 'teacherMoodleEnabled', col: 23, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'teacherMoodleDomain', name: 'teacherMoodleDomain', col: 24, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'studentMoodleEnabled', name: 'studentMoodleEnabled', col: 25, visible: false, searchable: false, printable: false, exportable: false},
          { title: 'studentMoodleDomain', name: 'studentMoodleDomain', col: 26, visible: false, searchable: false, printable: false, exportable: false},
          
          { title: 'teacherWhitelistAccessEnabled', name: 'teacherWhitelistAccessEnabled', col: 27, visible: false, searchable: false, printable: false, exportable: false},
        ],
        data: {
          url: '/data/schulart.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },

    {
      route: 'klassen',
      icon: 'klasse',
      endpoint: '/api/school/schoolClasses',
      endpoints: {
      },
      preHooks: {
        list: 'klassen_list_preHook'
      },
      tag: 'paedml-card-klassen',
      category: 'Benutzerverwaltung',
      menuTitle: 'Klassen verwalten',
      pageTitle: 'paedML - Benutzerverwaltung: Klassen',
      name: 'klasse',
      contentTitle: 'Klassen',
      prefix: 'klasse',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Schulart', name: 'schoolType', col: 1, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'name', col: 2 },
          { title: 'Schuljahr', name: 'currentSchoolYear', col: 3, searchable: false, priority: 9 },
          { title: 'Internet sperren', name: 'isInternetLocked', visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Tauschlaufwerk', name: 'sharedFolderPath', col: 5, visible: false, searchable: false, printable: false },
          { title: 'Zugriff Tauschlaufwerk', name: 'sharedFolderAccessLevel', col: 6, searchable: false },
          { title: 'Klassenbezeichnung', name: 'klassenbezeichnung', col: 7, visible: false, printable: false }
        ],
        data: {
          url: '/data/klassen.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },

    {
      route: 'lehrerinnen',
      icon: 'lehrer',
      endpoint: '/api/teachers',
      endpoints: {
        password: '/api/teachers/password',
      },
      tag: 'paedml-card-lehrer',
      category: 'Benutzerverwaltung',
      menuTitle: 'Lehrer*innen verwalten',
      pageTitle: 'paedML - Benutzerverwaltung: Lehrer*innen',
      name: 'lehrer',
      contentTitle: 'Lehrer*innen',
      prefix: 'lehrer',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: "id", col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: "userName", col: 1 },
          { title: 'Nachname', name: "surname", col: 2 },
          { title: 'Vorname', name: "givenName", col: 3 },
          { title: 'Kürzel', name: "initials", col: 4 },
          { title: 'Basis-Schulart', name: "mainSchoolType", col: 5 },
          { title: 'Schulart(en)', name: "schoolTypes", separator: ', ', col: 6, filter: 'Schulart', filterType: 'regex' },
          { title: 'Ist deaktiviert', name: "isDeactivated", col: 7, searchable: false },
          { title: 'Homelaufwerk', name: "homeDirectory", col: 8, visible: false, searchable: false, printable: false },
          { title: 'E-Mail', name: "email", col: 9, priority: 2, exportable: false, printable: false },
          { title: 'Lehrer-ID', name: "externalIdentifier", col: 10, priority: 1 },
          { title: 'Notizen', name: "comments", col: 11, visible: true, searchable: false, priority: 1 },
        ],
        data: {
          url: '/data/lehrer.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'schuelerinnen',
      icon: 'schueler',
      endpoint: '/api/students',
      endpoints: {
        password: '/api/students/password',
      },
      preHooks: {
        list: 'schueler_list_preHook'
      },
      tag: 'paedml-card-schueler',
      category: 'Benutzerverwaltung',
      menuTitle: 'Schüler*innen verwalten',
      pageTitle: 'paedML - Benutzerverwaltung: Schüler*innen',
      name: 'student',
      contentTitle: 'Schüler*innen',
      prefix: 'student',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart', name: 'schoolType', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 5, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 6, searchable: false },
          { title: 'Internet sperren', name: 'isInternetLocked', col: 7, searchable: false, exportable: false, printable: false },
          { title: 'Ist deaktiviert', name: 'isDeactivated', col: 8, searchable: false },
          { title: 'Homelaufwerk', name: 'homeDirectory', col: 9, visible: false, searchable: false, printable: false },
          { title: 'E-Mail', name: 'email', col: 10, priority:2, exportable: false, printable: false },
          { title: 'Schüler-ID', name: 'externalIdentifier', col: 11, priority: 1 },
          { title: 'Klassenbezeichnung', name: 'klassenbezeichnung', col: 12, visible: false, searchable: false, printable: false },
          { title: 'Klassenbezeichnung2', name: 'klassenbezeichnung2', col: 13, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Notizen', name: "comments", col: 14, visible: true, searchable: false, priority: 1 },
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    }
  ]
}
