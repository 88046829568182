import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-printer>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group" if={parent.isManager()}>
        <div class="pb-1">
          Raumstandard-Einstellungen für den Drucker:
        </div>
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="isDefaultPrinter" col="6" placeholder="Ist Standard-Drucker"
              type="checkbox" revlut="jaNein"
              />
          </div>
        </div>
        <div class="py-1"></div>
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="isManagementAllowed" col="7" placeholder="Lehrer*innen dürfen Drucker verwalten"
              type="checkbox" revlut="jaNein"
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="defaultPrintingAllowed" col="8" placeholder="Schüler*innen dürfen standardmäßig drucken"
              type="checkbox" revlut="jaNein" 
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="pb-1">
          Aktuelle Einstellungen für den Drucker:
        </div>
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="isPrintingAllowed" col="9" placeholder="Schüler*innen dürfen drucken"
            type="checkbox" revlut="jaNein"
            />
          </div>
        </div>
      </div>


    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import {isEnabled} from '../util/config-util'
      import API from '../api'

      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      const convertingBooleans = R.mapObjIndexed(v => {
          if (v === 'true' || v === 'ja') return true
          if (v === 'false' || v === 'nein') return false
          return v
      })

      this.isManager = () => Session.user.roleAbbrev !== 'L'

      this.on('dataRequested', () => {
        // this.data.defaultInternetLock = false
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
        this.data.roomId = cols[3].id
      })

      this.submitHandler = formData => {
        // console.debug(this.opname, ':', formData)
        
        const {roomId} = this.data
        // const op = this.isManager() ? this.api.update : API.drucker.patch
        const op = this.isManager() ? () => this.api.update(formData.ids[0], R.omit(['ids', 'idTexts'], {...formData, roomId})) 
          : () => API.drucker.patch(formData.ids[0], {isPrintingAllowed : formData.isPrintingAllowed})
        return op(formData.ids[0], R.omit(['ids', 'idTexts'], {...formData, roomId}))
          .then(rsp => {
            const [data, status] = rsp
            if (status.success) {
              this.parent.trigger('reloadTable')
              return 'Änderung(en) erfolgreich übernommen'
            }

            console.error('error-status', status)
            console.error('error-data', data)
            if (data.message) {
              return Promise.reject(data.message)
            }
            return Promise.reject('Änderung(en) konnten nicht übernommen werden!')
          })
      }
      
      
    </script>
</paedml-modal-edit-printer>
