import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-access-cloud.tag'
import './paedml-modal-domain.tag'

<paedml-card-cloud>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{isPermitted('edit') && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-link" target="#{prefix}-edit-cloud-modal" title="Mit externer Domäne verknüpfen" />
      <paedml-modal-domain prefix="{prefix}-edit-cloud" edit={true} selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant} domain='cloud'
        heading="{config.contentTitle}: Externe Domäne verknüpfen" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{isPermitted('access') && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-toggle-on" target="#{prefix}-access-cloud-modal" title="Microsoft365-Zugriff (de)aktivieren" />
      <paedml-modal-access-cloud prefix="{prefix}-access-cloud" selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant} domain='cloud'
        patient="{{singular: 'Microsoft365-Zugriff', plural: 'Microsoft365-Zugriffe', for: 'für'}}"
        heading="{config.contentTitle}: Microsoft365-Zugriff (de)aktivieren" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
  </paedml-card>

  <script>
    import API from '../api'
    this.prefix=opts.prefix
    this.variant = opts.config.custom.variant

  </script>
</paedml-card-cloud>
