import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-createoredit-profil.tag'

<paedml-card-profil>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Neues Profil anlegen" />
      <paedml-modal-createoredit-profil prefix="{prefix}-create" edit={false} selectedrowsfunc={getSelectedRows}
        action="create" variant={config.custom.variant}
        heading="{config.contentTitle}: Neues Profil anlegen" opname="Profil anlegen" api={restApi}
      />
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Profil bearbeiten" />
      <paedml-modal-createoredit-profil prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        action="edit" heading="{config.contentTitle}: Profil bearbeiten" opname="Änderung speichern" api={restApi} />
    </virtual>

    <virtual if="{isPermitted('duplicate') && selectedExactlyOne()}">
      <paedml-icon-modal icon="far-copy" target="#{prefix}-duplicate-modal" title="Profil duplizieren" />
      <paedml-modal-createoredit-profil prefix="{prefix}-duplicate" selectedrowsfunc={getSelectedRows}
        action="duplicate" variant={config.custom.variant}
        heading="{config.contentTitle}: Profil duplizieren" opname="Profil duplizieren" api={restApi} />
    </virtual>

    <virtual if="{isPermitted('assign') && selectedExactlyOne()}">
      <paedml-icon-modal icon="fas-upload" target="#{prefix}-load-modal" title="Profil in (A)Prof... laden" />
      <paedml-modal-createoredit-profil prefix="{prefix}-load" edit={true} selectedrowsfunc={getSelectedRows}
        action="load" variant={config.custom.variant}
        heading="{config.contentTitle}: Profil in (A)Prof... laden" opname="Profil laden" api={restApi} />
    </virtual>

    <virtual if="{isPermitted('assign') && selectedExactlyOne() && parent.isAllocatedToAProf(this)}">
      <paedml-icon-modal icon="fas-download" target="#{prefix}-save-modal" title="Profil aus AProf... zurückspeichern" />
      <paedml-modal-createoredit-profil prefix="{prefix}-save" edit={true} selectedrowsfunc={getSelectedRows}
        action="save" variant={config.custom.variant}
        heading="{config.contentTitle}: Profil aus AProf... zurückspeichern" opname="Änderung speichern" api={restApi} />
    </virtual>

    <virtual if="{isPermitted('activate') && selectedExactlyOne() && parent.canBeActivated(this)}">
      <paedml-icon-modal icon="fas-toggle-on" target="#{prefix}-activate-modal" title="Profil aktivieren" />
      <paedml-modal-createoredit-profil prefix="{prefix}-activate" edit={true} selectedrowsfunc={getSelectedRows}
        action="activate" variant={config.custom.variant}
        heading="{config.contentTitle}: Profil aktivieren" opname="Profil aktivieren" api={restApi} />
    </virtual>
    
    <virtual if="{canDelete() && !parent.isProtected(this) && !parent.isDefaultProfile(this)}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Profile löschen" />
      <paedml-modal-delete patient="{{singular: 'Profil', plural: 'Profile', as: 'das'}}" 
        heading="{config.contentTitle}: Profile löschen" prefix="{prefix}-delete" 
        idcol=0 idtextcol=1 unescape=true
        selectedrowsfunc={getSelectedRows} api={restApi} doredirect={true}/>
    </virtual>

    <virtual if="{isPermitted('reset') && selectedNone()}">
      <paedml-icon-modal icon="fas-undo-alt" target="#{prefix}-reset-modal" title="AProf Profil zurücksetzen" />
      <paedml-modal-createoredit-profil prefix="{prefix}-reset" edit={true}
        action="reset" variant={config.custom.variant}
        heading="{config.contentTitle}: AProf Profil zurücksetzen" opname="AProf Profil zurücksetzen" api={restApi}
        />
    </virtual>

  </paedml-card>

  <script>
    import API from '../api'
    this.prefix = opts.prefix
    this.variant = opts.config.custom.variant

    this.isAllocatedToAProf = (ctx) => ctx.atLeastOnePositiveValueInColumn(12)
    this.canBeActivated = (ctx) => ctx.atLeastOneNegativeValueInColumn(11) && !ctx.atLeastOneMatchingValueInColumn('«Übergreifend»', 3)

    // Profile is protected if at least one selected profile is aktive.
    this.isProtected = (ctx) => ctx.atLeastOnePositiveValueInColumn(11) || ctx.atLeastOnePositiveValueInColumn(12)
    // Protect Basisprofil
    this.isDefaultProfile = (ctx) => ctx.atLeastOneDefaultProfileInColumn(1)
  </script>
</paedml-card-profil>
