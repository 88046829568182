export const jobverwaltung = {
  headerTitle: 'Jobverwaltung',
  route: 'jobverwaltung',
  pageTitle: 'paedML - Jobverwaltung',
  related: [
    { route: 'versetzung/verarbeitung', menuTitle: 'Versetzungs- / Verschiebungs-Status anschauen' },
    { route: 'benutzerverwaltung', menuTitle: 'Benutzerverwaltung' },
    { route: 'imports', menuTitle: 'Importverwaltung' },
  ],
  cards: [
    {
      route: 'jobs',
      icon: 'ausgewaehlte-jobs-ausfuehren-g',
      endpoint: '/api/jobs',
      endpoints: {
      },
      preHooks: {
        list: 'jobs_list_preHook'
      },
      tag: 'paedml-card-jobs',
      category: 'Jobverwaltung',
      menuTitle: 'Jobs verwalten',
      pageTitle: 'paedML - Jobverwaltung: Jobs',
      name: 'jobs',
      contentTitle: 'JobQueue',
      prefix: 'jobs',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0 },
          { title: 'Angelegt Am', name: 'createdUF', col: 1, orderData: [9] },
          { title: 'Titel', name: 'title', col: 2 },

          { title: 'Schulart', name: 'schoolType', col: 3, filter: 'Schulart', filterType: 'regex' },
          { title: 'Benutzer', name: 'userName', col: 4 },
          { title: 'Klasse', name: 'schoolClass', col: 5 },
          { title: 'Schuljahr', name: 'schoolYear', col: 6 },
          { title: 'Aktion', name: 'jobType', col: 7 },
          { title: 'Objekttyp', name: 'jobTarget', col: 8 },

          { title: 'created', name: 'created', col: 9, visible: false, searchable: false, printable: false, exportable: false }

        ],
        data: {
          url: '/data/jobs.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'tasks',
      icon: 'fas-tasks',
      endpoint: '/api/jobs/tasks',
      endpoints: {
      },
      preHooks: {
        list: 'tasks_list_preHook'
      },
      tag: 'paedml-card-tasks',
      category: 'Jobverwaltung',
      menuTitle: 'Tasks verwalten',
      pageTitle: 'paedML - Jobverwaltung: Tasks',
      name: 'tasks',
      contentTitle: 'Tasks',
      prefix: 'tasks',
      suffix: undefined,
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0 },
          { title: 'Titel', name: 'title', col: 1 },
          { title: 'Status', name: 'status', col: 2 },
          { title: 'Priorität', name: 'priority', col: 3, filter: 'Priorität' },
          { title: 'Anzahl Durchläufe', name: 'runCount', col: 4 },
          { title: 'Letzter Durchlauf Am', name: 'lastRunTimeUF', col: 5, orderData: [9] },
          { title: 'Anzahl Fehler', name: 'errorCount', col: 6 },
          { title: 'JobID', name: 'jobId', col: 7, visible: false },
          { title: 'jobDescription', name: 'jobDescription', group: 'simple', col: 8, visible: false, printable: false, exportable: false },

          { title: 'lastRunTime', name: 'lastRunTime', col: 9, visible: false, searchable: false, printable: false, exportable: false }

        ],
        data: {
          url: '/data/tasks.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

  ]
}
