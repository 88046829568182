<paedml-modal-edit-singlelogin>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="0" idtextcol="1"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true} ref="modal"
                >

    <yield to="fields">
      <div class="row justify-content-center">
        <div class="col-5 ">
          <span>{parent.data.text} verhindern</span>
        </div>
        <div class="col-1">
          <paedml-form-input name="isSingleLoginActive"
            type="checkbox" revlut="jaNein" handlechange={parent.handleToggle}
          />
        </div>
        <div class="col-5">
          <span>{parent.data.text} erlauben</span>
        </div>
      </div>
  
      <virtual if={parent.data.selectedRows > 1 }>
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-warning">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">Der aktuelle Zustand der {parent.data.text} kann nicht ermittelt werden, da mehrere {parent.patient.plural} ausgewählt sind.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientSingularFormatter} from '../util/modal-util'
    import API from '../api'

    this.prefix = opts.prefix
    this.variant = opts.variant
    this.patient = opts.variant === "teachers" 
      ? {singular: 'Lehrer', plural: 'Lehrer*innen'} 
      : opts.variant === "students" ? {singular: 'Schüler', plural: 'Schüler*innen'} : {singular: 'Klassenarbeits-Teilnehmer', plural: 'Klassenarbeits-Teilnehmer*innen'} 
    this.data = {
      text: opts.patient.singular + " wird erlaubt",
      patient: opts.patient,
      isSingleLoginActive: false,
      selectedRows: 0
    }
    this.api = API.singlelogin[opts.variant]
    this.op = opts.variant
    
    this.handleToggle = (val) => {
      this.data.isSingleLoginActive = val
      this.refs.modal.trigger('updateButton', this.data.isSingleLoginActive ? 'Mehrfachanmeldung erlauben' : 'Mehrfachanmeldung verhindern')
    }

    this.on('dataRequested', (selectedRows) => {
      
      this.data = {
        text: opts.patient.singular,
        selectedRows: selectedRows.length
      }
      var lockedState = opts.variant === "teachers" 
      ? selectedRows[0][4] 
      : opts.variant === "students" ? selectedRows[0][5] : selectedRows[0][6]
  
      this.setInitialState(lockedState)
    })

    this.setInitialState = (lockedState) => {
      var isSingleLoginActive = false;
      if(this.data.selectedRows === 1) {
        isSingleLoginActive = lockedState === "nein"
      } 
      this.handleToggle(isSingleLoginActive)
    }

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')
      
      return this.api.edit(formData.ids, !this.data.isSingleLoginActive).then(rsp => {
        const [data, status] = rsp
        if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server')

        if(status.success){
          this.parent.trigger('reloadTable')
          return 'Die Benutzerkonten wurden erfolgreich für Mehrfachanmeldung aufbereitet'
        }
      })
    }

  </script>
</paedml-modal-edit-singlelogin>
