import './paedml-icon.tag'

<paedml-selection-dropdown>
  <span class="dropdown">
      <button class="btn dropdown-toggle paedml-btn-dropdown" type="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <paedml-icon icon={selectionStatusAsIconName()} />
        <!-- <span if={opts.selectionstatusfunc() === 0}> <i class="far fa-square fa-lg"></i></span>
        <span if={opts.selectionstatusfunc() === 1}> <i class="far fa-minus-square fa-lg paedml-icon-selected"></i></span>
        <span if={opts.selectionstatusfunc() === 2}> <i class="far fa-check-square fa-lg paedml-icon-selected"></i></span> -->

        <span id="selection-text" class={'paedml-text-selected': opts.selectionstatusfunc()}>Auswahl</span>
      </button>
      <div class="dropdown-menu paedml-btn-dropdown">
        <a class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={opts.selectnothingfunc} title={textSelectNothing()}>zur&uuml;cksetzen ({selected})</a>
        <a class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={opts.selectfilteredfunc} title="Wählt gefilterte Einträge aus">Filter- / Suchergebnisse</a>
        <a class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={opts.selectpagefunc} title="Wählt Einträge auf dieser Seite aus">auf dieser Seite</a>
        <a show="{!opts.disablealle}" class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={opts.selectallfunc} title={textSelectAll()}>alles ({total})</a>
      </div>
    </span>
  
  <script>
    this.selectioninfofunc = opts.selectioninfofunc

    this.textSelectNothing = () => this.selected
      ? `Wählt ${this.calcNumEntries()} ab` : 'Keine Einträge zum Abwählen vorhanden'

    this.textSelectAll = () => this.total
      ? this.calcNumEntries2() : 'Keine Einträge zum Auswählen vorhanden'

    this.calcNumEntries = () => this.selected === 1 ? 'den einen ausgewählten Eintrag' : (this.selected === this.total ? 'alle ausgewählten Einträge' : `${this.selected} ausgewählte Einträge`)
    this.calcNumEntries2 = () => this.total === 1 ? 'Wählt den einen Eintrag aus' : 
      (this.selected === this.total ? 'Bereits alle Einträge ausgewählt' : 
       (this.total - this.selected === 1) ? 'Wählt einen noch nicht ausgewählten Eintrag aus' : `Wählt ${this.total - this.selected} noch nicht ausgewählte Einträge aus`)

    this.updateInfo = () => {
      const {total, selected, status} = this.selectioninfofunc()
      this.total = total
      this.selected = selected
      this.status = status
    }

    this.on('update', this.updateInfo)
    this.on('mount', this.updateInfo)

    this.selectionStatusAsIconName = () => {
      // const status = opts.selectionstatusfunc()
      const status = this.status
      return status === 0 ? 'far-square' :
             status === 1 ? 'far-minus-square' : 'far-check-square'
    }
  </script>
</paedml-selection-dropdown>
