import './paedml-icon.tag'

<paedml-filter-dropdown>
  <span class="dropdown btn">
    <button class="btn dropdown-toggle paedml-btn-dropdown" type="button"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <paedml-icon icon="filter"/>
      <span class={'paedml-text-selected': currentFilter}>{currentFilter || coltitle}</span>
    </button>
    <div class="dropdown-menu paedml-btn-dropdown">
      <a show="{!disablenofilter}"class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={setFilter} data-value="">{noFilterText()}</a>
      <a each={filter in values} class="dropdown-item paedml-btn-dropdown" href="javascript:void(0);" onclick={setFilter}>{filter}</a>
    </div>
  </span>

  <script>
    import * as R from 'ramda'

    this.reduxConnect(state => ({user: state.user, page: state.page}));
    this.colname = this.requiresOpt('colname')
    this.coltitle = this.opts.coltitle || this.colname
    this.values = opts.values;
    this.coldynamic = typeof this.values === 'function' ? this.values : undefined
    this.disablenofilter = false

    this.updateDynFilter = (colname, value) => {
      if (this.coldynamic) {
        this.coldynamic((values, disablenofilter) => {
          this.values = values
          this.disablenofilter = disablenofilter === true
          if (this.currentFilter && !R.includes(this.currentFilter, values)) {
            this.currentFilter = ""
            this.opts.filterfunc(this.colname, this.currentFilter)
          }
          this.update()
        }, colname, value)
      }
    }

    this.on('update', () => {
      if (this.coldynamic) {
      } else {
        this.values = this.opts.user.filters[this.colname];
      }
    })

    this.on('refresh', (colname, value) => this.updateDynFilter(colname, value))

    this.on('mount', this.updateDynFilter)

    this.on('filter', val => {
      this.currentFilter = val
      this.opts.filterfunc(this.colname, this.currentFilter)
      this.update()
    })
    this.currentFilter = ""

    this.noFilterText = () => this.colname === 'Favoriten' ? this.colname : 'kein Filter'

    this.setFilter = function(e) {
      this.currentFilter = e.target.dataset.value === undefined ? e.target.text: e.target.dataset.value;
      this.opts.filterfunc(this.colname, this.currentFilter)
    }.bind(this)
    
  </script>
</paedml-filter-dropdown>
