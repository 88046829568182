<paedml-modal-startstop-exam>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true}
                >

    <yield to="fields">
      <div class="m-2 p-2">
        <span class="pl-1 pt-2">Die Klassenarbeit '<strong class="text-primary">{parent.data.title}</strong>' wird {parent.data.op}!</span>
      </div>
      <div class="p-2"></div>
      <div class="mt-2 mr-1 p-2 border border-danger" if={parent.data.enabled}>
        <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" />
          <strong class="text-danger pl-1 pt-2">Alle noch angemeldeten Klassenarbeits-Teilnehmer werden automatisch und ohne Vorwarnung abgemeldet. Das kann zu Datenverlusten führen.</br></br>
            Fordern Sie vor dem Beenden die Teilnehmer auf, Ihre Ergebnisse zu speichern.</strong>
        </span>
      </div>
      <div class="mt-2 mr-1 p-2 border border-danger" if={!parent.data.enabled}>
        <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" />
          <strong class="text-danger pl-1 pt-2">Vorsicht beim Testen bzw. frühzeitigen Starten der Klassenarbeit.</br></br>
            Alle Klassenarbeits-Teilnehmer, die noch (irgendwo in der Schule) mit ihren persönlichen Zugangsdaten am System angemeldet sind, werden automatisch
            und ohne Vorwarnung abgemeldet. Das kann zu Datenverlusten führen.</strong>
        </span>
      </div>
      <div class="p-1"></div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'

    
    this.prefix = opts.prefix
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient
    this.variant = opts.variant
    
    this.on('dataRequested', () => {
    })

    this.on('rowMappingRequested', cols => {
      this.data.title = cols[2]
      this.data.enabled = cols[6] === 'ja'
      this.data.op = this.data.enabled ? 'beendet' : 'gestartet'
    })

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const enabled = !this.data.enabled
      const op = enabled ? 'gestartet' : 'beendet'

      return API.exam.start(formData.ids[0], {enabled: enabled}).then(rsp => {
        const [data, status] = rsp
          if (status.success) {
            this.parent.trigger('reloadTable', true)
            return `Klassenarbeit '${this.data.title}' erfolgreich ${op}`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Klassenarbeit '${this.data.title}' konnte nicht ${op} werden. Grund: ${reason}`)
      })

    }

  </script>
</paedml-modal-startstop-exam>
