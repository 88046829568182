import './paedml-card.tag'
import './paedml-modal-createoredit-admin.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-init-import.tag'
import './paedml-modal-edit-import.tag'
import './paedml-modal-hinweis-schulart.tag'

<paedml-card-import> 
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{active && selectedNone() && parent.startTimer()}">
      <span class="btn paedml {parent.session.timers[parent.variant] ? 're_sync' : ''}" onclick={parent.handleRefresh} id="{prefix}-rescan">
        <paedml-icon icon="aktualisieren" title="automatisch synchronisieren" type="zoom" />
      </span>
    </virtual>

    <virtual if="{selectedOneOrMore() && parent.clearTimer()}"></virtual>

    <virtual if="{canCreate()}">
      <virtual if="{parent.schoolTypeExists()}">
        <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Importvorgang einleiten" />
        <paedml-modal-init-import prefix="{prefix}-create" 
          heading="{config.contentTitle}: Importvorgang einleiten" opname="Importvorgang einleiten" api={restApi} usergroup={config.custom.usergroup}
        />
      </virtual>
      <virtual if="{!parent.schoolTypeExists()}">
        <paedml-icon-modal icon="anlegen" target="#{prefix}-hinweis-schulart-modal" title="Importvorgang einleiten" />
        <paedml-modal-hinweis-schulart prefix="{prefix}-hinweis-schulart"
          heading="{config.contentTitle}: Keine Schulart vorhanden" opname="OK" api={restApi} usergroup={config.custom.usergroup}
        />
      </virtual>
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Eintrag bearbeiten" />
      <paedml-modal-edit-import prefix="{prefix}-edit" selectedrowsfunc={getSelectedRows} edit={true}
        heading="{config.contentTitle}: Eintrag bearbeiten" opname="Speichern" api={restApi} usergroup={config.custom.usergroup}
        idcol="1"
      />
    </virtual>

    <virtual if="{canRun()}">
      <paedml-icon-modal icon="ausgewaehlte-jobs-ausfuehren-g" target="#{prefix}-run-modal" title="Import starten" />
      <paedml-modal-run-jobs prefix="{prefix}-run" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Import starten" opname="Import starten" idcol="1" idtextcol="1" api={restApi}
        patient="{{singular: 'Importvorgang', plural: 'Importvorgänge'}}"
      />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Einträge l&ouml;schen" />
      <paedml-modal-delete heading="{config.contentTitle}: Einträge löschen" idcol="1" idtextcol="4" 
        patient="{{singular: 'Eintrag', plural: 'Einträge', as: 'den'}}" prefix="{prefix}-delete" selectedrowsfunc={getSelectedRows} api={restApi}/>
    </virtual>
  </paedml-card>

  <script>
    import API from '../api'
    import * as R from 'ramda'
    import {usingExternalConfig} from '../util/config-util'
    import {createTimerTask, createTimerCanceler, createRefresher} from '../util/api-util'
    import Session from '../store/session'

    this.opname = this.opts.opname
    this.edit = this.opts.edit ? true : false
    this.data = {}
    this.api = this.opts.api
    this.session = Session
    this.variant = opts.config.custom.variant

    this.handleRefresh = e => {
      this.refs['card'].trigger('reloadTable')
    }

    this.schoolTypeExists = () => R.keys(Session.lut.schularten).length > 0

    this.hasNoErrorOrWarning = (selectedrowsfunc) => {
      const selectedRows = selectedrowsfunc()
      for (let i=0; i < selectedRows.length; ++i) {
        if (selectedRows[i][0] !== '') return false
      }
      return true
    }

    this.handleIntervalRefresh = createRefresher(this, this.variant, usingExternalConfig("raumverwaltung","refreshInterval", 30000))
    this.startTimer = createTimerTask(this.variant, this.handleIntervalRefresh)
    this.clearTimer = createTimerCanceler(this.variant)

  </script>
</paedml-card-import>
