import './paedml-card.tag'
import './paedml-modal-createoredit-admin.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-init-import.tag'
import './paedml-modal-edit-import.tag'
import './paedml-modal-hinweis-schulart.tag'
import './paedml-modal-edit-versetzung.tag'
import './paedml-modal-reset-versetzung.tag'
import './paedml-modal-freigabe-versetzung.tag'

<paedml-card-versetzung>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{active}">
      <span class="btn paedml" onclick={parent.handleRefresh}>
        <paedml-icon icon="aktualisieren"  title="Synchronisieren" type="zoom" />
      </span>
    </virtual>

    <virtual if="{parent.mode !== 'verarbeitung' && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-arrows-alt-h" target="#{prefix}-move-modal" title={parent.opnameVerschiebung} />
      <paedml-modal-edit-versetzung prefix="{prefix}-move" mode={parent.mode} variant="move" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: {parent.opnameVerschiebung}" opname={parent.opnameVerschiebung} api={restApi}
        patient="{{singular: 'Schüler*in', plural: 'Schüler*innen'}}"
        idcol="1" idtextcol="1"
      />
    </virtual>

    <virtual if="{parent.mode !== 'verarbeitung' && selectedOneOrMore() && parent.canRelocate(getSelectedRows)}">
      <paedml-icon-modal icon="fas-arrows-alt-v" target="#{prefix}-relocation-modal" title={parent.opnameVersetzung} />
      <paedml-modal-edit-versetzung prefix="{prefix}-relocation" mode={parent.mode} variant="relocation" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: {parent.opnameVersetzung}" opname={parent.opnameVersetzung} api={restApi}
        patient="{{singular: 'Schüler*in', plural: 'Schüler*innen'}}"
        idcol="1" idtextcol="1"
      />
    </virtual>

    <virtual if="{parent.mode !== 'aufbereitung' && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-undo-alt" target="#{prefix}-reset-modal" title="Einträge rücksetzen" />
      <paedml-modal-reset-versetzung prefix="{prefix}-reset" selectedrowsfunc={getSelectedRows} edit={true}
        heading="{config.contentTitle}: Einträge rücksetzen" opname="Rücksetzen" api={restApi}
        patient="{{singular: 'Schüler*in', plural: 'Schüler*innen'}}"
        idcol="1" idtextcol="1"
      />
    </virtual>

    <virtual if="{parent.mode === 'freigabe' && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-check-double" target="#{prefix}-freigabe-modal" title="Einträge freigeben" />
      <paedml-modal-freigabe-versetzung prefix="{prefix}-freigabe" selectedrowsfunc={getSelectedRows} edit={true}
        heading="{config.contentTitle}: Einträge freigeben" opname="Freigeben" api={restApi}
        patient="{{singular: 'Schüler*in', plural: 'Schüler*innen'}}"
        idcol="1" idtextcol="1"
      />
    </virtual>
  </paedml-card>

  <script>
    import store from '../store'
    import { client } from '../util/api-util'
    import * as mapper from '../mapper'
    import * as R from 'ramda'
    import Session from '../store/session'
    import Masterdata from '../config/masterdata'

    this.mode = this.config.custom.mode
    this.opnameVerschiebung = 'Verschiebung ' + (this.mode === 'aufbereitung' ? 'aufbereiten' : 'anpassen')
    this.opnameVersetzung = 'Versetzung ' + (this.mode === 'aufbereitung' ? 'aufbereiten' : 'anpassen')
    this.edit = this.opts.edit ? true : false
    this.data = {}
    this.api = this.opts.api
    this.session = Session

    this.handleRefresh = e => {
      this.refs['card'].trigger('reloadTable')
    }

    this.schoolTypeExists = () => R.keys(Session.lut.schularten).length > 0

    this.updateRelAST = () => {
      const {settings} = Session
      const {schularten} = Session.map
      const relAST = {}
      R.forEachObjIndexed((v,k) => {
        const tmp = settings[k].isRelocationModeActive
        if (tmp === 'true' || tmp === 'True' || tmp === true) {
          relAST[v] = true
        }
      }, schularten)
      this.relAST = relAST
    }

    this.canRelocate = (selectedrowsfunc) => {
      this.updateRelAST()
      const saCol = this.mode === 'freigabe' ? 6 : 4
      const selectedRows = selectedrowsfunc()
      for (let i=0; i < selectedRows.length; ++i) {
        if (!this.relAST[selectedRows[i][saCol]]) return false
      }
      return true
    }

    this.hasNoErrorOrWarning = (selectedrowsfunc) => {
      const selectedRows = selectedrowsfunc()
      for (let i=0; i < selectedRows.length; ++i) {
        if (selectedRows[i][0] !== '') return false
      }
      return true
    }
  </script>
</paedml-card-versetzung>
