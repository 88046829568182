import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-accesslist>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

    <div class="paedml-input-group">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Blacklist</label>
            <textarea class="form-control" maxlength=4097 data-rule-maxlength=4096 data-msg-maxlength="Es sind bis zu 4096 Zeichen erlaubt" name="blacklist" cols="28" rows="10"></textarea>
          </div>
          <paedml-form-input name="blacklistActive" col="7" placeholder="Blacklist aktiv" fgclass="py-1"
              show={parent.variant === 'klassen'}
              type="checkbox" revlut="jaNein"
              handlechange={parent.handleBlacklistActive}
          />
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Whitelist</label>
            <textarea class="form-control" maxlength=4097 data-rule-maxlength=4096 data-msg-maxlength="Es sind bis zu 4096 Zeichen erlaubt" name="whitelist" cols="28" rows="10"></textarea>
          </div>
          <paedml-form-input name="whitelistActive" col="10" placeholder="Whitelist aktiv" fgclass="py-1"
              show={parent.variant === 'klassen'}
              type="checkbox" revlut="jaNein"
              handlechange={parent.handleWhitelistActive}
          />
          <paedml-form-input name="whitelistOnly" col="11" placeholder="Nur Whitelist verwenden" fgclass="py-1"
              show={parent.variant === 'klassen'}
              type="checkbox" revlut="jaNein"
              handlechange={parent.handleWhitelistOnly}
          />
        </div>
      </div>
    </div>

    </yield>
  </paedml-modal>
    
  <script>
    import store from '../store'
    import { client } from '../util/api-util'
    import * as mapper from '../mapper'
    import * as R from 'ramda'
    import Session from '../store/session'
    import Masterdata from '../config/masterdata'
    import API from '../api'

    this.variant = opts.variant
    this.opname = this.opts.opname
    this.edit = this.opts.edit ? true : false
    this.data = {}
    this.api = this.opts.api
    this.session = Session

    this.handleBlacklistActive = (val) => {
      if (val) this.data.whitelistOnly = false
    }

    this.handleWhitelistActive = (val) => {
      if (!val) this.data.whitelistOnly = false
    }

    this.handleWhitelistOnly = (val) => {
      if (val) {
        this.data.whitelistActive = true
        this.data.blacklistActive = false
      }
    }

    this.on('rowMappingRequested', cols => {
      this.data.id = cols[0] || 0

      this.data.firewallCategories = cols[4]
      this.data.blacklist = R.join('\n', cols[6])
      this.data.blacklistActive = cols[7] === 'ja'

      this.data.whitelistOnly = cols[11] === 'ja'
      this.data.whitelist = R.join('\n', cols[9])
      this.data.whitelistActive = cols[10] === 'ja'
    })

    const normalizeList = str => str ? str.split('\n').map(R.trim).filter(e => !R.isEmpty(e)) : []

    this.getAPI = () => {
      if(this.variant === 'global') 
        return API.fwglobal
      if(this.variant === 'klassen') 
        return API.fwklassen
      return API.fwtemplate
    }

    this.load = (id) => {
      this.loading = true
      return this.getAPI().get(id).then(rsp => {
        this.loading = false
        return rsp
      })
    }

    this.submitHandler = formData => {
      const blacklist = normalizeList(formData.blacklist)
      const whitelist = normalizeList(formData.whitelist)
      const payload = { blacklist, whitelist }
      const id = this.data.id

      if (id) {
        payload.firewallCategories = this.data.firewallCategories
        if (this.variant === 'klassen') {
          payload.blacklistActive = this.data.blacklistActive
          payload.whitelistActive = this.data.whitelistActive
          payload.whitelistOnly = this.data.whitelistOnly
        }
      }
 
      const op = id === -1 ? () => this.getAPI().update(payload) : () => this.getAPI().update(id, payload)

      return op().then(rsp => {
        const [data, status] = rsp
        if (status.success) {
          this.parent.trigger('reloadTable')
          return 'Änderung(en) erfolgreich übernommen'
        }

        if (data.message) {
          return Promise.reject(data.message)
        }

        return Promise.reject('Änderung(en) konnten nicht übernommen werden!')
      })

    }
  </script>
</paedml-modal-accesslist>
