import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-basis>
<paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit}
    submitfunc={submitHandler}>

    <yield to="fields">
        <div class="paedml-input-group" show={parent.sysadm}>
            <div class="row">
                <div class="col-sm-6">
                    <paedml-form-input name="currentSchoolYear" label="aktuelles Schuljahr"
                        inputid="{prefix}-modal-input-currentSchoolYear" readonly={edit} required
                        data-msg="Bitte geben Sie das aktuelle Schuljahr ein" data-rule-schuljahr
                        data-msg-schuljahr="Bitte geben Sie ein gültiges Schuljahr ein"
                        data-rule-lessThan="#{prefix}-modal-input-nextSchoolYear"
                        data-msg-lessThan="Das aktuelle Schuljahr muss kleiner sein als das Kommende" />
                </div>
                <div class="col-sm-6">
                    <paedml-form-input name="nextSchoolYear" label="neues Schuljahr"
                        inputid="{prefix}-modal-input-nextSchoolYear" readonly={parent.data.isRelocationModeActive}
                        required data-msg="Bitte geben Sie das kommende Schuljahr ein" data-rule-schuljahr
                        data-msg-schuljahr="Bitte geben Sie ein gültiges Schuljahr ein"
                        data-rule-greaterThan="#{prefix}-modal-input-currentSchoolYear"
                        data-msg-greaterThan="Das kommende Schuljahr muss grösser sein als das Aktuelle" />
                </div>
            </div>
        </div>
        <div class="paedml-input-group" show={parent.firewallVisible}>
            <div class="pb-1">
                Firewall:
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <paedml-form-input name="firewallType" label="Firewalltyp" ref="firewallType" type="select"
                        values={parent.firewalls} required data-msg="Bitte wählen Sie den Firewalltyp"
                         />
                </div>
                <div class="col-sm-6" show={parent.data.firewallType === 'OCTO'}>
                    <paedml-form-input name="firewallInstanceName" label="Instanzname Ihrer OctoGate"
                        required
                        data-msg="Bitte geben Sie den Instanznamen ein" data-rule-fwinstname
                        data-msg-fwinstname="Bitte geben Sie den Hostnamen Ihrer OctoGate ein (8 Zeichen)." />
                </div>
                <div class="col-sm-6" show={parent.isSophosFirewall()}>
                    <paedml-form-input name="sophosApiUrl" label="Sophos API-URL"
                        required data-msg="Bitte geben Sie die API-URL ein" />
                </div>
                <div class="col-sm-6" show={parent.data.firewallType === 'SOPHOS_XG'}>
                    <paedml-form-input name="sophosXgApiUsername" label="Sophos API-Benutzer" required
                        data-msg="Bitte geben Sie den API-Benutzer ein" />
                </div>
                <div class="col-sm-6" show={parent.isSophosFirewall()}>
                    <paedml-form-input name="sophosApiToken" label="API-Token" required
                        data-msg="Bitte geben Sie den API-Token ein" />
                </div>
            </div>
            <div class="row" show={parent.data.firewallType === 'OCTO'}>
                <div class="col-sm-12 py-2">
                    <paedml-form-input name="teacherWhitelistAccessEnabled"
                        placeholder="Lehrkräfte dürfen Klassen-Whitelist bearbeiten" type="checkbox" revlut="jaNein" />
                </div>
            </div>
        </div>
        <div class="paedml-input-group" show={parent.sysadm}>
            <div class="pb-1">
                Mindestkennwortlänge:
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <paedml-form-input name="adminPasswordLength" label="Administratoren" required
                        data-msg="Bitte geben Sie die Mindestkennwortlänge für die Administratoren ein"
                        data-rule-min="{parent.minPwLengthAdmin()}"
                        data-msg-min="Mindestkennwortlänge darf nicht kleiner sein als {parent.minPwLengthAdmin()}"
                        data-rule-max="64" data-msg-max="Mindestkennwortlänge darf nicht grösser sein als 64" />
                </div>
                <div class="col-sm-6">
                    <paedml-form-input name="examPasswordLength" label="Klassenarbeiten" required
                        data-msg="Bitte geben Sie die Mindestkennwortlänge für die Klassenarbeiten ein"
                        data-rule-min="{parent.minPwLengthExam()}"
                        data-msg-min="Mindestkennwortlänge darf nicht kleiner sein als {parent.minPwLengthExam()}"
                        data-rule-max="64" data-msg-max="Mindestkennwortlänge darf nicht grösser sein als 64" />
                </div>
            </div>
        </div>
        <div class="paedml-input-group" show={parent.sysadm}>
            <div class="pb-1">
                Lehrer:
            </div>
            <div class="row">
                <div class="col-sm-2 pr-0">
                    <paedml-form-input name="teacherUsernamePrefix" label="Präfix" data-rule-prefix
                        data-msg-prefix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang."
                        maxlength="5" data-rule-maxlength="4"
                        data-msg-maxlength="Das Präfix darf nicht mehr als 4 Zeichen enthalten!" />
                </div>
                <div class="col-sm-4 px-0">
                    <paedml-form-input name="teacherUsernamePolicy" label="Schema" required
                        data-msg="Bitte geben Sie das Schema für Lehrer-Benutzerkonten ein" data-rule-schema
                        data-msg-schema="Erlaubt sind: n, N, v, V. Optional kann ein &quot;.&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende." />
                </div>
                <div class="col-sm-2 pl-0">
                    <paedml-form-input name="teacherUsernameSuffix" label="Suffix" data-rule-suffix
                        data-msg-suffix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Ende."
                        maxlength="5" data-rule-maxlength="4"
                        data-msg-maxlength="Das Suffix darf nicht mehr als 4 Zeichen enthalten!" />
                </div>
                <div class="col-sm-4">
                    <paedml-form-input name="teacherDefaultPassword" label="Basiskennwort" required
                        data-msg="Bitte geben Sie das Basiskennwort für Lehrer-Benutzerkonten ein"
                        data-rule-minlength="{parent.minPwLengthTeacher()}"
                        data-msg-minlength="Das Basiskennwort muss mindestens {parent.minPwLengthTeacher()} Zeichen haben!"
                        data-rule-maxlength="64"
                        data-msg-maxlength="Das Basiskennwort darf nicht mehr als 64 Zeichen enthalten!" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <paedml-form-input name="teacherPasswordLength" label="Mindestkennwortlänge" required
                        data-msg="Bitte geben Sie die Mindestkennwortlänge für die Lehrer-Benutzerkonten ein"
                        data-rule-min="{parent.minPwLengthTeacher()}"
                        data-msg-min="Mindestkennwortlänge darf nicht kleiner sein als {parent.minPwLengthTeacher()}"
                        data-rule-max="64" data-msg-max="Mindestkennwortlänge darf nicht grösser sein als 64" />
                </div>
                <div class="col-sm-8">
                    <paedml-form-input type="select" name="teacherDefaultPasswordPolicy" label="Kennwortoption" required
                        values={parent.pwPolicyValues} />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 py-2">
                    <paedml-form-input name="teacherEmailEnabled" placeholder="Email erstellen" type="checkbox"
                        revlut="jaNein" />
                </div>
                <div class="col-sm-8">
                    <paedml-form-input name="teacherEmailDomain" placeholder="Lehrer-Email-Domäne" prepend="@"
                        show={parent.data.teacherEmailEnabled} required
                        data-msg="Bitte geben Sie die Lehrer-Email-Domäne ein" />
                </div>
            </div>
            <div class="row" if={parent.moodleEnabled}>
                <div class="col-sm-4 py-2">
                    <paedml-form-input name="teacherMoodleEnabled" placeholder="Moodle aktivieren" type="checkbox"
                        revlut="jaNein" />
                </div>
                <div class="col-sm-8">
                    <paedml-form-input name="teacherMoodleDomain" placeholder="Lehrer-Moodle-Domäne" type="select"
                        values={parent.domains} show={parent.data.teacherMoodleEnabled} required
                        data-msg="Bitte geben Sie die Moodle-Email-Domäne ein" />
                </div>
            </div>
        </div>
        <div class="paedml-input-group" show={parent.sysadm}>
            <div class="pb-1">
                Schüler:
            </div>
            <div class="row">
                <div class="col-sm-2 pr-0">
                    <paedml-form-input name="studentUsernamePrefix" label="Präfix" data-rule-prefix
                        data-msg-prefix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang."
                        maxlength="5" data-rule-maxlength="4"
                        data-msg-maxlength="Das Präfix darf nicht mehr als 4 Zeichen enthalten!" />
                </div>
                <div class="col-sm-4 px-0">
                    <paedml-form-input name="studentUsernamePolicy" label="Schema" required
                        data-msg="Bitte geben das Sie Schema für die Schüler-Benutzerkonten ein" data-rule-schema
                        data-msg-schema="Erlaubt sind: n, N, v, V. Optional kann ein &quot;.&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende." />
                </div>
                <div class="col-sm-2 pl-0">
                    <paedml-form-input name="studentUsernameSuffix" label="Suffix" data-rule-suffix
                        data-msg-suffix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Ende."
                        maxlength="5" data-rule-maxlength="4"
                        data-msg-maxlength="Das Suffix darf nicht mehr als 4 Zeichen enthalten!" />
                </div>
                <div class="col-sm-4">
                    <paedml-form-input name="studentDefaultPassword" label="Basiskennwort" required
                        data-msg="Bitte geben Sie das Basiskennwort für Schüler-Benutzerkonten ein"
                        data-rule-minlength="{parent.minPwLengthStudent()}"
                        data-msg-minlength="Das Basiskennwort muss mindestens {parent.minPwLengthStudent()} Zeichen haben!"
                        data-rule-maxlength="64"
                        data-msg-maxlength="Das Basiskennwort darf nicht mehr als 64 Zeichen enthalten!" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <paedml-form-input name="studentPasswordLength" label="Mindestkennwortlänge" required
                        data-msg="Bitte geben Sie die Mindestkennwortlänge für die Schüler-Benutzerkonten ein"
                        data-rule-min="{parent.minPwLengthStudent()}"
                        data-msg-min="Mindestkennwortlänge darf nicht kleiner sein als {parent.minPwLengthStudent()}"
                        data-rule-max="64" data-msg-max="Mindestkennwortlänge darf nicht grösser sein als 64" />
                </div>
                <div class="col-sm-8">
                    <paedml-form-input type="select" name="studentDefaultPasswordPolicy" label="Kennwortoption" required
                        values={parent.pwPolicyValues} />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 py-2">
                    <paedml-form-input name="studentMailEnabled" placeholder="Email erstellen" type="checkbox"
                        revlut="jaNein" />
                </div>
                <div class="col-sm-8">
                    <paedml-form-input name="studentMailDomain" placeholder="Schüler-Email-Domäne" prepend="@"
                        show={parent.data.studentMailEnabled} required
                        data-msg="Bitte geben Sie die Schüler-Email-Domäne ein" />
                </div>
            </div>
            <div class="row" if={parent.moodleEnabled}>
                <div class="col-sm-4 py-2">
                    <paedml-form-input name="studentMoodleEnabled" placeholder="Moodle aktivieren" type="checkbox"
                        revlut="jaNein" />
                </div>
                <div class="col-sm-8">
                    <paedml-form-input name="studentMoodleDomain" type="select" values={parent.domains}
                        show={parent.data.studentMoodleEnabled} required
                        data-msg="Bitte geben Sie die Moodle-Email-Domäne ein" />
                </div>
            </div>
        </div>
    </yield>
</paedml-modal> 
    
<script>
  import store from '../store'
  import { client } from '../util/api-util'
  import * as mapper from '../mapper'
  import * as R from 'ramda'
  import Session from '../store/session'
  import Masterdata from '../config/masterdata'
  import API from '../api'
  import { isDisabled, usingExternalConfig } from '../util/config-util'

  this.reduxConnect(state => ({ user: state.user, page: state.page }));

  this.opname = this.opts.opname
  this.edit = this.opts.edit ? true : false
  this.data = {}
  this.domains = []
  this.api = this.opts.api
  this.session = Session
  this.pwPolicyValues = {
      1: 'Kennwort läuft nie ab',
      2: 'Kennwortänderung erzwingen',
      3: 'Kennwort nicht änderbar'
  }
  this.firewalls = []
  this.moodleEnabled = Session.feSettings.extensions.enableMoodle;
  this.sysadm = false
  this.fwadm = false

   this.isSysAdm = () => {
      if (!this.opts.user) return false
      const { roles } = this.opts.user
      return roles[0].id === 1
  }

  this.minPwLengthAdmin = () => usingExternalConfig("basis", "minPwLengthAdmin", 6)
  this.minPwLengthExam = () => usingExternalConfig("basis", "minPwLengthExam", 4)
  this.minPwLengthTeacher = () => usingExternalConfig("basis", "minPwLengthTeacher", 4)
  this.minPwLengthStudent = () => usingExternalConfig("basis", "minPwLengthStudent", 4)

  this.isFwAdm = () => {
      if (!this.opts.user) return false
      const { roles } = this.opts.user
      return roles[0].id === 16
  }

  this.isSophosFirewall = () => {
      if (!this.data.firewallType) return false;
      return R.startsWith('SOPHOS', this.data.firewallType)
  }

  const covertingBooleans = R.mapObjIndexed(v => {
      if (v === 'true' || v === 'True') return true
      if (v === 'false' || v === 'False') return false
      return v
  })

  this.on('dataRequested', () => {
      this.sysadm = this.isSysAdm()
      this.fwadm = this.isFwAdm()
      this.firewallVisible = this.sysadm || this.fwadm

      this.getDomains();
      this.getFirewalls();

      this.data = covertingBooleans(Session.settings[0])

      if (this.data.studentDefaultPasswordPolicy === 0 || this.data.studentDefaultPasswordPolicy === '0') this.data.studentDefaultPasswordPolicy = 2
      if (this.data.teacherDefaultPasswordPolicy === 0 || this.data.teacherDefaultPasswordPolicy === '0') this.data.teacherDefaultPasswordPolicy = 2
  })

  this.getDomains = () => {
      if (this.fwadm) return;
      API.moodle.domain.getAll().then(rsp => {
          const [data, status] = rsp
          this.domains = data.map(domain => [domain.domain, domain.domain])
          this.update()

          $('select[name=teacherMoodleDomain]').val(this.data.teacherMoodleDomain)
          $('select[name=studentMoodleDomain]').val(this.data.studentMoodleDomain)
      })
  }

  this.getFirewalls = () => {
      API.fw.get().then(rsp => {
          const [data, status] = rsp
          this.firewalls = data.map(firewall => [firewall.type, firewall.name])
          this.update()

          $('select[name=firewallType]').val(this.data.firewallType)
      })
  }

  this.submitHandler = formData => {
      const sData = R.mergeRight(this.data, formData)

      const op = this.sysadm ? () => API.settings.update(0, sData)
          : () => API.fwsettings.update(({
              firewallType: sData.firewallType,
              firewallInstanceName: sData.firewallInstanceName,
              sophosApiUrl: sData.sophosApiUrl,
              sophosApiToken: sData.sophosApiToken,
              sophosXgApiUsername: sData.sophosXgApiUsername
          }))
      return op().then(rsp => {
          const [data, status] = rsp
          if (status.success) {
              window.request.relogin()
              return 'Änderungen erfolgreich durchgeführt'
          }

          console.error('error-status', status)
          console.error('error-data', data)
          if (data.message) {
              return Promise.reject('Änderungen konnten nicht durchgeführt werden! Grund: ' + data.message)
          }
          return Promise.reject('Änderungen konnten nicht durchgeführt werden!')
      })
  }     
</script>
</paedml-modal-basis>
