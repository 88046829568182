<paedml-modal-usersync>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 0}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true}
                >

    <yield to="fields">
      <div class="row">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">Es werden Jobs erstellt, um {patient.plural} zu synchronisieren.</span>
          <p></p>
          <paedml-form-input name="redirect" placeholder="Nach der Ausführung zur 'JobQueue'-Ansicht springen" fgclass="py-1"
            type="checkbox" revlut="jaNein"
            />
        </div>
      </div>
    
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'

    this.prefix = opts.prefix
    this.data = { }
    this.api = this.opts.api
    this.variant = this.opts.variant
    this.patient = this.opts.patient || this.opts.variant === 'schueler' ? {singular: 'Schüler*in', plural: 'Schüler*innen'} : {singular: 'Lehrer*in', plural: 'Lehrer*innen'}

    this.on('dataRequested', (selectedRows) => {
      this.data.redirect = true
    })

    this.on('hide', () => {
      this.text = ''
    })
    
    this.submitHandler = formData => {
      const op = this.variant === 'schueler' ? 'student' : 'lehrer'

      return API[op].sync().then(rsp => {
        
        if (rsp[1].error) {
          return Promise.reject('Kommunikationsfehler mit dem Server')
        }

        if (this.data.redirect) setTimeout(() => { 
          $('body').removeClass('modal-open')
          window.location.href = '/#jobverwaltung/jobs' 
        }, 500)

        return "Synchronisation erfolgreich eingeleitet."
      })
    }

    const formatter = patientFormatter(this.patient, 'gestartet')

  </script>
</paedml-modal-usersync>
