import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-createoredit-klassen.tag'
import './paedml-modal-info-klasse.tag'
import './paedml-modal-fav-klasse.tag'
import './paedml-modal-handout.tag'
import './paedml-modal-accesslist-meineklassen.tag'
import './paedml-modal-edit-meineklassen.tag'
import './paedml-modal-fix-perms-klassen.tag'

<paedml-card-klassen>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>
    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Klasse anlegen" />
      <paedml-modal-createoredit-klassen prefix="{prefix}-create" usersuffix="{prefix}" 
        heading="{config.contentTitle}: Klasse anlegen" opname="Klasse anlegen" api={restApi} />
    </virtual>

    <virtual if="{isPermitted('fav')}">
      <paedml-icon-modal icon="fas-star" target="#{prefix}-editfav-modal" title="Meine Klassen verwalten" />
      <paedml-modal-edit-meineklassen prefix="{prefix}-editfav"
        heading="{config.contentTitle}: Meine Klassen verwalten" opname="Änderungen übernehmen" api={restApi} />
    </virtual>

    <virtual if="{canSeeInfo()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Klassen-Info ansehen" />
      <paedml-modal-info-klasse prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Info" opname="OK" api={restApi} />
    </virtual>

    <virtual if="{selectedOneOrMore() && isPermitted('handout')}">
      <paedml-icon-modal icon="fas-file-upload" target="#{prefix}-handout-modal" title="Dateien austeilen" />
      <paedml-modal-handout prefix="{prefix}-handout" variant="klasse" selectedrowsfunc={getSelectedRows}
        patient="{{singular: 'Klasse', plural: 'Klassen'}}"
        heading="{config.contentTitle}: Dateien austeilen" opname="Dateien austeilen" api={restApi} idtextcol="7" />
    </virtual>
  
    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Klasse bearbeiten" />
      <paedml-modal-createoredit-klassen prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Klasse bearbeiten" opname="Änderung speichern" api={restApi} />
    </virtual>

    <virtual if="{selectedExactlyOne() && isPermitted('fwaccess') && parent.canEditWhitelist(this)}">
      <paedml-icon-modal icon="fas-list" target="#{prefix}-edit-fw-modal" title="Whitelist bearbeiten" />
      <paedml-modal-accesslist-meineklassen prefix="{prefix}-edit-fw" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Whitelist bearbeiten" opname="Änderung speichern" api={restApi} />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Klassen löschen" />
      <paedml-modal-delete idtextcol="7" patient="{{singular: 'Klasse', plural: 'Klassen', as: 'die'}}" heading="{config.contentTitle}: Klasse(n) löschen" 
      prefix="{prefix}-delete" selectedrowsfunc={getSelectedRows}
        api={restApi} relogin={true} doredirect={true}/>
    </virtual>

    <virtual if="{selectedOneOrMore() && isPermitted('cleanup')}">
      <paedml-icon-modal icon="fas-folder-open" target="#{prefix}-fix-modal" title="Tauschverzeichnis bearbeiten" />
      <paedml-modal-fix-perms-klassen idtextcol="7" prefix="{prefix}-fix" edit={true} selectedrowsfunc={getSelectedRows}
        variant="cleanup" heading="{config.contentTitle}: Tauschverzeichnis bearbeiten" opname="Korrekturen ausführen" api={restApi} />
    </virtual>

  </paedml-card>

  <script>
    import Session from '../store/session'

    this.session = Session

    this.prefix = opts.prefix

    this.isTeacher = () => Session.user.roleAbbrev === 'L'
    this.isAdmin = () => Session.user.roleAbbrev === 'FADM' || Session.user.roleAbbrev === 'SYSA' || Session.user.roleAbbrev === 'SADM'  || Session.user.roleAbbrev === 'RADM'

    this.canEditWhitelist = (ctx) => this.isAdmin() || ctx.isSchoolSettingMatchingValue(1, 'teacherWhitelistAccessEnabled', 'true')

  </script>
</paedml-card-klassen>
