<paedml-modal-people-picker>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={true}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient} large={true}
                cancelbutton={true} okcondition={okcondition}
                >

    <yield to="fields">
      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
          <p class="my-auto">Liste der Benutzer wird aktualisiert...</p>
          <div class="sk-wave">
            <div class="sk-rect sk-rect1"></div>
            <div class="sk-rect sk-rect2"></div>
            <div class="sk-rect sk-rect3"></div>
            <div class="sk-rect sk-rect4"></div>
            <div class="sk-rect sk-rect5"></div>
          </div>
        </div>

      <div class="d-flex" show={!parent.loading}>
        <div style="width: 45%;">
          <div class="row" if="{parent.variant === 'members'}">
            <div class="col-md-12 col-lg-5">
              <strong>Noch nicht enthalten</strong>
            </div>
            <div class="col-md-12 col-lg-7 col-xl-7">
              <paedml-form-input placeholder="Ganze Klasse hinzufügen" small={true}
                type="select" values={parent.getClassesMapped()}
                handlechange={parent.handleSourceSchoolClassChange}
              />
            </div>
            <div class="col-md-12 col-lg-7 col-xl-7 offset-lg-5">
              <paedml-form-input placeholder="Ganzes Projekt hinzufügen" small={true}
                type="select" values={parent.getProjects()}
                handlechange={parent.handleSourceProjectChange}
              />
            </div>
          </div>
          <table id="{opts.prefix}-source-modal-table" style="width: 45%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningSource} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningSource}</strong> </span>
          </div>
        </div>
        <div style="width: 10%;">
          <div class="d-flex justify-content-center pt-5 mt-5">
            <paedml-icon icon="fas-long-arrow-alt-right" inv={true} title="Ausgewählte(n) Benutzer hinzufügen" onclick={parent.moveSource} />
          </div>
          <div class="d-flex justify-content-center">
            <paedml-icon icon="fas-long-arrow-alt-left" inv={true} title="Ausgewählte(n) Benutzer entfernen" onclick={parent.moveTarget} />
          </div>
        </div>
        <div style="width: 45%;">
          <div class="row" if="{parent.variant === 'members'}">
            <div class="col-md-12 col-lg-5">
              <strong>Enthalten</strong>
            </div>
            <div class="col-md-12 col-lg-7 col-xl-7">
              <paedml-form-input placeholder="Ganze Klasse entfernen" small={true}
                type="select" values={parent.getClassesMapped(false)}
                handlechange={parent.handleTargetSchoolClassChange}
              />
            </div>
            <div class="col-md-12 col-lg-7 col-xl-7 offset-lg-5">
              <paedml-form-input placeholder="Ganzes Projekt entfernen" small={true}
                type="select" values={parent.getProjects(false)}
                handlechange={parent.handleTargetProjectChange}
              />
            </div>
          </div>
          <table id="{opts.prefix}-target-modal-table" style="width: 100%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningTarget} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningTarget}</strong> </span>
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>

  <script>
    import * as i18n from '../config/i18n/german'
    import * as R from 'ramda'
    import { toTrueMap } from '../util/mapping-util'
    import API from '../api'
    import { patientFormatter, handleSubmitEdit } from '../util/api-util'
    import Session from '../store/session'
    
    this.op = opts.op
    this.prefix = opts.prefix
    this.variant = opts.variant
    this.target = opts.target || 'schueler'
    this.loadStudents = R.includes('schueler', this.target)
    this.loadTeachers = R.includes('lehrer', this.target)
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient || {singular: 'Schüler', plural: 'Schüler*innen'}
    
    this.okcondition = () => !(this.data.warningSource || this.data.warningTarget)

    this.on('update', () => {
      this.loading = false
    })

    this.getClassesMapped = (add = true) => {
      const klassen = [[' ','Ganze Klasse ' + (add ? 'hinzufügen' : 'entfernen')]]

      R.forEachObjIndexed((klasse, schulartId) => {
        if (schulartId !== 'undefined') {
          const schulart = Session.map.schularten[schulartId]
          klasse.forEach(v => {
            const klasse = `${schulart}_${v[1]}`
            klassen.push([klasse, klasse])
          })
        }
      })(Session.lut.klassenBySchulart)
      return klassen
    }

    this.handleSourceSchoolClassChange = (val) => {
      if (val === ' ' || val == null) {
        this.tableSource.columns( 2 ).search('').draw()
        return
      }
      this.tableSource.columns(2).search('^' + val + '$', true, false).draw()
      this.tableSource.rows({filter: 'applied'}).select()
      this.tableSource.columns(2).search('').draw()
      this.moveRows(this.tableSource, this.tableTarget)
    }

    this.handleTargetSchoolClassChange = (val) => {
      if (val === ' ' || val == null) {
        this.tableTarget.columns( 2 ).search('').draw()
        return
      }
      this.tableTarget.columns(2).search('^' + val + '$', true, false).draw()
      this.tableTarget.rows({filter: 'applied'}).select()
      this.tableTarget.columns(2).search('').draw()
      this.moveRows(this.tableTarget, this.tableSource)
    }

    this.getProjects = (add = true) => {
      const projects = [[' ','Ganzes Projekt ' + (add ? 'hinzufügen' : 'entfernen')]]
      R.forEach((project) => {
        projects.push([project.name, project.name])
      }, Session.projects)
      return projects
    }

    this.handleSourceProjectChange = (val) => {
      if (val === ' ' || val == null) {
        this.tableSource.columns(1).search('').draw()
        return
      }

      R.forEach((member) => {
        this.tableSource.columns(1).search(member).draw()
        this.tableSource.rows({filter: 'applied'}).select()
        this.tableSource.columns(1).search('').draw()
        this.moveRows(this.tableSource, this.tableTarget)
      }, Session.map.projectsWithMembers[val])
    }

    this.handleTargetProjectChange = (val) => {
      if (val === ' ' || val == null) {
        this.tableTarget.columns(1).search('').draw()
        return
      }
      R.forEach((member) => {
        this.tableTarget.columns(1).search(member).draw()
        this.tableTarget.rows({filter: 'applied'}).select()
        this.tableTarget.columns(1).search('').draw()
        this.moveRows(this.tableTarget, this.tableSource)
      }, Session.map.projectsWithMembers[val])
    }

    this.moveRows = (from, to) => {
      const selected = from.rows( { selected: true } )
      const selectedRows = selected.data()
      const targetRow = to.row
      selectedRows.each((e,i) => {
        targetRow.add(e)
      })
      selected.remove()

      from.draw()
      to.draw()
      $('input[type=search]').val('').trigger('keyup')
      $('select[ref=input]').val(' ').trigger('change')
    }

    this.moveSource = () => {
      this.moveRows(this.tableSource, this.tableTarget)
    }

    this.moveTarget = () => {
      this.moveRows(this.tableTarget, this.tableSource)
    }

    this.mapStudent = (e, ro = false) => ({ 
      id: e.id,
      dp: `${e.givenName} ${e.surname} (${e.userName})`,
      kl: e.mainSchoolType ? e.mainSchoolType + ' *' : e.schoolType === undefined ? 'KEIN_ZUGRIFF' : e.schoolType + '_' + e.schoolClass,
      ro: ro
    })

    this.load = () => {
      if (this.loadTeachers) {
        return API.lehrer.getAll().then(lrsp => {
          const [lsource, status] = lrsp
          if (this.loadStudents) {
            return API.student.getAll().then(srsp => {
              const [ssource, status] = srsp
              const source = R.union(lsource, ssource)
              return [source, status]
            })
          } else {
            return lrsp
          }
        })
      } else {
        return API.student.getAll()
      }
    }

    this.getTableSource = () => this.tableSource
    this.getTableTarget = () => this.tableTarget

    this.refreshSource = (existing) => {
      this.loading = true
      this.load().then(rsp => {
        this.loading = false
        const [source, status] = rsp

        const idLut = source.reduce((acc,e) => {
          acc[e.id] = e
          return acc
        }, {})

        const exIds = {}
        const targetList = existing.map(e => {
          exIds[e.id] = true
          const access = idLut[e.id]
          return access === undefined ? this.mapStudent(e, true) : this.mapStudent(idLut[e.id])
        })

        const sourceList = source.filter(e => !exIds[e.id]).map(this.mapStudent)
        const selector = `#${opts.prefix}-source-modal-table`
        this.tableSource = $(selector).DataTable( {
          data: sourceList,
          columns: [
            { data: 'id', title: 'id', visible: false, searchable: false },
            { data: 'dp', title: 'Benutzer' },
            { data: 'kl', title: 'Schulart/Klasse' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          order: [[ 2, 'asc' ], [ 1, 'asc' ]],
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableSource()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningSource = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningSource = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningSource = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningSource = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        const sourceNode = $(selector + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
        if (this.variant !== 'members')
          sourceNode.html('<strong>Noch nicht enthalten</strong>')
        $(selector + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-7 col-xl-7')

        const selectorTarget = `#${opts.prefix}-target-modal-table`
        this.tableTarget = $(selectorTarget).DataTable( {
          data: targetList,
          columns: [
            { data: 'id', title: 'id', visible: false, searchable: false },
            { data: 'dp', title: 'Benutzer' },
            { data: 'kl', title: 'Schulart/Klasse' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          order: [[ 2, 'asc' ], [ 1, 'asc' ]],
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableTarget()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningTarget = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningTarget = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningTarget = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningTarget = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        const targetNode = $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
        if (this.variant !== 'members')
          targetNode.html('<strong>Enthalten</strong>')
        $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-6 col-xl-7')
        
        this.update()
      })
    }

    this.on('hide', () => {
      if (this.tableSource) this.tableSource.destroy()
      this.tableSource = null
      if (this.tableTarget) this.tableTarget.destroy()
      this.tableTarget = null
    })

    this.on('rowMappingRequested', cols => {
      this.loading = true
      this.api.get(cols[0]).then(rsp => {
        this.loading = false
        const [entity, status] = rsp
        if (status.error) {
          this.update()
        }
        this.data.entity = entity
        this.refreshSource(this.variant === 'members' ? entity.members : entity.leaders )
      })
    })

    this.mapProject = e => ({
      name: e[0],
      description: e[1]
    })

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const entityId = this.data.entity.id
      
      const members = []
        this.tableTarget.data().each(e => {
          members.push(e.id)
        })
      
      return API[this.op][this.variant](entityId, members).then(rsp => {
          return handleSubmitEdit(this, rsp, undefined, undefined, true)
        })
    }

  </script>
</paedml-modal-people-picker>
