import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-computer>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="name" col="1" label="Name"
              readonly={true}
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="description" col="10" label="Beschreibung"
              readonly={false}
              maxlength="251" data-rule-maxlength="250" data-msg-maxlength="Die Beschreibung darf nicht länger als 250 Zeichen sein."
            />
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client, handleSubmitCreateOrEdit } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      this.on('dataRequested', () => {
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
      })

      this.submitHandler = formData => {
       
        return handleSubmitCreateOrEdit(this, data => `Computer "${data.name}"`, data => `Computer "${data.name}"`, true)(formData)
      }
    </script>
</paedml-modal-edit-computer>
