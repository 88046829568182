import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-info-handout>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} large={true} extra="{membersCount > 25}">
  
    <yield to="fields">
      <div class="row">
        <div class="col-sm-6 col-xl-6">
         <paedml-form-input name="name" col="1" label="Name" readonly />
        </div>
        <div class="col-sm-6 col-xl-6">
          <paedml-form-input name="initiatorDP" col="4" label="Ausgeteilt von" readonly />
        </div>
      </div>

      <div class="row pb-2" if={parent.membersCount > 0}>
        <div class="col-sm-12 pt-2 paedml-exam-text">
          {parent.membersCount} Teilnehmer:
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3" each="{m in parent.data.users}">
          <p class="paedml-exam-user">
            <span>{m.name}</span><br/>
            <span class="paedml-exam-username">{m.userName}</span>
          </p>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
  <script>
    import {autoMapRowToData} from '../util/modal-util'
    
    this.opname = this.opts.opname
    this.data = {}

    this.membersCount = 0

    this.on('rowMappingRequested', cols => {
      this.membersCount = 0
      autoMapRowToData(this, cols)
      this.data.users = cols[8].map((e,i) => {
        let name = e ? `${e.givenName} ${e.surname}` : 'UNBEKANNT'
        
        return {...e, name}
      })
      this.membersCount = this.data.users.length
    })

    </script>
</paedml-modal-info-handout>
