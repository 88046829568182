import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-versetzung>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="targetSchoolYear" label="Ziel-Schuljahr"
              readonly
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="targetSchoolType" label="Ziel-Schulart" ref="targetSchoolType"
              type="select" values={parent.session.map.schulartenLong} revlut="schularten"
              required data-msg="Bitte wählen Sie die Schulart aus"
              handlechange={parent.handleSchoolTypeChange}
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="targetSchoolClass" label="Ziel-Klasse" if={!parent.createNewSchoolClass}
              type="select" values={parent.getSchoolClassValues()}
              required data-msg="Bitte wählen Sie eine Klasse aus"
              handlechange={parent.handleSchoolClassChange}
              ref="klasse"
            />
            <paedml-form-input name="newTargetSchoolClass" label="Neue Klasse" if={parent.createNewSchoolClass} 
              required data-msg="Bitte geben Sie eine Klasse an"
              data-rule-alphanum data-msg-alphanum="Nur Buchstaben und Zahlen sind erlaubt!"
              data-rule-maxlength="20" data-msg-maxlength="Erlaubt sind maximal 20 Zeichen."
            />
            <paedml-form-input name="createNewSchoolClass" placeholder="Klasse erstellen"
              type="checkbox" revlut="jaNein" handlechange={parent.newSchoolClassChanged} value={false}
            />
          </div>
        </div>
      </div>
    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session
      this.variant = this.opts.variant === 'relocation' || this.opts.variant === 'klassen' ? this.opts.variant : 'move'
      this.createNewSchoolClass = false

      this.getSchoolClassValues = () => 
        (this.session.lut.klassenBySchulart[this.data.targetSchoolType] || []).map(v => [v[0], v[1]])

      this.handleSchoolTypeChange = (val) => {
        const existingClasses = (this.session.lut.klassenBySchulart[this.data.targetSchoolType] || []).map(v => v[0])
        const settings = Session.settings[this.data.targetSchoolType]
        this.data.targetSchoolYear = this.variant === 'relocation' || this.variant === 'klassen' ? settings.nextSchoolYear : settings.currentSchoolYear
        if (!R.includes(this.data.targetSchoolClass)(existingClasses)) {
          this.data.targetSchoolClass = ''
          $('select[name=targetSchoolClass]').removeClass('is-valid').addClass('is-invalid')
        }
      }

      this.handleSchoolClassChange = val => {
        $('select[name=targetSchoolClass]').removeClass('is-invalid').addClass('is-valid')
      }

      this.newSchoolClassChanged = (val) => {
          this.createNewSchoolClass = val
      }

      const firstOrEmptySelection = (name, map, callback) => {
        if (this.data[name]) return
        const keys = R.keys(map)
        this.data[name] = keys.length === 1 ? keys[0] : ''
        if (callback && keys.length === 1) {
          callback(keys[0])
        }
      }

      this.on('dataRequested', () => {
        firstOrEmptySelection('targetSchoolType', Session.map.schularten, this.handleSchoolTypeChange)
      })

      this.on('rowMappingRequested', cols => {
        if(this.opts.variant === 'klassen' )
        {
          this.data.schoolClass = cols[0]
        }
        autoMapRowToData(this, cols)

        // FIXME: workaround of backend API change
        const klassen = Session.lut.klassenBySchulart[this.data.targetSchoolType] || []
        const klassenId = klassen.find(e => e[1] === this.data.targetSchoolClass)

        this.data.targetSchoolClass = klassenId ? klassenId[0] : ''
      })

      this.submitHandler = formData => {
        const {targetSchoolYear} = formData
        const targetSchoolType = Session.map.schularten[formData.targetSchoolType]
        const targetSchoolClass = formData.createNewSchoolClass ? formData.newTargetSchoolClass : R.find(v => v[0] == formData.targetSchoolClass)(R.values(Session.lut.klassenBySchulart[formData.targetSchoolType]))[1]
        const reqData = this.variant === 'klassen'
        ? {
          schoolClass: formData.ids[0],
          targetSchoolType,
          targetSchoolClass
        }
        : R.map(userName => ({
          userName,
          targetSchoolType,
          targetSchoolYear,
          targetSchoolClass
        }), formData.ids)

        return this.api.create(reqData).then(rsp => {
          const [data, status] = rsp
          this.parent.parent.opts.siblings.forEach(card => {
            card.tags['paedml-card'].trigger('reloadTable')
          })
          if (status.success) {
            return this.variant === 'klassen' ?  `Schüler*in(nen) in der Klasse erfolgreich für Versetzung(en) aufbereitet` : `Schüler*in(nen) erfolgreich für Versetzung(en) aufbereitet`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Aufbereitung fehlgeschlagen. Grund: ${reason}`)
        })

      }
    </script>
</paedml-modal-edit-versetzung>
