import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-createoredit-profil>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="schoolType" col="4" label="Schulart" ref="schoolType"
              type="select" values={parent.getSchoolTypeMap()} revlut="schularten"
              required data-msg="Bitte wählen Sie die Schulart aus"
              readonly={parent.edit}
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="platform" col="6" label="Plattform"
              type="select" values={parent.session.map.platforms}
              required data-msg="Bitte wählen Sie die Plattform aus"
              readonly="{! (parent.isAction('create') || parent.isAction('reset'))}"
            />
          </div>
        </div>
      </div>

      <virtual if="{!parent.isAction('reset')}">
        <div class="paedml-input-group">
          <div class="row">
            <div class="col-sm-12">
              <paedml-form-input name="name" col="1" label="Name"
                required data-msg="Bitte geben Sie den Namen ein"
                readonly="{! (parent.isAction('create') || parent.isAction('duplicate'))}"
                data-rule-project data-msg-project='Erlaubt sind max. 30 Zeichen: Buchstaben, Ziffern, Umlaute. Optional kann &quot;_&quot; verwendet werden, jedoch nicht am Anfang und Ende.'
                handlechange={parent.handleNameChange}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <paedml-form-input name="description" col="2" label="Beschreibung"
                readonly="{! (parent.isAction('create') || parent.isAction('edit') || parent.isAction('duplicate'))}"
              />
            </div>
          </div>
        </div>

        <div class="paedml-input-group" if="{parent.isAction('create')}">
          <div class="row">
            <div class="col-sm-12">
              <paedml-form-input name="copyAProfProfile" placeholder="Aktuellen Stand des AProf-Benutzers als Vorlage verwenden" fgclass="py-1"
                  type="checkbox" revlut="jaNein"
              />
            </div>
          </div>
        </div>

        <div class="paedml-input-group" show="{parent.isAction('load')}">
          <div class="row">
            <div class="col-sm-12">
              <paedml-form-input name="allocatedToAProf" col=12 placeholder="Dem AProf-Benutzer für die Erstellung / Anpassung bereitstellen" fgclass="py-1"
                  type="checkbox" revlut="jaNein" handlechange={parent.handleLoadChange}
              />
              <paedml-form-input name="allocatedToProf" col=13 placeholder="Dem Prof-Benutzer für die Überprüfung bereitstellen" fgclass="py-1"
                  type="checkbox" revlut="jaNein" handlechange={parent.handleLoadChange}
              />
            </div>
          </div>
        </div>

        <virtual if="{parent.isAction('activate')}">
          <div class="mt-2 mr-1 p-2 border border-info">
            <span><paedml-icon icon="fas-info-circle" simple="info" />
              <strong class="text-info pl-1 pt-2">Bitte bedenken Sie, dass zurzeit pro Benutzerrolle innerhalb einer Schulart nur ein Profil pro Plattform aktiv sein kann.</strong>
            </span>
          </div>
          <div class="p-1"></div>
        </virtual>

        <virtual if="{parent.isAction('load')}">
          <div class="mt-2 mr-1 p-2 border border-info">
            <span><paedml-icon icon="fas-info-circle" simple="info" />
              <strong class="text-info pl-1 pt-2">Das &ldquo;ausgewählte Profil&ldquo; wird zur Bearbeitung in den Profilordner des &ldquo;AProf&ldquo;-Benutzers geladen. Die ursprüngliche Profilvorlage des &ldquo;ausgewählten Profils&ldquo; bleibt davon unberührt.</strong>
            </span>
            <br>
            <br>
            <span><paedml-icon icon="fas-info-circle" simple="info" />
              <strong class="text-info pl-1 pt-2">Bitte bedenken Sie, dass zurzeit innerhalb einer Schulart nur ein Profil pro Plattform dem AProf-Benutzer zugeordnet werden kann.</strong>
            </span>
          </div>
          <div class="p-1"></div>
        </virtual>
      </virtual>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client, handleSubmitCreateOrEdit, handleSubmitAction, handleSubmitActionExecutor } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import API from '../api'

      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session
      this.resolved = false
      this.action = this.opts.action

      this.isAction = matchingAction => this.action === matchingAction
      this.isSysAdm = () => Session.user.roles[0].id === 1
      this.getSchoolTypeMap = () => this.isSysAdm() ? Session.map.schulartenLongOverall : Session.map.schulartenLong

      const firstOrEmptySelection = (name, map, callback) => {
        if (this.data[name]) return
        const keys = R.keys(map)
        this.data[name] = keys.length === 1 ? keys[0] : ''
        if (callback && keys.length === 1) {
          callback(keys[0])
        }
      }

      const firstSelection = (name, map, callback) => {
        if (this.data[name]) return
        const keys = R.keys(map)
        this.data[name] = keys[0]
        if (callback) {
          callback(keys[0])
        }
      }

      this.on('dataRequested', (selected, b) => {
        if (!this.isAction('create') && !this.isAction('reset')) {
          const sel = selected[0]
          this.data.id = sel[0] || 0
          this.data.schoolType = sel[4] || 0
        }

        firstSelection('schoolType', this.getSchoolTypeMap())
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
        this.data.schoolType = cols[4] || 0
        this.data.id = cols[0] || 0
        firstSelection('schoolType', this.getSchoolTypeMap())
      }) 

      const submitHandlersByAction = {
        create: handleSubmitCreateOrEdit(this, data => `Profil "${this.data.name}"`, data => `Profil "${this.data.name}"`),
        edit: handleSubmitCreateOrEdit(this, data => `Profil "${this.data.name}"`, data => `Profil "${this.data.name}"`),
        duplicate: (formData) => {
          const {name, description, schoolType} = this.data
          const template = this.data.id
          const req = { template, name, description, schoolType }
          return handleSubmitActionExecutor(this, () => this.api.duplicate(req), "Das Profil", "dupliziert")
        },
        activate: (formData) => {
          const req = { id: this.data.id }
          return handleSubmitActionExecutor(this, () => this.api.activate(req), "Das Profil", "aktiviert")
        },
        load: (formData) => {
          const {id, allocatedToAProf, allocatedToProf} = this.data
          const req = { id, allocatedToAProf, allocatedToProf }
          return handleSubmitActionExecutor(this, () => this.api.load(req), "Das Profil", "geladen")
        },
        save: (formData) => {
          const req = { id: this.data.id }
          return handleSubmitActionExecutor(this, () => this.api.save(req), "Das Profil", "gespeichert")
        },
        reset: (formData) => {
          const { platform } = formData
          const req = { platform }
          return handleSubmitActionExecutor(this, () => this.api.reset(req), "Das Profil", "zurückgesetzt")
        },
      }

      this.submitHandler = submitHandlersByAction[this.action]
    </script>
</paedml-modal-createoredit-profil>
