import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'
import './paedml-qrcode.tag'

<paedml-modal-fav-klasse>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} patient="{{singular: 'Klasse', plural: 'Klassen'}}"
    okcondition={okcondition} cancelbutton={true}
    >
  
    <yield to="fields">

      <div class="row" if={!edit}>
        <div class="col-sm-6">
          <paedml-form-input name="schoolTypeId" col="1" label="Schulart"
            type="select" values={parent.session.map.schularten} revlut="schularten"
            required data-msg="Bitte wählen Sie eine Schulart aus"
            handlechange={parent.handleSchoolTypeChange}
            readonly={edit}
          />
        </div>
        <div class="col-sm-6">
          <paedml-form-input name="klasse" col="2" label="Klasse"
            type="select" values={parent.klassen}
            required
            show={parent.klassen.length}
          />
        </div>
      </div>

      <div class="row" show="{!edit && parent.data.schoolTypeId && parent.klassen.length === 0}">
        <div class="m-3 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" /> <strong class="text-info pl-1 pt-2">Alle Klassen in dieser Schulart sind bereits meinen Klassen zugeordnet</strong> </span>
        </div>
      </div>

      <div class="row" if={edit}>
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">{parent.text}</span>
        </div>
        <div class="m-3 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" /> <strong class="text-info pl-1 pt-2">Schüler*innen dieser Klasse(n) werden nicht mehr unter "meinen Schüler*innen" aufgelistet.</strong> </span>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import {toTrueMap} from '../util/mapping-util'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      this.klassen = []

      this.okcondition = () => this.edit || (this.data.schoolTypeId && this.klassen.length)

      this.on('dataRequested', (selectedRows) => {
        this.klassen = []
        this.data = {}

        if (this.edit) {
          if (selectedRows.length === 1) {
            this.text = `Die Klasse "${selectedRows[0][this.opts.idtextcol]}" wird aus meinen Klassen entfernt.`
          } else {
            this.text = 'Die unten aufgeführten Klassen werden aus meinen Klassen entfernt.'
          }
        }

        const val = R.keys(Session.user.schoolTypes)
        this.data.schoolTypeId = val.length === 1 ? val[0] : ''
        this.api.list().then(rsp => {
          const [klassen, status] = rsp
          this.klassenIds = klassen.map(klasse => klasse[0] + '')
          this.klassenIdsLut = toTrueMap(this.klassenIds)
          this.updateKlassen()
        })
      })

      this.on('hide', () => {
        this.text = ''
      })

      this.updateKlassen = () => {
        if (! this.data.schoolTypeId) {
          this.klassen = []
        } else {
          this.klassen = Session.lut.klassenBySchulart[this.data.schoolTypeId].filter(e => !this.klassenIdsLut[e[0]])
          if (this.klassen.length) this.data.klasse = this.klassen[0][0]
        }
        this.update()
      }

      this.on('rowMappingRequested', cols => {
        if (this.edit) autoMapRowToData(this, cols)
      })

      this.handleSchoolTypeChange = (val) => {
        this.updateKlassen()
      }

      this.submitHandler = formData => {
        const meineKlassen = this.edit ? R.without(formData.ids, this.klassenIds)
                                       : [...this.klassenIds, formData.klasse]
        return this.api.set(meineKlassen).then(rsp => {
          const [emptyContent, status] = rsp
          if (rsp.error) return Propmise.reject('Fehler bei der Kommunikation mit dem Server')

          this.parent.parent.opts.siblings.forEach(card => {
            card.tags['paedml-card'].trigger('reloadTable')
          })

          return 'Meine Klassen erfolgreich aktualisiert'
        })
      }

    </script>
</paedml-modal-fav-klasse>
