import Session from '../store/session'
import { getValue } from '../util/func-util'

export const isEnabled = (cat, name) => {
    const category = Session.feSettings[cat]
    if (category === undefined) return false
    return category[name] === true
}

export const isDisabled = (cat, name) => !isEnabled(cat, name)

export const usingExternalConfig = (cat, name, defaultValueOrFunction) => {
    const category = Session.feSettings[cat]
    if (category === undefined || category[name] === undefined) return getValue(defaultValueOrFunction)
    return category[name]
}
