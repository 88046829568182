export const software = {
    headerTitle: 'Softwareverwaltung',
    route: 'software',
    pageTitle: 'paedML - Softwareverwaltung',
    related: [
      { route: 'jobverwaltung/jobs', menuTitle: 'Jobs ansehen' },
      { route: 'raumverwaltung/raum', menuTitle: 'Räume verwalten' },
      { route: 'raumverwaltung/computer', menuTitle: 'Computer verwalten' },
    ],
    cards: [
      {
        route: 'software',
        icon: 'fas-gift',
        endpoint: '/api/software',
        tag: 'paedml-card-software',
        category: 'Softwareverwaltung',
        menuTitle: 'Softwareprodukte',
        pageTitle: 'paedML - Softwareprodukte',
        name: 'software',
        contentTitle: 'Softwareprodukte',
        prefix: 'software',
        suffix: undefined,
        preHooks: {
            list: 'software_list_preHook'
        },  
        custom: {
          variant: 'software'
        },
        table: {
          columns: [
            { title: 'Id', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Name', name: 'name', col: 1 },
            { title: 'Produkt ID', name: 'productId', col: 2, visible: true, searchable: true, printable: true, exportable: true },
            { title: 'schoolTypes', name: "schoolTypes", col: 3, visible: false, searchable: false, printable: false, exportable: false  },
            { title: 'Schulart(en)', name: "schoolTypesDP", separator: ', ', col: 4, filter: 'Schulart', filterType: 'regex' },
            { title: 'productVersion', name: 'productVersion', col: 5, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'packageVersion', name: 'packageVersion', col: 6, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Softwareversion', name: 'version', col: 7 },
            { title: 'actions', name: 'actions', col: 8, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'description', name: 'description', col: 9, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Auf x PCs installiert', name: 'installedCount', col: 10 },
            { title: 'Auf x PCs veraltet', name: 'outdatedCount', col: 11 },
            { title: 'defaultInstallAction', name: 'defaultInstallAction', col: 12, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'defaultInstallActionDP', name: 'defaultInstallActionDP', col: 13, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'defaultUpdateAction', name: 'defaultUpdateAction', col: 14, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'defaultUpdateActionDP', name: 'defaultUpdateActionDP', col: 15, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'defaultUninstallAction', name: 'defaultUninstallAction', col: 16, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'defaultUninstallActionDP', name: 'defaultUninstallActionDP', col: 17, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Hinweis', name: 'message', col: 18 },
          ],
          data: {
            url: '',
            dataSrc: ''
          },
          custom: {
            pageLength: 10
          },
          order: [[ 2, 'asc' ]],
        }
      },
      {
        route: 'softwarecomputer',
        icon: 'fas-gift',
        endpoint: '/api/software/computers',
        tag: 'paedml-card-software',
        category: 'Softwareverwaltung',
        menuTitle: 'Computer',
        pageTitle: 'paedML - Computer',
        name: 'softwarecomputer',
        contentTitle: 'Computer',
        prefix: 'softwarecomputer',
        suffix: undefined,
        preHooks: {
            list: 'devices_list_preHook'
        },  
        custom: {
          variant: 'computer'
        },
        table: {
          columns: [
            { title: 'Id', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Name', name: 'name', col: 1 },
            { title: 'Raum', name: 'roomDP', col: 2, priority: 1, filter:"Raum", dynamicfilter: "rooms", filterType: 'regex' },
            { title: 'room', name: 'room', col: 3, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Anzahl installierte Pakete', name: 'installedCount', col: 4 },
            { title: 'Anzahl veraltete Pakete', name: 'outdatedCount', col: 5 },
          ],
          data: {
            url: '',
            dataSrc: ''
          },
          custom: {
            pageLength: 10
          },
          order: [[ 2, 'asc' ]],
        }
      }
    ]
  }
  