import './paedml-icon.tag'

<paedml-tiles-home>
  <div class="paedml-tiles-container row">
    
      <div class="paedml-tile-container col-sm-6 col-md-4 col-xl-3 col-xxl-2" each={tile in entries()}>
          <div class="paedml-tile paedml-hover-pointer" onclick={gogogo} id="{prefix}-{tile.id}">
            <div class="paedml-tile-header paedml-center paedml-tile-header-{tile.ncategory}">
                <span>{tile.category}</span>
            </div>
            <div class="paedml-tile-body paedml-center">
              <div if={config.options !== undefined && config.options.showBigIcons}>
                <paedml-icon big={true} icon="{tile.icon || 'far-sad-tear'}" title="{tile.ncategory}/{tile.contentTitle}"></paedml-icon>
              </div>
            </div>
            <div class="paedml-tile-header paedml-center paedml-tile-footer-{tile.ncategory}">
                <span>{tile.contentTitle}</span>
            </div>
          </div>
      </div>

    <div class="col-1 my-sizer-element"></div>
  </div>

    <script>
      import route from 'riot-route'
      import { getAPIForCard, createDataTablesCallback } from '../util/api-util'
      import API from '../api'
      import * as R from 'ramda'

      this.requiresOpt('config');
      this.prefix = this.config.prefix;
      
      const tableConfig = this.config.table
      if (! tableConfig) throw Error("Unknown tableConfig '" + this.prefix + "'");

      this.columns = tableConfig.columns;


      this.selectedRows = []
      this.totalRows = -1;

      this.tiles = []
      this.terms = ''

      const includes = (term, content) => R.includes(term, R.toLower(content))
      const includesAny = (terms, content) => {
        for(i=0; i < terms.length; ++i) {
          if (includes(terms[i], content)) return true
        }
        return false
      }

      this.filter = ""

      this.entries = () => {
        if (this.terms === undefined || this.terms == "") return this.tiles

        const terms = R.map(R.toLower, R.split(' ',this.terms))
        return this.tiles.filter(tile => {
          return includesAny(terms, tile['category']) || includesAny(terms, tile['contentTitle'])
        })
      }

      this.gogogo = e => {
        const {tile} = e.item
        window.location = '/#' + tile.url
      }

      this.fetchTiles = () => {
        const icons = []
        $('symbol').each((i,e) => {
          icons.push($(e).attr('id'))
        })

        const filterFunc = this.filter === 'Übrige Karten' ? e => !e[5] : this.filter === 'Alle Karten' ? e => e : e => e[5]
        API.all.list().then(rsp => {
          const [list, status] = rsp
          const tiles = []
          if (list.filter(e => !e[5]).length === 0) {
            delete this.columns[5].filter
          } else {
            this.columns[5].filter = 'Favoriten'
          }

          list
            .filter(filterFunc)
            .forEach(e => {
            tiles.push({
              id: e[0],
              pseudoId: e[1],
              url: e[2],
              category: e[3],
              ncategory: e[3].replace(/[.]+/,''),
              contentTitle: e[4],
              favorite: e[5],
              selected: false,
              icon: e[7]
            })
          })
          this.tiles = R.sortWith([
            R.ascend(R.prop('category')),
          ])(tiles)
          if (tiles.length === 0) {
            this.tiles.push({ url: 'dashboard/all', contentTitle: 'Favoriten verwalten', icon: 'fas-star'})
          }
          this.totalRows = tiles.length
          this.parent.trigger('tableLoaded', { totalRows: this.totalRows})
        })
      }

      this.on('mount', function() {
        this.fetchTiles()
        const self = this
      });

      this.on('update', () => {
        setTimeout(() => {
          $('input', this.parent.tags['paedml-search'].root).focus()
        }, 500)
      })

      this.on('reload', () => {
        this.fetchTiles()
      })

      // triggered via search field
      this.on('search', function(term) {
        this.terms = term
        this.update()
      })

      // triggered via filter dropdown
      this.on('setFilter', function(colname, value) {
        const colIndex = R.findIndex(R.propEq('filter', colname))(this.columns);

        this.filter = value
        this.trigger('reload')
      })

      this.on('selectAll', function() {
        this.datatable.rows().select();
        this.selectedRows = this.datatable.rows('.selected').data();
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectFiltered', function() {
        this.datatable.rows({filter: 'applied'}).select();
        this.selectedRows = this.datatable.rows('.selected').data();
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

      this.on('selectNothing', function() {
        this.selectedRows = []
        this.parent.trigger('rowSelectionChanged', this.selectedRows)
      });

    </script>
</paedml-tiles-home>
