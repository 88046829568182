import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-edit-singlelogin.tag'
import './paedml-modal-freigabe-singlelogin.tag'

<paedml-card-singlelogin>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{isPermitted('edit') && selectedOneOrMore()}">
      <paedml-icon-modal icon="fas-toggle-on" target="#{prefix}-edit-singlelogin-modal" title="Mehrfachanmeldung verhindern / erlauben" />
      <paedml-modal-edit-singlelogin prefix="{prefix}-edit-singlelogin" selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant}
        patient="{{singular: 'Mehrfachanmeldung', plural: 'Mehrfachanmeldung', for: 'für'}}"
        heading="{config.contentTitle}: Mehrfachanmeldung verhindern / erlauben" opname="Änderung speichern" api={restApi}
      />
    </virtual>

    <virtual if="{isPermitted('freigabe') && selectedExactlyOne()}">
      <paedml-icon-modal icon="fas-check-double" target="#{prefix}-freigabe-singlelogin-modal" title="Anmeldung freigeben" />
      <paedml-modal-freigabe-singlelogin prefix="{prefix}-freigabe-singlelogin" selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant} 
        patient="{{singular: 'Anmeldung', plural: 'Anmeldung', for: 'für'}}"
        heading="{config.contentTitle}: Anmeldung freigeben" opname="Anmeldung freigeben" api={restApi}
      />
    </virtual>
  
  </paedml-card>
 
  <script>
    import API from '../api'
    this.prefix = opts.prefix
    this.variant = opts.config.custom.variant

  </script>
</paedml-card-singlelogin>
