import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-quota>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
      <div class="custom-control custom-radio">
        <input type="radio" id="{prefix}-no-quota" name="quotaEnabled" class="custom-control-input" onclick={parent.handleSelection} >
        <label class="custom-control-label" for="{prefix}-no-quota">Speicherplatz nicht beschränken</label>
      </div>
      <div class="p-2"></div>
      <div class="custom-control custom-radio">
        <input type="radio" id="{prefix}-use-quota" name="quotaEnabled" class="custom-control-input" onclick={parent.handleSelection}>
        <label class="custom-control-label" for="{prefix}-use-quota">Speicherplatz beschränken</label>
      </div>
      <div class="p-2"></div>

      <div class="paedml-input-group" show={parent.isQuotaEnabled()}>
        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="quotaLimitPrefix" label="Kontingentgrenze" 
              handleChange={parent.handleQuotaLimit}
              data-rule-quota 
              required data-msg="Bitte geben Sie eine gültige Zahl >= 1 ein"
            />
          </div>
          <div class="col-sm-3">
            <paedml-form-input name="quotaLimitUnit"  id="{prefix}-quotaLimitUnit" label="Einheit"
              type="select" values={parent.sizeDropdown} handleChange={parent.handleQuotaLimitUnit}
              required
            />
          </div>
          <div class="col-sm-5">
            <paedml-form-input disabled name="quotaLimitFormatted" label="in Bytes"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="quotaThresholdPrefix" label="Warngrenze"
              id="{prefix}-quotaThresholdPrefix"
              handleChange={parent.handleQuotaThreshold}
              data-rule-check="limitwarn" data-msg-check="Warngrenze darf die Kontingentgrenze nicht überschreiten"
              data-rule-quota 
              required data-msg="Bitte geben Sie eine gültige Zahl >= 1 ein"
            />
          </div>
          <div class="col-sm-3">
            <paedml-form-input name="quotaThresholdUnit"  id="{prefix}-quotaThresholdUnit" label="Einheit"
              type="select" values={parent.sizeDropdown} handleChange={parent.handleQuotaThresholdUnit}
              required
            />
          </div>
          <div class="col-sm-5">
            <paedml-form-input disabled name="quotaThresholdPercent" label="in Prozent"
            />
          </div>
        </div>

      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import API from '../api'
      import {isDisabled, usingExternalConfig} from '../util/config-util'

      this.userGroup = this.opts.usergroup
      
      this.prefix = opts.prefix
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session
      this.variant = opts.variant

      const numberFormat = new Intl.NumberFormat('de-DE')
      const defaultLimit = usingExternalConfig('quota', 'defaultLimit' + (this.variant === 'teachers' ? 'Teachers' : 'Students'), 52428800)
      const defaultThreshold = usingExternalConfig('quota', 'defaultThreshold' + (this.variant === 'teachers' ? 'Teachers' : 'Students'), 41943040)

      this.sizeLut = {
        KB: 1024,
        MB: 1024 * 1024,
        GB: 1024 * 1024 * 1024,
        TB: 1024 * 1024 * 1024 * 1024,
      }
      this.sizeDropdown = { 'KB' : 'KB', 'MB': 'MB', 'GB': 'GB', 'TB': 'TB' }

      this.handleSelection = () => {
        const selected = $(`input[name='quotaEnabled']:checked`).attr('id')
        this.data.quotaEnabled = selected === `${this.prefix}-use-quota`
      }

      this.setQuotaThresholdPercent = () => {
        this.data.quotaThresholdPercent = this.data.quotaLimit 
          ? (this.data.quotaThreshold * 100 / this.data.quotaLimit).toFixed(2)
          : 'UNGÜLTIG'
      }

      this.validateWarnLimitRelation = () => {
        const limitwarn = this.data.quotaThreshold <= this.data.quotaLimit
        const node = $('input[name=quotaThresholdPrefix]', this.root)
        node.data('limitwarn', limitwarn).valid()
      }

      this.setQuotaLimit = (val, unit) => {
        this.data.quotaLimit = Math.round(val.replace(',','.') * this.sizeLut[unit])
        this.data.quotaLimitFormatted = numberFormat.format(this.data.quotaLimit)
      }

      this.setQuotaThreshold = (val, unit) => {
        this.data.quotaThreshold = Math.round(val.replace(',','.') * this.sizeLut[unit])
        this.setQuotaThresholdPercent()
      }

      this.getPrefix = (val, unit) => {
        return String(val / this.sizeLut[unit]).replace('.', ',')
      }

      this.handleQuotaLimit = (val) => {
        this.setQuotaLimit(val || "1", this.data.quotaLimitUnit)
        this.validateWarnLimitRelation()
        this.setQuotaThresholdPercent()
      }
      
      this.handleQuotaLimitUnit = val => {
        this.setQuotaLimit(this.data.quotaLimitPrefix, val)
        this.validateWarnLimitRelation()
        this.setQuotaThresholdPercent()
      }

      this.handleQuotaThreshold = (val) => {
        this.setQuotaThreshold(val || "1", this.data.quotaThresholdUnit)
        this.validateWarnLimitRelation()
      }  

      this.handleQuotaThresholdUnit = val => {
        this.setQuotaThreshold(this.data.quotaThresholdPrefix, val)
        this.validateWarnLimitRelation()
      }

      this.calcUnit = val => 
        val >= 1024 * 1024 * 1024 * 1024 ? 'TB' :
        val >= 1024 * 1024 * 1024 ? 'GB' :
        val >= 1024 * 1024 ? 'MB' : 'KB'

      this.on('dataRequested', (selectedRows) => {
        const filled = selectedRows.reduce((acc, v) => {
          if (v[7] > 0) {
            acc.quotaLimit += v[7]
            acc.quotaThreshold += v[9]
            acc.count += 1
          }
          return acc
        },{quotaLimit: 0, quotaThreshold: 0, count: 0})
        const stat = {
          quotaLimit: filled.count ? Math.round(filled.quotaLimit / filled.count) : -1,
          quotaThreshold: filled.count ? Math.round(filled.quotaThreshold / filled.count) : -1,
        }

        this.data = {
          quotaEnabled: stat.quotaLimit > 0,
          quotaLimit: stat.quotaLimit >= 1024 ? stat.quotaLimit : defaultLimit,
        }
        this.data.quotaLimitFormatted = numberFormat.format(this.data.quotaLimit)
        this.data.quotaThreshold = stat.quotaThreshold >= 1024 ? stat.quotaThreshold : defaultThreshold
        this.data.quotaLimitUnit = this.calcUnit(this.data.quotaLimit)
        this.data.quotaThresholdUnit = this.calcUnit(this.data.quotaThreshold)
        this.data.quotaLimitPrefix = this.getPrefix(this.data.quotaLimit, this.data.quotaLimitUnit)
        this.data.quotaThresholdPrefix = this.getPrefix(this.data.quotaThreshold, this.data.quotaThresholdUnit)
        this.setQuotaThresholdPercent()
      })

      this.on('validate', () => {
        if (!this.data.quotaEnabled) {
          $(`#${this.prefix}-no-quota`).prop('checked', true)
        }
        const limitwarn = this.data.quotaThreshold <= this.data.quotaLimit
        $('input[name=quotaThresholdPrefix]', this.root).data('limitwarn', limitwarn)
      })

      this.isQuotaEnabled = () => {
        this.handleSelection()
        if (!this.data.quotaEnabled) {
          $(`#${this.prefix}-no-quota`).prop('checked', true)
        }
        return this.data.quotaEnabled !== false;
      }

      this.submitHandler = formData => {
        const quotaEnabled = this.data.quotaEnabled !== false
        const {quotaLimit, quotaThreshold} = this.data
        const req = {
          userIds: formData.ids,
          diskQuota: {
            limitDiskUsage: quotaEnabled,
            quotaLimit: quotaEnabled ? quotaLimit : -1,
            quotaThreshold: quotaEnabled ? quotaThreshold : -1
          }
        }

        return this.api.create(req)
            .then(rsp => {
              const [data, status] = rsp
              if (status.success) {
                this.parent.trigger('reloadTable')
                return 'Änderung(en) erfolgreich übernommen'
              }
              if (data.message) {
                return Promise.reject(data.message)
              }
              return Promise.reject('Änderung(en) konnten nicht übernommen werden!')
            })

      }
    </script>
</paedml-modal-edit-quota>
