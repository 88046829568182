import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-meineklassen>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} large={true}
    cancelbutton={true} okcondition={okcondition}>
  
    <yield to="fields">

      <div class="d-flex" show={!parent.loading}>
        <div style="width: 45%;">
          <table id="{opts.prefix}-source-modal-table" style="width: 45%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningSource} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningSource}</strong> </span>
          </div>
        </div>
        <div style="width: 10%;">
          <div class="d-flex justify-content-center pt-5 mt-5">
            <paedml-icon icon="fas-long-arrow-alt-right" inv={true} title="Ausgewählte Kategorien hinzufügen" onclick={parent.moveSource} />
          </div>
          <div class="d-flex justify-content-center">
            <paedml-icon icon="fas-long-arrow-alt-left" inv={true} title="Ausgewählte Kategorien entfernen" onclick={parent.moveTarget} />
          </div>
        </div>
        <div style="width: 45%;">
          <table id="{opts.prefix}-target-modal-table" style="width: 100%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningTarget} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningTarget}</strong> </span>
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
    import * as i18n from '../config/i18n/german'
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'
    import Masterdata from '../config/masterdata'
    import { withPseudoClientResponse } from '../api/client'

    
    this.prefix = opts.prefix
    this.variant = opts.variant
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient || {singular: 'Klasse', plural: 'Klassen'}
    
    this.okcondition = () => !(this.data.warningSource || this.data.warningTarget)

    this.on('update', () => {
      this.loading = false
    })

    this.moveRows = (from, to) => {
      const selected = from.rows( { selected: true } )
      const selectedRows = selected.data()
      const targetRow = to.row
      selectedRows.each((e,i) => {
        targetRow.add(e)
      })
      selected.remove()

      from.draw()
      to.draw()
    }

    this.moveSource = () => {
      this.moveRows(this.tableSource, this.tableTarget)
    }

    this.moveTarget = () => {
      this.moveRows(this.tableTarget, this.tableSource)
    }

    this.getTableSource = () => this.tableSource
    this.getTableTarget = () => this.tableTarget

    this.refreshSource = (existing) => {
      this.loading = true
      API.klasse.getAll().then(rsp => {
        this.loading = false
        const [source, status] = rsp
        
        const idLut = source.reduce((acc,e) => {
          acc[e.id] = {id: e.id, klasse: `${e.schoolType}_${e.name}`}
          return acc
        }, {})

        const exIds = {}
        const targetList = existing.map(e => {
          exIds[e.id] = true
          return idLut[e.id]
        })

        const sourceList = source.filter(e => !exIds[e.id]).map(e => idLut[e.id])
        const selector = `#${opts.prefix}-source-modal-table`
        this.tableSource = $(selector).DataTable( {
          data: sourceList,
          columns: [
            { data: 'id', title: 'id', visible: false, searchable: false },
            { data: 'klasse', title: 'Klasse' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          order: [[ 1, 'asc' ]],
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableSource()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningSource = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningSource = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningSource = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningSource = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        $(selector + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
          .html('<strong>Noch nicht enthalten</strong>')
        $(selector + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-6 col-xl-7')

        const selectorTarget = `#${opts.prefix}-target-modal-table`
        this.tableTarget = $(selectorTarget).DataTable( {
          data: targetList,
          columns: [
          { data: 'id', title: 'id', visible: false, searchable: false },
            { data: 'klasse', title: 'Klasse' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          order: [[ 1, 'asc' ]],
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableTarget()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningTarget = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningTarget = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningTarget = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningTarget = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
          .html('<strong>Enthalten</strong>')
        $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-6 col-xl-7')

        this.update()

      })

    }

    this.on('hide', () => {
      if (this.tableSource) this.tableSource.destroy()
      this.tableSource = null
      if (this.tableTarget) this.tableTarget.destroy()
      this.tableTarget = null
    })

    this.on('dataRequested', () => {
      this.loading = true
      API.meineklassen.getAll().then(rsp => {
        this.loading = false
        const [meineklassen, status] = rsp
        if (status.error) {
          this.update()
          return rsp
        }
        this.loading = true
        this.refreshSource(meineklassen)
      })
    })

    this.submitHandler = formData => {
        const meineklassen = []
        this.tableTarget.data().each(e => {
          meineklassen.push(e.id)
        })

        return API.meineklassen.set(meineklassen).then(rsp => {
          const [emptyContent, status] = rsp
          if (rsp.error) return Propmise.reject('Fehler bei der Kommunikation mit dem Server')

          this.parent.parent.opts.siblings.forEach(card => {
            card.tags['paedml-card'].trigger('reloadTable')
          })

          return 'Meine Klassen erfolgreich aktualisiert'
        })
      }

    </script>
</paedml-modal-edit-meineklassen>
