const thunkAction = (actionName, func) => dispatch => {
  dispatch({type: actionName})

  func()
    .then(result => dispatch({type: `${actionName}Success`, payload: result}))
    .catch(error => {
      const err = error.message ? error.message : typeof error === 'string' ? error : JSON.stringify(error)
      dispatch({type: `${actionName}Failure`, payload: err})}
    )
}

export default thunkAction
