import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-info-exam.tag'
import './paedml-modal-createoredit-exam.tag'
import './paedml-modal-people-picker.tag'
import './paedml-modal-startstop-exam.tag'
import './paedml-modal-handout.tag'
import './paedml-modal-collect.tag'

<paedml-card-exam>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Klassenarbeit anlegen" />
      <paedml-modal-createoredit-exam prefix="{prefix}-create"
        heading="Klassenarbeit anlegen" opname="Klassenarbeit anlegen" api={restApi} />
    </virtual>

    <virtual if="{canSeeInfo()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Klassenarbeit-Info ansehen" />
      <paedml-modal-info-exam prefix="{prefix}-see" edit={true}
        heading="Klassenarbeit-Info" opname="OK" api={restApi}
        selectedrowsfunc={getSelectedRows} />
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="lehrer" target="#{prefix}-manage-leaders-modal" title="Leiter*innen verwalten" />
      <paedml-modal-people-picker prefix="{prefix}-manage-leaders" lock={true} selectedrowsfunc={getSelectedRows}
        target="lehrer" variant="leaders" op="{prefix}"
        heading="Klassenarbeit: Leiter*innen verwalten" opname="Änderungen übernehmen" api={restApi} />
    </virtual>
  
    <virtual if="{canEdit() && !parent.isActive(getSelectedRows)}">
      <paedml-icon-modal icon="schueler" target="#{prefix}-manage-members-modal" title="Teilnehmer verwalten" />
      <paedml-modal-people-picker prefix="{prefix}-manage-members" selectedrowsfunc={getSelectedRows} edit={true}
        target="schueler" variant="members" op="{prefix}"
        heading="Klassenarbeit: Teilnehmer verwalten" opname="Änderungen übernehmen" api={restApi} />
    </virtual> 

    <virtual if="{selectedExactlyOne() && isPermitted('handout') && parent.hasMembers(getSelectedRows) && !parent.isActive(getSelectedRows)}">
      <paedml-icon-modal icon="fas-file-upload" target="#{prefix}-handout-modal" title="Dateien austeilen" />
      <paedml-modal-handout prefix="{prefix}-handout" variant="exam" selectedrowsfunc={getSelectedRows}
        patient="{{singular: 'Klassenarbeit', plural: 'Klassenarbeiten'}}"
        heading="Klassenarbeit: Dateien austeilen" opname="Dateien austeilen" api={restApi} />
    </virtual>

    <virtual if="{selectedExactlyOne() && isPermitted('collect') && parent.hasMembers(getSelectedRows)}">
      <paedml-icon-modal icon="fas-file-download" target="#{prefix}-collect-modal" title="Dateien einsammeln" />
      <paedml-modal-collect prefix="{prefix}-collect" selectedrowsfunc={getSelectedRows} edit={true}
        variant="exam"
        heading="Klassenarbeit: Dateien einsammeln" opname="Dateien einsammeln" api={restApi} />
    </virtual>

    <virtual if="{selectedExactlyOne() && isPermitted('run') && parent.hasMembers(getSelectedRows) && !parent.isActive(getSelectedRows)}">
      <paedml-icon-modal icon="far-play-circle" target="#{prefix}-start-modal" title="Klassenarbeit starten" />
      <paedml-modal-startstop-exam prefix="{prefix}-start" selectedrowsfunc={getSelectedRows} edit={true}
        variant="start"
        heading="Klassenarbeit starten" opname="Starten" api={restApi} />
    </virtual>

    <virtual if="{selectedExactlyOne() && isPermitted('run') && parent.hasMembers(getSelectedRows) && parent.isActive(getSelectedRows)}">
      <paedml-icon-modal icon="far-stop-circle" target="#{prefix}-stop-modal" title="Klassenarbeit beenden" />
      <paedml-modal-startstop-exam prefix="{prefix}-stop" selectedrowsfunc={getSelectedRows} edit={true}
        variant="stop"
        heading="Laufende Klassenarbeit beenden" opname="Beenden" api={restApi} />
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Klassenarbeit bearbeiten" />
      <paedml-modal-createoredit-exam prefix="{prefix}-edit" selectedrowsfunc={getSelectedRows} edit={true}
        heading="{config.contentTitle}: Klassenarbeit bearbeiten" opname="Klassenarbeit bearbeiten" api={restApi}
        fwaccess={() => isPermitted('fwaccess')} />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Klassenarbeit(en) löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Klassenarbeit(en) löschen" prefix="{prefix}-delete" 
        idcol="0" idtextcol="1"
        patient="{{singular: 'Klassenarbeit', plural: 'Klassenarbeiten', as: 'die'}}" selectedrowsfunc={getSelectedRows} api={restApi} 
        doredirect={true}/>
    </virtual>
    </div>
  </paedml-card>

  <script>
    this.isActive = (getSelectedRows) => {
      const selected = getSelectedRows()
      return selected[0][6] === 'ja'
    }

     this.hasMembers = (getSelectedRows) => {
      const selected = getSelectedRows()
      return selected[0][13].length > 0
    }

  </script>
</paedml-card-exam>
