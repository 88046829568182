import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'
import './paedml-qrcode.tag'

<paedml-modal-info-exam>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} large={true} extra="{susCount.total > 25}">
  
    <yield to="fields">
      <div class="top-right">
        <paedml-icon icon="drucker-entsperrt" inv={true} onclick={parent.printExam}/>
        <paedml-icon icon="fas-file-pdf" inv={true} onclick={parent.exportExam}/>
      </div>

      <div class="row">
        <div class="col-sm-6 col-xl-4">
         <paedml-form-input name="title" col="2" label="Titel" readonly />
        </div>
        <div class="col-sm-6 col-xl-4">
          <paedml-form-input name="leadersDP" col="9" label="Leitung" readonly />
        </div>
        <div class="col-sm-12 col-xl-4">
          <paedml-form-input name="password" col="7" label="Kennwort" readonly />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <paedml-form-input name="description" col="3" label="Beschreibung" readonly />
        </div>
      </div>

      <div class="row pb-2" if={parent.susCount.total}>
        <div class="col-sm-12 pt-2 paedml-exam-text">
          {parent.susCount.total} Teilnehmer:
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-2" each="{m in parent.data.members}">
          <p class="paedml-exam-user {m.loggedIn ? 'selected' : ''}">
            <span>{m.givenName} {m.surname}</span><br/>
            <span class="paedml-exam-username">{m.examUsername || '«KA-Konto wird erstellt»'}</span>
          </p>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
  <script>
    import {autoMapRowToData} from '../util/modal-util'
    import store from '../store'
    import { client } from '../util/api-util'
    import * as mapper from '../mapper'
    import * as R from 'ramda'
    import Session from '../store/session'
    import Masterdata from '../config/masterdata'
    import {toTrueMap} from '../util/mapping-util'
    import pdfMake from 'pdfmake'
    
    this.opname = this.opts.opname
    this.edit = this.opts.edit ? true : false
    this.data = {}
    this.api = this.opts.api
    this.session = Session

    const zeroCount = {total: 0, loggedIn: 0}

    this.susCount = zeroCount

    this.on('rowMappingRequested', cols => {
      this.susCount = zeroCount
      autoMapRowToData(this, cols)
      let loggedInCount = 0
      this.data.members = cols[13].map((e,i) => {
        const loggedIn = false
        if (loggedIn) ++loggedInCount
        let name = `${e.givenName} ${e.surname}`
        return {...e, loggedIn, name}
      })
      this.susCount = {total: this.data.members.length, loggedIn: loggedInCount}
    })

    this.exportExam = () => {
      pdfMake.createPdf(this.getDocDefinition()).download("Klassenarbeit.pdf");
    }

    this.printExam = () => {
      pdfMake.createPdf(this.getDocDefinition()).print();
    }

    this.submitHandler = formData => {
      return Promise.resolve('OK')
    }

    this.getDocDefinition = () => {
      var docDefinition = {
        content: [
          { text: `Klassenarbeit ${this.data.title} - ${this.data.description}`, fontSize: 20, bold: true, lineHeight: 1.5 },
          { text: `Kennwort: ${this.data.password}`, lineHeight: 1.5 },
          this.table(this.data.members, ['name', 'examUsername'])
         ]
      }
      return docDefinition;
    }

    this.buildTableBody = (data, columns) => {
      var body = [];

      body.push(['Name', 'Benutzername']);

      data.forEach(function(row) {
        var dataRow = [];

        columns.forEach(function(column) {
          dataRow.push(row[column].toString());
        })

        body.push(dataRow);
      });

      return body;
    }

    this.table = (data, columns) => {
      return {
        table: {
          headerRows: 1,
          widths: ['*', '*'],
          body: this.buildTableBody(data, columns)
        },
        layout: { 
          hLineWidth: function (i, node) { 
            if (i === 0 || i === node.table.body.length) { 
              return 0; 
            } 
            return (i === node.table.headerRows) ? 2 : 1; 
          }, 
          vLineWidth: function (i, node) { 
            return (i === 0 || i === node.table.widths.length) ? 0 : 1; 
          }, 
          hLineColor: function (i) { 
            return i === 1 ? 'black' : 'gray'; 
          }, 
          vLineColor: function () { 
            return 'gray'; 
          }, 
          paddingLeft: function (i) { 
            return 8; 
          }, 
          paddingRight: function (i, node) { 
            return (i === node.table.widths.length - 1) ? 0 : 8; 
          }, 
          paddingTop: function (i, node) { 
            return (i === 0) ? 0 : 10; 
          }, 
          paddingBottom: function (i, node) { 
              return (i === 0) ? 5 : 10; 
          } 
        } 
      };
    } 
     
    </script>
</paedml-modal-info-exam>
