import '../paedml-modal.tag'
import '../paedml-form-input.tag'
import '../components/paedml-date-time-picker.tag'

<paedml-modal-createoredit-projekt>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields"> 

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="name" col="1" label="Name"
              readonly={edit}
              required data-msg="Bitte geben Sie den Projektnamen ein"
              maxlength="31" data-rule-maxlength="30" data-msg-maxlength="Der Projektname darf nicht länger als 30 Zeichen sein"
              data-rule-project data-msg-project='Erlaubt sind: Buchstaben, Ziffern, Umlaute. Optional kann &quot;_&quot; verwendet werden, jedoch nicht am Anfang und Ende.'
              handlechange={parent.handleProjNameChange} changemode="keyup"
              data-rule-check="name" data-msg-check="Projektname bereits vorhanden!"
            />
            <svg if={parent.isChecking} class="paedml-icon-overlay fullrotation">
              <use xlink:href="#far-question-circle" />
            </svg>
          </div>
          <div class="col-sm-12">
            <paedml-form-input name="description" col="2" label="Beschreibung"
              required data-msg="Bitte geben Sie die Projektbeschreibung ein"
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <paedml-form-input name="sharedFolderPath" col="9" label="Tauschlaufwerk" if={edit} readonly
        />

        <paedml-form-input name="sharedFolderAccessLevel" col="10" label="Zugriff Tauschlaufwerk"
          type="select" values={parent.session.map.projectShareFolderAccessPolicy} revlut="projectShareFolderAccessPolicy"
          required data-msg="Bitte wählen Sie eine Zugriffsart aus"
        />

      </div>

      <div class="paedml-input-group">
          <paedml-form-input name="expiryDateActive" col="14" placeholder="Ablaufdatum aktiv" fgclass="py-1"
              type="checkbox" revlut="jaNein"
              handlechange={parent.handleExpiryDateActive}
          />
          <paedml-date-time-picker name="expiryDateDP1" col="12" 
              placeholder="Ablaufdatum" label="Ablaufdatum"
              allowPastDates={false}
              handlechange={parent.handleExpiryDateChanged}
              show={parent.data.expiryDateActive}
              required data-msg="Bitte geben Sie das Ablaufdatum an"
          />
          <label class="text-info" show={parent.data.expiryDateActive}>Das Projekt bleibt erhalten. Die Mitglieder werden entfernt.</label>
      </div>

    </yield>
  </paedml-modal>
    
  <script>
    import {autoMapRowToData} from '../../util/modal-util'
    import { handleSubmitCreateOrEdit, projNameAvailable, clearProjNameAvailabilityCtx } from '../../util/api-util'
    import * as R from 'ramda'
    import Session from '../../store/session'
    import moment from 'moment'
    moment.locale('de')

    this.opname = this.opts.opname
    this.edit = this.opts.edit ? true : false
    this.data = {}
    this.api = this.opts.api
    this.session = Session

    this.expiryDate = null

    const convertingBooleans = R.mapObjIndexed(v => {
      if (v === 'true' || v === 'ja') return true
      if (v === 'false' || v === 'nein') return false
        return v
    })

    this.projNameAvailable = projNameAvailable(this, 1000)

    this.handleProjNameChange = (projName) => {
      if (!projName) {
        this.isChecking = false
        this.resolved = true
        this.update()
        return
      }

      $('input[name=name]', this.root).data('name', true)
      if (/^[A-Za-z0-9ÜüÖöÄäß][-_A-Za-z0-9ÜüÖöÄäß]{0,28}[A-Za-z0-9ÜüÖöÄäß]$|^[A-Za-z0-9ÜüÖöÄäß]$/.test(projName)) {
        this.isChecking = true
        this.projNameAvailable(projName)
      }
    }

    this.handleExpiryDateActive = (val) => {
      this.data.expiryDateActive = val
    }  

    this.handleExpiryDateChanged = (dateTime) => {
      this.expiryDate = dateTime
    }

    this.on('dataRequested', () => {
      this.data.sharedFolderAccessLevel = this.data.sharedFolderAccessLevel ? this.data.sharedFolderAccessLevel : 0
      this.data.expiryDateDP1 = this.data.expiryDateDP1 ? this.data.expiryDateDP1 : ''
    })

    this.on('rowMappingRequested', cols => {
      autoMapRowToData(this, cols)
      this.data.expiryDateDP1 = cols[13] ? cols[15] : ''
    })

    this.submitHandler = formData => {

      if(formData.expiryDateActive) {
        var date = new Date(this.expiryDate)
        formData.expiryDate = date.toISOString()
      } else {
        formData.expiryDate = null
      }

      return handleSubmitCreateOrEdit(this, data => `Projekt "${data.name}"`, data => `Projekt "${data.name}"`, false, true)(formData)
    }
  </script>
</paedml-modal-createoredit-projekt>
