import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-domain-moodle>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
        <p class="my-auto">Emaildomänen werden aktualisiert...</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </div>
  
      <div class="row" if={!parent.loading}>
        <div class="col-sm-11">
          <paedml-form-input name="domain" label="Moodle-Emaildomäne"
              type="select" values={parent.getDomains()}
              required data-msg="Bitte wählen Sie eine Emaildomäne aus"
            />
        </div>
        <div class="col-sm-1 my-auto">
          <button class="btn btn-sm" onclick="{() => parent.refreshDomains(true)}" style="margin-left: -18px; margin-top: 18px;">
            <paedml-icon icon="aktualisieren" inv={true} title="Moodle-Emaildomänen aktualisieren" />
          </button>
        </div>
      </div>

      <div class="p-2"></div>
      <div class="mt-2 mr-1 p-2 border border-info">
        <span><paedml-icon icon="fas-info-circle" simple="info" />
          <strong class="text-info pl-1 pt-2">Die Emailadressen für Moodleanmeldung werden automatisch erstellt. Nach der Zuweisung der Domäne werden manuell zugewiesene Emails überschrieben!</strong>
        </span>
      </div>
      <div class="p-1"></div>

    </yield>
  </paedml-modal>
    
    <script>
      import store from '../store'
      import * as R from 'ramda'
      import API from '../api'
      import {isEnabled} from '../util/config-util'

      this.opname = this.opts.opname
      this.domain = this.opts.domain

      this.getDefault = () => ({
        domain: 'nomail.belwue.de',
        domains: ['nomail.belwue.de']
      })

      this.data = this.getDefault()
      this.api = this.opts.api
      this.patient = opts.variant === "student" ? {singular: 'Schüler', plural: 'Schüler*innen'} : {singular: 'Lehrer', plural: 'Lehrer*innen'}
      this.op = opts.variant === 'student' ? "student" : 'teacher'

      this.getDomains = () => this.data.domains ? this.data.domains.map(domains => [domains, domains])
        : ['nomail.belwue.de', 'nomail.belwue.de']

      this.refreshDomains = () => {
        this.loading = true
        API[this.domain].domain.getAll().then(rsp => {
          const [data, status] = rsp
          this.loading = false

          this.data.domains = data.map(list => list.domain)

          this.update()
        })

      }

      this.on('dataRequested', () => {
        this.data = this.getDefault()

        this.refreshDomains()
      })

      this.submitHandler = formData => {
        
        return API[this.domain][this.op].domain(formData.ids, formData.domain)
          .then(rsp => {
            const [data, status] = rsp
            if (status.success) {
              this.parent.trigger('reloadTable')
              return `Die Moodle-Emaildomäne '${formData.domain}' wurde erfolgreich zugewiesen`
            }

            if (data.message) {
              return Promise.reject(data.message)
            }
            return Promise.reject(`Die Moodle-Emaildomäne '${formData.domain}' konnte nicht zugewiesen werden`)
          })
      }
    </script>
</paedml-modal-domain-moodle>
