<paedml-modal-reset-versetzung>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler}
                cancelbutton={true}
                >

    <yield to="fields">
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'

    this.prefix = opts.prefix
    this.data = {
    }
    this.api = this.opts.api
    
    this.submitHandler = formData => {
      
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      return this.api.reset(formData.ids).then(rsp => {
        const [data, status] = rsp
        
        this.parent.parent.opts.siblings.forEach(card => {
          card.tags['paedml-card'].trigger('reloadTable')
        })
        if (status.success) {
          return `Rücksetzung erfolgreich`
        }

        console.error('error-data', data)
        console.error('error-status', status)
        const reason = data && data.message ? data.message : 'unbekannt'
        return Promise.reject(`Rücksetzung fehlgeschlagen. Grund: ${reason}`)
      })

    }

    const formatterLock = patientFormatter({singular: 'Internetsperre', plural: 'Internetsperren', for: 'für'}, 'gesetzt')
    const formatterUnlock = patientFormatter({singular: 'Internetsperre', plural: 'Internetsperren', for: 'für'}, 'entfernt')

  </script>
</paedml-modal-reset-versetzung>
