export const singlelogin = {
  headerTitle: 'Mehrfachanmeldung',
  route: 'singlelogin',
  pageTitle: 'Mehrfachanmeldung',
  related: [
    { route: 'benutzerverwaltung/lehrerinnen', menuTitle: 'Lehrer*innen verwalten' },
    { route: 'benutzerverwaltung/schuelerinnen', menuTitle: 'Schüler*innen verwalten' }
  ],
  cards: [
    {
      route: 'lehrerinnen',
      icon: 'fa-users-slash',
      endpoint: '/api/singlelogin/teachers',
      endpoints: {
      },
      preHooks: {
        list: 'singlelogin_list_preHook'
      },
      tag: 'paedml-card-singlelogin',
      category: 'Mehrfachanmeldung',
      menuTitle: 'Lehrer*innen',
      pageTitle: 'paedML - Mehrfachanmeldung Lehrer*innen',
      name: 'singleloginlul',
      contentTitle: 'Lehrer*innen',
      prefix: 'singleloginlul',
      suffix: undefined,
      custom: {
        variant: 'teachers'
      },
      table: {
        columns: [
          { title: 'Benutzername', name: 'userName', col: 0 },
          { title: 'Name', name: 'displayName', col: 1 },
          { title: 'Basis-Schulart', name: 'mainSchoolType', col: 2 },
          { title: 'Schulart(en)', name: 'schoolTypes', separator: ', ', col: 3, filter: 'Schulart', filterType: 'regex' },
          { title: 'Mehrfachanmeldung verhindert', name: 'isSingleLoginEnabled', col: 4, filter: 'JaNein', filtertitle: 'Mehrfachanmeldung verhindert' },
          { title: 'Angemeldet', name: 'isLoggedIn', col: 5 },
          { title: 'angemeldetPc', name: 'loggedOnDevices', col: 6, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Anmeldung freigegeben', name: 'allowLogin', col: 7 },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },

    {
      route: 'schuelerinnen',
      icon: 'fa-users-slash',
      endpoint: '/api/singlelogin/students',
      endpoints: {
      },
      preHooks: {
        list: 'singlelogin_list_preHook'
      },
      tag: 'paedml-card-singlelogin',
      category: 'Mehrfachanmeldung',
      menuTitle: 'Schüler*innen',
      pageTitle: 'paedML - Mehrfachanmeldung Schüler*innen',
      name: 'singleloginsus',
      contentTitle: 'Schüler*innen',
      prefix: 'singleloginsus',
      suffix: undefined,
      custom: {
        variant: 'students'
      },
      table: {
        columns: [
          { title: 'Benutzername', name: 'userName', col: 0 },
          { title: 'Name', name: 'displayName', col: 1 },
          { title: 'Schulart', name: 'schoolType', col: 2, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 3, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 4, searchable: false },
          { title: 'Mehrfachanmeldung verhindert', name: 'isSingleLoginEnabled', col: 5, filter: 'JaNein', filtertitle: 'Mehrfachanmeldung verhindert' },
          { title: 'Angemeldet', name: 'isLoggedIn', col: 6 },
          { title: 'angemeldetPc', name: 'loggedOnDevices', col: 7, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Anmeldung freigegeben', name: 'allowLogin', col: 8 },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },

    {
      route: 'klassenarbeiten',
      icon: 'fa-users-slash',
      endpoint: '/api/singlelogin/exammembers',
      endpoints: {
      },
      preHooks: {
        list: 'singlelogin_list_preHook'
      },
      tag: 'paedml-card-singlelogin',
      category: 'Mehrfachanmeldung',
      menuTitle: 'Klassenarbeits-Teilnehmer',
      pageTitle: 'paedML - Mehrfachanmeldung Klassenarbeits-Teilnehmer',
      name: 'singleloginexam',
      contentTitle: 'Klassenarbeits-Teilnehmer',
      prefix: 'singleloginexam',
      suffix: undefined,
      custom: {
        variant: 'exammembers'
      },
      table: {
        columns: [
          { title: 'Benutzername', name: 'userName', col: 0 },
          { title: 'Name', name: 'displayName', col: 1 },
          { title: 'Schulart', name: 'schoolType', col: 2, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 3, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 4, searchable: false },
          { title: 'Klassenarbeit', name: 'examTitle', col: 5 },
          { title: 'Mehrfachanmeldung verhindert', name: 'isSingleLoginEnabled', col: 6, filter: 'JaNein', filtertitle: 'Mehrfachanmeldung verhindert' },
          { title: 'Angemeldet', name: 'isLoggedIn', col: 7 },
          { title: 'angemeldetPc', name: 'loggedOnDevices', col: 8, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Anmeldung freigegeben', name: 'allowLogin', col: 9 },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
        }
      }
    },
  ]
}
