import './paedml-card.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-edit-fwsettings.tag'
import './paedml-modal-accesslist.tag'
import './paedml-modal-edit-fwcategories.tag'

<paedml-card-firewall>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings} ref='card'>

    <virtual if="{canEdit() && parent.isGlobalTemplate(getSelectedRows)}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Firewall-Basiseinstellungen bearbeiten" />
      <paedml-modal-edit-fwsettings prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Firewall-Basiseinstellungen bearbeiten" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{canEdit() && config.custom.variant !== 'projekte' && config.custom.variant != 'global'}">
      <paedml-icon-modal icon="fas-tags" target="#{prefix}-edit-category-modal" title="Firewall-Kategorien bearbeiten" />
      <paedml-modal-edit-fwcategories prefix="{prefix}-edit-category" edit={true} selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant}
        heading="{config.contentTitle}: Firewall-Kategorien bearbeiten" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="fas-list" target="#{prefix}-edit-access-modal" title="{parent.getAccessListTitle()}" />
      <paedml-modal-accesslist prefix="{prefix}-edit-access" edit={true} selectedrowsfunc={getSelectedRows}
        variant={config.custom.variant}
        heading="{config.contentTitle}: {parent.getAccessListTitle()}" opname="Änderung speichern" api={restApi}
      />
    </virtual>
  
  </paedml-card>

  <script>
    import API from '../api'
    this.prefix = opts.prefix
    this.variant = opts.config.custom.variant

    this.getAccessListTitle = () => (this.variant === 'projekte' ? '' : 'Black- / ') + 'Whitelist bearbeiten'

    this.isGlobalTemplate = (getSelectedRows) => {
      const selected = getSelectedRows()
      if (selected.length === 1 && selected[0][0] === "") {
        return true
      }
      return false
    }

  </script>
</paedml-card-firewall>
