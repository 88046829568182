<paedml-date-time-picker>
    <div class="form-group" title={opts.title || opts.placeholder || opts.label}>
        <label if="{opts.label}">{opts.label}</label>
        <input 
            ref="dpicker"
            type="text" 
            id="{inputid}-dpicker"
            name="{opts.name}"
            required={isRequired()}
            placeholder="{opts.placeholder}"
            class="form-control {opts.small ? 'form-control-sm' : ''} {getClassRequired()}"
            data-inputmask-alias="datetime" data-inputmask-inputformat="dd-mm-yyyy"
        />
    </div>

<script>
    import * as R from 'ramda'
    import {genDocId} from '../../util/crypto-util'
    import * as i18n from '../../config/i18n/german'
    import moment from 'moment'
    import InputMask from 'inputmask'

    moment.locale('de')
    DateTime.use(moment);

    this.inputid = genDocId();
    this.hasBooleanAttr = attrName => () => this.opts[attrName] !== undefined && this.opts[attrName] !== false
    this.isRequired = this.hasBooleanAttr('required')

    this.getClassRequired = () => this.isRequired() ? 'paedml-input-required' : ''
        
    this.handleChange = this.opts.handlechange
    
    this.allowPastDates = this.opts.allowPastDates
    
    this.getDatePicker = () => this.datepicker

    const datePickerProperties = {
        locale: 'de',
        i18n: i18n.datetimepicker,
        format: 'DD-MM-YYYY',
        firstDay: 1,
        onChange: (value, date, input) => {
            this.handleDateChange(value, date, input)
        }
    }

    this.updateData = () => {
        const data = $(this.root).data()
        const inp = $(this.refs.dpicker)
        R.forEachObjIndexed((value, key) => {
            inp.data(key, value)
        }, data)
    }

    this.on('mount', function() {
        const datePickerElement = document.getElementById(this.inputid+'-dpicker');
        this.updateData()
        var self = this
        InputMask({
            showMaskOnHover: false,
            showMaskOnFocus: true,
            clearMaskOnLostFocus: false,
            clearIncomplete: true,
                oncomplete: () => {
                    self.maskChanged($('#'+self.inputid+'-dpicker').val())
                },
                oncleared: function(){
                    self.maskChanged('')
                }
            }).mask(datePickerElement)
            
        this.datepicker =  new DateTime(datePickerElement, datePickerProperties)
        if(!this.allowPastDates) {
            var minDate = new Date()
            minDate.setDate(minDate.getDate() - 1)
            this.datepicker.min(minDate)
        }
    })

    this.maskChanged = (value) => {
        if(this.value === '') {
            return
        }

        if(!this.isSelectedDateValid(value)) {
            value = moment().format('DD-MM-YYYY')  
        }

        let date = moment(value, 'DD-MM-YYYY').format()  

        this.datepicker.val(value)
        this.handleDateChange(value, date, this)
    }

    this.isSelectedDateValid = (value) => {
        if(this.allowPastDates) {
            return true;
        }
        let date = moment(value, 'DD-MM-YYYY')
        return moment().subtract(1, "days").isBefore(date)
    }

    this.handleDateChange = (value, date, input) => {
        this.handleAndNotify(date, input)
    }

    this.handleAndNotify = (val, e) => {
        if (this.handleChange) {
           this.handleChange(val, this, e)
        } 
    }
    
</script>

</paedml-date-time-picker>
