
export const asMap = (keySelector, valueSelector) => list => list.reduce((acc, entry) => {
    acc[keySelector(entry)] = valueSelector(entry)
    return acc
}, {})

export const asGroups = (keySelector, valueSelector) => list => list.reduce((acc, entry) => {
    const key = keySelector(entry)
    const value = valueSelector(entry)
    const entriesByKey = acc[key]
    if (entriesByKey) {
        entriesByKey.push(value)
    } else {
        acc[key] = [value]
    }
    return acc
}, {})
