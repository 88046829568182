export const cloudverwaltung = {
  headerTitle: 'Microsoft-365',
  route: 'cloud',
  pageTitle: 'Microsoft 365',
  related: [
    { route: 'benutzerverwaltung/lehrerinnen', menuTitle: 'Lehrer*innen verwalten' },
    { route: 'benutzerverwaltung/schuelerinnen', menuTitle: 'Schüler*innen verwalten' },
    { route: 'moodle', menuTitle: 'Moodle verwalten' },
  ],
  cards: [
    {
      route: 'domains',
      icon: 'fa-microsoft',
      endpoint: '/api/cloud/domains',
      endpoints: {
      },
      preHooks: {
      },
      tag: 'paedml-card-domain',
      category: 'Microsoft-365',
      menuTitle: 'Externe Domäne',
      pageTitle: 'paedML - Externe Domäne',
      name: 'domain',
      contentTitle: 'Externe Domäne',
      prefix: 'clouddomain',
      suffix: undefined,
      custom: {
        variant: 'clouddomain'
      },
      table: {
        columns: [
          { title: 'Externe Domäne', name: 'domain', col: 0 },
          { title: 'Aufgelöst', name: 'resolved', col: 1 }
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10
        }
      }
    },

    {
      route: 'lehrerinnen',
      icon: 'fa-microsoft',
      endpoint: '/api/cloud/teachers',
      endpoints: {
      },
      tag: 'paedml-card-cloud',
      category: 'Microsoft-365',
      menuTitle: 'Lehrer*innen',
      pageTitle: 'paedML - Microsoft-365-Lehrer*innen',
      name: 'cloudlul',
      contentTitle: 'Lehrer*innen',
      prefix: 'cloudlul',
      suffix: undefined,
      custom: {
        variant: 'teachers'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Name', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Basis-Schulart', name: 'mainSchoolType', col: 4 },
          { title: 'Schulart(en)', name: 'schoolTypes', separator: ', ', col: 5, filter: 'Schulart', filterType: 'regex' },
          { title: 'MS 365 Domain', name: 'domain', col: 6 },
          { title: 'MS 365 aktiv', name: 'isO365Enabled', col: 7, filter: 'JaNein', filtertitle:"MS 365 aktiv", filterType: 'smart' },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'schuelerinnen',
      icon: 'fa-microsoft',
      endpoint: '/api/cloud/students',
      endpoints: {
      },
      preHooks: {
        list: 'cloud_schueler_list_preHook'
      },
      tag: 'paedml-card-cloud',
      category: 'Microsoft-365',
      menuTitle: 'Schüler*innen',
      pageTitle: 'paedML - Cloud-Schüler*innen',
      name: 'cloudsus',
      contentTitle: 'Schüler*innen',
      prefix: 'cloudsus',
      suffix: undefined,
      custom: {
        variant: 'students'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Name', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart', name: 'mainSchoolType', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 5, filter: 'Klasse', dynamicfilter: 'klassen', filterType:'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 6, searchable: false },
          { title: 'MS 365 Domain', name: 'domain', col: 7 },
          { title: 'MS 365 aktiv', name: 'isO365Enabled', col: 8, filter: 'JaNein', filtertitle:"MS 365 aktiv" },
        ],
        data: {
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

  ]
}