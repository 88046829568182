<paedml-modal-handout>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true}
                >

    <yield to="fields">

      <virtual if="{parent.variant !== 'exam'}">
        <paedml-form-input name="name" label="Name"
          required data-msg="Bitte geben Sie den Namen ein"
          data-rule-maxlength="30" data-msg-maxlength="Der Name darf nicht länger als 30 Zeichen sein"
          data-rule-project data-msg-project="Erlaubt sind: Buchstaben, Ziffern, Umlaute. Optional kann &quot;_&quot; verwendet werden, jedoch nicht am Anfang und Ende."
        />
        
        <paedml-form-input name="redirect" placeholder="Nach dem Austeilen zu 'meinen ausgeteilten Dateien' springen" fgclass="py-1"
          type="checkbox" revlut="jaNein"
        />
      </virtual>

      <virtual if="{parent.variant === 'exam' || parent.variant === 'projekte'}">
        <div class="row">
          <div class="m-2 p-2">
            <span class="pl-1 pt-2">Die Dateien in dem Ordner '<strong class="text-primary">_austeilen</strong>' werden an {parent.text} ausgeteilt.</span>
          </div>
        </div>
      </virtual>

    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'

    this.prefix = opts.prefix
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient
    this.variant = opts.variant
    
    this.on('dataRequested', (selectedRows) => {
      this.data.redirect = true
      this.text = ''

      if (this.variant === 'exam') {
        const anzTeilnehmer = selectedRows[0][13].length
        this.text = `${anzTeilnehmer} Teilnehmer`
      }
      else if (this.variant === 'projekte') {
        const anzTeilnehmer = selectedRows[0][11]
        this.text = `${anzTeilnehmer} Mitglieder`
      }
    })

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')
      
      const reqData = {
        name: formData.name,
        members: formData.ids
      }
     
      if (this.variant === 'exam') {
        return API[this.variant].share(formData.ids[0]).then(rsp => {
          const [data, status] = rsp
          if (status.success) {
              this.parent.trigger('reloadTable')
              this.parent.parent.opts.siblings.filter(card => card.config.name === 'handout').forEach(card => {
                card.tags['paedml-card'].trigger('reloadTable')
              })
              
              return `Dateien erfolgreich ausgeteilt.`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Austeilen fehlgeschlagen. Grund: ${reason}`)
        })
      }

      if (this.variant === 'projekte') {
        return API[this.variant].share(formData.ids[0], reqData).then(rsp => {
          const [data, status] = rsp
          if (status.success) {
              this.parent.trigger('reloadTable')
              this.parent.parent.opts.siblings.filter(card => card.config.name === 'handout').forEach(card => {
                card.tags['paedml-card'].trigger('reloadTable')
              })
              
              if (this.data.redirect) setTimeout(() => { window.location.href = '/#meine/ausgeteilte-dateien' }, 500)
              return `Dateien erfolgreich ausgeteilt.`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Austeilen fehlgeschlagen. Grund: ${reason}`)
        })
      }

      const op = this.variant === 'klasse' ? () => API.handout.shareToClass(formData.ids[0], reqData.name) : () => API.handout.create(reqData)

      return op().then(rsp => {
        const [data, status] = rsp
          if (status.success) {
            this.parent.trigger('reloadTable')
            this.parent.parent.opts.siblings.filter(card => card.config.name === 'handout').forEach(card => {
              card.tags['paedml-card'].trigger('reloadTable')
            })

            if (this.data.redirect) setTimeout(() => { window.location.href = '/#meine/ausgeteilte-dateien' }, 500)
            return `Dateien erfolgreich ausgeteilt`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Austeilen fehlgeschlagen. Grund: ${reason}`)
      })
    }

  </script>
</paedml-modal-handout>
