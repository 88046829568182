import './paedml-modal-contact.tag'
import './paedml-modal-impressum.tag'

<paedml-footer>
  <footer class="sub-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col text-left mb-auto" style="margin-top: -15px;">
          <a href="https://www.lmz-bw.de" target="_blank" style="padding-bottom: 2px;">
            <img src="img/lmz.png" alt="Logo LMZ" height="30" width="73" style="vertical-align: unset;">
            <div class="d-none d-md-inline" style="line-height: 0.8rem;">
              <span class="paedml-nav-text-orange p-0" style="font-size: 0.7rem;">LANDESMEDIENZENTRUM<br/>BADEN-WÜRTTEMBERG</span>
            </div>
          </a>
          <p style="color: gray; font-size: 0.8rem; margin-bottom: 0;">&copy; 2018-{currentYear} | Version {version}</p>
        </div>
        <div class="col-sm-4 col-md-3 col-lg-2 text-right" style="font-size: 0.9rem; margin-top: -3px;">
          <span data-target="#paedml-contact-modal" class="btn paedml text-primary" data-toggle="modal">Kontakt</span><br/>
          <span data-target="#paedml-impressum-modal" class="btn paedml text-primary" data-toggle="modal">Impressum</span>
          <paedml-modal-contact prefix="paedml-contact" />
          <paedml-modal-impressum prefix="paedml-impressum" />
        </div>
      </div>
    </div>
  </footer>
  
  <script>
    this.on('mount', () => {
      this.version = $('meta[name=version]').attr('content')
      this.backendVersion = $('meta[name=bev]').attr('content')
      let date = new Date();
      this.currentYear = date.getFullYear();
      this.update()
      // TODO: remove inline styles
    })
  </script>
</paedml-footer>
