<paedml-modal-fix-perms>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname='Korrekturen durchführen'
                selectedrowsfunc={opts.selectedrowsfunc}
                submitfunc={submitHandler} patient={patient}
                idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                cancelbutton={true} ref="modal"
                okcondition={checkDeleteHomeDirs}
                >

    <yield to="fields">
      <!--  <div class="custom-control custom-radio">
        <input type="radio" id="{prefix}-fix-perm" name="cleanup-type" class="custom-control-input" onclick={parent.handleSelection} >
        <label class="custom-control-label font-weight-bold" for="{prefix}-fix-perm">Ordner-Berechtigungen korrigieren</label>
        <div class="p-2" show="{parent.data && parent.data.cleanupType === 'fix-perm'}">
          Die Rechte auf die Ordner und Dateien des H:\-Laufwerks der ausgewählten Benutzer werden nach den Vorgaben der paedML Windows&trade; korrigiert.
        </div>
      </div>
      <div class="p-2"></div>  -->
      <div class="custom-control custom-radio">
        <input type="radio" id="{prefix}-cleanup-home" name="cleanup-type" class="custom-control-input" onclick={parent.handleSelection}>
        <label class="custom-control-label text-danger font-weight-bold" for="{prefix}-cleanup-home">Homeverzeichnisse leeren</label>
        <div class="col text-danger" show="{parent.data && parent.data.cleanupType === 'cleanup-home'}">
          Ich möchte alle Dateien und Ordner im Homeverzeichnis löschen.
          <paedml-form-input name="deleteHomeDirsOK" placeholder="Ich möchte Homeverzeichnisse leeren"
            type="checkbox" revlut="jaNein" handlechange={parent.updateDeleteHomeDirsOK}
            />
        </div>
      </div>
      <div class="p-2"></div>
        <div class="custom-control custom-radio">
        <input type="radio" id="{prefix}-move-long" name="cleanup-type" class="custom-control-input" onclick={parent.handleSelection}>
        <label class="custom-control-label text-danger font-weight-bold" for="{prefix}-move-long">Lange Dateinamen korrigieren</label>
        <div class="col text-danger" show="{parent.data && parent.data.cleanupType === 'move-long'}">
            Alle Dateien mit langen Namen werden aus dem Homeverzeichnis nach D:\ZuLang auf dem Server SP01 verschoben.
          </br></br>
            Auf diesen Ordner haben nur Systemadministratoren Zugriff.
          <paedml-form-input name="moveLongDirsOK" placeholder="Ich möchte die Dateien verschieben"
            type="checkbox" revlut="jaNein" handlechange={parent.updateMoveLongDirsOK}
          />
        </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatterFuture, multiSubmitHandler} from '../util/modal-util'

    this.prefix = opts.prefix
    this.api = this.opts.api
    this.data = {
        cleanupType: 'cleanup-home',
        deleteHomeDirsOK: false,
        moveLongDirsOK: false
      }

    const patientSupplier = () => {
      switch(this.data.cleanupType)
      {
        case 'cleanup-home':
          return {singular: 'Bereinigung des Homeverzeichnisses', plural: 'Bereinigung der Homeverzeichnisse', for: 'für'};
        case 'move-long':
          return {singular: 'Dateien verschieben', plural: 'Dateien verschieben', for: 'für'};
       // case 'fix-perm':
       //   return {singular: 'Korrektur des Homeverzeichnisses', plural: 'Korrektur der Homeverzeichnisse', for: 'für'};  
      }
    }
    
    const opFuncSupplier = () =>{
      switch(this.data.cleanupType)
      {
        case 'cleanup-home':
          return this.api.cleanupFolder;
        case 'move-long':
          return this.api.moveLongFiles;
       // case 'fix-perm':
       //   return this.api.fixPermissions;  
      }
    }

    this.patient = {singular: 'Benutzerkonto', plural: 'Benutzerkonten'}

    this.checkDeleteHomeDirs = () => {
      switch(this.data.cleanupType)
      {
        case 'cleanup-home':
          return this.data.deleteHomeDirsOK;
        case 'move-long':
          return this.data.moveLongDirsOK;
      //  case 'fix-perm':
     //     return true;  
      }
    } 

    this.updateDeleteHomeDirsOK = (val) => {
      this.data.deleteHomeDirsOK = val;
    }

    this.updateMoveLongDirsOK = (val) => {
      this.data.moveLongDirsOK = val;
    }

    this.updateModalButton = () => {
      let textMsg = 'Korrekturen durchführen';
      let className = 'primary';
      switch(this.data.cleanupType)
      {
        case 'cleanup-home':
          textMsg = "Bereinigungen durchführen"
          className = "danger"
          break;
        case 'move-long':
          textMsg = "Dateien verschieben"
          className = "danger"
          break;
      //  case 'fix-perm':
      //    textMsg = "Korrekturen durchführen"
       //   className = "primary"
       //   break;
      }
      this.refs.modal.trigger('updateButton', textMsg, className)
    }

    this.handleSelection = () => {
      const selected = $(`input[name='cleanup-type']:checked`).attr('id')
      switch(selected)
      {
        case `${this.prefix}-cleanup-home`:
          this.data.cleanupType = 'cleanup-home'; 
          break;
        case `${this.prefix}-move-long`:
          this.data.cleanupType = 'move-long'; 
          break;
      //  case `${this.prefix}-fix-perm`:
      //    this.data.cleanupType = 'fix-perm'; 
       //   break;  
      }
      this.updateModalButton()
    }

    this.on('dataRequested', (selectedRows) => {
      this.data = {
        cleanupType: 'cleanup-home',
        deleteHomeDirsOK: false,
        moveLongDirsOK: false
      }
    })

    this.on('validate', () => {

      if (this.data.cleanupType === 'cleanup-home') {
        $(`#${this.prefix}-cleanup-home`).prop('checked', true)
      }

      this.updateModalButton()
    })

    const formatter = patientFormatterFuture(patientSupplier, 'durchgeführt')

    this.submitHandler = multiSubmitHandler(this, opFuncSupplier, formatter)

  </script>
</paedml-modal-fix-perms>
