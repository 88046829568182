import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-createoredit-schularten>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="name" col="1" label="Schulartkürzel"
              readonly={parent.edit}
              required data-msg="Bitte geben Sie eine Schulartkürzel ein"
              data-rule-alphanum data-msg-alphanum="Bitte geben Sie eine gültige Schulartkürzel ein"
              data-rule-sessionmap="schularten" data-msg-sessionmap="Bereits vorhanden!"
              data-rule-maxlength="5" data-msg-maxlength="Das Schulartkürzel darf nicht mehr als 5 Zeichen enthalten!"
            />
          </div>
          <div class="col-sm-8">
            <paedml-form-input name="description" col="2" label="Schulartname"
              required data-msg="Bitte geben Sie einen Schulartnamen ein"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="currentSchoolYear" col="4" label="aktuelles Schuljahr"
              inputid="{prefix}-modal-input-currentSchoolYear"
              readonly={edit}
              required data-msg="Bitte geben Sie das aktuelle Schuljahr ein"
              data-rule-schuljahr data-msg-schuljahr="Bitte geben Sie ein gültiges Schuljahr ein"
              data-rule-lessThan="#{prefix}-modal-input-nextSchoolYear" data-msg-lessThan="Das aktuelle Schuljahr muss kleiner sein als das Kommende"
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="nextSchoolYear" col="3" label="neues Schuljahr"
              inputid="{prefix}-modal-input-nextSchoolYear"
              readonly={parent.data.isRelocationModeActive}
              required data-msg="Bitte geben Sie das kommende Schuljahr ein"
              data-rule-schuljahr data-msg-schuljahr="Bitte geben Sie ein gültiges Schuljahr ein"
              data-rule-greaterThan="#{prefix}-modal-input-currentSchoolYear" data-msg-greaterThan="Das kommende Schuljahr muss grösser sein als das Aktuelle"
            />
          </div>
          <div class="col-sm-4" if={edit}>
            <paedml-form-input name="isRelocationModeActive" col="18" label="Versetzungsmodus"
              fgclass="py-1"
              type="checkbox" revlut="jaNein"
              handlechange={parent.handleReolocationMode}
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group" show={parent.firewallType === 'OCTO'}>
        <div class="pb-1">
          Firewall:
        </div>
        <div class="row">
          <div class="col-sm-12 py-2">
            <paedml-form-input name="teacherWhitelistAccessEnabled" placeholder="Lehrkräfte dürfen Klassen-Whitelist bearbeiten"
              type="checkbox" revlut="jaNein" col="27"
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="pb-1">
          Lehrer:
        </div>
        <div class="row">
          <div class="col-sm-2 pr-0">
            <paedml-form-input name="teacherUsernamePrefix" col="19" label="Präfix"
              data-rule-prefix data-msg-prefix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang."
              maxlength="5" data-rule-maxlength="4" data-msg-maxlength="Das Präfix darf nicht mehr als 4 Zeichen enthalten!" 
            />
          </div>
          <div class="col-sm-4 px-0">
            <paedml-form-input name="teacherUsernamePolicy" col="6" label="Schema"
              required data-msg="Bitte geben Sie das Schema für Lehrer-Benutzerkonten ein"
              data-rule-schema data-msg-schema="Erlaubt sind: n, N, v, V. Optional kann ein &quot;.&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende."
            />
          </div>
          <div class="col-sm-2 pl-0">
            <paedml-form-input name="teacherUsernameSuffix" col="20" label="Suffix"
              data-rule-suffix data-msg-suffix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Ende."
              maxlength="5" data-rule-maxlength="4" data-msg-maxlength="Das Suffix darf nicht mehr als 4 Zeichen enthalten!" 
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="teacherDefaultPassword" col="7" label="Basiskennwort"
              required data-msg="Bitte geben Sie das Basiskennwort für Lehrer-Benutzerkonten ein"
              data-rule-minlength="{parent.minPwLengthTeacher()}" data-msg-minlength="Das Basiskennwort muss mindestens {parent.minPwLengthTeacher()} Zeichen haben!"
              data-rule-maxlength="64" data-msg-maxlength="Das Basiskennwort darf nicht mehr als 64 Zeichen enthalten!"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="teacherPasswordLength" col="8" label="Mindestkennwortlänge"
              required data-msg="Bitte geben Sie die Mindestkennwortlänge für die Lehrer-Benutzerkonten ein"
              data-rule-min="{parent.minPwLengthTeacher()}" data-msg-min="Mindestkennwortlänge darf nicht kleiner sein als {parent.minPwLengthTeacher()}"
              data-rule-max="64" data-msg-max="Mindestkennwortlänge darf nicht grösser sein als 64"
            />
          </div>
          <div class="col-sm-8">
            <paedml-form-input type="select" name="teacherDefaultPasswordPolicy" col="9" label="Kennwortoption"
              required values={parent.pwPolicyValues}
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 py-2">
            <paedml-form-input name="teacherEmailEnabled" col="10" placeholder="Email erstellen"
              type="checkbox" revlut="jaNein"
            />
          </div>
          <div class="col-sm-8">
            <paedml-form-input name="teacherEmailDomain" col="11" placeholder="Lehrer-Email-Domäne" prepend="@"
              show={parent.data.teacherEmailEnabled} required data-msg="Bitte geben Sie die Lehrer-Email-Domäne ein"
            />
          </div>
        </div>
        <div class="row" if={parent.moodleEnabled}>
          <div class="col-sm-4 py-2">
            <paedml-form-input name="teacherMoodleEnabled" col="23" placeholder="Moodle aktivieren"
              type="checkbox" revlut="jaNein"
            />
          </div>
          <div class="col-sm-8">
            <paedml-form-input name="teacherMoodleDomain" col="24" placeholder="Lehrer-Moodle-Domäne"
              type="select" values={parent.domains}
              show={parent.data.teacherMoodleEnabled} required data-msg="Bitte geben Sie die Moodle-Email-Domäne ein"
            />
          </div>
		    </div>
      </div>
      
      <div class="paedml-input-group">
        <div class="pb-1">
          Schüler:
        </div>
        <div class="row">
          <div class="col-sm-2 pr-0">
            <paedml-form-input name="studentUsernamePrefix" col="21" label="Präfix"
              data-rule-prefix data-msg-prefix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang."
              maxlength="5" data-rule-maxlength="4" data-msg-maxlength="Das Präfix darf nicht mehr als 4 Zeichen enthalten!" 
            />
          </div>
          <div class="col-sm-4 px-0">
            <paedml-form-input name="studentUsernamePolicy" col="12" label="Schema"
              required data-msg="Bitte geben das Sie Schema für die Schüler-Benutzerkonten ein"
              data-rule-schema data-msg-schema="Erlaubt sind: n, N, v, V. Optional kann ein &quot;.&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende."
            />
          </div>
          <div class="col-sm-2 pl-0">
            <paedml-form-input name="studentUsernameSuffix" col="22" label="Suffix"
              data-rule-suffix data-msg-suffix="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Ende."
              maxlength="5" data-rule-maxlength="4" data-msg-maxlength="Das Suffix darf nicht mehr als 4 Zeichen enthalten!" 
            />
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="studentDefaultPassword" col="13" label="Basiskennwort"
              required data-msg="Bitte geben Sie das Basiskennwort für Schüler-Benutzerkonten ein"
              data-rule-minlength="{parent.minPwLengthStudent()}" data-msg-minlength="Das Basiskennwort muss mindestens {parent.minPwLengthStudent()} Zeichen haben!"
              data-rule-maxlength="64" data-msg-maxlength="Das Basiskennwort darf nicht mehr als 64 Zeichen enthalten!"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <paedml-form-input name="studentPasswordLength" col="14" label="Mindestkennwortlänge"
              required data-msg="Bitte geben Sie die Mindestkennwortlänge für die Schüler-Benutzerkonten ein"
              data-rule-min="{parent.minPwLengthStudent()}" data-msg-min="Mindestkennwortlänge darf nicht kleiner sein als {parent.minPwLengthStudent()}"
              data-rule-max="64" data-msg-max="Mindestkennwortlänge darf nicht grösser sein als 64"
            />
          </div>
          <div class="col-sm-8">
              <paedml-form-input type="select" name="studentDefaultPasswordPolicy" col="15" label="Kennwortoption"
                required values={parent.pwPolicyValues}
              />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 py-2">
            <paedml-form-input name="studentMailEnabled" col="16" placeholder="Email erstellen"
              type="checkbox" revlut="jaNein"
            />
          </div>
          <div class="col-sm-8">
            <paedml-form-input name="studentMailDomain" col="17" placeholder="Schüler-Email-Domäne" prepend="@"
              show={parent.data.studentMailEnabled} required data-msg="Bitte geben Sie die Schüler-Email-Domäne ein"
            />
          </div>
        </div>
        <div class="row" if={parent.moodleEnabled}>
          <div class="col-sm-4 py-2">
            <paedml-form-input name="studentMoodleEnabled" col="25" placeholder="Moodle aktivieren"
              type="checkbox" revlut="jaNein"
            />
          </div>
          <div class="col-sm-8">
            <paedml-form-input name="studentMoodleDomain" col="26" type="select" values={parent.domains}
              show={parent.data.studentMoodleEnabled} required data-msg="Bitte geben Sie die Moodle-Email-Domäne ein"
            />
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import { autoMapRowToData } from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import { usingExternalConfig } from '../util/config-util'
      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.pwPolicyValues = {
          1: 'Kennwort läuft nie ab',
          2: 'Kennwortänderung erzwingen',
          3: 'Kennwort nicht änderbar'
      }
      this.firewallValues = Masterdata.firewallMap

      this.moodleEnabled = Session.feSettings.extensions.enableMoodle;
      this.firewallType = Session.settings[0].firewallType;

      this.minPwLengthTeacher = () => usingExternalConfig("basis","minPwLengthTeacher", 4)
      this.minPwLengthStudent = () => usingExternalConfig("basis","minPwLengthStudent", 4)

      const convertingBooleans = R.mapObjIndexed(v => {
          if (v === 'true' || v === 'True') return true
          if (v === 'false' || v === 'False') return false
          return v
      })

      this.on('dataRequested', () => {
        this.data = convertingBooleans(Session.settings[0])
        this.getDomains();
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
      })

      this.getDomains = () => {
        API.moodle.domain.getAll().then(rsp => {
          const [data, status] = rsp
          this.domains = data.map(domain => [domain.domain, domain.domain])
          this.update()

          $('select[name=teacherMoodleDomain]').val(this.data.teacherMoodleDomain)
          $('select[name=studentMoodleDomain]').val(this.data.studentMoodleDomain)
        })
      }

      this.submitHandler = formData => {
        const settingsKeyLut = R.mapObjIndexed(()=>true, Session.settings[0])
        const submitData = {
          settings: {}
        }

        R.forEachObjIndexed((v,k) => {
          if (settingsKeyLut[k]) {
            submitData.settings[k] = v
          } else {
            submitData[k] = v
          }
        }, formData)

        if (this.edit) {
          return this.api.update(formData.ids[0], R.omit(['ids', 'idTexts'], submitData))
            .then(rsp => {
              const [data, status] = rsp
              if (status.success) {
                Session.settings[formData.ids[0]].isRelocationModeActive = formData.isRelocationModeActive
                this.parent.trigger('reloadTable')
                window.request.relogin()
                return this.opname + ' erfolgreich durchgeführt'
              }

              console.error('error-status', status)
              console.error('error-data', data)
              if (data.message) {
                return Promise.reject(data.message)
              }
              return Promise.reject('Fehler bei ' + this.opname + '!')
            })
        }

        return this.api.create(submitData).then(rsp => {
          const [data, status] = rsp
          if (status.success) {
            this.parent.trigger('reloadTable')
            window.request.relogin()
            return `Schulart "${data.name}" erfolgreich angelegt`
          }

          console.error('error-data', data)
          console.error('error-status', status)
          const reason = data && data.message ? data.message : 'unbekannt'
          return Promise.reject(`Die Schulart "${formData.name}" konnte nicht angelegt werden. Grund: ${reason}`)
        })
      }
    </script>
</paedml-modal-createoredit-schularten>
