<paedml-modal-move-devices>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 0}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true} okcondition="{() => !this.loading}"
                >

    <yield to="fields">

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
          <p class="my-auto">Verfügbare Räume werden aktualisiert...</p>
          <div class="sk-wave">
            <div class="sk-rect sk-rect1"></div>
            <div class="sk-rect sk-rect2"></div>
            <div class="sk-rect sk-rect3"></div>
            <div class="sk-rect sk-rect4"></div>
            <div class="sk-rect sk-rect5"></div>
          </div>
        </div>
    
      <div class="row" if={!parent.loading}>
        <div class="col-sm-11">
          <paedml-form-input name="roomId" label="Ziel-Raum"
              type="select" values={parent.getTargetRooms()}
              required data-msg="Bitte den Ziel-Raum aus"
            />
        </div>
        <div class="col-sm-1 my-auto">
          <button class="btn btn-sm" onclick="{() => parent.refreshRooms(true)}" style="margin-left: -18px; margin-top: 18px;">
            <paedml-icon icon="aktualisieren" inv={true} title="Raumliste aktualisieren" />
          </button>
        </div>
      </div>

    </div>
  
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'

    this.prefix = opts.prefix
    this.data = { }
    this.api = this.opts.api
    this.variant = opts.variant
    this.patient = opts.variant === 'computer' ? {singular: 'Computer', plural: 'Computer'} : {singular: 'Drucker', plural: 'Drucker'}
    
    this.on('update', () => {
      this.loading = false
    })

    this.getTargetRooms = () => this.data.availableTargetRooms || []

    this.refreshRooms = sync => {
      this.loading = true
      const op = sync ? 'sync' : 'list'
      API.raum[op]().then(rsp => {
        const [rooms, status] = rsp
        // console.debug(rooms)
        if (sync) {
          this.data.availableTargetRooms = rooms.map(e => [e.id, e.name])
        } else {
          this.data.availableTargetRooms = rooms.map(e => [e[0], e[1]])
        }
        this.loading = false
        // console.debug(this.data)
        this.update()
      })

    }
    this.on('dataRequested', () => {
      this.refreshRooms(false)
    })

    this.submitHandler = formData => {
      // console.debug('move devices', formData)
      // return Promise.reject('implementing')

      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const deleteMessageSuccess = formatter(idLut, true)
      const deleteMessageFailure = formatter(idLut, false)

      const op = opts.variant === 'computer' ? 'computer' : 'drucker'
      return API[op].move(formData.ids, formData.roomId).then(rsp => {
        const [deletedIds, status] = rsp
        if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server')

        const {succeeded, failed} = deletedIds
        if (failed.length) {
          if (succeeded.length) {
            // partly success
            const sLut = toTrueMap(R.map(id => idLut[id], succeeded))
            // remove deleted entries
            $('span.tag', this.refs.tip).each(function(i,e) { 
              if (sLut[$(e).text()]) $('span', e).click()
            })

            this.parent.trigger('reloadTable')
            // if (this.opts.relogin) window.request.relogin()
            return Promise.reject([deleteMessageSuccess(succeeded), deleteMessageFailure(failed)])
          }
          return Promise.reject(deleteMessageFailure(failed))
        }

        this.parent.trigger('reloadTable')
        // if (this.opts.relogin) window.request.relogin()
        return deleteMessageSuccess(succeeded)
      })

    }

    const formatter = patientFormatter(this.patient, 'verschoben')

  </script>
</paedml-modal-move-devices>
